import {
  mapRecommendedNewbuildingToRecommendation,
  IRecommendationNewbuildingWithContact,
} from '../../mappers/requestCallback';
import { INewbuildingRecommendation } from '../../types/microfrontends/newbuildingCallbackWidget';
import { IRecommendationNewbuilding } from '../../types/recommendations';
import { IApplicationState } from '../../types/redux';
import { getAgentForCallback } from '../newbuilding/agent';
import { selectRecommendations } from '../newbuildingRecommendations';

export const isValidRecommendedNewbuilding = (
  newbuilding: IRecommendationNewbuilding,
): newbuilding is IRecommendationNewbuildingWithContact => Boolean(newbuilding.contact && newbuilding.contact.phone);

export const getCallbackRecommendations = (state: IApplicationState): INewbuildingRecommendation[] => {
  const agentForCallback = getAgentForCallback(state);
  const recommendations = selectRecommendations(state);

  if (recommendations.length === 0 || !agentForCallback) {
    return [];
  }

  const recommendationsByBuilder = recommendations.reduce<Map<number, INewbuildingRecommendation>>((acc, item) => {
    if (isValidRecommendedNewbuilding(item)) {
      const builderRealtyId = item.contact.realtyUserId;

      if (!acc.has(builderRealtyId)) {
        const recommendation: INewbuildingRecommendation = mapRecommendedNewbuildingToRecommendation(item);

        acc.set(builderRealtyId, recommendation);
      }
    }

    return acc;
  }, new Map());

  recommendationsByBuilder.delete(agentForCallback.realtyUserId);

  return Array.from(recommendationsByBuilder.values()).slice(0, 3);
};
