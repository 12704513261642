import { IApplicationState } from '../../types/redux';
import { IBreadcrumb } from '../../types/newbuilding/breadcrumb';

export function getBreadCrumbs(state: IApplicationState): IBreadcrumb[] {
  const {
    newbuilding: { breadcrumbs },
  } = state;

  if (breadcrumbs && breadcrumbs.length) {
    return breadcrumbs;
  }

  return [];
}
