import { isCorrectPhoneObject } from './isCorrectPhoneObject';
import { IPhone } from '../../../types/newbuilding/builder';

export function phoneToString(phone?: IPhone | null) {
  if (phone && isCorrectPhoneObject(phone)) {
    return `${phone.countryCode}${phone.code}${phone.number}`;
  }

  return '';
}
