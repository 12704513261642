import * as React from 'react';
import { useSelector } from 'react-redux';

import { RecommendedNewbuildings } from '../../components/RecommendedNewbuildings';
import { selectCompilations, selectRecommendations } from '../../selectors/newbuildingRecommendations';
import { LayoutBlock } from '../../components/LayoutBlock';

import { CompilationsListContainer } from './parts/CompilationsListContainer';
import { RecommendationsListContainer } from './parts/RecommendationsListContainer';
import { RecommendationsLinkContainer } from './parts/RecommendationsLinkContainer';

export const RecommendedNewbuildingsContainer: React.FC = () => {
  const newbuildings = useSelector(selectRecommendations);
  const compilations = useSelector(selectCompilations);
  const isCompilationsAvailable = compilations.length > 0;

  if (newbuildings.length === 0) {
    return null;
  }

  return (
    <LayoutBlock>
      <RecommendedNewbuildings
        headerAside={<RecommendationsLinkContainer />}
        recommendations={<RecommendationsListContainer newbuildings={newbuildings} />}
        compilations={isCompilationsAvailable ? <CompilationsListContainer compilations={compilations} /> : null}
      />
    </LayoutBlock>
  );
};
