import { pathOr } from 'ramda';
import { MORTGAGE_PROMO_TYPES } from '../../constants/promos';
import { IPromoInfoSchema } from '../../types/promo';
import { IApplicationState } from '../../types/redux';

export const selectNewbuildingPromos = (state: IApplicationState): IPromoInfoSchema[] => {
  const promos: IPromoInfoSchema[] = pathOr([], ['newbuilding', 'promoInfos'], state);

  return promos.filter(promo => !MORTGAGE_PROMO_TYPES.includes(promo.promoType));
};
