import * as React from 'react';
import { LinkButton, UIHeading4, UIText2 } from '@cian/ui-kit';
import clsx from 'clsx';

import * as styles from './OffersKPNRow.css';

export interface IOffersKPNRowProps {
  onClick?(): void;
  title: React.ReactNode;
  url: string;
  withMarginTop?: boolean;
}

export const OffersKPNRow: React.FC<IOffersKPNRowProps> = ({ onClick, title, url, withMarginTop = false }) => {
  return (
    <div className={clsx(styles['container'], withMarginTop && styles['container-margin-top'])}>
      <div className={styles['image']} />
      <div>
        <div className={styles['heading']}>
          <UIHeading4>{title}</UIHeading4>
        </div>
        <UIText2 color="gray60_100">Составим персональную подборку по вашим параметрам</UIText2>
      </div>
      <div className={styles['button']}>
        <LinkButton
          href={url}
          onClick={onClick}
          target="_blank"
          theme="fill_secondary"
          size="XS"
          data-testid="OffersKPNRowLink"
        >
          Подобрать
        </LinkButton>
      </div>
    </div>
  );
};
