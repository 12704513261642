import { connect } from 'react-redux';
import { BuilderItem, IBuilderItemProps } from '../../components/BuilderItem';
import { getFirstBuilder } from '../../selectors/newbuilding';
import { IApplicationState } from '../../types/redux';

type TBuildersStateProps = Pick<IBuilderItemProps, 'builder'>;

export function mapStateToProps(state: IApplicationState): TBuildersStateProps {
  return {
    // @ts-ignore: Поле обязательное, когда поправят свагер, перегенерить схемы и убрать
    builder: getFirstBuilder(state),
  };
}

export const BuilderContainer = connect(mapStateToProps)(BuilderItem);
