import { Image, UIText1 } from '@cian/ui-kit';
import classNames from 'clsx';
import * as React from 'react';

import * as styles from './PromosBanner.css';
import promoSvg from './assets/promo.svg';

export interface IPromosBannerProps {
  text: string;
  contactsButton: React.ReactElement;
}

export const PromosBanner = ({ contactsButton, text }: IPromosBannerProps) => {
  return (
    <div className={classNames(styles['banner'])} data-testid="promos-banner">
      <div className={styles['image-wrapper']}>
        <Image src={promoSvg} objectFit="contain" height="80px" display="block" />
      </div>
      <div className={styles['text']}>
        <UIText1>{text}</UIText1>
      </div>
      <div className={styles['call-button-wrapper']}>{contactsButton}</div>
    </div>
  );
};
