import * as React from 'react';
import { ISpecificationItem } from '../../types/newbuilding/specification';

import * as style from './style.css';

interface ISpecificationsSeoTableProps {
  specifications: ISpecificationItem[];
}

export const SpecificationsSeoTable = ({ specifications }: ISpecificationsSeoTableProps) => {
  return (
    <table className={style['container']}>
      <tbody>
        {specifications.map(
          ({ itemType, title, value }) =>
            value && (
              <tr key={itemType}>
                <td>{title}</td>
                <td>{value}</td>
              </tr>
            ),
        )}
      </tbody>
    </table>
  );
};
