import { ca } from '@cian/analytics';
import { IRecommendationNewbuilding } from '../../../types/recommendations';
import { mapRecommendationNewbuildingToProduct } from '../../../utils/analytics';

interface ITrackClickSimilarNewbuildingParams {
  index: number;
  modelVersion: string;
  newbuildingId: number;
  recommendation: IRecommendationNewbuilding;
}

export function trackClickSimilarNewbuilding({
  index,
  modelVersion,
  newbuildingId,
  recommendation,
}: ITrackClickSimilarNewbuildingParams) {
  ca('eventSite', {
    action: 'click_similar_JK',
    category: 'similar_objects',
    label: '',
    modelVersion,
    name: 'oldevent',
    page: {
      pageType: 'CardJK',
      siteType: 'desktop',
      extra: {
        PageTypeJK: 'Normal',
        newbuilding_from: newbuildingId,
      },
    },
    products: [mapRecommendationNewbuildingToProduct(recommendation, index)],
  });
}
