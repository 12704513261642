import * as React from 'react';

import { ISpecificationsDetailed } from '../../types/newbuilding/specificationsDetailed';
import { LayoutBlock } from '../LayoutBlock';
import { Section } from '../Section';
import { DetailedSpecifications } from './components/DetailedSpecifications';

export function SpecificationsDetailed({ shortInfrastructure, parking, security }: ISpecificationsDetailed) {
  if (!shortInfrastructure.length && !parking.length && !security.length) {
    return null;
  }

  return (
    <LayoutBlock>
      <Section title="Объекты на территории жилого комплекса">
        {shortInfrastructure.length > 0 && <DetailedSpecifications items={shortInfrastructure} />}
        {parking.length > 0 && <DetailedSpecifications title="Парковка" items={parking} />}
        {security.length > 0 && <DetailedSpecifications title="Безопасность" items={security} />}
      </Section>
    </LayoutBlock>
  );
}
