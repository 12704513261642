import { ITabData } from '../types';

export const FROM_TOP_TO_BLOCK_TITLE_OFFSET = 60;

export function getActiveTab(yOffset: number, clauses: ITabData[]) {
  const len = clauses.length;

  if (len < 1) {
    return null;
  }

  let lastHovered = 0;

  for (let i = 0; i < len; i += 1) {
    if (yOffset + FROM_TOP_TO_BLOCK_TITLE_OFFSET < clauses[i].point) {
      break;
    } else {
      lastHovered = Math.max(i, lastHovered);
    }
  }

  return clauses[lastHovered];
}
