import { ca } from '@cian/analytics';
import { getNewbuildingProduct } from '../../../utils/analytics';
import { EInteriorDecorationType } from '../../../types/newbuilding/decoration';

export const trackClickInteriorDecoration = (id: number, type: EInteriorDecorationType) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Finishing',
    action: 'click_sopr',
    label: 'jk',
    products: [
      getNewbuildingProduct({
        newbuildingId: id,
        extra: {
          finishing_type: type,
        },
      }),
    ],
  });
};
