import { IApplicationState } from '../../types/redux';
import { getBuilders } from './getBuilders';
import { getSellers } from './getSellers';

export function getIsCPL(state: IApplicationState): boolean {
  const builders = getBuilders(state);
  const sellers = getSellers(state);

  const contacts = [...builders, ...sellers].filter(contact => !!contact.phone);
  const isFromLeadFactory = sellers.some(contact => contact.isLeadFactory);

  return contacts.length > 0 && !isFromLeadFactory;
}
