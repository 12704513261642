import * as React from 'react';
import { ArticleHeading2 } from '@cian/ui-kit';

export interface ISectionTitleProps {
  children: React.ReactNode;
  dataMark?: string;
}

/***** COMPONENT *****/
export function SectionTitle(props: ISectionTitleProps) {
  const { children, dataMark = 'SectionTitle' } = props;

  return (
    <ArticleHeading2 as="h2" data-mark={dataMark}>
      {children}
    </ArticleHeading2>
  );
}
