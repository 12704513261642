import { IApplicationState } from '../../../types/redux';
import { TAgent } from '../../../types/agent';

export function getAgentForCallback(state: IApplicationState): TAgent | null {
  const {
    newbuilding: { fromDevelopersPropsCount, builders, sellers },
  } = state;

  const builderWithPhone = (builders || []).find(hasPhone);

  if (builderWithPhone && fromDevelopersPropsCount) {
    return builderWithPhone;
  }

  const consultantWithPhone = (sellers || []).find(seller => seller.isLeadFactory && hasPhone(seller));

  return consultantWithPhone || null;
}

function hasPhone(agent: TAgent) {
  return agent.phone && agent.phone.number;
}
