import * as React from 'react';
import { InlineText2 } from '@cian/ui-kit';

import * as styles from './tooltipContent.css';

export const TooltipContent = ({ description, onVisible }: { description: string; onVisible(): void }) => {
  React.useEffect(() => {
    const id = setTimeout(onVisible, 3000);

    return () => clearTimeout(id);
  }, []);

  return (
    <div className={styles['tooltip']}>
      <InlineText2>{description}</InlineText2>
    </div>
  );
};

TooltipContent.displayName = 'TooltipContent';
