import * as React from 'react';
import { UndergroundIcon } from './parts/UndergroundIcon';
import { FromUndergroundTime } from './parts/FromUndergroundTime';
import * as styles from './Underground.css';
import { ArticleParagraph2 } from '@cian/ui-kit';
import { IGeoUndergroundInfo } from '../../../../../../types/newbuilding/geo';

interface IUndergroundProps {
  underground?: IGeoUndergroundInfo[] | null;
  regionId: number | null;
}

export const Underground: React.FC<IUndergroundProps> = props => {
  const { underground, regionId } = props;

  if (!(underground && underground.length)) {
    return null;
  }

  return (
    <>
      {underground.map(undergroundItem => (
        <div key={`${undergroundItem.name}`} className={styles['underground']}>
          {/*Station*/}
          <div className={styles['underground-station']}>
            <div className={styles['underground-icon']}>
              <UndergroundIcon lineColor={undergroundItem.lineColor} regionId={regionId} />
            </div>
            <ArticleParagraph2>{`м. ${undergroundItem.name}`}</ArticleParagraph2>
          </div>
          {/*Distance*/}
          <div className={styles['underground-distance']}>
            <FromUndergroundTime timeTransport={undergroundItem.timeTransport} timeWalk={undergroundItem.timeWalk} />
          </div>
        </div>
      ))}
    </>
  );
};
