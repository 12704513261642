import { IApplicationState } from '../../types/redux';
import { IGeoUndergroundInfo } from '../../types/newbuilding/geo';

export function getUndergroundInfo(state: IApplicationState): IGeoUndergroundInfo[] | null {
  const {
    newbuilding: { geo },
  } = state;

  if (geo && geo.undergroundInfo && geo.undergroundInfo.length) {
    return geo.undergroundInfo;
  }

  return null;
}
