import { ca } from '@cian/analytics';

export function trackOffersNearbyClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'go_to_similar_on_map',
    label: '',
    useLastProducts: true,
  });
}
