import * as React from 'react';
import * as styles from './styles.css';

export interface IPhoneProps {
  phone: string;
  siteBlockId?: number | null;
}

export function SinglePhoneComponent(props: IPhoneProps) {
  return <div className={styles['phone']}>{props.phone}</div>;
}
