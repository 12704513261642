import * as React from 'react';
import { scrollToAnchor } from '@cian/newbuilding-utils';
import { useSelector } from 'react-redux';

import { getTransportAccessibility } from '../../selectors/newbuilding';
import { prepareRate } from '../../utils/transportAccessibility';
import { TransportAccessibilityEntry } from '../../components/TransportAccessibilityEntry';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { trackTransportAccessibilityEntryClick, trackTransportAccessibilityEntryShown } from './tracking';

export const TransportAccessibilityEntryContainer: React.FC = () => {
  const transportAccessibilityRate = useSelector(getTransportAccessibility);

  const handleClick: React.MouseEventHandler<HTMLElement> = React.useCallback(event => {
    document.dispatchEvent(new CustomEvent('map-services:open-transport-accessibility:v1'));

    scrollToAnchor({
      anchor: '#infrastructure',
      offset: 0,
      hasRelocation: false,
      hasAnimation: true,
    })(event);

    trackTransportAccessibilityEntryClick();
  }, []);

  if (!transportAccessibilityRate) {
    return null;
  }

  const preparedRate = prepareRate(transportAccessibilityRate.rate);

  return (
    <ActionAfterViewObserver callback={trackTransportAccessibilityEntryShown} triggerOnce>
      <TransportAccessibilityEntry rate={preparedRate} onClick={handleClick} />
    </ActionAfterViewObserver>
  );
};
