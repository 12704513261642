import { IApplicationState } from '../../types/redux';
import { hasValidPhone, isLeadFactory } from '../../utils/agent';
import { getBuilders } from './getBuilders';
import { getSellers } from './getSellers';

export const getHasContacts = (state: IApplicationState): boolean => {
  const builders = getBuilders(state);
  const sellers = getSellers(state);

  const buildersWithPhones = builders.filter(hasValidPhone);
  const sellersWithPhones = sellers.filter(hasValidPhone);
  const leadFactory = sellers.find(isLeadFactory);
  const contactsAmount = buildersWithPhones.length + sellersWithPhones.length;

  return Boolean(leadFactory || contactsAmount > 0);
};
