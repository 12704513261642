import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import { trackClickPromoBlock, trackPromoOffersShow, trackViewMorePromosClick } from '../../analytics/promos';
import { openPromoInfo } from '../../actions/promoInfo';
import { LayoutBlock } from '../../components/LayoutBlock';
import { Promos } from '../../components/Promos';
import { getNewbuildingDisplayName, getNewbuildingId } from '../../selectors/newbuilding';
import { selectNewbuildingPromos } from '../../selectors/newbuildingMortgageCalculator';
import { IPromoInfoSchema } from '../../types/promo';
import { PromosBanner } from './parts/PromosBanner';
import { PromoItemContainer } from './parts/PromoItemContainer';
import { ToggleButton } from './parts/ToggleButton';
import { SeoPromosLink } from '../SeoPromosLink';

export const PromosSectionContainer: React.FC = () => {
  const promos = useSelector(selectNewbuildingPromos);
  const newbuildingId = useSelector(getNewbuildingId);
  const title = useSelector(getNewbuildingDisplayName);
  const dispatch = useDispatch();

  const handlePromosExpand = React.useCallback((opened: boolean) => {
    if (opened) {
      trackViewMorePromosClick();
    }
  }, []);

  const openPromo = React.useCallback(
    (promo: IPromoInfoSchema) => {
      dispatch(openPromoInfo({ promo }));
      trackClickPromoBlock(promo, newbuildingId);
    },
    [dispatch, newbuildingId],
  );

  const onBlockShow = React.useCallback(() => {
    trackPromoOffersShow(promos, newbuildingId);
  }, [promos, newbuildingId]);

  return (
    <LayoutBlock>
      <ActionAfterViewObserver callback={onBlockShow} triggerOnce>
        <Promos
          banner={<PromosBanner />}
          seoLink={<SeoPromosLink />}
          title={`Акции в ${title}`}
          Button={ToggleButton}
          onChangeOpen={handlePromosExpand}
        >
          {promos.map((promo: IPromoInfoSchema) => (
            <PromoItemContainer key={promo.uniquePromoId} promo={promo} onClick={openPromo} />
          ))}
        </Promos>
      </ActionAfterViewObserver>
    </LayoutBlock>
  );
};
