import { Spinner } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './InfrastructureLoader.css';

export const InfrastructureLoader: React.FC = () => {
  return (
    <div className={styles['wrapper']} data-testid="Loading">
      <Spinner size={40} />
    </div>
  );
};
