import * as React from 'react';
import { useSelector } from 'react-redux';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import { LayoutBlock } from '../../components/LayoutBlock';
import { Reliability } from '../../components/Reliability';
import { ReliableBlock } from '../../components/ReliableBlock';
import { getNewbuildingId, getIsReliable, getHasProblems, getProblems } from '../../selectors/newbuilding';
import { ReliabilityHousesContainer } from './ReliabilityHousesContainer';
import { trackReliableMoreClick, trackReliableBlockShow } from '../../analytics/trackReliableJK';
import { ProblemsContent } from '../../components/ProblemsContent';
import { isReliabilityEnabledSelector } from '../../selectors/reliability/isReliabilityEnabledSelector';

export function ReliableBlockContainer() {
  const isReliable = useSelector(getIsReliable);
  const isReliabilityV2Enabled = useSelector(isReliabilityEnabledSelector);
  const hasProblems = useSelector(getHasProblems);
  const problems = useSelector(getProblems);
  const newbuildingId = useSelector(getNewbuildingId);

  const onReliableMoreLinkClick = React.useCallback(() => {
    trackReliableMoreClick(newbuildingId);
  }, [newbuildingId]);

  const onReliableBlockShow = React.useCallback(() => {
    trackReliableBlockShow();
  }, []);

  if ((!isReliable && !hasProblems) || isReliabilityV2Enabled) {
    return null;
  }

  return (
    <LayoutBlock>
      <ActionAfterViewObserver callback={onReliableBlockShow} triggerOnce>
        <ReliableBlock hasProblems={hasProblems}>
          {hasProblems ? (
            <ProblemsContent problems={problems} />
          ) : (
            <Reliability houses={<ReliabilityHousesContainer />} onReliableMoreLinkClick={onReliableMoreLinkClick} />
          )}
        </ReliableBlock>
      </ActionAfterViewObserver>
    </LayoutBlock>
  );
}
