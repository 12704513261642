import * as React from 'react';
import { TDirection } from '@cian/frontend-newbuilding-carousel-component';
import * as styles from './ArrowControls.css';
import { Arrow } from './parts/Arrow';

interface IArrowControlsProps {
  showLeft: boolean;
  showRight: boolean;
  clickHandler?(direction: TDirection): void;
}

export const ArrowControls: React.FC<IArrowControlsProps> = props => {
  const { showLeft, showRight, clickHandler } = props;

  return (
    <div className={styles['container']}>
      <div className={styles['wrapper']}>
        <Arrow direction={-1} clickHandler={clickHandler} show={showLeft} />
        <Arrow direction={1} clickHandler={clickHandler} show={showRight} />
      </div>
    </div>
  );
};

ArrowControls.displayName = 'ArrowControls';
