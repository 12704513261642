export function isWebGLSupported() {
  try {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const canvasWebglContext = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

    return Boolean(window.WebGLRenderingContext && canvasWebglContext);
  } catch (e) {
    return false;
  }
}
