import { MAX_TRANSPORT_ACCESSIBILITY_RATE } from '../../constants/transportAccessibility';

export const prepareRate = (rate: number): string => {
  if (rate >= MAX_TRANSPORT_ACCESSIBILITY_RATE) {
    return String(MAX_TRANSPORT_ACCESSIBILITY_RATE);
  }

  return rate.toLocaleString('ru-RU', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
    useGrouping: false,
  });
};
