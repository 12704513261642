import * as React from 'react';
import { TAdFoxBannerTemplate } from '@cian/adfox-component';
import { AdFoxTemplates } from '@cian/adfox-templates';

import { Base64 } from 'js-base64';

import { BuilderLinkTemplate, IBuilderLinkTemplateProps } from '../BuilderLinkTemplate';

const OWN_BUNDLE_NAMES = ['banner.transfer'];
const OWN_TYPES = ['link_banner'];

interface IUnknownTemplate {
  type: string;
}

export type TAdFoxTemplatesParams = IBuilderLinkTemplateProps;

export const OwnTemplates: React.FC<TAdFoxBannerTemplate> = props => {
  const { bundleName, bundleParams } = props;

  if (bundleName && OWN_BUNDLE_NAMES.includes(bundleName) && bundleParams) {
    if (bundleName === 'banner.transfer' && bundleParams.htmlBase64) {
      try {
        const decodedParams = Base64.decode(bundleParams.htmlBase64);
        const parsedDecodedParams = JSON.parse(decodedParams);

        if (isParsedParamsValid(parsedDecodedParams)) {
          if (OWN_TYPES.includes(parsedDecodedParams.type)) {
            switch (parsedDecodedParams.type) {
              case 'link_banner':
                return <BuilderLinkTemplate {...parsedDecodedParams} />;

              default:
                console.warn(
                  '[AdFox] Known type is not found',
                  (parsedDecodedParams as IUnknownTemplate).type,
                  bundleParams,
                );
            }
          }
        }
      } catch (e) {
        console.error('[AdFox] Failed to decode/parse bundleParams', bundleParams, e);
      }
    }
  }

  return <AdFoxTemplates {...props} />;
};

OwnTemplates.displayName = 'OwnTemplates';

function isParsedParamsValid(params: object): params is TAdFoxTemplatesParams {
  return 'type' in params;
}
