import * as React from 'react';
import { UIText2 } from '@cian/ui-kit';
import { IconCar16, IconWalk16 } from '@cian/ui-kit/icons';

import { TTransportType } from '../../../../types/transportType';
import * as styles from './FromUndergroundTime.css';

interface IFromUndergroundProps {
  transportType: TTransportType;
  timeTo: string;
}

export const FromUndergroundTime: React.FC<IFromUndergroundProps> = ({ transportType, timeTo }) => {
  const Icon = transportType === 'walk' ? IconWalk16 : IconCar16;

  return (
    <div className={styles['distance']}>
      <div className={styles['icon']}>
        <Icon color={'gray_icons_100'} />
      </div>
      <UIText2 color={'gray60_100'}>{timeTo}</UIText2>
    </div>
  );
};
