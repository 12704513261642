import { isEmpty } from 'ramda';

import { IPricesFiltersSchema } from '../../../repositories/newbuilding-estimate/entities/valuation_filters/PricesFiltersSchema';
import { ISalesFiltersSchema } from '../../../repositories/newbuilding-estimate/entities/valuation_filters/SalesFiltersSchema';
import { IPriceFiltersData, ISalesFiltersData } from '../../../types/realtyValuation';
import { IFilterProps } from '../../../components/RealtyValuation';

const mapFilterToLabel: Record<TFiltersKeys, string> = {
  roomCount: 'Количество комнат',
  period: 'Период',
  pricesType: 'Стоимость',
};

export function getPreparedFiltersProps<V extends TFiltersData, T extends TFiltersState>({
  filtersData,
  filtersState,
  onChange,
}: IParams<V, T>) {
  return Object.keys(filtersData.options).reduce((acc, filterName: keyof typeof filtersData.options) => {
    const filterOptions = filtersData.options[filterName];

    if (filterOptions && !isEmpty(filterOptions)) {
      acc.push({
        defaultValue: filtersState[filterName] || filtersData.defaultValues[filterName],
        options: filterOptions.map(({ value, name }) => ({ value, label: name })),
        onChange(_, filterValue) {
          onChange(filterName, filterValue);
        },
        label: mapFilterToLabel[filterName],
      });
    }

    return acc;
  }, [] as IFilterProps[]);
}

export type TFiltersData = IPriceFiltersData | ISalesFiltersData;
export type TFiltersState = IPricesFiltersSchema | ISalesFiltersSchema;
export type TFiltersKeys = keyof IPriceFiltersData['options'] | keyof ISalesFiltersData['options'];

interface IParams<V extends TFiltersData, T extends TFiltersState> {
  filtersData: V;
  filtersState: T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange(filterName: keyof V['defaultValues'], filterValue: any): void;
}
