import * as React from 'react';
import * as style from './Basic.css';
import { ArticleHeading1, IconArrowUpRight16, InlineText1 } from '@cian/ui-kit';
import { SalesLeader } from '../SalesLeader';
import { Link } from '../../../Link';
import { trackGoToDeveloperSite } from '../../../../analytics/trackGoToDeveloperSiteButtonClick';

export interface IBasicProps {
  /** Название для отображения **/
  name: string;
  /** Информация о сдаче ЖК **/
  specialStatusDisplay: string;
  /** ЖК находится в списке лидеров продаж **/
  isSalesLeader: boolean;
  /** Ссылка на сайт ЖК */
  website?: string | null;
  builderLink?: React.ReactNode;
}

export const Basic: React.FC<IBasicProps> = props => {
  const { name, specialStatusDisplay, isSalesLeader, website, builderLink } = props;

  const salesLeaderIcon = isSalesLeader ? <SalesLeader /> : null;

  return (
    <div className={style['basic']}>
      <div className={style['basic-content']}>
        <div className={style['basic-top']}>
          <ArticleHeading1 as={'h1'} color={'superblack_100'}>
            {name} {salesLeaderIcon}
          </ArticleHeading1>
          <div className={style['basic-section']}>
            {specialStatusDisplay && (
              <div className={style['basic-special-status']}>
                <InlineText1 color={'black_60'}>{specialStatusDisplay}</InlineText1>
              </div>
            )}
            {builderLink && <div className={style['developerSiteLink']}>{builderLink}</div>}
            {website && !builderLink && (
              <Link
                target="blank"
                linkStyle={style['developerSiteLink']}
                rel="nofollow noopener"
                url={website}
                onClick={() => trackGoToDeveloperSite(website)}
              >
                <InlineText1 color="primary_100">Перейти на сайт ЖК</InlineText1>
                <div className={style['developerSiteLink-icon']}>
                  <IconArrowUpRight16 color="primary_100" />
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
