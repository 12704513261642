import * as React from 'react';
import { useDispatch } from 'react-redux';
import { trackOpenContacts, trackShowPhone } from '../../actions/analytics';
import { TThunkDispatch } from '../../types/redux/thunk';
import { CallButtonContainer } from '../CallButton';

export interface ICallToActionContainerProps {
  analyticOnOpenActionName?: string;
  dynamicCalltrackingPlaceType: string;
  onlyBuilderPhone?: boolean;
  fullWidth?: boolean;
  secondary?: boolean;
  XXL?: boolean;
}

export const CallToActionContainer: React.FC<ICallToActionContainerProps> = ({
  analyticOnOpenActionName,
  dynamicCalltrackingPlaceType,
  onlyBuilderPhone,
  fullWidth,
  secondary,
  XXL,
}) => {
  const dispatch: TThunkDispatch = useDispatch();

  const handleOpenContacts = React.useCallback(() => {
    dispatch(trackOpenContacts());
  }, [dispatch]);

  const handleShowPhone = React.useCallback(() => {
    dispatch(trackShowPhone(analyticOnOpenActionName));
  }, [dispatch, analyticOnOpenActionName]);

  return (
    <CallButtonContainer
      openContactsStyles={{ size: XXL ? 'M' : 'XS', theme: secondary ? 'fill_secondary' : 'fill_primary', fullWidth }}
      showPhoneStyles={{ size: XXL ? 'M' : 'XS', theme: secondary ? 'fill_secondary' : 'fill_primary', fullWidth }}
      onOpenContactsClick={handleOpenContacts}
      onlyBuilderPhone={onlyBuilderPhone}
      onShowPhoneClick={handleShowPhone}
      placeType={dynamicCalltrackingPlaceType}
    />
  );
};
