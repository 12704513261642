import * as React from 'react';
import { useSelector } from 'react-redux';

import { FlatsError } from '../../components/FlatsError';
import { OffersBlock } from '../../components/OffersBlock';
import { OffersHeader } from '../../components/OffersHeader';
import {
  getBuildingHasLayouts,
  getIsUpcomingSale,
  getNewbuildingDisplayName,
  getSalesStartHousesString,
} from '../../selectors/newbuilding';
import { getOffers, getSelectedHouseOffers } from '../../selectors/offers';

import { HousesFilterContainer } from '../HousesFilter';
import { OffersContainer } from '../Offers';
import { OffersReliableBlockContainer } from '../OffersReliableBlock';
import { trackDeveloperFlatsLinkClick, trackOffersBlockShown, trackOffersToLayoutsSwitcherClick } from './tracking';
import { PriceRelevance } from '../PriceRelevance';
import { LayoutBlock } from '../../components/LayoutBlock';
import { LayoutsContainer } from '../LayoutsContainer';
import { OffersToLayoutsSwitcher } from '../../components/OffersToLayoutsSwitcher';
import { LayoutsLinkContainer } from '../LayoutsLink';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

export function OffersBlockContainer() {
  const { fromDeveloperPropsCountDisplay, fromDeveloperPropsCountUrl, fromAgentsPropsCountDisplay } =
    useSelector(getOffers);
  const flatsStats = useSelector(getSelectedHouseOffers);
  const newbuildingName = useSelector(getNewbuildingDisplayName);
  const salesStartHousesString = useSelector(getSalesStartHousesString);
  const isUpcomingSale = useSelector(getIsUpcomingSale);
  const hasBuildingLayouts = useSelector(getBuildingHasLayouts);

  const [isLayoutsVisible, setIsLayoutsVisible] = React.useState<boolean>(false);

  const onChange = React.useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      setIsLayoutsVisible(value);
      trackOffersToLayoutsSwitcherClick({ isActive: value });
    },
    [setIsLayoutsVisible],
  );

  if (isUpcomingSale) {
    return (
      <LayoutBlock>
        <OffersBlock
          title={<OffersHeader newbuildingName={newbuildingName} />}
          offers={
            <FlatsError
              image="start"
              title="Продажи в этом ЖК скоро начнутся"
              text={
                <>
                  Оставьте заявку, и застройщик свяжется с вами,
                  <br />
                  как только у него появятся подробности
                </>
              }
            />
          }
        />
      </LayoutBlock>
    );
  }

  if (!fromAgentsPropsCountDisplay && !fromDeveloperPropsCountDisplay && !flatsStats) {
    return null;
  }

  return (
    <ActionAfterViewObserver callback={trackOffersBlockShown} wrapper={false}>
      <LayoutBlock>
        <OffersBlock
          title={
            <OffersHeader
              fromDeveloperPropsCountDisplay={fromDeveloperPropsCountDisplay}
              fromDeveloperPropsCountUrl={fromDeveloperPropsCountUrl}
              newbuildingName={newbuildingName}
              onLinkClick={trackDeveloperFlatsLinkClick}
              salesStartHousesString={salesStartHousesString}
            />
          }
          offers={
            <>
              <PriceRelevance />
              <HousesFilterContainer />
              {hasBuildingLayouts && <OffersToLayoutsSwitcher defaultChecked={isLayoutsVisible} onChange={onChange} />}
              {!isLayoutsVisible && <OffersContainer />}
              {isLayoutsVisible && <LayoutsContainer />}
              <LayoutsLinkContainer />
              <OffersReliableBlockContainer />
            </>
          }
        />
      </LayoutBlock>
    </ActionAfterViewObserver>
  );
}
