import * as React from 'react';
import { TooltipDesktop, ArticleHeading4, ArticleParagraph2, IconSalesStart12 } from '@cian/ui-kit';

import * as styles from './SalesStartTooltip.css';

export const SalesStartTooltip = () => {
  return (
    <TooltipDesktop
      title={
        <>
          <ArticleHeading4>Старт продаж корпуса</ArticleHeading4>
          <ArticleParagraph2>Есть выгодные предложения и скидки</ArticleParagraph2>
        </>
      }
      theme="white"
      placement="right-start"
    >
      <span className={styles['icon']}>
        <IconSalesStart12 color="error_100" />
      </span>
    </TooltipDesktop>
  );
};
