import * as React from 'react';
import { Layouts, NewbuildingLayoutsContext } from '@cian/frontend-newbuilding-layouts-widget';
import { useDispatch, useSelector } from 'react-redux';
import { selectHouseId } from '../../actions/offers';
import { AgentsOffersRow } from '../../components/AgentsOffersRow';
import { getHouseHasLayouts, getNewbuildingId } from '../../selectors/newbuilding';
import { getSelectedHouseId, getLayoutsRoomsCountsByHouse, getOffers } from '../../selectors/offers';
import { BuilderOffersNotAvailable } from '../BuilderOffersNotAvailable';
import { useApplicationContext } from '../../utils/applicationContext';
import { getGaLabel } from '../../selectors/analytics';
import { CallButtonContainer } from '../CallButton';
import { trackShowPhoneInLayoutsClick } from './tracking';

export const LayoutsContainer = () => {
  const dispatch = useDispatch();

  const selectedHouseId = useSelector(getSelectedHouseId);
  const newbuildingId = useSelector(getNewbuildingId);
  const roomsCountList = useSelector(getLayoutsRoomsCountsByHouse);
  const isHouseLayoutsAvailable = useSelector(getHouseHasLayouts);
  const gaLabel = useSelector(getGaLabel);
  const { fromDeveloperRooms, fromAgentsPropsCountUrl, fromAgentsPropsCountDisplay } = useSelector(getOffers);

  const { httpApi, logger } = useApplicationContext();

  const contextValue = React.useMemo(() => ({ httpApi, logger }), [httpApi, logger]);

  const onHouseFilterReset = React.useCallback(() => {
    dispatch(selectHouseId(undefined));
  }, []);

  const trackShowPhone = React.useCallback(() => {
    trackShowPhoneInLayoutsClick(gaLabel);
  }, [gaLabel]);

  if (!fromDeveloperRooms || fromDeveloperRooms.length === 0) {
    return (
      <>
        <BuilderOffersNotAvailable />
        {fromAgentsPropsCountDisplay && (
          <AgentsOffersRow
            fromAgentsPropsCountDisplay={fromAgentsPropsCountDisplay}
            fromAgentsPropsCountUrl={fromAgentsPropsCountUrl as string}
          />
        )}
      </>
    );
  }

  return (
    <div data-testid="offers-layouts">
      <NewbuildingLayoutsContext.Provider value={contextValue}>
        <Layouts
          newbuildingId={newbuildingId}
          houseId={selectedHouseId}
          pageTypeJK="Normal"
          isHouseLayoutsAvailable={Boolean(isHouseLayoutsAvailable)}
          roomsCountList={roomsCountList}
          onHouseFilterReset={onHouseFilterReset}
          contactsButton={
            <CallButtonContainer
              placeType="layouts"
              onShowPhoneClick={trackShowPhone}
              openContactsStyles={{ size: 'M' as const, theme: 'fill_secondary' }}
              showPhoneStyles={{ size: 'M' as const, theme: 'fill_secondary' }}
              SinglePhoneComponent={null}
              singlePhoneButtonText="Контакты застройщика"
            />
          }
        />
      </NewbuildingLayoutsContext.Provider>
    </div>
  );
};
