import { ca } from '@cian/analytics';
import { IRecommendationNewbuilding } from '../../../types/recommendations';
import { mapRecommendationNewbuildingToProduct } from '../../../utils/analytics';

interface ITrackShowSimilarNewbuildingParams {
  modelVersion: string;
  newbuildingId: number;
  recommendations: IRecommendationNewbuilding[];
}

export function trackShowSimilarNewbuilding({
  modelVersion,
  newbuildingId,
  recommendations,
}: ITrackShowSimilarNewbuildingParams) {
  ca('eventSite', {
    action: 'show_similar_JK',
    category: 'similar_objects',
    label: '',
    modelVersion,
    name: 'oldevent',
    page: {
      pageType: 'CardJK',
      siteType: 'desktop',
      extra: {
        PageTypeJK: 'Normal',
        newbuilding_from: newbuildingId,
      },
    },
    products: recommendations.map(mapRecommendationNewbuildingToProduct),
  });
}
