import { Decoder, object, string, array, number } from '@mojotech/json-type-validation';
import { ISchedule } from '../../../shared/repositories/newbuilding-calltracking/entities/FlatTourBooking/Schedule';

export const isValidFlatTourSchedule = (value: ISchedule): boolean => {
  const timeInterval = object({
    start: string(),
    end: string(),
  });

  const mapConfigDecoder: Decoder<ISchedule> = object({
    availableDateIntervals: array(timeInterval),
    general: array(
      object({
        availableTimeIntervals: array(timeInterval),
        weekday: number(),
        step: number(),
      }),
    ),
    special: array(
      object({
        availableTimeIntervals: array(timeInterval),
        day: string(),
        step: number(),
      }),
    ),
  });

  const result = mapConfigDecoder.run(value);

  return result.ok && value.general.length === 7 && !!value.availableDateIntervals.length;
};
