import { ca } from '@cian/analytics';
import { EMortgageOffersTabs } from '../../types/mortgageOffers';

const ANALYTICS_TABS_MAP: Record<EMortgageOffersTabs, string> = {
  [EMortgageOffersTabs.Banks]: 'accredited_banks',
  [EMortgageOffersTabs.Mortgage]: 'mortgage_offers',
  [EMortgageOffersTabs.SeoUrl]: 'seo_url',
};

export function trackMortgageOffersTabClick(tab: EMortgageOffersTabs) {
  ca('eventSite', {
    action: 'click',
    category: 'Promo',
    label: ANALYTICS_TABS_MAP[tab],
    name: 'oldevent',
    useLastProducts: true,
  });
}
