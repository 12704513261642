/* eslint-disable react/jsx-no-constructed-context-values */
import * as React from 'react';
import { Provider } from 'react-redux';
import { ErrorLogComponent } from '@cian/error-log-component';
import { DynamicCalltrackingContext } from '@cian/frontend-dynamic-calltracking-component';
import { Provider as MicrofrontendProvider } from '@cian/mf-react';
import { UiKitRoot } from '@cian/ui-kit';

import { getIsLeadFactory } from '../../selectors/newbuilding';
import { IApplicationContext } from '../../types/applicationContext';
import { TReduxStore } from '../../types/redux';
import { ApplicationContext } from '../../utils/applicationContext';
import { setRegionCookies } from '../../utils/businessLogic/geo';
import { NewbuildingCallbackWidgetProvider } from '../NewbuildingCallbackWidgetProvider';
import { StandardPage } from './StandardPage';
import { LeadFactoryPage } from './LeadFactoryPage';

import './App.css';

export interface IApplicationProps extends IApplicationContext {
  reduxStore: TReduxStore;
}

export const Application: React.FC<IApplicationProps> = props => {
  const { logger, reduxStore, httpApi, config, microfrontendsRegistry, telemetry } = props;

  const isLeadFactory = getIsLeadFactory(reduxStore.getState());

  React.useEffect(() => {
    const state = reduxStore.getState();
    const { newbuilding } = state;
    setRegionCookies(newbuilding.geo.regionId as number, newbuilding.geo.mainTownId);
  }, []);

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={{ config, logger, httpApi, microfrontendsRegistry, telemetry }}>
        <UiKitRoot>
          <DynamicCalltrackingContext.Provider value={{ logger, httpApi }}>
            <Provider store={reduxStore}>
              <MicrofrontendProvider registry={microfrontendsRegistry}>
                <NewbuildingCallbackWidgetProvider>
                  {isLeadFactory ? <LeadFactoryPage /> : <StandardPage />}
                </NewbuildingCallbackWidgetProvider>
              </MicrofrontendProvider>
            </Provider>
          </DynamicCalltrackingContext.Provider>
        </UiKitRoot>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
