import * as React from 'react';
import { InlineText2, ArticleHeading4 } from '@cian/ui-kit';
import classNames from 'clsx';

import * as styles from './styles.css';
import { Link } from '../Link';

interface IRightReliableBlockProps {
  icon: React.ReactNode;
  title: string;
  text: string;
  linkText: string;
  hasProblems?: boolean;
  onClick(event: React.MouseEvent<HTMLElement>): void;
}

export function RightReliableLabel({
  icon,
  title,
  text,
  linkText,
  hasProblems = false,
  onClick,
}: IRightReliableBlockProps) {
  return (
    <div className={classNames(styles['container'], hasProblems && styles['container-problematic'])}>
      <div className={styles['icon']}>{icon}</div>
      <div className={styles['text']}>
        <ArticleHeading4>{title}</ArticleHeading4>
        <InlineText2>
          {text}
          <Link url={hasProblems ? '#problem' : '#reliable'} onClick={onClick} linkStyle={styles['link']}>
            {linkText}
          </Link>
        </InlineText2>
      </div>
    </div>
  );
}
