import { connect } from 'react-redux';
import { BreadCrumbs, IBreadCrumbsProps } from '../../components/BreadCrumbs';
import { IApplicationState } from '../../types/redux';
import { getBreadCrumbs } from '../../selectors/newbuilding/getBreadCrumbs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TBreadCrumbsStateProps = Pick<IBreadCrumbsProps, 'breadCrumbs'>;

export function mapStateToProps(state: IApplicationState): TBreadCrumbsStateProps {
  return {
    breadCrumbs: getBreadCrumbs(state),
  };
}

export const BreadCrumbsContainer = connect(mapStateToProps)(BreadCrumbs);
