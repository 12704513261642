import * as React from 'react';
import { UIText2 } from '@cian/ui-kit';

import { SectionWrapper } from './SectionWrapper';
import { Title } from './Title';
import { Promo } from './Promo';
import { IRealtyValuationData } from '../../types/realtyValuation';
import * as styles from './styles.css';

export interface IRealtyValuationProps {
  title: string;
  promoText: IRealtyValuationData['promo'];
  lastUpdatedAt: IRealtyValuationData['lastUpdatedText'];
  content: React.ReactElement;
}

export const RealtyValuation = React.forwardRef<HTMLDivElement, IRealtyValuationProps>((props, ref) => {
  const { content, title, promoText, lastUpdatedAt } = props;

  return (
    <SectionWrapper ref={ref}>
      <Title>{title}</Title>
      {promoText && <Promo {...promoText} />}
      <div className={styles['content']}>{content}</div>
      {lastUpdatedAt && (
        <div className={styles['lastUpdated']}>
          <UIText2 color="gray60_100">{lastUpdatedAt}</UIText2>
        </div>
      )}
    </SectionWrapper>
  );
});

RealtyValuation.displayName = 'RealtyValuation';
