import * as React from 'react';
import {
  ECounterPosition,
  FullscreenCarousel,
  TOnSlideChangeParams,
} from '@cian/frontend-fullscreen-carousel-component';
import { IFullscreenSlide } from '../Carousel';
import { Header } from './Header/Header';
import { prepareSlides } from './utils';
import { IMediaItem } from '../../types/newbuilding/media';

export interface IFullscreenGalleryProps {
  images: IMediaItem[];
  title?: string;
  defaultSlideIndex: number | undefined;
  onClose(): void;
  onOpen?(): void;
  onSlideChange(params: TOnSlideChangeParams): void;
}

export interface IFullscreenGalleryState {
  slides: IFullscreenSlide[];
}

export class FullscreenGallery extends React.Component<IFullscreenGalleryProps, IFullscreenGalleryState> {
  public state = {
    slides: prepareSlides(this.props.images),
  };

  public componentDidMount() {
    const { onOpen } = this.props;

    if (onOpen) {
      onOpen();
    }
  }

  public render() {
    const { defaultSlideIndex, title, onClose, onSlideChange } = this.props;
    const { slides } = this.state;

    return (
      <FullscreenCarousel
        slides={slides}
        media={[
          {
            minWidth: -Infinity,
            maxWidth: 1023,
            options: {
              defaultControls: [],
              hideThumbnails: true,
              counterPosition: ECounterPosition.HEADER,
              contentOptions: {
                headerContent: <Header title={title} />,
              },
            },
          },
          {
            minWidth: 1024,
            maxWidth: Infinity,
            options: {
              contentOptions: {
                headerContent: <Header title={title} />,
              },
            },
          },
        ]}
        onClose={onClose}
        defaultCurrentSlide={defaultSlideIndex}
        onSlideChange={onSlideChange}
      />
    );
  }
}
