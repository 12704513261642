/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import * as style from './NavigationLink.css';

export interface INavigationLinkProps {
  label: string;
  anchor: string;
  isAcitve?: boolean;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
}

export const NavigationLink = (props: INavigationLinkProps) => {
  const { label, anchor, isAcitve, onClick } = props;

  return (
    <a
      role="button"
      onClick={onClick}
      {...mergeStyles(style['navigation-link'], isAcitve && style['active-link'])}
      href={`#${anchor}`}
    >
      {label}
    </a>
  );
};
