import * as React from 'react';
import { ArticleParagraph3, FormField, InputSlider } from '@cian/ui-kit';
import { FILTERS } from '../../constants/mortgage';
import { getDownPaymentInPercents } from './utils/getDownPaymentInPercents';
import * as styles from './styles.css';

interface IMortgageOffersFiltersProps {
  price: number;
  downPayment: number;
  term: number;
  maxPrice: number;
  onPriceChanged: (value: number) => void;
  onDownPaymentChanged: (value: number) => void;
  onTermChanged: (value: number) => void;
  onDownPaymentMouseDown: () => void;
  onTermMouseDown: () => void;
}

export const MortgageOffersFilters: React.VFC<IMortgageOffersFiltersProps> = ({
  price,
  downPayment,
  term,
  maxPrice,
  onPriceChanged,
  onDownPaymentChanged,
  onTermChanged,
  onDownPaymentMouseDown,
  onTermMouseDown,
}) => {
  const currencyAdornment = <span className={styles['adornment']}>₽</span>;
  const termAdornment = <span className={styles['adornment']}>лет</span>;
  const downPaymentInPercents = getDownPaymentInPercents(price, downPayment);

  return (
    <div className={styles['container']}>
      <div className={styles['field']}>
        <FormField label="Стоимость недвижимости" withoutMargin={true}>
          <InputSlider
            value={price}
            max={maxPrice}
            min={0}
            rightAdornment={currencyAdornment}
            onChange={onPriceChanged}
            data-testid="PriceInput"
          />
        </FormField>
      </div>
      <div className={styles['field']}>
        <FormField label="Первоначальный взнос" withoutMargin={true}>
          <>
            <InputSlider
              value={downPayment}
              max={price}
              min={FILTERS.MIN_DOWN_PAYMENT}
              step={Math.round(price / 100)}
              rightAdornment={currencyAdornment}
              onChange={onDownPaymentChanged}
              onMouseDown={onDownPaymentMouseDown}
              data-testid="DownPaymentInput"
            />
            <ArticleParagraph3 color="gray_icons_100">{downPaymentInPercents}%</ArticleParagraph3>
          </>
        </FormField>
      </div>
      <div className={styles['field']}>
        <FormField label="Срок" withoutMargin={true}>
          <InputSlider
            value={term}
            max={FILTERS.MAX_LOAN_TERM}
            min={FILTERS.MIN_LOAN_TERM}
            rightAdornment={termAdornment}
            onChange={onTermChanged}
            onMouseDown={onTermMouseDown}
            data-testid="LoanTermInput"
          />
        </FormField>
      </div>
    </div>
  );
};
