import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import * as styles from './NoImage.css';

export interface INoImageProps {
  img?: string;
  text?: string;
}

export function NoImage({ img, text }: INoImageProps) {
  return (
    <div {...mergeStyles(styles['no_image'], text && styles['no_image--with_text'])}>
      {img && (
        <div className={styles['no_image-icon']}>
          <img src={img} alt="" />
        </div>
      )}
      {text && <p className={styles['no_image-text']}>{text}</p>}
    </div>
  );
}
