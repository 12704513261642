import { IMicrofrontendManifest } from '@cian/mf-registry/shared';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { INFRASTRUCTURE_MF_NAME } from '../../constants/microfrontends';
import { getNewbuildingId } from '../../selectors/newbuilding';
import { getInfrastructureMicrofrontendManifest } from '../../services/getInfrastructureMicrofrontendManifest';
import { useApplicationContext } from '../../utils/applicationContext';

export function useInfrastructureMicrofrontend() {
  const newbuildingId = useSelector(getNewbuildingId);
  const ctx = useApplicationContext();
  const [manifest, setManifest] = useState<IMicrofrontendManifest | null>(null);
  const [status, setStatus] = useState<'loading' | 'error' | 'success'>('loading');

  const fetchManifest = useCallback(async () => {
    setStatus('loading');
    const mfManifest = await getInfrastructureMicrofrontendManifest(ctx, newbuildingId);

    if (mfManifest !== null) {
      ctx.microfrontendsRegistry.register(mfManifest);
      setStatus('success');
      setManifest(mfManifest);
    } else {
      setStatus('error');
    }
  }, [ctx, newbuildingId]);

  useEffect(() => {
    const mf = ctx.config.get<IMicrofrontendManifest>(`microfrontendManifests.${INFRASTRUCTURE_MF_NAME}`);

    if (!mf) {
      fetchManifest();
    } else {
      setManifest(mf);
      setStatus('success');
    }
  }, [ctx.config, fetchManifest]);

  return { manifest, fetchManifest, status };
}
