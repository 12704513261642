import * as React from 'react';
import * as style from '../../NewbuildingItem.css';
import { IconWalk16, IconCar16 } from '@cian/ui-kit';

interface IUndergroundTimeToProps {
  time?: string | null;
}

export const UndergroundTimeTo: React.FC<IUndergroundTimeToProps> = ({ time }) => {
  if (!time) {
    return null;
  }

  const timeSplitted = time.split('.');
  const isWalk = timeSplitted[1] === ' пешком';

  return (
    <div className={style['time']}>
      <span className={style['time-icon']}>{isWalk ? <IconWalk16 /> : <IconCar16 />}</span>
      <span className={style['time-text']}>{timeSplitted[0]}</span>
    </div>
  );
};
