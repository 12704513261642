import * as React from 'react';
import { pluralize } from '@cian/newbuilding-utils';
import { Image, UIText1, UIText2 } from '@cian/ui-kit';
import { numberToPrettyString } from '@cian/utils';
import cn from 'clsx';

import { IPromoInfoSchema } from '../../../../types/promo';
import { PaymentColumnBottom } from '../PaymentColumnBottom';
import { getImageUrl, getInterestRate, isMortgageButtonAvailable, shortenMaxCredit } from './utils';
import * as styles from './styles.css';

interface IMortgageOfferRowProps {
  monthlyPayment: number | null;
  mortgagePartnerLink: string;
  onClick: (promoInfo: IPromoInfoSchema) => void;
  onGetOnlineClick: (promoInfo: IPromoInfoSchema) => void;
  promoInfo: IPromoInfoSchema;
}

export const MortgageOfferRow: React.FC<IMortgageOfferRowProps> = ({
  monthlyPayment,
  mortgagePartnerLink,
  onClick,
  onGetOnlineClick,
  promoInfo,
}) => {
  const { name: promoName, bank, authorName, isBlackFriday } = promoInfo;
  const { maxCredit, maxLoanTerm } = promoInfo?.mortgageParams || {};
  const { name: bankName } = bank || {};

  const imageUrl = getImageUrl(promoInfo);
  const isPaymentBottomVisible = isMortgageButtonAvailable(promoInfo) || isBlackFriday;

  const handleClick = React.useCallback(() => {
    onClick(promoInfo);
  }, [promoInfo, onClick]);

  const handleGetOnlineClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onGetOnlineClick(promoInfo);
    },
    [onGetOnlineClick, promoInfo],
  );

  const maxCreditText = maxCredit ? <UIText1>до {shortenMaxCredit(maxCredit)} ₽</UIText1> : '—';
  const maxLoanTermText = maxLoanTerm ? <UIText1>{pluralize(maxLoanTerm, ['год', 'года', 'лет'])}</UIText1> : '—';
  const monthlyPaymentText = monthlyPayment ? <span>от {numberToPrettyString(monthlyPayment)} ₽/мес</span> : '—';

  const rowClassName = isPaymentBottomVisible ? styles['row'] : styles['centered-row'];

  return (
    <div className={rowClassName} onClick={handleClick} role="button" data-testid="MortgageOfferRow">
      <div className={styles['title-col']}>
        <span data-testid="MortgageOfferRowImage" className={cn(styles['image-block'])}>
          <Image objectFit="contain" src={imageUrl} height={40} width={40} borderRadius={'50%'} />
        </span>
        <span className={styles['title-block']}>
          <div className={styles['promo-name']}>{promoName}</div>
          <UIText2 color="gray_icons_100">{bankName || authorName}</UIText2>
        </span>
      </div>
      <span className={styles['rate-col']}>
        <UIText1>{getInterestRate(promoInfo)}</UIText1>
      </span>
      <span className={styles['sum-col']}>{maxCreditText}</span>
      <span className={styles['term-col']}>{maxLoanTermText}</span>
      <span className={styles['payment-col']}>
        <UIText1 textAlign="right">{monthlyPaymentText}</UIText1>
        {isPaymentBottomVisible && (
          <PaymentColumnBottom
            isBlackFridayPromo={promoInfo.isBlackFriday}
            isGetOnlineButtonAvailable={isMortgageButtonAvailable(promoInfo)}
            mortgagePartnerLink={mortgagePartnerLink}
            onGetOnlineButtonClick={handleGetOnlineClick}
          />
        )}
      </span>
    </div>
  );
};
