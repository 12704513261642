import * as React from 'react';
import { InlineText1, InlineText2 } from '@cian/ui-kit';

import { ISpecificationItem, EItemType } from '../../types/newbuilding/specification';
import { SpecificationTooltip } from './parts/SpecificationTooltip';
import { FinishYearsTitle } from './parts/FinishYearsTitle';

import * as styles from './Specifications.css';
import { ITurnInfo } from '../../types/newbuilding/turn';

export interface ISpecificationsProps {
  specifications: ISpecificationItem[];
  housesByTurnDisplay: ITurnInfo[];
  housesCount: number;
}

export const Specifications: React.FC<ISpecificationsProps> = ({
  specifications,
  housesByTurnDisplay,
  housesCount,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['specifications']}>
        {specifications.map(
          ({ itemType, title, value, tooltipText }) =>
            title && (
              <div key={itemType} className={styles['specification']}>
                <div className={styles['text']}>
                  {itemType === EItemType.FinishDate ? (
                    <FinishYearsTitle housesByTurnDisplay={housesByTurnDisplay} housesCount={housesCount} />
                  ) : (
                    <InlineText2 color={'black_60'}>{title}</InlineText2>
                  )}
                  <div className={styles['value']}>
                    <InlineText1 fontWeight={'bold'}>{value || '—'}</InlineText1>
                    {tooltipText && <SpecificationTooltip text={tooltipText} />}
                  </div>
                </div>
              </div>
            ),
        )}
      </div>
    </div>
  );
};
