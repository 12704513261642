import * as React from 'react';
import { EStarSize, Stars } from '@cian/frontend-reviews-components';
import { ArticleHeading4, UIText3, Avatar } from '@cian/ui-kit';
import * as style from './ReviewAuthor.css';
import { EUserType } from '../../types/newbuilding/reviews';

export interface IReviewAuthorProps {
  avatarUrl: string;
  type: EUserType;
  name: string;
  date: string;
  rating?: number;
}

export const ReviewAuthor: React.FC<IReviewAuthorProps> = ({ avatarUrl, type, name, date, rating }) => {
  return (
    <div className={style['author']}>
      <div className={style['avatar']}>
        <Avatar size={48} src={avatarUrl} type={type === EUserType.Regular ? 'user' : 'builder'} />
      </div>
      <div>
        <ArticleHeading4>{name}</ArticleHeading4>
        <div className={style['rating']}>
          {rating && (
            <div className={style['stars']}>
              <Stars size={EStarSize.M} value={rating} />
            </div>
          )}
          <UIText3>{date}</UIText3>
        </div>
      </div>
    </div>
  );
};
