/* eslint-disable no-irregular-whitespace */
//istanbul ignore file
//TO DO Покрыть тестами

import * as React from 'react';
import { UIText2 } from '@cian/ui-kit';
import { IconChevronUpSmall16, IconChevronDownSmall16 } from '@cian/ui-kit/icons';

import * as style from './Shutter.css';

export interface IShutterMasters {
  open?: React.ReactNode;
  close?: React.ReactNode;
}

export interface IShutterProps {
  masters?: IShutterMasters;
  defaultMaxHeight: number;
  maxHeights?: IShutterHeightsProps[];
  onChange?(open: boolean): void;
  onResize?(isOverHeight: boolean): void;
  children: React.ReactNode;
  openLabel?: string;
  onMoreButtonClick?(): void;
}

interface IShutterHeightsProps {
  minWidth: number;
  maxHeight: number;
}

export interface IShutterState {
  open: boolean;
  height: number;
  maxHeight: number;
}

const getDefaultToggles = (openLabel: string) => {
  return {
    open: (
      <div className={style['toggle-item']}>
        <UIText2 as="span" color="primary_100" fontWeight="normal">
          Свернуть  
        </UIText2>
        <IconChevronUpSmall16 color="primary_100" />
      </div>
    ),
    close: (
      <div className={style['toggle-item']}>
        <UIText2 as="span" color="primary_100" fontWeight="normal">
          {openLabel}  
        </UIText2>
        <IconChevronDownSmall16 color="primary_100" />
      </div>
    ),
  };
};

export class Shutter extends React.PureComponent<IShutterProps, IShutterState> {
  public constructor(props: IShutterProps) {
    super(props);
    this.state = { open: false, height: 0, maxHeight: 0 };
  }
  private ref: HTMLElement | null;

  public componentDidMount() {
    this.setHeight();
    window.addEventListener('resize', this.setHeight, false);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight, false);
  }

  public componentDidUpdate(prevProps: IShutterProps) {
    if (prevProps.defaultMaxHeight !== this.props.defaultMaxHeight) {
      this.setHeight();
    }
  }

  private getMaxHeight = () => {
    const { defaultMaxHeight, maxHeights } = this.props;
    let currentMaxHeight = defaultMaxHeight;
    let currentMinWidth = 0;

    if (!maxHeights) {
      return currentMaxHeight;
    }

    const width = window.innerWidth;
    maxHeights.forEach(item => {
      if (item.minWidth <= width && item.minWidth > currentMinWidth) {
        currentMinWidth = item.minWidth;
        currentMaxHeight = item.maxHeight;
      }
    });

    return currentMaxHeight;
  };

  private setHeight = () => {
    const elem = this.ref;

    if (!elem) {
      return;
    }

    const maxHeight = this.getMaxHeight();
    const { height } = elem.getBoundingClientRect();
    const isOverHeight = height > maxHeight;

    this.setState({ height, maxHeight });
    this.handleResize(isOverHeight);
  };

  public render() {
    const { children } = this.props;
    const { open, height, maxHeight } = this.state;

    const isOverHeight = height > maxHeight;

    return (
      <div data-mark="Shutter" className={style['container']}>
        <div
          className={style['content']}
          style={{ maxHeight: open ? `${height}px` : `${maxHeight}px` }}
          data-testid="ShutterContent"
        >
          <div className={style['layout']} data-id="content" ref={ref => (this.ref = ref)}>
            {children}
          </div>
        </div>
        {isOverHeight ? (
          <span className={style['toggle']} onClick={this.handleChange} data-id="toggle" data-mark="ShutterToggle">
            {this.getToggleElement(open)}
          </span>
        ) : null}
      </div>
    );
  }

  private handleChange = () => {
    if (this.props.onMoreButtonClick) {
      this.props.onMoreButtonClick();
    }

    const open = !this.state.open;
    this.setState({ open });

    if (this.props.onChange) {
      this.props.onChange(open);
    }
  };

  private handleResize = (isOverHeight: boolean) => {
    if (this.props.onResize) {
      this.props.onResize(isOverHeight);
    }
  };

  private getToggleElement = (opened: boolean) => {
    const { masters = {} } = this.props;
    const openLabel = this.props.openLabel || 'Читать полностью';
    const defaulToggles = getDefaultToggles(openLabel);
    const { close = defaulToggles.close, open = defaulToggles.open } = masters;

    return opened ? open : close;
  };
}
