import * as React from 'react';
import { ArticleParagraph2 } from '@cian/ui-kit';
import * as styles from './styles.css';

export const HeaderRow: React.VFC = () => {
  return (
    <div className={styles['row']}>
      <span className={styles['common-title-col']}>
        <ArticleParagraph2 color="gray40_100">Банки и застройщики</ArticleParagraph2>
      </span>
      <span className={styles['common-rate-col']}>
        <ArticleParagraph2 color="gray40_100">Ставка</ArticleParagraph2>
      </span>
      <span className={styles['common-sum-col']}>
        <ArticleParagraph2 color="gray40_100">Сумма</ArticleParagraph2>
      </span>
      <span className={styles['common-term-col']}>
        <ArticleParagraph2 color="gray40_100">Срок</ArticleParagraph2>
      </span>
      <span className={styles['common-payment-col']}>
        <ArticleParagraph2 color="gray40_100" textAlign="right">
          Ежемесячный платёж
        </ArticleParagraph2>
      </span>
    </div>
  );
};
