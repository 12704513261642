import * as React from 'react';
import { Basic, Address, IBasicProps, IAddressProps } from './parts';
import { PageRowLayout } from '../PageRowLayout';
import { Specifications } from '../Specifications';
import { SpecificationsSeoTable } from '../SpecificationsSeoTable';
import { ISpecificationItem } from '../../types/newbuilding/specification';

import * as style from './BasicInfo.css';
import { ITurnInfo } from '../../types/newbuilding/turn';

export interface IBasicInfoProps {
  basic: IBasicProps;
  address: IAddressProps;
  specifications: ISpecificationItem[];
  housesByTurnDisplay: ITurnInfo[];
  housesCount: number;
  transportAccessibility: React.ReactNode;
}

export function BasicInfo({
  basic,
  address,
  specifications,
  housesByTurnDisplay,
  housesCount,
  transportAccessibility,
}: IBasicInfoProps) {
  return (
    <div className={style['basic-info']}>
      <PageRowLayout fullwidth dataMark={'BasicInfo'}>
        <Basic {...basic} />
        <Address {...address} />
        {transportAccessibility && <div className={style['transport-accessibility']}>{transportAccessibility}</div>}
        <SpecificationsSeoTable specifications={specifications} />
        <Specifications
          specifications={specifications}
          housesByTurnDisplay={housesByTurnDisplay}
          housesCount={housesCount}
        />
      </PageRowLayout>
    </div>
  );
}
