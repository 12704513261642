import * as React from 'react';
import { IReliability } from '../../types/reliability';
import { ReliableBanner } from './ReliableBanner';
import { ProblemBanner } from './ProblemBanner';

interface IRightReliableLabelRedesignedProps {
  onReliableClick(event: React.MouseEvent<HTMLElement>): void;
  onProblemClick(event: React.MouseEvent<HTMLElement>): void;
  reliability: IReliability | null;
  isBoldTitle?: boolean;
}

export const RightReliableBanner = ({
  onReliableClick,
  onProblemClick,
  reliability,
  isBoldTitle = true,
}: IRightReliableLabelRedesignedProps) => {
  if (!reliability) {
    return null;
  }

  const isReliable = reliability.checkStatus.status === 'reliable';

  if (isReliable) {
    return <ReliableBanner onClick={onReliableClick} title={reliability.banner.title} isBoldTitle={isBoldTitle} />;
  }

  return <ProblemBanner reliabilityBanner={reliability.banner} onClick={onProblemClick} />;
};
