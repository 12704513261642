import { IApplicationState } from '../../types/redux';

export function getHouseHasLayouts(state: IApplicationState) {
  const hasLayouts = getBuildingHasLayouts(state);
  const { selectedHouseId } = state.offers;

  if (!hasLayouts) {
    return false;
  }

  if (!selectedHouseId) {
    return hasLayouts;
  }

  const houses = state.newbuilding.housesByName;

  if (!houses) {
    return hasLayouts;
  }

  const house = houses.find(house => house.houseId === selectedHouseId);

  if (!house) {
    return false;
  }

  return house.hasLayouts;
}

export function getBuildingHasLayouts(state: IApplicationState) {
  return state.newbuilding.hasLayouts;
}
