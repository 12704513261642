import { IApplicationState } from '../../types/redux';
import { IDocument } from '../../types/newbuilding/document';

export function getDocuments(state: IApplicationState): IDocument[] {
  const {
    newbuilding: { documentation },
  } = state;

  if (documentation && documentation.length) {
    return documentation;
  }

  return [];
}
