import { isStorageEnable } from '../../sessionStorage';

export function setNewbuildingIdInStorage(key: string, value: number): void {
  if (!value || !isStorageEnable()) {
    return;
  }

  try {
    const item = window.sessionStorage.getItem(key) || '[]';
    const json: number[] | void = JSON.parse(item);

    if (!Array.isArray(json)) {
      throw new Error();
    }

    if (json.indexOf(value) === -1) {
      json.push(value);
      window.sessionStorage.setItem(key, JSON.stringify(json));
    }
  } catch (err) {
    window.sessionStorage.removeItem(key);
  }
}
