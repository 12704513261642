import { IApplicationState } from '../../types/redux';

export function getNewbuildingDisplayName(state: IApplicationState) {
  const {
    newbuilding: { displayName },
  } = state;

  if (displayName) {
    return displayName;
  }

  return '';
}
