import { connect } from 'react-redux';
import { RightColumn, IRightColumnProps } from '../../components/RightColumn';
import { getIsLeadFactory, getIsUpcomingSale } from '../../selectors/newbuilding';
import { IApplicationState } from '../../types/redux';

type TRightColumnStateProps = Pick<IRightColumnProps, 'isLeadFactory' | 'isUpcomingSale'>;

export function mapStateToProps(state: IApplicationState): TRightColumnStateProps {
  return {
    isLeadFactory: getIsLeadFactory(state),
    isUpcomingSale: getIsUpcomingSale(state),
  };
}

export const RightColumnContainer = connect(mapStateToProps)(RightColumn);
