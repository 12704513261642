import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EFloorPlanTypeActionTypes {
  SetOpen = 'floorPlan/setOpen',
  SetHouseId = 'floorPlan/setHouseId',
}

export type TFloorPlanOpen = ITypedReduxAction<EFloorPlanTypeActionTypes.SetOpen, boolean>;

export const setOpenFloorPlan: (state: boolean) => TFloorPlanOpen = actionGenerator<
  EFloorPlanTypeActionTypes.SetOpen,
  boolean
>(EFloorPlanTypeActionTypes.SetOpen);

export type TSetFloorPlanHouseId = ITypedReduxAction<EFloorPlanTypeActionTypes.SetHouseId, number | null>;

export const setFloorPlanHouseId: (state: number | null) => TSetFloorPlanHouseId = actionGenerator<
  EFloorPlanTypeActionTypes.SetHouseId,
  number | null
>(EFloorPlanTypeActionTypes.SetHouseId);
