import * as React from 'react';
import { ModalFullscreen } from '@cian/ui-kit';

import * as styles from './AerialViewModal.css';
import { AsideContent } from './parts/AsideContent';

export interface IAerialViewModalProps {
  callbackButton?: React.ReactNode;
  contactsButton?: React.ReactNode;
  description: string;
  isOpen: boolean;
  source: string;
  newbuildingName: string;
  onClose(): void;
  priceForObjectFromDeveloperDisplay?: string | null;
  priceForMeterFromDeveloperDisplay?: string | null;
}

export const AerialViewModal: React.FC<IAerialViewModalProps> = ({
  callbackButton,
  contactsButton,
  description,
  isOpen,
  newbuildingName,
  onClose,
  priceForMeterFromDeveloperDisplay,
  priceForObjectFromDeveloperDisplay,
  source,
}) => {
  return (
    <ModalFullscreen open={isOpen} onClose={onClose} theme="white">
      <div className={styles['modal-content']} data-testid="aerial-view">
        <iframe
          frameBorder={0}
          src={source}
          width="100%"
          height="100%"
          allowFullScreen
          sandbox="allow-scripts allow-same-origin allow-popups"
        />
        <aside className={styles['aside']}>
          <AsideContent
            callbackButton={callbackButton}
            contactsButton={contactsButton}
            newbuildingName={newbuildingName}
            onClose={onClose}
            description={description}
            priceForObjectFromDeveloperDisplay={priceForObjectFromDeveloperDisplay}
            priceForMeterFromDeveloperDisplay={priceForMeterFromDeveloperDisplay}
          />
        </aside>
      </div>
    </ModalFullscreen>
  );
};
