import React from 'react';
import { useSelector } from 'react-redux';

import { BasicInfo } from '../../components/BasicInfo';
import {
  getNewbuildingDisplayName,
  getSpecialStatusDisplay,
  getFullAddress,
  getUndergroundInfo,
  getRoadInfo,
  getRegionId,
  getIsSalesLeader,
  getSpecifications,
  getHousesByTurnDisplay,
  getHousesCount,
  getNewbuildingWebsite,
} from '../../selectors/newbuilding';

import { TransportAccessibilityEntryContainer } from '../TransportAccessibilityEntry';
import { BuilderLinkContainer } from '../BuilderLink';
import { selectIsBuilderLinkEnabled } from '../../selectors/builderLink';

export const BasicInfoContainer: React.FC = () => {
  const isBuilderLinkEnabled = useSelector(selectIsBuilderLinkEnabled);

  const basic = {
    name: useSelector(getNewbuildingDisplayName),
    specialStatusDisplay: useSelector(getSpecialStatusDisplay),
    isSalesLeader: useSelector(getIsSalesLeader),
    website: useSelector(getNewbuildingWebsite),
    builderLink: isBuilderLinkEnabled ? <BuilderLinkContainer /> : null,
  };

  const address = {
    fullAddress: useSelector(getFullAddress),
    regionId: useSelector(getRegionId),
    undergroundInfo: useSelector(getUndergroundInfo),
    roadInfo: useSelector(getRoadInfo),
  };

  const specifications = useSelector(getSpecifications);
  const housesByTurnDisplay = useSelector(getHousesByTurnDisplay);
  const housesCount = useSelector(getHousesCount);

  return (
    <BasicInfo
      basic={basic}
      address={address}
      specifications={specifications}
      housesByTurnDisplay={housesByTurnDisplay}
      housesCount={housesCount}
      transportAccessibility={<TransportAccessibilityEntryContainer />}
    />
  );
};
