import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actual, TooltipContent, Warning } from '../../components/PriceRelevance';
import { getPriceRelevance } from '../../selectors/newbuilding/priceRelevance';
import { EType } from '../../types/newbuilding/priceRelevance';
import { trackHover } from './tracking';
import { WarningTooltip } from './WarningTooltip';

export const PriceRelevance = () => {
  const tooltip = useSelector(getPriceRelevance);
  if (!tooltip) {
    return null;
  }

  if (tooltip.type === EType.Warning) {
    return <Warning label={tooltip.label} tooltip={<WarningTooltip />} />;
  }

  return (
    <Actual
      label={tooltip.label}
      title={tooltip.title}
      description={<TooltipContent description={tooltip.description} onVisible={trackHover} />}
    />
  );
};

PriceRelevance.displayName = 'PriceRelevance';
