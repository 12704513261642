import * as React from 'react';
import { CustomTooltip, IconWarningSign16 } from '@cian/ui-kit';

import * as styles from './styles.css';

interface IPriceRelevanceIconProps {
  description: string;
}

export const PriceRelevanceIcon = ({ description }: IPriceRelevanceIconProps) => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const onMouseEnter = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onMouseLeave = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <CustomTooltip
        open={open}
        placement="bottom"
        anchorRef={anchorRef}
        theme="white"
        content={<div className={styles['tooltip']}>{description}</div>}
      />
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={anchorRef} className={styles['container']}>
        <IconWarningSign16 color="error_100" />
      </div>
    </>
  );
};
