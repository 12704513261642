import * as React from 'react';

import { Link } from '../Link';

import { SectionTitle } from '../SectionTitle';
import { SalesStartInfo } from '../SalesStartInfo';
import * as styles from './styles.css';

interface IOffersHeaderProps {
  fromDeveloperPropsCountDisplay?: string | null;
  fromDeveloperPropsCountUrl?: string | null;
  newbuildingName: string;
  onLinkClick?(): void;
  salesStartHousesString?: string | null;
}

export function OffersHeader({
  fromDeveloperPropsCountDisplay,
  fromDeveloperPropsCountUrl,
  newbuildingName,
  onLinkClick,
  salesStartHousesString,
}: IOffersHeaderProps) {
  return (
    <div className={styles['container']}>
      <SectionTitle>
        {fromDeveloperPropsCountDisplay && fromDeveloperPropsCountUrl ? (
          <Link url={fromDeveloperPropsCountUrl} target="blank" onClick={onLinkClick}>
            {fromDeveloperPropsCountDisplay}
          </Link>
        ) : (
          'Квартиры и апартаменты'
        )}
        {` в ${newbuildingName}`}
      </SectionTitle>
      {salesStartHousesString && (
        <div className={styles['sales-start']}>
          <SalesStartInfo content={salesStartHousesString} />
        </div>
      )}
    </div>
  );
}
