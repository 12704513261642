import * as React from 'react';
import { ArticleHeading1, ArticleHeading2, InlineText1 } from '@cian/ui-kit';
import { EscrowIconLarge } from '../Escrow/EscrowIcons';

import * as styles from './styles.css';

export const EscrowDescription = () => {
  return (
    <div>
      <div className={styles['icon']}>
        <EscrowIconLarge />
      </div>
      <div className={styles['title']}>
        <ArticleHeading1>Что такое эскроу-счёт и&nbsp;почему это повышает надежность застройщика</ArticleHeading1>
      </div>
      <InlineText1>
        Покупатель квартиры в&nbsp;строящемся доме после заключения договора ДДУ с&nbsp;застройщиком открывает
        эскроу-счёт в&nbsp;банке. Только после завершения строительства застройщик сможет получить эти деньги.
        <br />
        Если дом не&nbsp;сдан &mdash; покупатель (дольщик) получает деньги назад.
      </InlineText1>
      <div className={styles['subtitle']}>
        <ArticleHeading2>В чем преимущества?</ArticleHeading2>
      </div>
      <InlineText1>
        Средства дольщиков на&nbsp;эскроу-счетах застрахованы Агентством по&nbsp;страхованию вкладов (АСВ). При
        наступлении страхового случая дольщикам выплачивается 100% вложенных средств, но&nbsp;не&nbsp;более 10&nbsp;млн
        руб. Если&nbsp;же у&nbsp;покупателя открыто несколько эскроу-счетов (то&nbsp;есть он&nbsp;приобретает несколько
        квартир), то&nbsp;возмещение выплачивается по&nbsp;каждому из&nbsp;вкладов, но&nbsp;не&nbsp;более 10&nbsp;млн
        руб.&nbsp;в&nbsp;сумме.
      </InlineText1>
      <div className={styles['agency']}>
        <div className={styles['text']}>
          <InlineText1>
            100% страхование сделки Агенством по&nbsp;страхованию вкладов (АСВ) на&nbsp;сумму 10&nbsp;млн&nbsp;руб
          </InlineText1>
        </div>
      </div>
    </div>
  );
};
