export interface IReliableLabel {
  /** Заголовок лейбла **/
  title: string;
  /** Тип надежности ЖК **/
  type: EType;
}
export enum EType {
  /** Проблемный ЖК **/
  'Problem' = 'problem',
  /** Надежный ЖК **/
  'Reliable' = 'reliable',
}

export interface IReliableHouse {
  /** Id корпуса ЖК **/
  houseId: number;
  /** Название **/
  name: string;
  /** Ссылка на выдачу объявлений **/
  url: string;
}
