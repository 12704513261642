import * as React from 'react';
import { InlineText2 } from '@cian/ui-kit';
import * as styles from './HousesByTurnTitle.css';

export interface IHousesByTurnTitleProps {
  onClick?: () => void;
}

export const HousesByTurnTitle: React.VFC<IHousesByTurnTitleProps> = props => {
  const { onClick } = props;

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <span>
      <span className={styles['title']} onClick={handleClick}>
        <InlineText2 color={'black_60'}>Срок сдачи</InlineText2>
      </span>
    </span>
  );
};
