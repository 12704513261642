import { IApplicationState } from '../../types/redux';

export function getDescription(state: IApplicationState) {
  const {
    newbuilding: { description },
  } = state;

  if (description && description.length) {
    return description;
  }

  return null;
}
