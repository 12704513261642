import * as React from 'react';
import { InlineText2, ArticleHeading1 } from '@cian/ui-kit';
import { DOM_RF_LINK } from '../../constants/externalLinks';
import { Link } from '../Link';
import { ReliabilityChecks } from './ReliabilityChecks';

import * as styles from './Reliability.css';

export interface IReliableContentProps {
  houses: React.ReactNode;
  onReliableMoreLinkClick(): void;
}

export function Reliability({ houses, onReliableMoreLinkClick }: IReliableContentProps) {
  return (
    <div className={styles['container']} data-testid="Reliability">
      <div className={styles['content']}>
        <div className={styles['title']}>
          <div className={styles['title-text']}>
            <ArticleHeading1 as={'h2'} fontWeight={'bold'} color="white_100">
              Надёжность корпусов проверена по 7 критериям надежности
            </ArticleHeading1>
          </div>
          <div className={styles['title-icon']} />
        </div>
        <ReliabilityChecks />
        <div className={styles['description']}>
          <div className={styles['description-info']}>
            <InlineText2 color="white_100">На основе данных </InlineText2>
            <Link url={DOM_RF_LINK} target="blank" linkStyle={styles['link']}>
              <InlineText2 color="white_100">наш.дом.рф</InlineText2>
            </Link>
          </div>
          <div className={styles['description-info']}>
            <Link
              url="https://support.cian.ru/ru/knowledge_base/art/334/cat/103/"
              target="blank"
              onClick={onReliableMoreLinkClick}
              linkStyle={styles['link']}
            >
              <InlineText2 color="white_100">О методике проверки</InlineText2>
            </Link>
          </div>
        </div>
      </div>
      {houses}
    </div>
  );
}
