export interface IMlReview {
  legacy?: string | null;
  minuses?: string | null;
  pluses?: string | null;
  subtitle: string;
  title: string;
}

export interface IReviews {
  rating: number;
  ratingText: string;
  reviewsCount: number;
  reviewsCountText: string;
  title: string;
  questionsCountText: string;
  reviews: IReviewWeb[];
  sections: IReviewSection[];
  mlReview?: IMlReview | null;
}

export interface IReviewWeb {
  /** Список ответов на отзыв **/
  answers?: IAnswer[];
  /** Количество ответов текстом **/
  answersCount: string;
  /** Количество ответов числом **/
  answersCountInt: number;
  /** Текст отзыва **/
  comment: IComment;
  /** Дата создания отзыва **/
  date: string;
  /** Есть ли ответы на отзыв **/
  hasAnswers: boolean;
  /** Есть ли еще ответы на отзыв **/
  hasMoreAnswers: boolean;
  /** uuid **/
  id: string;
  /** Старый тип отзывов (без плюсов и минусов) **/
  isLegacy: boolean;
  /** Ожидает модерации **/
  isOnModeration: boolean;
  /** Лейбл Полезный **/
  isUseful: boolean;
  /** Список лейблов **/
  labels: ILabel[];
  /** Лайк/дизлайк **/
  like: ILike;
  /** Общая оценка **/
  rating: number;
  /** Пользователь отзыва **/
  user: IUser;
}

export interface IReviewSection {
  /** Название секции **/
  name: string;
  /** Средняя оценка **/
  rating: number;
  /** Тип секции **/
  type: TReviewSectionType;
}

export type TReviewSectionType = // Экология

    | 'ecology'
    // Инфраструктура
    | 'infrastructure'
    // Цена / Качество
    | 'priceQuality'
    // Транспортная доступность
    | 'transportAffordable';

export interface IAnswer {
  /** Текст комментарии на ответ **/
  comment: IAnswerComment;
  /** Дата создания ответа **/
  date: string;
  /** uuid **/
  id: string;
  /** Лайк/дизлайк **/
  like: ILike;
  /** Пользователь ответа **/
  user: IUser;
}

export interface IAnswerComment {
  /** Текст ответа на комментрий **/
  text: string;
  /** Текстовая репрезентация ReviewUserStatus **/
  type: string;
}

export interface IComment {
  /** Текст старого отзыва **/
  legacy?: string;
  /** Минусы **/
  negative?: string;
  /** Плюсы **/
  positive?: string;
  /** Текстовая репрезентация ReviewUserStatus **/
  type: string;
}

export interface ILabel {
  /** Цвет лейбла **/
  color: string;
  /** Текст лейбла **/
  text: string;
}

export interface ILike {
  /** Кол. дизлайков **/
  dislikesCount: number;
  /** Кол. лайков **/
  likesCount: number;
  /** Наличие лайка/дизлайка от текущего пользователя **/
  status: TLikeStatus;
}

export type TLikeStatus = // Поставлен дизлайк

    | 'disliked'
    // Поставлен лайк
    | 'liked'
    // Нету реакции
    | 'unknown';

export interface IUser {
  /** Аватар **/
  avatarUrl: string;
  /** Id пользователя realty **/
  id: number;
  /** Отображаемое имя **/
  name: string;
  /** Тип пользователя **/
  type: EUserType;
}

export enum EUserType {
  /** Застройщик **/
  'Builder' = 'builder',
  /** Поискун **/
  'Regular' = 'regular',
}
