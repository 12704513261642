import { ILayoutRoomCount as IWidgetLayoutRoomCount, ERoomType } from '@cian/frontend-newbuilding-layouts-widget';
import { IApplicationState } from '../../types/redux';
import { ERoomValue, ILayoutRoomCount } from '../../types/newbuilding/layouts';

const ROOM_TYPE_MAP: { [key in ERoomValue]: ERoomType } = {
  [ERoomValue.Studio]: ERoomType.Studio,
  [ERoomValue.OneRoom]: ERoomType.OneRoom,
  [ERoomValue.TwoRoom]: ERoomType.TwoRoom,
  [ERoomValue.ThreeRoom]: ERoomType.ThreeRoom,
  [ERoomValue.FourPlusRoom]: ERoomType.FourRoom,
};

export const getLayoutsRoomsCountsByHouse = (state: IApplicationState): IWidgetLayoutRoomCount[] => {
  if (!state.newbuilding.layoutsInfo) {
    return [];
  }

  const houseId = state.offers.selectedHouseId;
  const roomsCountsByHouse = state.newbuilding.layoutsInfo.roomsCountsByHouse.find(list =>
    houseId ? list.houseId === houseId : !list.houseId,
  );
  if (!roomsCountsByHouse) {
    return [];
  }

  return roomCountMapper(roomsCountsByHouse.roomsCountList);
};

function roomCountMapper(rooms: ILayoutRoomCount[]): IWidgetLayoutRoomCount[] {
  return rooms.map(room => ({
    ...room,
    isAvailable: Boolean(room.isAvailable),
    roomValue: ROOM_TYPE_MAP[room.roomValue],
  }));
}
