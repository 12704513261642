import * as React from 'react';
import * as styles from './Counter.css';
import { IconPhoto16, InlineText3 } from '@cian/ui-kit';

interface ICounterProps {
  currentSlide: number;
  totalSlides: number;
}

export const Counter: React.FC<ICounterProps> = props => {
  const { currentSlide, totalSlides } = props;

  return (
    <div className={styles['container']}>
      <IconPhoto16 color={'white_100'} />
      <div className={styles['counter']}>
        <InlineText3 color={'white_100'}>
          {currentSlide}/{totalSlides}
        </InlineText3>
      </div>
    </div>
  );
};

Counter.displayName = 'Counter';
