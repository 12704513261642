// tslint:disable:max-line-length
import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import * as styles from './PlayerIcon.css';

export enum EPlayerIconType {
  Small,
  Normal,
}

export interface IPlayerIconProps {
  type: EPlayerIconType;
}

export function PlayerIcon({ type }: IPlayerIconProps) {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg
      width="80"
      height="56"
      viewBox="0 0 80 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...mergeStyles(styles['playerIcon'], type === EPlayerIconType.Small && styles['playerIconSmall'])}
    >
      <path
        d="M79.1949 12.0782C79.1949 12.0782 78.4153 6.59273 76.0128 4.17454C72.9712 1.00545 69.5591 0.992727 68 0.801818C56.8051 1.51721e-07 40.0128 0 40.0128 0H39.9744C39.9744 0 23.1821 1.51721e-07 11.9872 0.801818C10.4281 0.992727 7.01597 1.00545 3.97444 4.17454C1.57188 6.59273 0.792332 12.0782 0.792332 12.0782C0.792332 12.0782 -1.52344e-08 18.5182 -1.52344e-08 24.9709V31.0164C-1.52344e-08 37.4564 0.805112 43.8964 0.805112 43.8964C0.805112 43.8964 1.58466 49.3818 3.98722 51.8C7.02875 54.9691 11.0288 54.8673 12.8051 55.1982C19.1949 55.8091 40 56 40 56C40 56 56.8051 55.9745 68 55.1727C69.5591 54.9818 72.9712 54.9691 76.0128 51.8C78.4153 49.3818 79.1949 43.8964 79.1949 43.8964C79.1949 43.8964 80 37.4564 80 31.0164V24.9709C80 18.5182 79.1949 12.0782 79.1949 12.0782Z"
        fill="#FC0D1C"
      />
      <path d="M31.7441 15.9473V38.3218L53.3544 27.1727L31.7441 15.9473Z" fill="white" />
    </svg>
  );
}
