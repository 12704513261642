import * as React from 'react';
import * as style from './SalesLeaderIcon.css';

/* istanbul ignore next */
export const SalesLeaderIcon = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg
      className={style['salesLeaderIcon']}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <g fillRule="evenodd">
        <path d="M0 3l4 4 2-4 2 4 4-4-1.826 8.217a1 1 0 0 1-.976.783H2.802a1 1 0 0 1-.976-.783L0 3z"></path>
        <rect width="2" height="2" x="5" rx="1"></rect>
      </g>
    </svg>
  );
};
