import * as React from 'react';
import { Header } from '../../components/FullscreenPopup';

export interface IAsideContentPopupHeaderContainerProps {
  onClose(): void;
}

export function AsideContentPopupHeader({ onClose }: IAsideContentPopupHeaderContainerProps) {
  return <Header onClose={onClose} call={null} />;
}
