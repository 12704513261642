import * as React from 'react';
import { CommentPart } from './parts';
import { IComment } from '../../types/newbuilding/reviews';

export interface IReviewCommentProps {
  isLegacy: boolean;
  comment: IComment;
}

export const ReviewComment: React.FC<IReviewCommentProps> = ({ isLegacy, comment }) => {
  return (
    <>
      {isLegacy ? (
        <CommentPart message={comment.legacy as string} />
      ) : (
        <>
          <CommentPart title="Плюсы:" message={comment.positive as string} />
          <CommentPart title="Минусы:" message={comment.negative as string} />
        </>
      )}
    </>
  );
};
