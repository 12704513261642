import { IProduct } from '../../types/analytics';
import { IRecommendationNewbuilding } from '../../types/recommendations';

export const mapRecommendationNewbuildingToProduct = (
  newbuilding: IRecommendationNewbuilding,
  index: number,
): IProduct => {
  return {
    id: newbuilding.id,
    offerType: 'JK',
    position: index + 1,
  };
};
