import * as React from 'react';
import { ArticleHeading4, InlineText1 } from '@cian/ui-kit';

import { HoveredLink } from '../HoveredLink';
import { Link } from '../Link';

import * as styles from './styles.css';

interface IAgentsOffersRowProps {
  fromAgentsPropsCountDisplay: string;
  fromAgentsPropsCountUrl: string;
}

export function AgentsOffersRow({ fromAgentsPropsCountDisplay, fromAgentsPropsCountUrl }: IAgentsOffersRowProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['agents-title']}>
        <HoveredLink url={fromAgentsPropsCountUrl} childNode={ArticleHeading4} as="h4" color="black_100">
          Предложения от агентов и собственников
        </HoveredLink>
      </div>
      <InlineText1 color="primary_100">
        <Link url={fromAgentsPropsCountUrl} target="blank">
          {fromAgentsPropsCountDisplay}
        </Link>
      </InlineText1>
    </div>
  );
}
