/* eslint-disable @typescript-eslint/no-explicit-any */
import { initDependencies, setupDependencies } from './app';

setupDependencies();

initDependencies();

const { runApp } = require('./services/runApp');
runApp();

if ((module as any).hot) {
  (module as any).hot.accept();
}
