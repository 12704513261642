import * as React from 'react';

export const ReliablePointIcon = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="white" />
      <path d="M4 7L7 10L12 5" stroke="#0468FF" strokeWidth="2" />
    </svg>
  );
};

ReliablePointIcon.displayName = 'ReliablePointIcon';

export const ReliableZhkIcon = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5L7.5 15V35H32.5V15L31.475 14.18L18.3637 32.5L10 22.1786L13.8188 18.9154L18.3637 25.1327L28.1693 11.5355L20 5Z"
        fill="#0468FF"
      />
    </svg>
  );
};

ReliableZhkIcon.displayName = 'ReliableZhkIcon';

export const ReliableFaqIcon = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="white" fillOpacity="0.4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6ZM9 12V7H7V12H9Z"
        fill="white"
      />
    </svg>
  );
};

ReliableFaqIcon.displayName = 'ReliableFaqIcon';
