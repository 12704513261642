import * as React from 'react';

import { ChartWrapper } from '../../../../components/RealtyValuation';
import { useDynamicSalesChartCfg } from './utils/useDynamicSalesChartCfg';

export function SalesChartContainer() {
  const chartCfg = useDynamicSalesChartCfg();

  if (!chartCfg) {
    return null;
  }

  return <ChartWrapper chartCfg={chartCfg} />;
}
