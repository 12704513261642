import * as React from 'react';
import { Button, ArticleHeading1, ModalWindow } from '@cian/ui-kit';
import { useSelector } from 'react-redux';
import { EscrowHouses } from '../../components/EscrowHouses';
import { getNewbuildingId, getEscrowHouses } from '../../selectors/newbuilding';
import { trackHousesWithEscrouLabelClick, trackHousesWithEscrouLinkClick } from './tracking';

export const EscrowHousesContainer = () => {
  const houses = useSelector(getEscrowHouses);
  const newbuildingId = useSelector(getNewbuildingId);
  const [isOpen, setOpen] = React.useState(false);

  const onOpen = React.useCallback(() => {
    setOpen(true);
    trackHousesWithEscrouLabelClick();
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <ModalWindow
        open={isOpen}
        escape
        header={<ArticleHeading1 as={'span'}>Корпуса с эскроу-счётом</ArticleHeading1>}
        content={
          <EscrowHouses newbuildingId={newbuildingId} houses={houses} onClick={trackHousesWithEscrouLinkClick} />
        }
        onClose={onClose}
        size="M"
        width={730}
      />
      <Button size="XS" theme="fill_secondary" onClick={onOpen}>
        Список корпусов с эскроу
      </Button>
    </>
  );
};
