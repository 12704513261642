import * as React from 'react';

import { Section } from '../Section';

import * as styles from './MortgageSection.css';

export interface IMortgageSectionProps extends React.PropsWithChildren {
  tabs?: React.ReactNode;
  title: string;
}

export const MortgageSection: React.FC<IMortgageSectionProps> = ({ children, tabs, title }) => {
  return (
    <Section title={title} data-testid="MortgageSection">
      {tabs && <div className={styles['tabs']}>{tabs}</div>}
      {children}
    </Section>
  );
};
