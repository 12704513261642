import * as React from 'react';
import * as style from './TooltipLink.css';
import classNames from 'clsx';
import { scrollToAnchor } from '@cian/newbuilding-utils';
import { mergeStyles } from '@cian/utils';
import { TLinkTarget } from '../Link/Link';

export interface ITooltipLinkProps {
  className?: string;
  anchor?: string;
  url?: string | null;
  text?: string | null;
  width?: number;
  children: React.ReactNode;
  trackClick?(): void;
  content?: React.ReactNode;
  target?: TLinkTarget;
  showBottom?: boolean;
}

export const TooltipLink: React.FC<ITooltipLinkProps> = props => {
  const { className, anchor, text, width, children, trackClick, url, target = 'self', showBottom = false } = props;

  const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    if (trackClick) {
      trackClick();
    }

    if (anchor) {
      scrollToAnchor({ anchor, hasRelocation: false, hasAnimation: true })(event);

      if (history.pushState) {
        history.pushState(null, '', anchor);
      } else {
        setTimeout(() => {
          window.location.hash = anchor;
        }, 500);
      }
    }
  };

  return (
    <div {...mergeStyles(style['tooltipContainer'], className)}>
      <div className={style['tooltipTrigger']}>{children}</div>
      <a
        style={{ width: `${width}px` }}
        className={classNames(style['tooltipContent'], showBottom && style['tooltipContent--bottom'])}
        href={url || anchor}
        onClick={clickHandler}
        target={`_${target}`}
      >
        {text || 'Узнать подробнее'}
      </a>
    </div>
  );
};
