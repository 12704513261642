/* eslint-disable  @typescript-eslint/no-explicit-any */
export function doOnceDecorator<T extends (...args: any[]) => any>(cb: T): T {
  let isDone = false;

  return ((...args) => {
    if (!isDone) {
      isDone = true;

      return cb(...args);
    }
  }) as T;
}
