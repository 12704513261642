import { IApplicationState } from '../../types/redux';

export function getPromoLabelType(state: IApplicationState): string | null {
  const {
    newbuilding: { promoInfos },
  } = state;
  if (promoInfos && promoInfos.length) {
    return promoInfos[0].promoType;
  }

  return null;
}
