import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IMicrofrontendManifest } from '@cian/mf-registry/shared';

export type TGetInfrastructureMicrofrontendModel = IModel<
  TGetInfrastructureMicrofrontendRequest,
  TGetInfrastructureMicrofrontendResponse
>;

export interface IBaseParams {
  /** Идентификатор, определяющий настройки получаемой инфры (порядок, типы инры и тд) **/
  profile?: string;
  /** Радиус получения инфры в метрах (получаем инфру либо по bbox либо по radius) **/
  radius?: number;
  /** Координаты левого нижнего и правого верхнего угла через запятую (получаем инфру либо по bbox либо по radius) **/
  bbox?: string;
}

export interface INewbuildingIdParams extends IBaseParams {
  /** Id ЖК **/
  newbuildingId: number;
}

export interface IHousesIdParams extends IBaseParams {
  /** Id дома **/
  houseId?: number;
}

export interface IOfferParams extends IBaseParams {
  /** Тип сделки **/
  dealType: 'sale' | 'rent';
  /** Id объявления **/
  offerId: number;
  /** Тип объявления **/
  offerType: 'flat' | 'newobject' | 'commercial' | 'suburban';
}

export interface ICoordinateParams extends IBaseParams {
  /** Широта **/
  lat: number;
  /** Долгота **/
  lng: number;
}

export enum EDealType {
  /** Аренда **/
  'Rent' = 'rent',
  /** Продажа **/
  'Sale' = 'sale',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type TGetInfrastructureMicrofrontendRequest =
  | IOfferParams
  | INewbuildingIdParams
  | ICoordinateParams
  | IHousesIdParams;

export type TResponse = {
  manifest: IMicrofrontendManifest;
};

export interface IGetInfrastructureMicrofrontendResponse200 extends IModelResponse<TResponse> {
  statusCode: 200;
}

export type TGetInfrastructureMicrofrontendResponse = IGetInfrastructureMicrofrontendResponse200;

export type TResponses = IMicrofrontendManifest;

export interface IMappers<TResponse200> {
  200(response: IMicrofrontendManifest): TResponse200;
}
