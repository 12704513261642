import { ICianMFUIElement } from '@cian/mf-registry/browser';
import { IMicrofrontendManifest } from '@cian/mf-registry/shared';
import { useCallback, useEffect, useRef, useState } from 'react';

import { registerNewbuildingBrokerWidgetManifest } from '../../../services/newbuildingBrokerWidget';
import {
  INewbuildingMicrofrontendWidgetLocalAPI,
  IOpenBrokerModalParams,
} from '../../../types/microfrontends/newbuildingBrokerWidget';
import { TOpenWidgetResultHandler } from '../../../types/newbuildingBroker';
import { useApplicationContext } from '../../../utils/applicationContext';

export const useBrokerWidgetAPI = (resultCallback: TOpenWidgetResultHandler) => {
  const { httpApi, logger, microfrontendsRegistry } = useApplicationContext();

  const widgetRef = useRef<ICianMFUIElement>(null);

  const [widgetManifest, setWidgetManifest] = useState<IMicrofrontendManifest>();

  const getLocalAPI = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (): INewbuildingMicrofrontendWidgetLocalAPI => (widgetRef.current?.instance?.customElement as any)?.api,
    [widgetRef],
  );

  const paramsRef = useRef<IOpenBrokerModalParams>();
  const firstAPICallRef = useRef(false);

  useEffect(() => {
    // Логика выполняется один раз при первом вызове локального API после установки манифеста в стейт.
    if (widgetManifest && !firstAPICallRef.current) {
      firstAPICallRef.current = true;

      // Хак, чтобы подождать рендера `<Microfrontend />` и инициализации рефа для локального API.
      setTimeout(() => {
        const api = getLocalAPI();

        if (api) {
          api['/v2/open'](paramsRef.current as IOpenBrokerModalParams);
          resultCallback({ isError: false });
        } else {
          resultCallback({ isError: true });
        }
      }, 0);
    }
  }, [resultCallback, getLocalAPI, logger, widgetManifest]);

  const registerAndSetManifest = useCallback(
    async (params: IOpenBrokerModalParams) => {
      const result = await registerNewbuildingBrokerWidgetManifest({
        httpApi,
        logger,
        microfrontendsRegistry,
      });

      if (result) {
        // Сохраняем в реф, чтобы вызвать с этими параметрами после установки в стейт и инициализации рефа.
        paramsRef.current = params;

        setWidgetManifest(result);
      } else {
        resultCallback({ isError: true });
      }
    },
    [httpApi, logger, microfrontendsRegistry, resultCallback],
  );

  const openWidget = useCallback(
    async (params: IOpenBrokerModalParams): Promise<void> => {
      if (widgetManifest) {
        await getLocalAPI()['/v2/open'](params);
        resultCallback({ isError: false });
      } else {
        await registerAndSetManifest(params);
      }
    },
    [getLocalAPI, registerAndSetManifest, resultCallback, widgetManifest],
  );

  return {
    widgetManifest,
    widgetRef,
    openWidget,
  };
};
