import * as React from 'react';
import { useSelector } from 'react-redux';

import { Advantages } from '../../components/Advantages';
import { FullscreenPopup, Content } from '../../components/FullscreenPopup';
import { LayoutBlock } from '../../components/LayoutBlock';
import { getAdvantages } from '../../selectors/newbuilding';
import { AsideContentPopupHeader } from '../AsideContentPopupHeader';
import { trackAdvantagesItemClick } from './tracking';

export function AdvantagesContainer() {
  const [isOpened, setOpenedState] = React.useState(false);
  const [advantageIndex, setAdvantageIndex] = React.useState(0);

  const advantages = useSelector(getAdvantages);

  const onItemClick = React.useCallback(
    (i: number) => {
      setAdvantageIndex(i);
      setOpenedState(true);

      const item = advantages[i];
      if (item) {
        trackAdvantagesItemClick(item.advantageType);
      }
    },
    [setOpenedState, setAdvantageIndex, advantages],
  );

  const onClose = React.useCallback(() => {
    setOpenedState(false);
  }, [setOpenedState]);

  if (!advantages || advantages.length === 0) {
    return null;
  }

  const advantage = advantages[advantageIndex];

  return (
    <LayoutBlock>
      <Advantages advantages={advantages} onItemClick={onItemClick} />
      <FullscreenPopup
        isOpened={isOpened}
        header={<AsideContentPopupHeader onClose={onClose} />}
        onClose={onClose}
        content={<Content {...advantage} />}
      />
    </LayoutBlock>
  );
}
