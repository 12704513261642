import * as React from 'react';
import { Section } from '../Section';
import * as style from './OffersBlock.css';

export interface IPriceProps {
  title: React.ReactNode;
  offers: React.ReactNode;
}

export function OffersBlock({ title, offers }: IPriceProps) {
  return (
    <Section data-nav-tab="filter/Квартиры" id="filter">
      {title}
      <div className={style['container']}>
        <div className={style['offers']}>{offers}</div>
      </div>
    </Section>
  );
}
