import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import { RealtyValuation } from '../../components/RealtyValuation';
import { selectRealtyValuationData } from '../../selectors/realtyValuation/';
import { trackRealtyValuationInView } from './tracking';
import { PricesDynamicsContainer } from './containers/PricesDynamicsContainer';
import { SalesDynamicsContainer } from './containers/SalesDynamicsContainer';
import { TabsContainer } from './containers/TabsContainer';

export function RealtyValuationContainer() {
  const realtyValuation = useSelector(selectRealtyValuationData);

  if (!realtyValuation || isEmpty(realtyValuation)) {
    return null;
  }

  const { promo, lastUpdatedText } = realtyValuation;

  return (
    <ActionAfterViewObserver callback={trackRealtyValuationInView} wrapper={false}>
      <RealtyValuation
        title="Цены и спрос"
        promoText={promo}
        lastUpdatedAt={lastUpdatedText}
        content={
          <TabsContainer pricesDynamics={<PricesDynamicsContainer />} salesDynamics={<SalesDynamicsContainer />} />
        }
      />
    </ActionAfterViewObserver>
  );
}
