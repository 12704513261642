interface IGetDescriptionTextParams {
  builderName?: string;
  hasOffersFromDeveloper: boolean;
}

export const getDescriptionText = ({ builderName, hasOffersFromDeveloper }: IGetDescriptionTextParams): string => {
  if (!hasOffersFromDeveloper) {
    return 'Нет предложений от застройщика в этом ЖК';
  }

  const displayBuilderName = builderName ? ` у\xA0застройщика ${builderName}` : '';

  return `Узнайте больше подробностей о\xA0жилом комплексе${displayBuilderName}`;
};
