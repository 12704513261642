import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { INewbuilding } from '../../types/newbuilding/newbuilding';
import { IOrderStatus } from '../../types/newbuilding/flatViewOrder';

export enum ENewbuildingActionTypes {
  SetNewbuilding = 'newbuilding/set',
  SetFlatViewOrder = 'newbuilding/setFlatViewOrder',
}

// Warning флаг isFavorite нужен только при инициализации, в дальнейшнем он меняется в FavoriteBasic
export type TSetNewbuilding = ITypedReduxAction<ENewbuildingActionTypes.SetNewbuilding, INewbuilding>;

export type TSetFlatViewOrder = ITypedReduxAction<ENewbuildingActionTypes.SetFlatViewOrder, IOrderStatus>;

/**
 * Redux action, который записывает в store фичи
 * @param features IConfigFeatures
 */
export const setNewbuilding: (newbuilding: INewbuilding) => TSetNewbuilding = actionGenerator<
  ENewbuildingActionTypes.SetNewbuilding,
  INewbuilding
>(ENewbuildingActionTypes.SetNewbuilding);

export const setFlatViewOrder = actionGenerator<ENewbuildingActionTypes.SetFlatViewOrder, IOrderStatus>(
  ENewbuildingActionTypes.SetFlatViewOrder,
);
