import { ITabData } from '../types';

export function mapClausesToTabs(clauses: HTMLElement[]) {
  if (clauses.length < 1) {
    return [];
  }

  return clauses
    .map((tabElement: HTMLElement) => {
      const [anchor, label = ''] = (tabElement.dataset.navTab || '/').split('/');

      const point = tabElement.getBoundingClientRect().top + window.scrollY;

      return {
        label,
        anchor,
        point,
      };
    })
    .filter((clause: ITabData) => Boolean(clause.label && clause.anchor))
    .sort((a, b) => a.point - b.point);
}
