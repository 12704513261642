import * as React from 'react';
import { useSelector } from 'react-redux';
import { pluralize } from '@cian/newbuilding-utils';

import { ToggleButton as ToggleButtonComponent } from '../../../components/Promos/ToggleButton';
import { QUANTITY_PROMOS_IN_ROW } from '../../../constants/promos';
import { selectNewbuildingPromos } from '../../../selectors/newbuildingMortgageCalculator';

interface IToggleButtonProps {
  opened: boolean;
  onClick(): void;
}

export const ToggleButton: React.FC<IToggleButtonProps> = props => {
  const promos = useSelector(selectNewbuildingPromos);
  const count = promos.length;

  return (
    <ToggleButtonComponent
      {...props}
      closedText={`Показать еще ${pluralize(count - QUANTITY_PROMOS_IN_ROW, ['акцию', 'акции', 'акций'])}`}
    />
  );
};
