import * as React from 'react';

import { BankItem } from '../BankItem';
import { IBank } from '../../../../types/newbuilding/bank';

export interface IBankListItemProps {
  bank: IBank;
  onLinkClick?(bank: IBank): void;
}

export const BankListItem: React.FC<IBankListItemProps> = ({ bank, onLinkClick }) => {
  const handleLinkClick = React.useCallback(() => {
    if (onLinkClick) {
      onLinkClick(bank);
    }
  }, [onLinkClick, bank]);

  return (
    <BankItem logoUrl={bank.secondSquareImageUrl} name={bank.name} url={bank.searchUrl} onLinkClick={handleLinkClick} />
  );
};
