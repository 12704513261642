import * as React from 'react';

import { IRecommendationNewbuilding } from '../../../../types/recommendations';
import { ItemsCarousel } from '../../../ItemsCarousel';
import { NewbuildingCard } from '../NewbuildingCard';

export interface IRecommendationsListProps {
  newbuildings: IRecommendationNewbuilding[];
  onItemClick?(index: number): void;
}

export const RecommendationsList: React.FC<IRecommendationsListProps> = ({ newbuildings, onItemClick }) => {
  return (
    <ItemsCarousel onItemClick={onItemClick} size="M" scrollVisible>
      {newbuildings.map(item => (
        <NewbuildingCard
          key={item.id}
          address={item.address}
          forOffer={item.roomsSquareMeter}
          fromPrice={item.minPrice}
          label={item.status}
          media={item.media}
          name={item.name}
          underground={item.underground}
          url={item.url}
        />
      ))}
    </ItemsCarousel>
  );
};
