import * as React from 'react';

import { trackSelectionButtonClick } from './tracking';
import { SelectionsButton } from '../../components/SelectionsButton';
import { useApplicationContext } from '../../utils/applicationContext';
import { getNewbuildingSelectionsUrl } from '../../utils/config';

interface ISelectionsButtonContainerProps {
  analyticsType?: string;
  fullWidth?: boolean;
  secondary?: boolean;
  XXL?: boolean;
}

export const SelectionsButtonContainer: React.FC<ISelectionsButtonContainerProps> = ({
  analyticsType,
  fullWidth,
  secondary,
  XXL,
}) => {
  const { config } = useApplicationContext();
  const selectionsUrl = getNewbuildingSelectionsUrl(config);

  const handleSelectionButtonClick = React.useCallback(() => {
    trackSelectionButtonClick(analyticsType);
  }, []);

  return (
    <SelectionsButton
      onClick={handleSelectionButtonClick}
      href={selectionsUrl}
      fullWidth={fullWidth}
      XXL={XXL}
      secondary={secondary}
    />
  );
};
