import * as React from 'react';
import {
  ArticleHeading2,
  ArticleHeading3,
  ArticleParagraph1,
  ArticleParagraph2,
  ArticleParagraph3,
  ModalWindow,
} from '@cian/ui-kit';

import { IBankSchema, IFeatureSchema } from '../../types/promo';
import { BankItem } from './components/BankItem';
import { AuthorItem } from './components/AuthorItem';
import { getBankLogoUrl } from './helpers';

import * as styles from './PromoDetailsModal.css';

export interface IPromoDetailsModalProps {
  authorDescription?: string | null;
  authorName?: string | null;
  authorLabel?: string | null;
  authorLogoUrl?: string | null;
  contactsButton?: React.ReactNode;
  bank?: IBankSchema | null;
  banks?: IBankSchema[] | null;
  description?: string | null;
  features?: IFeatureSchema[] | null;
  mortgagePartnerLink: string | null;
  onClose(): void;
  onMortgageClick?(): void;
  open: boolean;
  subtitle?: string | null;
  title: string;
}

export const PromoDetailsModal: React.FC<IPromoDetailsModalProps> = ({
  authorDescription,
  authorLabel,
  authorLogoUrl,
  authorName,
  bank,
  banks,
  contactsButton,
  description,
  features,
  mortgagePartnerLink,
  onClose,
  onMortgageClick,
  open,
  subtitle,
  title,
}) => {
  return (
    <ModalWindow open={open} fixed={false} width="100%" maxWidth={688} onClose={onClose} escape outside>
      <div className={styles['content']} data-testid="PromoDetailsModal">
        <div className={styles['title']}>
          <ArticleHeading2 as="h2">{title}</ArticleHeading2>
        </div>
        {subtitle && (
          <div className={styles['subtitle']}>
            <ArticleParagraph1>{subtitle}</ArticleParagraph1>
          </div>
        )}
        {features && !!features.length && (
          <div className={styles['features-list']}>
            {features.map(({ name, value }, idx) => (
              <div key={idx} className={styles['feature']}>
                <ArticleParagraph3 color="gray60_100">{name}</ArticleParagraph3>
                <ArticleParagraph1>{value}</ArticleParagraph1>
              </div>
            ))}
          </div>
        )}
        {description && (
          <div className={styles['description']}>
            <ArticleParagraph1 whiteSpace="pre-line">{description}</ArticleParagraph1>
          </div>
        )}
        {banks && !!banks.length && (
          <div className={styles['banks']}>
            <div className={styles['banks-title']}>
              <ArticleHeading3>Банки</ArticleHeading3>
            </div>
            <ul className={styles['banks-list']}>
              {banks.map(bank => (
                <li key={bank.id} className={styles['bank-item']}>
                  <BankItem
                    logoUrl={getBankLogoUrl(bank)}
                    mortgagePartnerLink={mortgagePartnerLink}
                    name={bank.name}
                    onMortgageClick={onMortgageClick}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
        {bank && (
          <div className={styles['banks']}>
            <BankItem
              label="Банк"
              logoUrl={getBankLogoUrl(bank)}
              mortgagePartnerLink={mortgagePartnerLink}
              name={bank.name}
              onMortgageClick={onMortgageClick}
            />
          </div>
        )}
        {authorName && (
          <>
            <AuthorItem label={authorLabel} name={authorName} logoUrl={authorLogoUrl} contactsButton={contactsButton} />
            {authorDescription && (
              <div className={styles['author-description']}>
                <ArticleParagraph2 color="gray60_100">{authorDescription}</ArticleParagraph2>
              </div>
            )}
          </>
        )}
      </div>
    </ModalWindow>
  );
};
