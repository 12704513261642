import * as React from 'react';
import * as style from '../../NewbuildingItem.css';

interface IAddressProps {
  address?: string | null;
  undergroundName?: string | null;
}

export const Address: React.FC<IAddressProps> = props => {
  const { address, undergroundName } = props;

  if (!address || undergroundName) {
    return null;
  }

  return <div className={style['address']}>{props.address}</div>;
};
