import * as React from 'react';
import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import { useSelector } from 'react-redux';

import { NewbuildingReviewsTeaser } from '../../components/NewbuildingReviewsTeaser';
import { LayoutBlock } from '../../components/LayoutBlock';
import { EmptyReviewsTeaser, ManyReviewsTeaser } from '../../components/NewbuildingReviewsTeaser/parts';
import { getAnalyticsUser } from '../../selectors/analytics';
import { getProductsData } from '../../selectors/analytics/builder';
import {
  getBuilders,
  getNewbuildingDisplayName,
  getNewbuildingId,
  getNewbuildingReviewsStat,
} from '../../selectors/newbuilding';
import { getUrlPath } from '../../selectors/path';
import { trackNewbuildingReviewsAnalytic } from '../../utils/analytics/reviewsAnalytics';
import { MasterReviewContainer } from '../MasterReviewContainer';

export const EMPTY_REVIEWS_COUNT = 0;

export const NewbuildingReviewsTeaserContainer: React.FC = () => {
  const reviews = useSelector(getNewbuildingReviewsStat);
  const fullUrl = useSelector(getUrlPath);
  const name = useSelector(getNewbuildingDisplayName);

  const user = useSelector(getAnalyticsUser);

  const builder = useSelector(getBuilders)[0] || null;
  const id = useSelector(getNewbuildingId);
  const products = [getProductsData(id, builder)];

  const page = {
    pageType: 'CardJK',
    siteType: 'desktop',
    extra: {
      type: 'review',
      rating: reviews?.rating,
      PageTypeJK: 'Normal',
    },
  };

  const trackingReviewsTeaserView = React.useCallback(() => {
    trackNewbuildingReviewsAnalytic({
      action: 'Show_sopr',
      category: 'Card_JK',
      label: 'NewbuildingReviews',
      user,
      page,
      products,
    });
  }, []);

  const trackingShowReviewsLinkClick = React.useCallback(() => {
    trackNewbuildingReviewsAnalytic({
      action: 'Click_sopr',
      category: 'NewbuildingReviews',
      label: 'All_Reviews',
      user,
      page,
      products,
    });
  }, []);

  const trackingReadAllClick = React.useCallback(() => {
    trackNewbuildingReviewsAnalytic({
      action: 'Click_sopr',
      category: 'NewbuildingReviews',
      label: 'read_more',
      user,
      page,
      products,
    });
  }, []);

  if (!reviews || reviews.reviewsCount === EMPTY_REVIEWS_COUNT) {
    return <NewbuildingReviewsTeaser reviews={<EmptyReviewsTeaser name={name} fullUrl={fullUrl as string} />} />;
  }

  return (
    <LayoutBlock>
      <ActionAfterViewed callback={trackingReviewsTeaserView}>
        <NewbuildingReviewsTeaser
          reviews={
            <ManyReviewsTeaser
              fullUrl={fullUrl as string}
              rating={reviews.rating}
              reviews={reviews.reviews}
              sections={reviews.sections}
              showReviewsLinkClick={trackingShowReviewsLinkClick}
              readReviewLinkClick={trackingReadAllClick}
              masterReview={<MasterReviewContainer />}
              title={reviews.title}
            />
          }
        />
      </ActionAfterViewed>
    </LayoutBlock>
  );
};
