import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { useSelector } from 'react-redux';

import { Content, FullscreenPopup } from '../../components/FullscreenPopup';
import { InteriorDecoration } from '../../components/InteriorDecoration';
import { LayoutBlock } from '../../components/LayoutBlock';
import { getJKid, getInteriorDecoration } from '../../selectors/newbuilding';
import { AsideContentPopupHeader } from '../AsideContentPopupHeader';
import { trackClickInteriorDecoration, trackInteriorDecorationShowing } from './tracking';

export function InteriorDecorationContainer() {
  const list = useSelector(getInteriorDecoration);
  const id = useSelector(getJKid);
  const [isOpened, setOpenedState] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const onItemClick = React.useCallback(
    (i: number) => {
      setIndex(i);
      setOpenedState(true);
      trackClickInteriorDecoration(id, list[i].type);
    },
    [setOpenedState, setIndex],
  );

  const onClose = React.useCallback(() => {
    setOpenedState(false);
  }, [setOpenedState]);

  if (!list.length) {
    return null;
  }

  const item = list[index];

  return (
    <LayoutBlock>
      <ActionAfterViewObserver callback={trackInteriorDecorationShowing} triggerOnce>
        <InteriorDecoration items={list} onItemClick={onItemClick} />
      </ActionAfterViewObserver>
      <FullscreenPopup
        isOpened={isOpened}
        header={<AsideContentPopupHeader onClose={onClose} />}
        onClose={onClose}
        content={<Content title={item.title} description={item.description} images={item.images} />}
      />
    </LayoutBlock>
  );
}
