import { EOffersActionTypes } from '../../actions/offers';
import { IOffers } from '../../types/offers';
import { TReduxActions } from '../../types/redux';

export function offersReducer(state: IOffers = {} as IOffers, action: TReduxActions): IOffers {
  switch (action.type) {
    case EOffersActionTypes.SelectHouseId:
      return {
        ...state,
        selectedHouseId: action.payload,
      };
    case EOffersActionTypes.SetHouseStats:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.houseId]: action.payload.stats,
        },
      };
    default:
      return state;
  }
}
