import * as React from 'react';
import classNames from 'clsx';
import { IconChevronDownSmall16 } from '@cian/ui-kit';
import { ButtonLink } from '../ButtonLink';
import * as styles from './styles.css';

export interface IToggleButtonProps {
  children: React.ReactNode;
  isOpened: boolean;
  onClick(e: React.MouseEvent<HTMLElement>): void;
}

export function ToggleButton({ children, isOpened, onClick }: IToggleButtonProps) {
  return (
    <ButtonLink onClick={onClick} className={styles['button']}>
      {children}&nbsp;
      <span className={classNames(styles['icon'], isOpened && styles['icon--opened'])}>
        <IconChevronDownSmall16 color="primary_100" display="inline-block" />
      </span>
    </ButtonLink>
  );
}
