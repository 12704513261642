import * as React from 'react';
import * as styles from './ProblemsContent.css';
import { ProblemItem } from './ProblemItem';
import { ArticleHeading2, InlineText2 } from '@cian/ui-kit';
import { INewbuildingProblem } from '../../types/newbuilding/problem';

export interface IProblemsContent {
  problems: INewbuildingProblem[];
}

export function ProblemsContent({ problems }: IProblemsContent) {
  return (
    <div className={styles['container']}>
      <div className={styles['title']}>
        <ArticleHeading2 color="white_100">Осторожно! Есть проблемные корпуса</ArticleHeading2>
        <div className={styles['title-icon']} />
      </div>
      {problems.length ? (
        problems.map((problem, idx) => {
          return <ProblemItem key={idx} problem={problem} />;
        })
      ) : (
        <div className={styles['problem']}>
          <InlineText2 color="white_100">{`В жилом комплексе есть неизвестные проблемы.`}</InlineText2>
        </div>
      )}
    </div>
  );
}
