export interface IMediaItem {
  /** Текст для атрибута alt **/
  alt: string;
  /** Ссылка на исходное медиа **/
  fullUrl: string;
  /** ID медиа **/
  id: number;
  /** Главное изображение **/
  isPrimaryImage?: boolean;
  /** Тип медиа **/
  mediaType: EMediaType;
  /** Ссылка на превью **/
  thumbnailUrl: string;
  /** Ссылка на YouTube **/
  videoUrl?: string;
}

export enum EMediaType {
  /** Изображение **/
  'Image' = 'image',
  /** Карта **/
  'Map' = 'map',
  /** Видео **/
  'Video' = 'video',
}
