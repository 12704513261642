import { ca } from '@cian/analytics';

export const trackTransferToDeveloperCard = (developerCardLink: string) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'to_developerCard',
    label: developerCardLink,
    useLastProducts: true,
  });
};
