import * as React from 'react';
import * as styles from './LayoutBlock.css';

interface ILayoutBlockProps {
  'data-mark'?: string;
  'data-name'?: string;
  'data-testid'?: string;
  children: React.ReactNode;
  as?: 'div' | 'span';
}

/**
 * Представляет секцию в основном контенте страницы.
 */
export const LayoutBlock = React.forwardRef<HTMLDivElement, ILayoutBlockProps>(({ children, as, ...props }, ref) => {
  const Tag = as || 'div';

  return (
    <Tag {...props} ref={ref} className={styles['block']}>
      {children}
    </Tag>
  );
});

LayoutBlock.displayName = 'LayoutBlock';
