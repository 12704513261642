import { IPromoInfoSchema } from '../../types/promo';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EPromoInfoActionTypes {
  ClosePromoInfo = 'promoInfo/closePromoInfo',
  OpenPromoInfo = 'promoInfo/openPromoInfo',
}

export type TClosePromoInfo = ITypedReduxAction<EPromoInfoActionTypes.ClosePromoInfo>;

export const closePromoInfo = actionGenerator<EPromoInfoActionTypes.ClosePromoInfo>(
  EPromoInfoActionTypes.ClosePromoInfo,
);

interface IOpenPromoInfoPayload {
  promo: IPromoInfoSchema;
}

export type TOpenPromoInfo = ITypedReduxAction<EPromoInfoActionTypes.OpenPromoInfo, IOpenPromoInfoPayload>;

export const openPromoInfo = actionGenerator<EPromoInfoActionTypes.OpenPromoInfo, IOpenPromoInfoPayload>(
  EPromoInfoActionTypes.OpenPromoInfo,
);

export type TPromoInfoActions = TClosePromoInfo | TOpenPromoInfo;
