import * as React from 'react';
import { useSelector } from 'react-redux';
import { AerialViewModal } from '../../components/AerialViewModal';
import {
  getFirstBuilder,
  getHasOffersFromDeveloper,
  getIsLeadFactory,
  getNewbuildingDisplayName,
  getPriceForMeterFromDeveloperDisplay,
  getPriceForObjectFromDeveloperDisplay,
} from '../../selectors/newbuilding';
import { ActionButtonContainer } from '../ActionButton';
import { CallMeBackContainer } from '../CallMeBack';
import { getDescriptionText } from './helpers/getDescriptionText';
import { LeadFactoryCallButtonContainer } from '../LeadFactoryCallButton';
import { SelectionsButtonContainer } from '../SelectionsButtonContainer';

interface IAerialViewModalContainerProps {
  isOpen: boolean;
  source: string;
  onClose(): void;
}

export const AerialViewModalContainer: React.FC<IAerialViewModalContainerProps> = ({ isOpen, source, onClose }) => {
  const priceForObjectFromDeveloperDisplay = useSelector(getPriceForObjectFromDeveloperDisplay);
  const priceForMeterFromDeveloperDisplay = useSelector(getPriceForMeterFromDeveloperDisplay);
  const newbuildingName = useSelector(getNewbuildingDisplayName);
  const { name: builderName } = useSelector(getFirstBuilder) || {};
  const hasOffersFromDeveloper = useSelector(getHasOffersFromDeveloper);
  const description = getDescriptionText({ builderName, hasOffersFromDeveloper });

  const isLeadFactory = useSelector(getIsLeadFactory);

  const contactsButton = isLeadFactory ? (
    <LeadFactoryCallButtonContainer size="M" placeType="virtualLandTourModalWindow" />
  ) : (
    <ActionButtonContainer
      dynamicCalltrackingPlaceType="virtualLandTourModalWindow"
      analyticOnOpenActionName="Open_airphoto"
      fullWidth
      XXL
    />
  );

  const callbackButton = isLeadFactory ? (
    <SelectionsButtonContainer analyticsType="sidebar" fullWidth secondary XXL />
  ) : (
    <CallMeBackContainer placeType="airphoto" />
  );

  return (
    <AerialViewModal
      isOpen={isOpen}
      source={source}
      newbuildingName={newbuildingName}
      description={description}
      onClose={onClose}
      priceForMeterFromDeveloperDisplay={priceForMeterFromDeveloperDisplay}
      priceForObjectFromDeveloperDisplay={priceForObjectFromDeveloperDisplay}
      callbackButton={callbackButton}
      contactsButton={contactsButton}
    />
  );
};
