// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetNewbuildingValuationBlockWebRequest,
  TGetNewbuildingValuationBlockWebModel,
  IMappers,
  TGetNewbuildingValuationBlockWebResponse,
  IGetNewbuildingValuationBlockWebResponse,
  IGetNewbuildingValuationBlockWebResponseError,
} from './types';

export const defaultConfig: TGetNewbuildingValuationBlockWebModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'newbuilding-estimate',
  pathApi: '/v2/get-newbuilding-valuation-block-web/',
  requestType: 'json',
} as TGetNewbuildingValuationBlockWebModel;

export function createGetNewbuildingValuationBlockWebModel(
  parameters: IGetNewbuildingValuationBlockWebRequest,
): TGetNewbuildingValuationBlockWebModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewbuildingValuationBlockWebOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetNewbuildingValuationBlockWebRequest;
}

export async function fetchGetNewbuildingValuationBlockWeb<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetNewbuildingValuationBlockWebOptions<TResponse200, TResponse400>): Promise<
  TGetNewbuildingValuationBlockWebResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createGetNewbuildingValuationBlockWebModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetNewbuildingValuationBlockWebResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetNewbuildingValuationBlockWebResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetNewbuildingValuationBlockWebResponse;
}
