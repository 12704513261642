import * as React from 'react';
import { useSelector } from 'react-redux';

import { SecondaryContactButtons } from '../../components/SecondaryContactButtons';
import { getIsFlatViewOrderEnabled } from '../../selectors/newbuilding';
import { getAgentForCallback } from '../../selectors/newbuilding/agent';
import { CallMeBackContainer } from '../CallMeBack';
import { FlatViewOrderButtonContainer } from '../FlatViewOrderButton';

export const SecondaryContactButtonsContainer = () => {
  const isFlatViewEnabled = useSelector(getIsFlatViewOrderEnabled);
  const agentForCallback = useSelector(getAgentForCallback);

  if (!agentForCallback && !isFlatViewEnabled) {
    return null;
  }

  return (
    <SecondaryContactButtons
      callbackButton={agentForCallback ? <CallMeBackContainer placeType="floating_block" /> : null}
      flatViewButton={isFlatViewEnabled ? <FlatViewOrderButtonContainer /> : null}
    />
  );
};
