import * as React from 'react';
import { Button, UIText2 } from '@cian/ui-kit';
import * as styles from './BrokerBlock.css';

interface IBrokerBlock {
  loading: boolean;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  rewardTariffsLink: string;
  buttonText: string;
}

export const BrokerBlock: React.FC<IBrokerBlock> = ({ loading, onClick, rewardTariffsLink, buttonText }) => {
  return (
    <div className={styles['wrapper']} data-testid="BrokerBlock">
      <div className={styles['container']}>
        <div className={styles['item']}>
          <UIText2 whiteSpace="nowrap" color="black_100">
            Заработайте комиссию от застройщика
          </UIText2>
          <div className={styles['divider']} />
          <UIText2 whiteSpace="nowrap" color="black_100">
            от 0,7%
          </UIText2>
        </div>
        <a
          className={styles['link']}
          href={rewardTariffsLink}
          data-testid="BrokerLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          Сколько можно зарабатывать
        </a>
      </div>
      <div className={styles['button']}>
        <Button
          loading={loading}
          onClick={onClick}
          size="M"
          theme="fill_success_primary"
          type="button"
          fullWidth
          data-testid="BrokerButton"
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
