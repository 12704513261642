import * as React from 'react';
import { useSelector } from 'react-redux';
import * as style from './style.css';
import { LayoutBlock } from '../../../LayoutBlock';
import { getGenplanSelector } from '../../../../selectors/newbuilding';

export const GenplanNavContainer = ({ children }: React.PropsWithChildren) => {
  const genplan = useSelector(getGenplanSelector);

  if (!genplan) {
    return null;
  }

  return (
    <LayoutBlock>
      <div data-nav-tab="genplan/Генплан" id="genplan" className={style['nav-container']}>
        {children}
      </div>
    </LayoutBlock>
  );
};
