import { phoneToString } from '../../../utils/businessLogic/phone/phoneToString';
import { INewbuildingBuilder } from '../../../types/newbuilding/builder';

export function getPageData(builder: INewbuildingBuilder) {
  if (!builder || !builder.phoneAnalyticsData) {
    return {
      pageType: 'CardJK',
      siteType: 'desktop',
      extra: {
        PageTypeJK: 'Normal',
      },
    };
  }

  const { phoneAnalyticsData, phone } = builder;

  return {
    dealType: phoneAnalyticsData && phoneAnalyticsData.dealType,
    objectType: phoneAnalyticsData && phoneAnalyticsData.objectType,
    offerID: builder.id,
    offerPhone: phoneToString(phone),
    pageType: 'CardJK',
    siteType: 'desktop',
    extra: {
      PageTypeJK: 'Normal',
    },
  };
}
