import * as React from 'react';
import { Button } from '@cian/ui-kit';

export interface IFlatViewOrderButtonProps {
  disabled?: boolean;
  onClick(e: React.MouseEvent<HTMLElement>): void;
  small?: boolean;
}

export function FlatViewOrderButton({ onClick, disabled, small }: IFlatViewOrderButtonProps) {
  return (
    <Button theme="fill_secondary" size={small ? 'XS' : 'M'} onClick={onClick} disabled={disabled} fullWidth>
      Записаться на просмотр
    </Button>
  );
}
