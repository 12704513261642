import * as React from 'react';
import { ModalWindow } from '@cian/ui-kit';
import { ButtonLink } from '../../components/ButtonLink';
import { EscrowDescription } from '../../components/EscrowDescription/EscrowDescription';

export const EscrowDescriptionContainer = () => {
  const [isOpen, setOpen] = React.useState(false);

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <ModalWindow open={isOpen} content={<EscrowDescription />} escape onClose={onClose} size="M" width={610} />
      <ButtonLink onClick={onOpen}>В чём преимущества?</ButtonLink>
    </>
  );
};
