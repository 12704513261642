import {
  fetchGetFlatTourBookingSchedule,
  IGetFlatTourBookingScheduleResponse200,
  IGetFlatTourBookingScheduleResponse400,
} from '../../../shared/repositories/newbuilding-calltracking/v1/get-flat-tour-booking-schedule';
import { getIsFlatViewOrderEnabled, getNewbuildingId } from '../../../shared/selectors/newbuilding';
import { setScheduleData } from '../../../shared/actions/flatTourBooking';
import { transformFlatTourBooking } from '../../../shared/mappers/transformFlatTourBooking';
import { isValidFlatTourSchedule } from '../../../shared/utils/flatTourSchedule';
import { TThunkAction } from '../../../shared/types/redux/thunk';

export function getFlatTourSchedule(): TThunkAction {
  return async (dispatch, getState, { httpApi, logger }): Promise<void> => {
    try {
      const isTourEnabled = getIsFlatViewOrderEnabled(getState());

      if (!isTourEnabled) {
        return;
      }

      const newbuildingId = getNewbuildingId(getState());

      const response = await fetchGetFlatTourBookingSchedule<
        IGetFlatTourBookingScheduleResponse200,
        IGetFlatTourBookingScheduleResponse400
      >({
        httpApi,
        parameters: { newbuildingId },
      });

      if (response.statusCode !== 200) {
        throw response.response.message;
      }

      if (!isValidFlatTourSchedule(response.response.schedule)) {
        throw new Error(`Ошибка валидации ISchedule в getFlatTourSchedule`);
      }

      const preparedData = transformFlatTourBooking(response.response.schedule);
      dispatch(setScheduleData(preparedData));
    } catch (error) {
      logger.error(error, {
        domain: 'getFlatTourSchedule',
      });
    }
  };
}
