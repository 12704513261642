import { ca } from '@cian/analytics';

export const trackStickyHeaderTabClick = (label: string) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'click_tab',
    label,
    useLastProducts: true,
  });
};
