import { Microfrontend } from '@cian/mf-react';
import { ICianMFUIElement } from '@cian/mf-registry/browser';
import { IMicrofrontendManifest } from '@cian/mf-registry/shared';
import * as React from 'react';

interface INewbuildingBrokerWidgetContainerProps {
  widgetManifest: IMicrofrontendManifest | undefined;
  widgetRef: React.RefObject<ICianMFUIElement>;
}

export const NewbuildingBrokerWidgetContainer: React.FC<INewbuildingBrokerWidgetContainerProps> = ({
  widgetManifest,
  widgetRef,
}) => {
  if (!widgetManifest) {
    return null;
  }

  return (
    <Microfrontend
      ref={widgetRef}
      name={widgetManifest.microfrontendName}
      image={widgetManifest.imageVersion}
      runtime={widgetManifest.runtimeName}
      instance={widgetManifest.instanceId}
    />
  );
};
