import * as React from 'react';
import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { registry as getRegistry } from '@cian/mf-registry/browser';
import * as ReactDOM from 'react-dom/client';
import { Application } from '../../../shared/containers/App';
import { TReduxStore } from '../../../shared/types/redux';
import { getTelemetry } from '@cian/telemetry/browser';

interface IDependencies {
  store: TReduxStore;
}

export function renderApplication(dependencies: IDependencies) {
  const config = getConfig();
  const httpApi = getHttpApi();
  const logger = getLogger();
  const microfrontendsRegistry = getRegistry();
  const telemetry = getTelemetry();
  const { store } = dependencies;

  const projectName = config.getStrict<string>('projectName');
  const rootElement = document.getElementById(projectName as string);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  ReactDOM.hydrateRoot(
    rootElement,
    <Application
      config={config}
      logger={logger}
      httpApi={httpApi}
      telemetry={telemetry}
      reduxStore={store}
      microfrontendsRegistry={microfrontendsRegistry}
    />,
  );
}
