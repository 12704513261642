import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import * as style from './image.css';
import { NoImage } from '../NoImage';

enum LoadingStatus {
  loading = 0,
  loaded = 1,
  error = 2,
}

export interface IImageSimpleState {
  status: LoadingStatus;
}

export interface IImageProps {
  alt?: string;
  src: string;
}

export class Image extends React.Component<IImageProps, IImageSimpleState> {
  public constructor(props: IImageProps) {
    super(props);
    this.state = { status: LoadingStatus.loading };
  }

  public render() {
    const { alt = '', src } = this.props;

    const isLoaded = this.state.status === LoadingStatus.loaded;
    const isLoading = this.state.status === LoadingStatus.loading;
    const isError = this.state.status === LoadingStatus.error;

    return (
      <div {...mergeStyles(style['image'])}>
        {!isError && (
          <div {...mergeStyles(style['image-container'], isLoaded && style['image-loaded'])}>
            <img alt={alt} src={src} onLoad={this.onLoad} onError={this.onError} />
          </div>
        )}
        {!isLoading && isError && <NoImage />}
      </div>
    );
  }

  public onLoad = () => {
    this.setState({ status: LoadingStatus.loaded });
  };

  public onError = () => {
    this.setState({ status: LoadingStatus.error });
  };
}
