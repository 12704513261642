import * as React from 'react';
import { Button, UIHeading2, UIText2, UIText3 } from '@cian/ui-kit';

import { PARTNER_BANKS_COUNT, PARTNER_BANKS_ICONS } from './constants';
import * as styles from './CianMortageBanner.css';

export interface ICianMortgageBannerProps {
  monthlyPayment: string;
  onClick?(event: React.MouseEvent): void;
  url: string;
}

export const CianMortgageBanner: React.FC<ICianMortgageBannerProps> = ({ monthlyPayment, onClick, url }) => {
  return (
    <a
      className={styles['banner']}
      href={url}
      onClick={onClick}
      rel="noopener noreferrer"
      target="_blank"
      data-testid="CianMortgageBanner"
    >
      <div className={styles['logo-and-banks']}>
        <div className={styles['logo']} />
        <div className={styles['banks']}>
          {PARTNER_BANKS_ICONS.map(icon => (
            <div key={icon.key} className={styles['circle']}>
              <div className={styles['bank-logo']} style={{ backgroundImage: `url("${icon.source}")` }} />
            </div>
          ))}
          <div className={styles['circle']}>
            <UIText3>{PARTNER_BANKS_COUNT}</UIText3>
          </div>
        </div>
      </div>
      <div className={styles['payment']}>
        <UIText2>Ежемесячный платеж</UIText2>
        <UIHeading2 data-mark="BannerMonthlyPayment">{monthlyPayment}</UIHeading2>
      </div>
      <div className={styles['button']}>
        <Button type="button" fullWidth>
          Подать заявку онлайн
        </Button>
      </div>
    </a>
  );
};
