import {
  fetchCreateUpcomingSaleRequest,
  ICreateUpcomingSaleRequestRequest,
  ICreateUpcomingSaleRequestResponse400,
} from '../../../shared/repositories/newbuilding-flat-view-order/v1/create-upcoming-sale-request';
import { getNewbuildingId, getRegionId } from '../../../shared/selectors/newbuilding';

import { TThunkAction } from '../../../shared/types/redux/thunk';

type ICreateUpcomingSaleRequestParams = Pick<ICreateUpcomingSaleRequestRequest, 'phone' | 'userName' | 'email'>;

export function createUpcomingSaleRequest(params: ICreateUpcomingSaleRequestParams): TThunkAction<Promise<void>> {
  return async (dispatch, getState, { httpApi, logger }): Promise<void> => {
    try {
      const newbuildingId = getNewbuildingId(getState());
      const regionId = getRegionId(getState());

      const response = await fetchCreateUpcomingSaleRequest<ICreateUpcomingSaleRequestResponse400>({
        httpApi,
        parameters: {
          ...params,
          newbuildingId,
          regionId: regionId as number,
        },
        config: {
          requestConfig: {
            headers: [['Content-Type', 'application/json']],
          },
        },
      });

      if (![200, 204].includes(response.statusCode)) {
        throw (response as ICreateUpcomingSaleRequestResponse400).response.message;
      }
    } catch (error) {
      logger.error(error, {
        domain: 'createUpcomingSaleRequest',
      });
      throw error;
    }
  };
}
