import { ca } from '@cian/analytics';

export function trackAerialViewOpen() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'CardJK',
    action: 'show',
    label: 'popup_airphoto',
    useLastProducts: true,
  });
}
