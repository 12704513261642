import * as React from 'react';
import { LayoutBlock } from '../LayoutBlock';
import { Feature, BackgroundImage } from './parts';
import * as style from './Features.css';
import { Section } from '../Section';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { trackConstructionProgressShow } from './tracking';

export interface IFeaturesProps {
  hasProgressOfConstruction?: boolean | null;
  path: string | null;
  trackOpenProgressOfConstruction(): void;
}

export const Features: React.FC<IFeaturesProps> = props => {
  const { hasProgressOfConstruction, path, trackOpenProgressOfConstruction } = props;
  const progressOfConstructionLink = `${path}hod-stroitelstva/`;

  if (!hasProgressOfConstruction) {
    return null;
  }

  return (
    <LayoutBlock>
      <Section data-mark="Features">
        <div className={style['features']}>
          <div className={style['left']}>
            <ActionAfterViewObserver callback={trackConstructionProgressShow} triggerOnce={true}>
              <Feature
                id="building-process"
                backgroundNode={<BackgroundImage />}
                url={progressOfConstructionLink}
                title={'Ход строительства'}
                onClick={trackOpenProgressOfConstruction}
              />
            </ActionAfterViewObserver>
          </div>
        </div>
      </Section>
    </LayoutBlock>
  );
};
