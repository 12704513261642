import { EUserActionTypes } from '../../actions/user';
import { TUser } from '../../types/user';

export interface IUserAction {
  type: EUserActionTypes;
  payload: TUser;
}

export function userReduces(state: TUser, action: IUserAction) {
  switch (action.type) {
    case EUserActionTypes.SetUser:
      return action.payload;
    default:
      return { ...state };
  }
}
