import { ca } from '@cian/analytics';
import { TThunkAction } from '../../types/redux/thunk';

// Клик на ссылку на ход строительства
export function trackOpenProgressOfConstruction(): TThunkAction {
  return async () => {
    ca('eventSite', {
      name: 'oldevent',
      category: 'Card_JK',
      action: 'open_buildingProgress',
      label: '',
      pageType: 'CardJK',
      useLastProducts: true,
    });
  };
}
