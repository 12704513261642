import * as React from 'react';
import { InlineText1 } from '@cian/ui-kit';

import { Link } from '../Link';

import * as styles from './styles.css';

interface IOffersFooterProps {
  fromAgentsPropsCountDisplay?: string | null;
  fromAgentsPropsCountUrl?: string | null;
  propsCountDisplay: string;
  propsCountUrl: string;
  isHouseSelected: boolean;
  disclaimerText?: string | null;
  onAgentOffersLinkClick(): void;
  onAllOffersLinkClick(): void;
}

export function OffersFooter({
  fromAgentsPropsCountDisplay,
  fromAgentsPropsCountUrl,
  propsCountDisplay,
  propsCountUrl,
  isHouseSelected,
  disclaimerText,
  onAgentOffersLinkClick,
  onAllOffersLinkClick,
}: IOffersFooterProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['linksContainer']}>
        {fromAgentsPropsCountDisplay && fromAgentsPropsCountUrl && (
          <InlineText1>
            <Link
              url={fromAgentsPropsCountUrl}
              target="blank"
              onClick={onAgentOffersLinkClick}
            >{`Посмотреть ${fromAgentsPropsCountDisplay}`}</Link>
            {' от агентов и собственников'}
          </InlineText1>
        )}
        {!!propsCountDisplay && (
          <div className={styles['count']}>
            <InlineText1>
              Всего{' '}
              <Link url={propsCountUrl} target="blank" onClick={onAllOffersLinkClick}>
                {propsCountDisplay}
              </Link>
              {isHouseSelected ? ' в этом корпусе' : ' в ЖК'}
            </InlineText1>
          </div>
        )}
      </div>
      {!!disclaimerText && (
        <div className={styles['disclaimer']}>
          <InlineText1 color="gray60_100">{disclaimerText}</InlineText1>
        </div>
      )}
    </div>
  );
}
