import * as React from 'react';
import { scrollToAnchor } from '@cian/newbuilding-utils';
import { IconWarning16, IconTrust16, IconHighDemand16 } from '@cian/ui-kit';
import { useSelector } from 'react-redux';

import { LabelsLayout, TooltipLabel } from '../../components/Labels';
import {
  getIsReliable,
  getHasProblems,
  getIsEscrow,
  getDiscountMortgage,
  getReliableLabel,
} from '../../selectors/newbuilding';
import { trackLabelClick } from './tracking';
import { isReliabilityEnabledSelector } from '../../selectors/reliability/isReliabilityEnabledSelector';
import { ReliableLabel } from '../../components/Labels/ReliableLabel';

export function LabelsContainer() {
  const hasDiscountMortgage = useSelector(getDiscountMortgage);
  const isReliable = useSelector(getIsReliable);
  const hasProblems = useSelector(getHasProblems);
  const isEscrow = useSelector(getIsEscrow);
  const isReliabilityV2Enabled = useSelector(isReliabilityEnabledSelector);
  const reliableLabel = useSelector(getReliableLabel);

  const onLabelClick = (anchor: string, label: string) => (event: React.MouseEvent<HTMLElement>) => {
    scrollToAnchor({
      anchor,
      offset: 0,
      hasRelocation: false,
      hasAnimation: true,
    })(event);
    trackLabelClick(label);
  };

  return (
    <LabelsLayout>
      {hasDiscountMortgage && (
        <TooltipLabel
          background="#8729ff"
          color="white_100"
          icon={<IconHighDemand16 color="white_100" />}
          text="Льготная ипотека"
          tooltipText={
            <>
              Подробнее узнавайте <br /> у застройщика
            </>
          }
        />
      )}
      {reliableLabel && isReliabilityV2Enabled && (
        <ReliableLabel
          label={reliableLabel.title}
          reliableType={reliableLabel.type}
          onReliableClick={onLabelClick('#reliable', 'tab_reliable_dev')}
          onProblemClick={onLabelClick('#reliable', 'tab_problem_building')}
        />
      )}
      {isReliable && !isReliabilityV2Enabled && (
        <TooltipLabel
          text="Надёжный ЖК"
          background="primary_100"
          color="white_100"
          tooltipText="Подробнее о надёжности"
          icon={<IconTrust16 color="white_100" />}
          onClick={onLabelClick('#reliable', 'tab_reliable_dev')}
        />
      )}
      {hasProblems && !isReliabilityV2Enabled && (
        <TooltipLabel
          text="Стройка заморожена"
          background="error_100"
          color="white_100"
          tooltipText="Узнать подробности"
          icon={<IconWarning16 color="white_100" />}
          onClick={onLabelClick('#problem', 'tab_problem_building')}
        />
      )}
      {isEscrow && (
        <TooltipLabel
          text="Эскроу"
          background="primary_10"
          color="primary_100"
          tooltipText="Подробнее об эскроу–счёте"
          onClick={onLabelClick('#escrow', 'tab_escrow')}
        />
      )}
    </LabelsLayout>
  );
}
