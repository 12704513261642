import * as React from 'react';
import { IconWalk16, IconBus16, InlineText2 } from '@cian/ui-kit';
import { pluralize } from '@cian/newbuilding-utils';

interface IFromUndergroundProps {
  timeTransport?: number | null;
  timeWalk?: number | null;
}

export const FromUndergroundTime: React.FC<IFromUndergroundProps> = props => {
  const { timeTransport, timeWalk } = props;

  if (timeWalk && timeWalk <= 20) {
    return (
      <>
        <div style={{ lineHeight: '12px' }}>
          <IconWalk16 color={'gray_icons_100'} />
        </div>
        <InlineText2 color={'black_60'}>{pluralize(timeWalk, ['минута', 'минуты', 'минут'])}</InlineText2>
      </>
    );
  }
  if (timeTransport) {
    return (
      <>
        <div style={{ marginRight: '4px', lineHeight: '12px' }}>
          <IconBus16 color={'gray_icons_100'} />
        </div>
        <InlineText2 color={'black_60'}>{pluralize(timeTransport, ['минута', 'минуты', 'минут'])}</InlineText2>
      </>
    );
  }

  return null;
};
