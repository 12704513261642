import * as React from 'react';
import { ArrowR8 } from '@cian/ui-kit/prev';
import * as style from './BreadCrumbs.css';
import { PageRowLayout } from '../PageRowLayout';
import { IBreadcrumb } from '../../types/newbuilding/breadcrumb';

export interface IBreadCrumbsProps {
  breadCrumbs: IBreadcrumb[];
}

export class BreadCrumbs extends React.PureComponent<IBreadCrumbsProps> {
  public render() {
    const { breadCrumbs } = this.props;

    if (!breadCrumbs.length) {
      return null;
    }

    return (
      <PageRowLayout>
        <div className={style['container']} aria-label="Ссылки на поиск по квартирам">
          {breadCrumbs.map((crumb, index) => {
            const fullUrl = crumb.fullUrl || '/';
            const title = crumb.title || 'Ссылка';

            return (
              <React.Fragment key={`${title}${index}`}>
                {index === 0 ? null : (
                  <span className={style['arrow']}>
                    <ArrowR8 color={'gray_icons_100'} />
                  </span>
                )}
                <a className={style['crumb']} href={fullUrl} title={title}>
                  {title}
                </a>
              </React.Fragment>
            );
          })}
        </div>
      </PageRowLayout>
    );
  }
}
