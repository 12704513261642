import * as React from 'react';

export const EscrowIconLarge = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="66" height="63" viewBox="0 0 66 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.24716e-09 11.4337L29.9145 0L57.283 11.4337C57.283 11.4337 57.283 22.5498 57.283 34.3011C57.283 46.7024 42.6586 61.7423 29.9145 62.2501C16.327 62.7916 -0.000368977 47.5655 6.25415e-09 34.3011C0.000318063 22.8674 6.24716e-09 11.4337 6.24716e-09 11.4337Z"
        fill="#0468FF"
        fillOpacity="0.1"
      />
      <path d="M44.2077 14.9434L66.0002 32.9902V36.7358H22.4153V32.9902L44.2077 14.9434Z" fill="#0468FF" />
      <rect x="26.7734" y="36.7358" width="4.98113" height="20.5472" fill="#0468FF" />
      <rect x="25.528" y="36.7358" width="15.566" height="2.49057" fill="#0468FF" />
      <rect x="25.528" y="54.793" width="15.566" height="2.49057" fill="#0468FF" />
      <rect x="47.3205" y="36.7358" width="15.566" height="2.49057" fill="#0468FF" />
      <rect x="47.3205" y="54.793" width="15.566" height="2.49057" fill="#0468FF" />
      <rect x="48.5659" y="36.7358" width="4.98113" height="20.5472" fill="#0468FF" />
      <rect x="34.8677" y="36.7358" width="4.98113" height="20.5472" fill="#0468FF" />
      <rect x="56.6601" y="36.7358" width="4.98113" height="20.5472" fill="#0468FF" />
      <rect x="22.4148" y="57.2832" width="43.5849" height="4.98113" fill="#0468FF" />
      <circle cx="44.2074" cy="27.3965" r="3.73585" fill="white" />
    </svg>
  );
};

export const EscrowRoundIcon = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="17.5" fill="#0468FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 14.875L20 7.5L10 14.875V17.5H12.5V25H10V30H30V25H27.5V17.5H30V14.875ZM22.5 17.5H17.5V25H22.5V17.5Z"
        fill="white"
      />
    </svg>
  );
};
