import { UIHeading4, UIText1 } from '@cian/ui-kit';
import * as React from 'react';
import * as style from './ReliableLabel.css';
import { Link } from '../Link';

interface IReliableLabelProps {
  onClick(event: React.MouseEvent<HTMLElement>): void;
  title: string;
  isBoldTitle: boolean;
}

export const ReliableBanner = ({ onClick, title, isBoldTitle }: IReliableLabelProps) => {
  return (
    <div className={style['container']} data-testid="ReliableBanner">
      <div className={style['logo-block']}>
        <div className={style['logo']}></div>
      </div>
      <div className={style['info-block']}>
        {isBoldTitle ? <UIHeading4 as="h4">{title}</UIHeading4> : <UIText1>{title}</UIText1>}
        <Link url={'#reliable'} onClick={onClick} linkStyle={style['link']}>
          Узнать больше
        </Link>
      </div>
    </div>
  );
};
