import * as React from 'react';
import { InlineText3 } from '@cian/ui-kit';
import classNames from 'clsx';

import { CollapsedBlock } from '../CollapsedBlock';
import { ImageSourcesButton } from './ImageSourcesButton';

import * as styles from './styles.css';

export const ImageSources = ({ children, defaultOpened }: React.PropsWithChildren<{ defaultOpened?: boolean }>) => {
  const [opened, setOpened] = React.useState(defaultOpened || false);

  return (
    <div className={styles['container']}>
      <CollapsedBlock
        collapsedHeight={20}
        className={classNames(styles['block'], opened && styles['block--opened'])}
        onChangeOpen={setOpened}
        Button={ImageSourcesButton}
      >
        <InlineText3 color={'gray20_100'}>{children}</InlineText3>
      </CollapsedBlock>
    </div>
  );
};
