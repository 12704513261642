import { connect } from 'react-redux';
import { PromoLabel, IPromoLabelProps } from '../../components/PromoLabel';
import { getPromoLabelType, getFirstPromoIds, getNewbuildingId } from '../../selectors/newbuilding';
import { IApplicationState } from '../../types/redux';

export function mapStateToProps(state: IApplicationState): IPromoLabelProps {
  const { promoId, uniquePromoId } = getFirstPromoIds(state);

  return {
    newbuildingId: getNewbuildingId(state),
    promoLabelType: getPromoLabelType(state),
    promoId,
    uniquePromoId,
  };
}

export const PromoLabelContainer = connect(mapStateToProps)(PromoLabel);
