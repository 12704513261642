interface IGetMortgageOffersTitleParams {
  hasBanks: boolean;
  hasMortgageOffers: boolean;
}

export const getSectionTitle = ({ hasBanks, hasMortgageOffers }: IGetMortgageOffersTitleParams): string => {
  if (hasBanks && hasMortgageOffers) {
    return 'Ипотечные предложения и аккредитованные банки';
  }

  if (hasBanks) {
    return 'Аккредитованные банки';
  }

  return 'Ипотечные предложения';
};
