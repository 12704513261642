import * as React from 'react';
import { IconArrowUpRight16 } from '@cian/ui-kit';
import * as styles from './SpecialPromoLink.css';
import { Link } from '../Link';

export interface ISpecialPromoLinkProps {
  url: string;
  label: string;
  logoUrl?: string | null;
  trackSpecialPromoClick?(): void;
}

export const SpecialPromoLink: React.FC<ISpecialPromoLinkProps> = (props: ISpecialPromoLinkProps) => {
  const { url, label, logoUrl, trackSpecialPromoClick } = props;

  return (
    <Link url={url} target="blank" onClick={trackSpecialPromoClick} linkStyle={styles['link']}>
      {logoUrl && <img className={styles['logo']} src={logoUrl} />}
      {label}
      <IconArrowUpRight16 color={'primary_100'} />
    </Link>
  );
};
