import { INewbuildingChat } from '../../types/redux/newbuildingChat';
import { ENewbuildingChatActionTypes, TNewbuildingChatActions } from '../../actions/nebuildingChat';

const initialState = {
  isOpen: false,
};

export const newbuildingChatReducer = (state: INewbuildingChat = initialState, action: TNewbuildingChatActions) => {
  switch (action.type) {
    case ENewbuildingChatActionTypes.ToggleOpen:
      return {
        isOpen: action.payload,
      };
    default:
      return state;
  }
};
