import * as React from 'react';

export const NoLayoutImage = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0875 8.44647C18.9937 8.29274 17.9823 9.05484 17.8286 10.1487L17.4563 12.7977L19.5202 12.4827L19.8091 10.427L28.7215 11.6796L27.469 20.592L24.7738 20.2132L25.3396 24.332L26.9123 24.5531C28.0061 24.7068 29.0174 23.9447 29.1712 22.8509L30.7021 11.9579C30.8558 10.8641 30.0937 9.85275 28.9999 9.69903L20.0875 8.44647Z"
      fill="#A1A7B3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.29788 17.9581C9.14415 16.8643 9.90625 15.8529 11.0001 15.6992L19.9125 14.4466C21.0063 14.2929 22.0176 15.055 22.1714 16.1488L23.7023 27.0418C23.856 28.1356 23.0939 29.1469 22.0001 29.3007L13.0877 30.5532C11.9938 30.7069 10.9825 29.9448 10.8288 28.851L9.29788 17.9581ZM13.5373 19.3819C13.6142 19.9288 13.2331 20.4345 12.6862 20.5114C12.1393 20.5882 11.6336 20.2072 11.5568 19.6603C11.4799 19.1134 11.8609 18.6077 12.4079 18.5308C12.9548 18.454 13.4604 18.835 13.5373 19.3819ZM21.5825 26.3298L20.7475 20.3882L17.4328 25.0953L15.5112 23.5477L12.6701 27.5824L16.0123 27.1126L16.0123 27.1127L21.5825 26.3298Z"
      fill="#A1A7B3"
    />
  </svg>
);

NoLayoutImage.displayName = 'NoLayoutImage';
