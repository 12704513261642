import * as React from 'react';
import { TooltipDesktop, UIText2, Label } from '@cian/ui-kit';

import * as styles from './styles.css';

export interface IReliableLabelTooltipProps {
  icon?: React.ReactElement;
  text: string;
  tooltipText: React.ReactElement | string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  background: string;
  color: string;
}

export function TooltipLabel({ icon, text, tooltipText, background, color, onClick }: IReliableLabelTooltipProps) {
  return (
    <TooltipDesktop
      theme="black"
      placement="top"
      title={
        <div className={styles['tooltip']}>
          <UIText2 color="white_100">{tooltipText}</UIText2>
        </div>
      }
    >
      <div className={styles['label']} onClick={onClick} data-testid="TooltipLabel">
        <Label background={background} color={color} icon={icon}>
          {text}
        </Label>
      </div>
    </TooltipDesktop>
  );
}
