import clsx from 'clsx';
import * as React from 'react';

import * as styles from './DoubleColumnLayout.css';
import { LayoutBlock } from '../LayoutBlock';

interface IDoubleColumnLayoutProps extends React.PropsWithChildren {
  rightColumn: React.ReactNode;
  topContent?: boolean;
}

export const DoubleColumnLayout: React.FC<IDoubleColumnLayoutProps> = ({ children, rightColumn, topContent }) => {
  return (
    <LayoutBlock>
      <div className={clsx(styles['content'], topContent && styles['content--top'])}>
        <div className={styles['left-column']}>{children}</div>
        <aside className={styles['right-column']} data-testid="RightColumn">
          {rightColumn}
        </aside>
      </div>
    </LayoutBlock>
  );
};
