import * as React from 'react';
import { Link } from '../../../Link';
import * as style from './Feature.css';

export interface IFeatureProps {
  backgroundNode?: React.ReactNode;
  url?: string;
  title: string;
  id: string;
  onClick(): void;
}

export const Feature: React.FC<IFeatureProps> = props => {
  const { url, title, onClick, backgroundNode, id } = props;

  if (!url || !title) {
    return null;
  }

  return (
    <div className={style['feature']} onClick={onClick} id={id}>
      {backgroundNode ? backgroundNode : null}
      <div className={style['hoverBackground']} />
      <Link url={url} target="blank" linkStyle={style['link']}>
        {title}
      </Link>
    </div>
  );
};
