// tslint:disable:no-any  trailing-comma
import * as React from 'react';
import { IconArrowUpRight16 } from '@cian/ui-kit';
import { InlineText1, ITypographyComponentProps } from '@cian/ui-kit/typography';

import * as styles from './styles.css';

interface IHoveredLinkProps extends ITypographyComponentProps {
  url: string;
  childNode?: React.ComponentType<ITypographyComponentProps>;
}

export function HoveredLink({
  url,
  childNode: ChildNode = InlineText1,
  children,
  color = 'primary_100',
  ...nodeProps
}: React.PropsWithChildren<IHoveredLinkProps>) {
  const [hovered, setHovered] = React.useState(false);

  const changeHovered = React.useCallback(() => setHovered(!hovered), [hovered]);

  return (
    <a
      className={styles['link']}
      href={url}
      target="_blank"
      rel="noreferrer"
      onMouseOver={changeHovered}
      onMouseOut={changeHovered}
    >
      <ChildNode {...nodeProps} color={hovered ? 'primary_100' : color}>
        {children}
      </ChildNode>
      <div className={styles['arrow']}>
        <IconArrowUpRight16 color="primary_100" />
      </div>
    </a>
  );
}
