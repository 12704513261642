import { ca } from '@cian/analytics';
import { TAdvantageType } from '../../types/newbuilding/advantages';

export function trackAdvantagesItemClick(label: TAdvantageType) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'click_features_jk',
    label,
  });
}
