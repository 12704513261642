import * as React from 'react';
import YouTube from '@u-wave/react-youtube';
import { EPlayerIconType } from '../VideoPreview/Icons';
import { VideoPreview } from '../VideoPreview';

import * as styles from './styles.css';

export interface IVideoPlayerProps {
  isFullscreenSlideActive?: boolean;
  videoId: string;
  thumbnailUrl: string;
}

interface IVideoPlayerState {
  isActive: boolean;
}

export class VideoPlayer extends React.Component<IVideoPlayerProps, IVideoPlayerState> {
  public state: IVideoPlayerState = {
    isActive: Boolean(this.props.isFullscreenSlideActive),
  };

  public static getDerivedStateFromProps(props: IVideoPlayerProps, state: IVideoPlayerState) {
    if (state.isActive && props.isFullscreenSlideActive === false) {
      return { isActive: false };
    }

    return null;
  }

  public render() {
    const { isActive } = this.state;

    const content = isActive ? this.renderPlayer() : this.renderPreview();

    return <div className={styles['videoContainer']}>{content}</div>;
  }

  private renderPreview = () => {
    return (
      <VideoPreview
        url={this.props.thumbnailUrl}
        onClick={this.handlePreviewClick}
        iconType={EPlayerIconType.Normal}
        fit="contain"
      />
    );
  };

  private renderPlayer = () => {
    return <YouTube className={styles['video']} video={this.props.videoId} width="100%" height="100%" autoplay />;
  };

  private handlePreviewClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();

    this.setState({
      isActive: true,
    });
  };
}
