import { ca } from '@cian/analytics';
import { TValue as TRooms } from '../../repositories/newbuilding-estimate/entities/filters/RoomCountFilterSchema';
import { TValue as TPeriod } from '../../repositories/newbuilding-estimate/entities/filters/PeriodFilterSchema';
import { TValue as TType } from '../../repositories/newbuilding-estimate/entities/filters/PriceTypeFilterSchema';
import { EValuationTabs } from './constants/tabs';

type TKey = TRooms | TPeriod | TType;

const mapFilterValueToAnalytics: { [key in TKey]: string } = {
  studio: '0',
  oneRoom: '1',
  twoRoom: '2',
  threeRoom: '3',
  fourRoom: '4',

  halfYear: 'halfYear',
  year: 'year',
  allTime: 'allTime',

  priceSqm: 'price_sqm',
  price: 'price',
};

const mapFilterNameToAnalytics = {
  roomCount: 'PriceHistory',
  period: 'Period',
  pricesType: 'PriceType',
};

const mapTabsToAnalytics: Record<EValuationTabs, string> = {
  [EValuationTabs.PriceDynamic]: 'prices',
  [EValuationTabs.SalesDynamic]: 'sales',
};

export function trackRealtyValuationInView() {
  ca('teaser', {
    product: {
      name: 'prices_and_sales_show',
    },
  });
}

export function trackRealtyValuationFilterChange(
  filterName: keyof typeof mapFilterNameToAnalytics,
  filterValue: keyof typeof mapFilterValueToAnalytics,
) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'Select_sopr',
    label: `${mapFilterNameToAnalytics[filterName]}/${mapFilterValueToAnalytics[filterValue]}`,
    useLastProducts: true,
  });
}

export function trackRealtyValuationTabsChange(tab: EValuationTabs) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'Select_sopr',
    label: `Tab/${mapTabsToAnalytics[tab]}`,
    useLastProducts: true,
  });
}

export function trackRealtyValuationChartTooltipShow(tab: EValuationTabs) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'Show_sopr',
    label: `Tooltip/${mapTabsToAnalytics[tab]}`,
    useLastProducts: true,
  });
}
