import * as React from 'react';
import { IconClose16 } from '@cian/ui-kit';
import * as styles from './styles.css';

export interface IFullscreenGalleryCloseButtonProps {
  onClick(): void;
}

export function CloseButton({ onClick }: IFullscreenGalleryCloseButtonProps) {
  return (
    <button type="button" onClick={onClick} className={styles['close-button']} aria-label="Закрыть">
      <IconClose16 />
    </button>
  );
}
