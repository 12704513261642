import * as React from 'react';
import * as style from './Header.css';
import { scrollToAnchor, throttle } from '@cian/newbuilding-utils';

import { getCurrentDocHeight, collectClauses, getActiveTab, mapClausesToTabs } from './helpers';
import { NavigationLink, INavigationLinkProps } from './parts';
import { ITabData } from './types';
import { trackStickyHeaderTabClick } from '../../analytics/trackStickyHeaderTabClick';
import { STICKY_HEADER_HEIGHT } from '../../constants/elements';
import { FavoriteButtonContainer } from '../../containers/FavoriteButton';
import { HeaderRatingStat } from '../../containers/NewbuildingReviewsStat';
import { getCurrentScrollOffset } from '../../utils/dom/getCurrentScrollOffset';

export interface IHeaderProps {
  name: string;
  actionButton: React.ReactNode;
}

export interface IHeaderState {
  activeTab: string;
  tabs: ITabData[];
}

export class Header extends React.Component<IHeaderProps, IHeaderState> {
  private lastDocumentHeight: number;

  public constructor(props: IHeaderProps) {
    super(props);

    this.state = {
      activeTab: 'info',
      tabs: [],
    };
  }

  public componentDidMount() {
    this.updateTabData();
    window.addEventListener('resize', this.throttledResizeHandler);
    window.addEventListener('scroll', this.throttledScrollHandler);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResizeHandler);
    window.removeEventListener('scroll', this.throttledScrollHandler);
  }

  public throttledResizeHandler = throttle(400, () => {
    this.updateTabData();
  });

  public throttledScrollHandler = throttle(200, () => {
    this.checkDocHeight(() => {
      this.updateTabData();
    });
    this.updateActiveTab();
  });

  public updateActiveTab() {
    const { tabs } = this.state;

    if (tabs.length < 1) {
      return;
    }

    const yOffset = getCurrentScrollOffset();
    const activeTab = getActiveTab(yOffset, tabs);

    if (activeTab) {
      this.setState(state => {
        if (state.activeTab !== activeTab.anchor) {
          return { activeTab: activeTab.anchor };
        }

        return null;
      });
    }
  }

  public checkDocHeight(callback?: () => void) {
    if (typeof callback !== 'function') {
      return;
    }

    const currentHeight = getCurrentDocHeight();

    if (this.lastDocumentHeight !== currentHeight) {
      this.lastDocumentHeight = currentHeight;

      callback();
    }
  }

  public updateTabData() {
    const clauses = collectClauses();

    const tabs = mapClausesToTabs(clauses);

    this.setState({ tabs });
  }

  public onNavigationLinkClick(slug: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      trackStickyHeaderTabClick(slug);
      const anchor = `#${slug}`;

      scrollToAnchor({ anchor, hasRelocation: false })(event);

      if (history.pushState) {
        history.pushState(null, '', anchor);
      } else {
        setTimeout(() => {
          window.location.hash = anchor;
        }, 500);
      }
    };
  }

  public render() {
    const { activeTab, tabs } = this.state;

    const { actionButton, name } = this.props;

    return (
      <div style={{ height: `${STICKY_HEADER_HEIGHT}px` }} className={style['header']}>
        <div className={style['header--content']}>
          <div className={style['header--menu-wrapper']}>
            <span className={style['header--menu-title']}>
              <span className={style['header--menu-rating']}>
                <HeaderRatingStat />
              </span>
              <span className={style['header--title']}>{name}</span>
            </span>
            <nav className={style['header--menu-nav']}>
              {tabs.map((clause: INavigationLinkProps) => (
                <NavigationLink
                  onClick={this.onNavigationLinkClick(clause.anchor)}
                  key={clause.anchor}
                  {...clause}
                  isAcitve={clause.anchor === activeTab}
                />
              ))}
            </nav>
          </div>
          <div className={style['header--controlls-wrapper']}>
            {actionButton}
            <div
              style={{
                marginLeft: '8px',
              }}
            >
              <FavoriteButtonContainer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
