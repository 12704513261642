import * as React from 'react';
import { LayoutBlock } from '../LayoutBlock';
import { Section } from '../Section';

export interface IReliableBlockProps {
  hasProblems: boolean;
}

export const ReliableBlock = ({ hasProblems, children }: React.PropsWithChildren<IReliableBlockProps>) => {
  return (
    <LayoutBlock>
      <Section id={hasProblems ? 'problem' : 'reliable'} data-mark={'ReliableBlock'} title="Надёжность объекта">
        {children}
      </Section>
    </LayoutBlock>
  );
};
