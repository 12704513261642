/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';

import { IStyleArgument, mergeStyles } from '@cian/utils';

/***** STYLES *****/
import * as styles from './Link.css';

export type TLinkTarget = 'self' | 'blank' | 'parent' | 'top';

export interface ILinkProps extends React.HTMLProps<HTMLAnchorElement> {
  children: React.ReactNode;

  onClick?(event: React.SyntheticEvent): void;

  url: string;
  linkStyle?: IStyleArgument;
  target?: TLinkTarget;
  title?: string;
  dataMark?: string;
  rel?: string;
  underline?: boolean;
}

/***** COMPONENT *****/
export function Link(props: ILinkProps) {
  const {
    children,
    id,
    onClick,
    url,
    linkStyle,
    target = 'self',
    title = '',
    dataMark = 'Link',
    rel,
    underline,
  } = props;

  return (
    <a
      target={`_${target}`}
      title={title}
      id={id}
      data-mark={dataMark}
      onClick={(event: React.SyntheticEvent) => {
        if (onClick) {
          onClick(event);
        }
      }}
      href={url}
      {...mergeStyles(styles['element'], underline && styles['underline'], linkStyle)}
      rel={rel}
    >
      {children}
    </a>
  );
}
