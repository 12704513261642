import * as React from 'react';
import { UIText1 } from '@cian/ui-kit';

import * as styles from './styles.css';
import { TPriceDiff } from '../../../types/realtyValuation';
import { PriceDiff } from '../PriceDiff';

interface IRealtyValuationProps {
  text: string;
  diff: TPriceDiff;
}

export function Promo({ text, diff }: IRealtyValuationProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['icon']}>
        <PriceDiff diff={diff} />
      </div>
      <UIText1>{text}</UIText1>
    </div>
  );
}
