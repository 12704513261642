import { ca } from '@cian/analytics';

export function trackHouseClick(houseName?: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'Click',
    label: `select_corpus/${houseName || 'all'}`,
  });
}
