/* eslint-disable react/display-name */
import * as React from 'react';
import { getVideoId } from '../../../utils/businessLogic/gallery';
import { Image } from '../../Image';
import { IFullscreenSlide } from '../../Carousel';

import { MapWithPin } from '../../MapWithPin';
import { VideoPlayer } from '../../VideoPlayer';
import { VideoSmallIcon } from '../../VideoPreview/Icons';
import { EMediaType, IMediaItem } from '../../../types/newbuilding/media';

const thumbErrorImg = require('../thumb_error.svg');
const slideErrorImg = require('../slide_error.svg');

const slideError = { text: 'Ошибка загрузки :(', img: slideErrorImg };
const thumbError = { img: thumbErrorImg };

const slideRenderers = {
  [EMediaType.Image]: (media: IMediaItem, index: number) => {
    return <Image src={media.fullUrl} error={slideError} spinnerSize={32} key={index} />;
  },
  [EMediaType.Map]: (media: IMediaItem, index: number) => {
    return <MapWithPin fit="contain" src={media.fullUrl} alt={media.alt} key={index} />;
  },
  [EMediaType.Video]: (media: IMediaItem, index: number) => {
    return (
      <VideoPlayer
        thumbnailUrl={media.thumbnailUrl}
        videoId={getVideoId(media.videoUrl as string) as string}
        key={index}
      />
    );
  },
};

export function prepareSlides(mediaData: IMediaItem[]): IFullscreenSlide[] {
  return mediaData.map((media, index) => ({
    node: slideRenderers[media.mediaType](media, index),
    thumbnailNode: (
      <Image
        src={media.thumbnailUrl}
        fit="cover"
        error={thumbError}
        spinnerSize={16}
        key={index}
        icon={media.mediaType === EMediaType.Video ? <VideoSmallIcon /> : undefined}
      />
    ),
  }));
}
