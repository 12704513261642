import * as React from 'react';
import classNames from 'clsx';

import * as styles from './styles.css';

type TLabelType = 'pros' | 'cons';

export const Label = ({ type }: { type: TLabelType }) => {
  return (
    <div
      className={classNames(styles['label'], type === 'pros' && styles['pros'], type === 'cons' && styles['cons'])}
      aria-hidden="true"
    ></div>
  );
};
