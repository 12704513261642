import * as React from 'react';
import { UIText2 } from '@cian/ui-kit';

import { TTransportType } from '../../types/transportType';
import { FromUndergroundTime } from './parts/FromUndergroundTime';
import { UndergroundIcon } from './parts/UndergroundIcon';

import * as styles from './Underground.css';

interface IUndergroundProps {
  lineColor: string;
  name: string;
  transportType?: TTransportType | null;
  timeTo: string;
  regionId: number | null;
}

export const Underground: React.FC<IUndergroundProps> = ({ lineColor, name, transportType, timeTo, regionId }) => {
  return (
    <div className={styles['underground']}>
      <div className={styles['underground-station']}>
        {lineColor && <UndergroundIcon lineColor={lineColor} regionId={regionId} />}
        <UIText2>{`м. ${name}`}</UIText2>
      </div>
      {transportType && <FromUndergroundTime transportType={transportType} timeTo={timeTo} />}
    </div>
  );
};
