import * as React from 'react';
import { useSelector } from 'react-redux';
import { getBuilders, getSellers } from '../../selectors/newbuilding';
import { WorkTimeInfo } from '../../components/WorkTimeInfo';

export const WorkTimeInfoContainer = () => {
  const builders = useSelector(getBuilders);
  const sellers = useSelector(getSellers);

  const builderOrSeller = React.useMemo(() => {
    const leadFactorySeller = sellers.find(seller => seller.isLeadFactory);

    if (leadFactorySeller) {
      return leadFactorySeller;
    }

    const buildersWithPhones = builders.filter(builder => builder.showPhone && builder.phone && builder.phone.number);
    const sellersWithPhones = sellers.filter(seller => seller.showPhone && seller.phone && seller.phone.number);

    if (buildersWithPhones.length === 1 && sellersWithPhones.length === 0) {
      return buildersWithPhones[0];
    }

    return null;
  }, [builders, sellers]);

  if (!builderOrSeller || !builderOrSeller.workTimeInfo) {
    return null;
  }

  return <WorkTimeInfo info={builderOrSeller.workTimeInfo} />;
};
