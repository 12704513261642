import * as React from 'react';
import { Link } from '../../../Link';

export interface IRecommendationsLinkProps {
  onClick?(): void;
  url: string;
}

export const RecommendationsLink: React.FC<IRecommendationsLinkProps> = ({ url, onClick }) => {
  return (
    <Link url={url} onClick={onClick} target="blank" rel="noopener noreferrer">
      Смотреть все
    </Link>
  );
};
