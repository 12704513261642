import { ca } from '@cian/analytics';
import { getNewbuildingProduct } from '../../utils/analytics';

export function trackPromoOffersShow(newbuildingId: number) {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'Promo',
    label: 'mortgage_offers',
    name: 'oldevent',
    products: [getNewbuildingProduct({ newbuildingId })],
  });
}
