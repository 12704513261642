import { ITabProps } from '@cian/ui-kit/prev';
import { EMortgageOffersTabs } from '../../../types/mortgageOffers';

interface IGetMortgageOffersTabsParams {
  hasBanks: boolean;
  hasMortgageOffers: boolean;
}

export const getSectionTabs = ({ hasBanks, hasMortgageOffers }: IGetMortgageOffersTabsParams): ITabProps[] => {
  const tabs: ITabProps[] = [];

  if (hasMortgageOffers) {
    tabs.push({ title: 'Ипотечные предложения', value: EMortgageOffersTabs.Mortgage });
  }

  if (hasBanks) {
    tabs.push({ title: 'Аккредитованные банки', value: EMortgageOffersTabs.Banks });
  }

  return tabs;
};
