import * as React from 'react';
import { useSelector } from 'react-redux';
import { HCard } from '../../components/CompanySeoCard';
import { getFirstAvailablePhone, getNewbuildingName, getSchemaOrgAddress } from '../../selectors/newbuilding';
import { getFullUrl } from '../../selectors/path';

export const CompanySeoCard = () => {
  const newbuildingName = useSelector(getNewbuildingName);
  const url = useSelector(getFullUrl);
  const phone = useSelector(getFirstAvailablePhone);
  const { streetAddress, addressLocality, addressRegion } = useSelector(getSchemaOrgAddress);

  return (
    <HCard
      url={url}
      locality={`${addressRegion}, ${addressLocality}`}
      address={streetAddress}
      phone={phone}
      newbuildingName={newbuildingName}
    />
  );
};
