/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';
import { ArticleHeading2, Image } from '@cian/ui-kit';
import * as styles from './BuilderLogo.css';
import { mergeStyles } from '@cian/utils';

export interface IBuilderLogoProps {
  src?: string | null;
  name?: string | null;
  website?: string | null;
  customStyles?: string | null;
  onClick?(): void;
}

const BuilderLogoLink: React.FC<{
  website?: string | null;
  customStyles?: string | null;
  onClick?(): void;
  children: React.ReactNode;
}> = ({ website, customStyles, children, onClick }) => {
  return website ? (
    <a
      {...mergeStyles(styles['logo'], styles['logo-link'], customStyles)}
      href={website}
      title="Сайт застройщика"
      onClick={onClick}
      target="_blank"
    >
      <div className={styles['container']}>{children}</div>
    </a>
  ) : (
    <div {...mergeStyles(styles['logo'], customStyles)}>
      <div className={styles['container']}>{children}</div>
    </div>
  );
};

export const BuilderLogo: React.FC<IBuilderLogoProps> = props => {
  const { src, name, website = '#', customStyles, onClick } = props;

  return (
    <BuilderLogoLink onClick={onClick} website={website} customStyles={customStyles}>
      {src ? (
        <div className={styles['logo-image']}>
          <Image display={'block'} src={src} alt={name || ''} />
        </div>
      ) : (
        <div className={styles['logo-defaultContent']}>
          <ArticleHeading2 color={'black_100'} fontWeight={'bold'} textAlign={'center'}>
            {name && name[0]}
          </ArticleHeading2>
        </div>
      )}
    </BuilderLogoLink>
  );
};
