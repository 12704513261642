import * as React from 'react';
import { BuildersInfo } from '../../components/BuildersInfo';
import {
  getBuilders,
  getSellers,
  getNewbuildingDisplayName,
  getIsLeadFactory,
  getNewbuildingId,
} from '../../selectors/newbuilding';
import { trackShowPhone } from '../../actions/analytics';
import { TThunkDispatch } from '../../types/redux/thunk';
import { getIsDevCardRedesignEnabled } from '../../selectors/common';
import { useDispatch, useSelector } from 'react-redux';

export const BuildersInfoContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();

  const onOpenContactsCall = React.useCallback(() => {
    dispatch(trackShowPhone('Open_jk_card_blockDeveloper'));
  }, []);

  const newbuildingId = useSelector(getNewbuildingId);
  const builders = useSelector(getBuilders);
  const sellers = useSelector(getSellers);
  const isLeadFactory = useSelector(getIsLeadFactory);
  const newbuildingDisplayName = useSelector(getNewbuildingDisplayName);
  const isDevCardRedesignEnabled = useSelector(getIsDevCardRedesignEnabled);

  return (
    <BuildersInfo
      newbuildingId={newbuildingId}
      builders={builders}
      sellers={sellers}
      newbuildingDisplayName={newbuildingDisplayName}
      isLeadFactory={isLeadFactory}
      isDevCardRedesignEnabled={isDevCardRedesignEnabled}
      trackActionOpenContactsCall={onOpenContactsCall}
    />
  );
};
