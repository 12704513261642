import * as React from 'react';
import * as style from './NewbuildingFromTheBuilder.css';
import { NewbuildingItem } from '../NewbuildingItem';
import { Link } from '../Link';
import { VisionCheckWrapper } from '../VisionCheckWrapper';
import { LayoutBlock } from '../LayoutBlock';
import { Section } from '../Section';
import { INewbuildingFromTheBuilder } from '../../types/newbuilding/newbuildingFromBuilder';

export interface INewbuildingFromTheBuilderProps {
  newbuildings: INewbuildingFromTheBuilder[];
  newbuildingsLink?: string | null;
  trackNewbuildingFromTheBuilderBlockAppearance(): void;
  trackTransferToNewbuildingFromTheBuilder(index: number): void;
}

export class NewbuildingFromTheBuilder extends React.PureComponent<INewbuildingFromTheBuilderProps> {
  public render() {
    const {
      newbuildings,
      newbuildingsLink,
      trackNewbuildingFromTheBuilderBlockAppearance,
      trackTransferToNewbuildingFromTheBuilder,
    } = this.props;
    if (!newbuildings.length) {
      return null;
    }

    return (
      <LayoutBlock data-mark={'NewbuildingFromTheBuilder'}>
        <VisionCheckWrapper
          className={style['container']}
          makeWhenVisible={trackNewbuildingFromTheBuilderBlockAppearance}
        >
          <Section title="Другие ЖК от застройщика">
            <div className={style['list']}>
              {newbuildings.map((building, index) => (
                <div key={building.newbuildingId || index} className={style['item']}>
                  <NewbuildingItem onClick={() => trackTransferToNewbuildingFromTheBuilder(index)} {...building} />
                </div>
              ))}
            </div>
            {newbuildingsLink && (
              <Link linkStyle={style['link']} url={newbuildingsLink}>
                Все предложения
              </Link>
            )}
          </Section>
        </VisionCheckWrapper>
      </LayoutBlock>
    );
  }
}
