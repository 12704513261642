import * as React from 'react';
import { HousesByTurnModal } from '../HousesByTurnModal';
import { HousesByTurnTooltip } from '../HousesByTurnTooltip';
import { HousesByTurnTitle } from '../HousesByTurnTitle';
import { ITurnInfo } from '../../../../types/newbuilding/turn';

export interface IHousesByTurnTitleProps {
  housesByTurnDisplay: ITurnInfo[];
  housesCount: number;
}

export const FinishYearsTitle: React.FC<IHousesByTurnTitleProps> = props => {
  const { housesByTurnDisplay, housesCount } = props;

  if (!housesByTurnDisplay || !housesByTurnDisplay.length || !housesCount) {
    return <HousesByTurnTitle />;
  }

  return housesByTurnDisplay.length > 2 || housesCount > 20 ? (
    <HousesByTurnModal housesByTurnDisplay={housesByTurnDisplay} />
  ) : (
    <HousesByTurnTooltip housesByTurnDisplay={housesByTurnDisplay} />
  );
};
