import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import { IconRefresh16 } from '@cian/ui-kit';
import * as styles from './Spinner.css';

export interface ISpinnerProps {
  size?: number;
}

export function Spinner({ size }: ISpinnerProps) {
  return (
    <div {...mergeStyles(styles['container'], { height: size, width: size })}>
      <IconRefresh16 color={'white_100'} />
    </div>
  );
}
