import * as React from 'react';
import { useSelector } from 'react-redux';
import { ImageSources as ImageSourcesComponent } from '../../components/ImageSources';
import { getImageSourcesText } from '../../selectors/newbuilding';

export const ImageSources = () => {
  const imageSourcesText = useSelector(getImageSourcesText);

  if (!imageSourcesText) {
    return null;
  }

  return <ImageSourcesComponent>{imageSourcesText}</ImageSourcesComponent>;
};

ImageSources.displayName = 'ImageSources';
