import { connect } from 'react-redux';
import { Price, IPriceProps } from '../../components/Price';
import {
  getPriceForObjectFromDeveloperDisplay,
  getPriceForMeterFromDeveloperDisplay,
} from '../../selectors/newbuilding';
import { IApplicationState } from '../../types/redux';
import { PriceRelevanceIconContainer } from '../PriceRelevanceIcon/PriceRelevanceIconContainer';
import * as React from 'react';

interface IPriceStateProps
  extends Pick<IPriceProps, 'priceForObjectFromDeveloperDisplay'>,
    Pick<IPriceProps, 'priceForMeterFromDeveloperDisplay'>,
    Pick<IPriceProps, 'relevanceIcon'> {}

export function mapStateToProps(state: IApplicationState): IPriceStateProps {
  return {
    relevanceIcon: <PriceRelevanceIconContainer />,
    priceForObjectFromDeveloperDisplay: getPriceForObjectFromDeveloperDisplay(state),
    priceForMeterFromDeveloperDisplay: getPriceForMeterFromDeveloperDisplay(state),
  };
}

export const PriceContainer = connect(mapStateToProps)(Price);
