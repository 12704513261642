import * as React from 'react';
import { InlineText1 } from '@cian/ui-kit/typography';

import { HoveredLink } from '../../../HoveredLink';

import { NoLayoutImage } from './NoLayoutImage';
import * as styles from './styles.css';
import { ILayoutRoomResponse } from '../../../../types/newbuilding/stats';

type TOfferLayoutRowProps = Omit<ILayoutRoomResponse, 'offerId' | 'houseName' | 'gaLabel'> & {
  houseName?: string;
  onLinkClick(): void;
};

export function OfferLayoutRow({
  offerUrl,
  roomCount,
  houseName,
  finishDate,
  totalArea,
  priceDisplay,
  layoutImageUrl,
  onLinkClick,
}: TOfferLayoutRowProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['room-count']}>
        <HoveredLink url={offerUrl} onClick={onLinkClick}>
          {roomCount}
        </HoveredLink>
      </div>
      {houseName && (
        <div className={styles['house-name']}>
          <InlineText1 color="gray60_100">{houseName}</InlineText1>
        </div>
      )}
      <div className={styles['finish-date']}>
        <InlineText1 color="gray60_100">{finishDate}</InlineText1>
      </div>
      <div className={styles['area']}>
        <InlineText1 color="gray60_100">{totalArea}</InlineText1>
      </div>
      <div className={styles['price']}>
        <InlineText1 color="black_100">{priceDisplay}</InlineText1>
      </div>
      <div className={styles['layout-image']} style={{ backgroundImage: `url(${layoutImageUrl})` }}>
        {!layoutImageUrl && <NoLayoutImage />}
      </div>
    </div>
  );
}
