import * as React from 'react';
import { Button } from '@cian/ui-kit';
import { Link } from '../../../Link';
import * as style from './ManyReviewsTeaser.css';

export interface IReviewsFooterProps {
  fullUrl: string;
}

export const ReviewsFooter: React.FC<IReviewsFooterProps> = ({ fullUrl }) => (
  <div className={style['footer']}>
    <Link url={`${fullUrl}otzyvy/#new-review`} target="blank">
      <Button size="XS" theme="fill_primary">
        Написать отзыв
      </Button>
    </Link>
    <Link url={`${fullUrl}otzyvy/#new-question`} target="blank">
      <Button size="XS" theme="fill_secondary">
        Задать вопрос
      </Button>
    </Link>
  </div>
);
