import * as React from 'react';
import { UIHeading1, UIText2 } from '@cian/ui-kit';

import * as styles from './BuilderCallReasons.css';

const QUESTIONS = [
  'Какие есть варианты отделки?',
  'Есть акции и скидки?',
  'С каким банками работаете?',
  'Может, на других этажах есть квартиры дешевле?',
  'Когда сдаётся дом?',
];

export interface IBuilderCallReasonsProps {
  contactsButton: JSX.Element;
}

export const BuilderCallReasons: React.VFC<IBuilderCallReasonsProps> = ({ contactsButton }) => {
  return (
    <div className={styles['builder-call-reasons']} data-testid="BuilderCallReasons">
      <div className={styles['content']}>
        <UIHeading1>Спросите у застройщика</UIHeading1>
        <div className={styles['clarification']}>
          <UIText2>
            Выбрать квартиру непросто, застройщик поможет, подберет персональные условия и ответит на&nbsp;вопросы
          </UIText2>
        </div>
        <div className={styles['reasons']}>
          {QUESTIONS.map((question, index) => (
            <div key={index} className={styles['reason']}>
              {question}
            </div>
          ))}
        </div>
        {contactsButton}
      </div>
      <div className={styles['image']} />
    </div>
  );
};
