import { IApplicationState } from '../../types/redux';
import { selectIsBrokerAvailable } from './selectIsBrokerAvailable';

export const selectBrokerParams = (state: IApplicationState) => {
  if (selectIsBrokerAvailable(state)) {
    const brokerParams = state.newbuilding.newbuildingBroker;

    if (brokerParams?.accountLegalType) {
      return {
        accountLegalType: brokerParams.accountLegalType,
        hasConfirmedPhone: brokerParams.hasConfirmedPhone,
      };
    }
  }

  return null;
};
