import * as React from 'react';
import { ArticleHeading2, InlineText1 } from '@cian/ui-kit';
import { NewbuildingCarousel, TOnSlideChangeParams } from '@cian/frontend-newbuilding-carousel-component';

import { firstPaintOptions, mediaSettings } from '../../../../settings/carouselSettings';
import { VerticalArrow } from '../../../VerticalArrow';

import * as styles from './styles.css';

interface IContentProps {
  title: string;
  description?: string | null;
  images: { url: string }[];
}

export function Content({ title, description, images }: IContentProps) {
  const [firstRender, setFirstRender] = React.useState(true);
  const [currentIndex, setCurrentIndex] = React.useState(1);

  React.useEffect(() => {
    setFirstRender(false);
  }, [firstRender]);

  const prevSlide = React.useCallback(() => setCurrentIndex(currentIndex - 1), [currentIndex, setCurrentIndex]);
  const nextSlide = React.useCallback(() => setCurrentIndex(currentIndex + 1), [currentIndex, setCurrentIndex]);
  const onSlideChange = React.useCallback(
    ({ currentSlide }: TOnSlideChangeParams) => setCurrentIndex(currentSlide),
    [setCurrentIndex],
  );

  const count = images.length;
  const counter = `${currentIndex}/${count}`;
  const slides = React.useMemo(
    () =>
      images.map(img => <div key={img.url} className={styles['img']} style={{ backgroundImage: `url(${img.url})` }} />),
    [images],
  );

  return (
    <div className={styles['container']}>
      <div className={styles['gallery']}>
        {!firstRender && (
          <NewbuildingCarousel
            media={mediaSettings}
            firstPaintOptions={firstPaintOptions}
            slides={slides}
            defaultCurrentSlide={currentIndex}
            onSlideChange={onSlideChange}
          />
        )}
        {currentIndex > 1 && <VerticalArrow direction={-1} offset={40} onClick={prevSlide} />}
        {currentIndex < count && <VerticalArrow direction={1} offset={40} onClick={nextSlide} />}
        {count > 1 && (
          <div className={styles['counter']}>
            <InlineText1 color="black_100">{counter}</InlineText1>
          </div>
        )}
      </div>
      <div className={styles['description']}>
        <div className={styles['title']}>
          <ArticleHeading2 as="h2">{title}</ArticleHeading2>
        </div>
        {description}
      </div>
    </div>
  );
}
