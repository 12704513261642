import * as React from 'react';
import { Col, Row } from '@cian/ui-kit';
import { Link } from '../../../Link';
import { ReviewAnswers } from '../../../ReviewAnswers';
import { ReviewAuthor } from '../../../ReviewAuthor';
import { ReviewComment } from '../../../ReviewComment';
import { ReviewItem } from '../../../ReviewItem';
import { ReviewLabels } from '../../../ReviewLabels';
import { ReviewsSections } from '../../../ReviewsSections';
import * as style from './ManyReviewsTeaser.css';
import { ReviewsFooter } from './ReviewsFooter';
import { ReviewsTitle } from './ReviewsTitle';
import { ReviewsReactionsContainer } from '../../../../containers/ReviewsReactionsContainer';
import { IReviewSection, IReviewWeb } from '../../../../types/newbuilding/reviews';

const SINGLE_REVIEW_ITEM = 1;

export interface IManyReviewsTeaserProps {
  fullUrl: string;
  rating: number;
  sections: IReviewSection[];
  reviews: IReviewWeb[];
  showReviewsLinkClick: () => void;
  readReviewLinkClick: () => void;
  masterReview: React.ReactElement;
  title: string;
}

export const ManyReviewsTeaser: React.FC<IManyReviewsTeaserProps> = ({
  fullUrl,
  rating,
  reviews,
  sections,
  readReviewLinkClick,
  masterReview,
  title,
}) => {
  const isSingleReview = reviews.length === SINGLE_REVIEW_ITEM;

  return (
    <>
      <ReviewsTitle fullUrl={fullUrl} rating={rating} title={title} />

      <div className={style['sections']}>
        <ul className={style['list']}>
          {sections.map(({ name, rating }) => (
            <li key={name} className={style['item']}>
              <ReviewsSections name={name} value={rating} />
            </li>
          ))}
        </ul>
      </div>

      {masterReview}

      <div className={style['reviews']}>
        <Row>
          {reviews.map(({ id, user, date, isLegacy, comment, rating, labels, like, hasAnswers, answersCountInt }) => (
            <Col key={id} l={isSingleReview ? 12 : 6} xs={12}>
              <ReviewItem
                author={
                  <ReviewAuthor
                    avatarUrl={user.avatarUrl}
                    type={user.type}
                    name={user.name}
                    date={date}
                    rating={rating}
                  />
                }
                comment={<ReviewComment isLegacy={isLegacy} comment={comment} />}
                readFullReviewLink={
                  <Link url={`${fullUrl}otzyvy/#${id}`} target="blank" onClick={readReviewLinkClick}>
                    Читать полностью
                  </Link>
                }
                label={labels[0] && !isSingleReview && <ReviewLabels labels={labels} />}
                answers={hasAnswers && <ReviewAnswers reviewId={id} fullUrl={fullUrl} answersCount={answersCountInt} />}
                reactions={<ReviewsReactionsContainer reviewId={id} reactions={like} />}
              />
            </Col>
          ))}
        </Row>
      </div>

      <ReviewsFooter fullUrl={fullUrl} />
    </>
  );
};
