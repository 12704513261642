import { ca } from '@cian/analytics';

export const trackAnswersClick = (customPageURL: string): void => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Reviews',
    action: 'link_to_answers',
    label: customPageURL,
    useLastProducts: true,
  });
};
