import { clsx } from 'clsx';
import * as React from 'react';

import * as styles from './PromosCollapsedBlock.css';
import { ELEMENTS_IN_ROW } from './constants';
import { useContentHeight } from './hooks';

export interface IPromosCollapsedButtonProps {
  opened: boolean;
  onClick(): void;
}

interface IPromosCollapsedBlockProps {
  Button: React.ComponentType<React.PropsWithChildren<IPromosCollapsedButtonProps>>;
  control: React.ReactNode;
  onChangeOpen(opened: boolean): void;
}

export const PromosCollapsedBlock: React.FC<React.PropsWithChildren<IPromosCollapsedBlockProps>> = ({
  Button,
  children,
  control,
  onChangeOpen,
}) => {
  const count = React.Children.count(children);

  const [opened, setOpened] = React.useState(false);

  const { contentRef, animated, height, updateHeight } = useContentHeight(opened);

  const toggle = () => {
    if (onChangeOpen) {
      onChangeOpen(!opened);
    }

    updateHeight(!opened, true);

    // В момент нажатия кнопки она получает фокус, поэтому блок скроллится на позиции где кнопка, а не раскрывается вниз
    // Через ref сделать не получится, потому что кнопка может прийти как props
    if (!opened) {
      (document.activeElement as HTMLButtonElement).blur();
    }

    setOpened(!opened);
  };

  const withButton = count > ELEMENTS_IN_ROW;

  return (
    <div>
      <div
        className={clsx(styles['content'], opened && styles['opened'], animated && styles['animated'])}
        ref={contentRef}
        style={{ maxHeight: `${height}px` }}
      >
        {children}
      </div>
      <div className={clsx(styles['controls'], withButton && styles['controls--with-button'])}>
        {withButton && <Button opened={opened} onClick={toggle} />}
        {control}
      </div>
    </div>
  );
};
