import { prepareFlatViewOrderInfo } from '@cian/newbuilding-flat-view-order-component';
import { ISchedule } from '../repositories/newbuilding-calltracking/entities/FlatTourBooking/Schedule';
import { IFlatTourBooking } from '../types/flatTourBooking';

export const transformFlatTourBooking = (schedule: ISchedule): IFlatTourBooking => {
  const { availableDateIntervals, general, special } = schedule;

  const preparedSchedule = prepareFlatViewOrderInfo({
    availableDateInterval: {
      start: availableDateIntervals[0].start,
      end: availableDateIntervals[0].end as string,
    },
    general: general.map(date => ({ ...date, weekDay: date.weekday })),
    special,
  });

  return {
    ...preparedSchedule,
    minDate: availableDateIntervals[0].start,
    maxDate: availableDateIntervals[0].end as string,
  };
};
