import { useSelector } from 'react-redux';
import { getNewbuildingCoordinates, getNewbuildingId } from '../../../selectors/newbuilding';
import { useApplicationContext } from '../../../utils/applicationContext';

export const useMapPreviewUrl = () => {
  const { config } = useApplicationContext();
  const coordinates = useSelector(getNewbuildingCoordinates);
  const newbuildingId = useSelector(getNewbuildingId);

  const mapPreviewTemplate = config.get<string>('audience.mapPreviewTemplate');

  /* istanbul ignore next */
  if (!coordinates || !coordinates.lat || !coordinates.lng || !mapPreviewTemplate) {
    return null;
  }

  const { lat, lng } = coordinates;

  const params: Record<string, number> = { lat, lng, id: newbuildingId };

  return mapPreviewTemplate.replace(/{{(lat|lng|id)}}/g, (_, matched: string) => `${params[matched]}`);
};
