import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchStats } from '../../actions/offers/offersActions';
import { HousesFilter } from '../../components/HousesFilter';
import { getHouses } from '../../selectors/newbuilding';
import { getSelectedHouseId } from '../../selectors/offers';
import { TThunkDispatch } from '../../types/redux/thunk';
import { trackHouseClick } from './tracking';

export function HousesFilterContainer() {
  const dispatch: TThunkDispatch = useDispatch();
  const selectedHouseId = useSelector(getSelectedHouseId);
  const houses = useSelector(getHouses);

  const onHouseClick = React.useCallback(
    (houseId: number | undefined) => {
      const house = houses.find(el => el.houseId === houseId);

      dispatch(fetchStats(houseId));
      trackHouseClick(house ? house.houseName : undefined);
    },
    [dispatch, houses],
  );

  if (!houses || houses.length < 2) {
    return null;
  }

  return <HousesFilter selectedHouseId={selectedHouseId} houses={houses} onHouseClick={onHouseClick} />;
}
