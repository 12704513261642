import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackAccreditedBanksToListingClick } from '../../analytics/promos';
import { AccreditedBanks } from '../../components/AccreditedBanks';
import { getBanks, getNewbuildingId } from '../../selectors/newbuilding';
import { IBank } from '../../types/newbuilding/bank';

export const AccreditedBanksContainer: React.FC = () => {
  const banks = useSelector(getBanks);
  const newbuildingId = useSelector(getNewbuildingId);

  const handleBankClick = React.useCallback(
    (bank: IBank) => {
      trackAccreditedBanksToListingClick(bank, newbuildingId);
    },
    [newbuildingId],
  );

  if (!banks.length) {
    return null;
  }

  return <AccreditedBanks banks={banks} onBankClick={handleBankClick} />;
};
