import { Button, Image, UIHeading2, UIHeading4 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './InfrastructureError.css';
import icon from './icon-error.svg';

interface IInfrastructureErrorProps {
  retry(): void;
}

export const InfrastructureError: React.FC<IInfrastructureErrorProps> = ({ retry }) => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['icon']}>
        <Image src={icon} width={100} height={100} />
      </div>
      <div className={styles['message']}>
        <div className={styles['title']}>
          <UIHeading2 textAlign="center">Что-то пошло не так</UIHeading2>
        </div>
        <UIHeading4 textAlign="center" color="gray60_100">
          Попробуйте загрузить информацию снова
        </UIHeading4>
      </div>
      <Button size="L" theme="fill_primary" onClick={retry}>
        Обновить
      </Button>
    </div>
  );
};
