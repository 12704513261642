import * as React from 'react';
import { UIText1 } from '@cian/ui-kit';
import { ToggleButton as ToggleButtonComponent } from '../ToggleButton';

interface IToggleButtonProps {
  closedText: string;
  opened: boolean;
  onClick(): void;
}

export function ToggleButton({ closedText, opened, onClick }: IToggleButtonProps) {
  return (
    <ToggleButtonComponent isOpened={opened} onClick={onClick}>
      <UIText1 display="inline" color="primary_100">
        {opened ? 'Свернуть' : closedText}
      </UIText1>
    </ToggleButtonComponent>
  );
}
