import * as React from 'react';

import { IRecommendationCompilation } from '../../../../types/recommendations';
import { CompilationItem } from '../CompilationItem';
import * as styles from './CompilationsList.css';

export interface ICompilationsListProps {
  compilations: IRecommendationCompilation[];
  onItemClick(compilation: IRecommendationCompilation): void;
}

export const CompilationsList: React.VFC<ICompilationsListProps> = ({ compilations, onItemClick }) => {
  const itemClickHandlers = React.useMemo(() => {
    return compilations.map(item => () => onItemClick(item));
  }, [compilations, onItemClick]);

  return (
    <div className={styles['list']}>
      {compilations.map((item, idx) => (
        <div key={idx} className={styles['list-item']}>
          <CompilationItem
            description={item.description}
            icon={item.iconUrl}
            title={item.title}
            url={item.listingUrl}
            onClick={itemClickHandlers[idx]}
          />
        </div>
      ))}
    </div>
  );
};
