import {
  fetchAddFlatTourBooking,
  IAddFlatTourBookingResponse200,
  IAddFlatTourBookingResponse400,
  IAddFlatTourBookingRequest,
} from '../../../shared/repositories/newbuilding-calltracking/v1/add-flat-tour-booking';
import { getNewbuildingId } from '../../../shared/selectors/newbuilding';
import { setFlatViewOrder } from '../../../shared/actions/newbuilding';
import { toggleFlatViewOrderBanner } from '../../../shared/actions/flatViewOrder';
import { TThunkAction } from '../../../shared/types/redux/thunk';
import { transformUserBookingStatus } from '../../../shared/mappers/transformUserBookingStatus';

type IAddFlatTourBookingParams = Pick<IAddFlatTourBookingRequest, 'phone' | 'tourTime' | 'fullName' | 'builderUserId'>;

export function addFlatTourBooking(params: IAddFlatTourBookingParams): TThunkAction<Promise<void>> {
  return async (dispatch, getState, { httpApi, logger }): Promise<void> => {
    try {
      const newbuildingId = getNewbuildingId(getState());

      const response = await fetchAddFlatTourBooking<IAddFlatTourBookingResponse200, IAddFlatTourBookingResponse400>({
        httpApi,
        parameters: {
          ...params,
          newbuildingId,
        },
        config: {
          requestConfig: {
            headers: [['Content-Type', 'application/json']],
          },
        },
      });

      if (response.statusCode !== 200) {
        throw response.response.message;
      }

      const flatViewOrder = transformUserBookingStatus(response.response.userBookingStatus);
      dispatch(setFlatViewOrder(flatViewOrder));
      dispatch(toggleFlatViewOrderBanner(true));
    } catch (error) {
      logger.error(error, {
        domain: 'addFlatTourBooking',
      });
      throw error;
    }
  };
}
