import * as React from 'react';
import { DumbFavoriteButton } from './parts/DumbFavoriteButton';

export interface IFavoriteButtonProps {
  isFavorite: boolean;
  gaLabel: string;
  newbuildingId: number;

  actionAddFavorite(): void;
  actionRemoveFavorite(): void;
}

export class FavoriteButton extends React.Component<IFavoriteButtonProps, {}> {
  public updateFavorite = async (e: React.MouseEvent) => {
    const { __header_updateFavorites__ } = window;

    if (__header_updateFavorites__) {
      __header_updateFavorites__(!this.props.isFavorite);
    }

    if (this.props.isFavorite) {
      e.stopPropagation();
      this.props.actionRemoveFavorite();
    } else {
      this.props.actionAddFavorite();
    }
  };

  public render(): React.ReactNode {
    const { isFavorite } = this.props;

    return <DumbFavoriteButton onClick={this.updateFavorite} isFavorite={isFavorite} />;
  }
}
