import { MORTGAGE_PROMO_TYPES } from '../../../constants/promos';
import { IPromoInfoSchema } from '../../../types/promo';

export const getAuthorDescription = (promo: IPromoInfoSchema, hasContacts: boolean): string | null => {
  if (promo.promoType === 'cianMortgage') {
    return null;
  }

  if (!hasContacts) {
    return null;
  }

  const isMortgage = MORTGAGE_PROMO_TYPES.includes(promo.promoType);
  const promoLabel = isMortgage ? 'ипотечном' : 'акционном';
  const agentLabel = promo.isFromBuilder ? 'застройщика' : 'представителя застройщика';

  return `Узнайте у ${agentLabel} подробности об ${promoLabel} предложении`;
};
