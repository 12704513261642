import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { IStatsDesktopResponseSchema } from '../../../shared/repositories/newbuilding-card/entities/stats/StatsDesktopResponseSchema';

export enum EOffersActionTypes {
  SelectHouseId = 'offers/selectHouseId',
  SetHouseStats = 'offers/setHouseStats',
}

interface ISetHouseStatsPayload {
  houseId: number;
  stats: IStatsDesktopResponseSchema | null;
}

export type TSelectHouseId = ITypedReduxAction<EOffersActionTypes.SelectHouseId, number | undefined>;
export type TSetHouseStats = ITypedReduxAction<EOffersActionTypes.SetHouseStats, ISetHouseStatsPayload>;

export const selectHouseId = actionGenerator<EOffersActionTypes.SelectHouseId, number | undefined>(
  EOffersActionTypes.SelectHouseId,
);

export const setHouseStats = actionGenerator<EOffersActionTypes.SetHouseStats, ISetHouseStatsPayload>(
  EOffersActionTypes.SetHouseStats,
);

export type TOffersActions = TSelectHouseId | TSetHouseStats;
