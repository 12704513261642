import { ca } from '@cian/analytics';
import { IAnalyticsUser } from '../../types/analytics';

export enum EUpcomingSaleTrackActions {
  Fail = 'Fail',
  Send = 'Send',
  Success = 'Success',
}

export interface ITrackUpcomingSaleParams {
  action: EUpcomingSaleTrackActions;
  gaLabel: string;
  phone: string;
  user: IAnalyticsUser;
  name: string;
}

export function trackUpcomingSaleRequest({ action, gaLabel, phone, user, name }: ITrackUpcomingSaleParams) {
  ca('eventSite', {
    action,
    category: 'SalesSoonJKApplication',
    label: gaLabel,
    name: 'oldevent',
    useLastProducts: true,
    user: {
      ...user,
      extra: { phone, name },
    },
  });
}
