import * as React from 'react';
import { UIText3 } from '@cian/ui-kit';
import * as styles from './PhotoLabel.css';

export interface IPhotoLabelProps {
  label: string;
}

export const PhotoLabel: React.FC<IPhotoLabelProps> = ({ label }) => {
  return (
    <div className={styles['label']}>
      <UIText3>{label}</UIText3>
    </div>
  );
};
