import * as React from 'react';
import { InlineText1 } from '@cian/ui-kit';
import * as style from './BuilderItem.css';
import { Stats } from '../Stats';
import { BuilderLogo } from '../BuilderLogo';
import { trackTransferToDeveloperCard } from '../../analytics/trackTransferToDeveloperCard';
import { INewbuildingBuilder } from '../../types/newbuilding/builder';

export interface IBuilderItemProps {
  builder: INewbuildingBuilder | null;
}

export const BuilderItem: React.FunctionComponent<IBuilderItemProps> = props => {
  const { builder } = props;

  if (!builder) {
    return null;
  }

  const { name, logoUrl, stats, profileUrl } = builder;

  return (
    <div className={style['builderInfo']} data-mark={'Builders'}>
      <div className={style['builderName']}>
        <InlineText1>Застройщик «{name}»</InlineText1>
      </div>
      <Stats stats={stats} />
      <BuilderLogo
        name={name}
        src={logoUrl}
        website={profileUrl}
        onClick={() => trackTransferToDeveloperCard(profileUrl)}
        customStyles={style['builderLogo']}
      />
    </div>
  );
};
