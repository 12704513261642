export const MORTGAGE_BANNER_INTEREST_RATE = 0.1;

export const FILTERS = {
  DEFAULT_LOAN_TERM: 20,
  MIN_LOAN_TERM: 1,
  MAX_LOAN_TERM: 30,

  DEFAULT_DOWN_PAYMENT_RATE: 0.2,
  MIN_DOWN_PAYMENT: 0,

  DEFAULT_MIN_PRICE: 2e6,
  DEFAULT_MAX_PRICE: 99999999,
};
