import * as React from 'react';
import { Button, IconHeartOn16, IconHeartOff16 } from '@cian/ui-kit';

interface IDumbFavoriteButtonProps {
  isFavorite: boolean;
  onClick?(e: React.MouseEvent): void;
}

export const DumbFavoriteButton = ({ isFavorite, onClick }: IDumbFavoriteButtonProps) => {
  return (
    <Button
      data-mark={'DumbFavoriteButton'}
      theme={'fill_white_primary'}
      size={'XS'}
      onClick={onClick}
      beforeIcon={isFavorite ? <IconHeartOn16 color={'error_100'} /> : <IconHeartOff16 color={'error_100'} />}
    >
      {isFavorite ? 'В избранном' : 'В избранное'}
    </Button>
  );
};
