import * as React from 'react';

import { PromoItem } from '../../../components/PromoItem';
import { IPromoInfoSchema } from '../../../types/promo';
import { PromoSubtitle } from './PromoSubtitle';

interface IPromoContainerProps {
  onClick(promo: IPromoInfoSchema): void;
  promo: IPromoInfoSchema;
}

export const PromoItemContainer: React.FC<IPromoContainerProps> = ({ onClick, promo }) => {
  const handleClick = React.useCallback(() => {
    onClick(promo);
  }, [promo, onClick]);

  return <PromoItem promo={promo} subtitle={<PromoSubtitle promo={promo} />} onClick={handleClick} />;
};
