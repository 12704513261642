import * as React from 'react';
import * as style from './FixedBlock.css';

export interface IFixedBlockProps extends React.PropsWithChildren {
  dataMark?: string;
  tag?: 'div' | 'section' | 'p' | 'span';
  top?: number | string;
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
}

export class FixedBlock extends React.Component<IFixedBlockProps> {
  public render() {
    const {
      tag: Tag = 'div',
      dataMark = 'FixedBlock',
      children,
      top = 0,
      bottom = 'auto',
      left = 0,
      right = 'auto',
    } = this.props;

    return (
      <Tag
        style={{
          top,
          bottom,
          left,
          right,
        }}
        data-mark={dataMark}
        className={style['layout']}
      >
        {children}
      </Tag>
    );
  }
}
