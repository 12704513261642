import { EFloorPlanTypeActionTypes } from '../../actions/floorPlan';
import { IFloorPlan } from '../../types/floorPlan';
import { TReduxActions } from '../../types/redux';

const DEFAULT_FLOOR_PLAN_STATE: IFloorPlan = {
  isOpened: false,
  houseId: null,
};

export function floorPlanReducer(state: IFloorPlan = DEFAULT_FLOOR_PLAN_STATE, action: TReduxActions): IFloorPlan {
  switch (action.type) {
    case EFloorPlanTypeActionTypes.SetOpen:
      return {
        ...state,
        isOpened: action.payload,
      };

    case EFloorPlanTypeActionTypes.SetHouseId:
      return {
        ...state,
        houseId: action.payload,
      };
    default:
      return state;
  }
}
