import * as React from 'react';
import { ArticleHeading4, UIText2 } from '@cian/ui-kit';

import * as styles from './TransportAccessibilityEntry.css';

interface ITransportAccessibilityEntryProps {
  rate: string;
  onClick: React.MouseEventHandler<HTMLElement>;
}

export const TransportAccessibilityEntry: React.FC<ITransportAccessibilityEntryProps> = ({ rate, onClick }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['logo']} />
      <div>
        <ArticleHeading4>Оценка {rate}</ArticleHeading4>
        <div className={styles['link']} role="button" onClick={onClick}>
          <UIText2 color="primary_100">Транспортная доступность</UIText2>
        </div>
      </div>
    </div>
  );
};
