import * as React from 'react';
import { useSelector } from 'react-redux';

import { SeoMarkedList } from '../../components/SeoMarkedList';
import { getMarkedList } from '../../selectors/newbuilding/getMarkedList';

export const SeoMarkedListContainer: React.VFC = () => {
  const markedListItems = useSelector(getMarkedList);

  if (!markedListItems || markedListItems.length === 0) {
    return null;
  }

  return <SeoMarkedList items={markedListItems} />;
};
