import * as React from 'react';
import { useSelector } from 'react-redux';

import { CallToActionContainer, ICallToActionContainerProps } from '../CallToAction';
import { getHasOffersFromDeveloper } from '../../selectors/newbuilding';
import { SelectionsButtonContainer } from '../SelectionsButtonContainer';

type TActionButtonProps = ICallToActionContainerProps;

export function ActionButtonContainer(props: TActionButtonProps) {
  const { fullWidth, XXL, secondary } = props;
  const hasOffersFromDeveloper = useSelector(getHasOffersFromDeveloper);

  if (!hasOffersFromDeveloper) {
    return <SelectionsButtonContainer fullWidth={fullWidth} XXL={XXL} secondary={secondary} />;
  }

  return <CallToActionContainer {...props} />;
}
