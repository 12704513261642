export enum EType {
  Actual = 'actual',
  Warning = 'warning',
}

export interface IPriceRelevance {
  description: string;
  label: string;
  title: string;
  type: EType;
}
