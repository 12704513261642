import { pick } from 'ramda';
import { TExtra, IProduct } from '../../../types/analytics';
import { INewbuildingBuilder } from '../../../types/newbuilding/builder';

export function getProductsData(id: number, builder?: INewbuildingBuilder | null, extra?: TExtra): IProduct {
  if (!builder || !builder.phoneAnalyticsData) {
    return {
      id,
      offerType: 'JK',
      position: 1,
      extra,
    };
  }

  return {
    extra,
    ...pick(
      ['brand', 'nv', 'dealType', 'id', 'name', 'objectType', 'consultant', 'position'],
      builder.phoneAnalyticsData,
    ),
  };
}
