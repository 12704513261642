// eslint-disable-next-line import/no-restricted-paths
import { prepareUser } from '../../../node/mappers/prepareUser';
import { setUser } from '../../../shared/actions/user';
import { resolveUser } from '../../../shared/services/user';
import { TThunkAction } from '../../../shared/types/redux/thunk';
import { TUser } from '../../../shared/types/user';

export function getUserAction(): TThunkAction<Promise<TUser | undefined>> {
  return async (dispatch, getState, context) => {
    const { httpApi, logger, config } = context;
    const state = getState();
    const user = await resolveUser({ config, httpApi, logger, subdomain: state.subdomain });
    const mappedUser = prepareUser(user);
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    dispatch(setUser(mappedUser) as any);

    return mappedUser;
  };
}
