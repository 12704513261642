import * as React from 'react';
import {
  IconChildPlayground16,
  IconPet16,
  IconFastService16,
  IconFitness16,
  IconOffice16,
  IconHospital16,
  IconPool16,
  IconRestaurant16,
  IconSpa16,
  IconSupermarket16,
  IconParking16,
  IconBarrier16,
  IconSecurity16,
  IconReception16,
  IconSecurityParking16,
  IconCloseArea16,
  IconSchool16,
  IconCommercialObject16,
  IconKid16,
  IconSportsground16,
  IconFireExtinguisher16,
  IconSignal16,
  IconOutdoorCamera16,
} from '@cian/ui-kit/icons';
import {
  EShortInfrastructureType,
  EParkingItemType,
  ESecurityItemType,
} from '../types/newbuilding/specificationsDetailed';

export const DETAILED_SPECIFICATIONS_ICON_MAP: { [id: string]: React.ReactNode } = {
  [EShortInfrastructureType.Children]: <IconChildPlayground16 />,
  [EShortInfrastructureType.Commerce]: <IconCommercialObject16 />,
  [EShortInfrastructureType.Dogs]: <IconPet16 />,
  [EShortInfrastructureType.Domestic]: <IconFastService16 />,
  [EShortInfrastructureType.Fitness]: <IconFitness16 />,
  [EShortInfrastructureType.Kindergarten]: <IconKid16 />,
  [EShortInfrastructureType.Offices]: <IconOffice16 />,
  [EShortInfrastructureType.Polyclinic]: <IconHospital16 />,
  [EShortInfrastructureType.Pool]: <IconPool16 />,
  [EShortInfrastructureType.Recreation]: <IconChildPlayground16 />, //
  [EShortInfrastructureType.Restaurant]: <IconRestaurant16 />,
  [EShortInfrastructureType.School]: <IconSchool16 />,
  [EShortInfrastructureType.Spa]: <IconSpa16 />,
  [EShortInfrastructureType.Sport]: <IconSportsground16 />,
  [EShortInfrastructureType.Supermarket]: <IconSupermarket16 />,
  [EParkingItemType.Guest]: <IconParking16 />,
  [EParkingItemType.Standalone]: <IconParking16 />,
  [EParkingItemType.Underground]: <IconParking16 />,
  [ESecurityItemType.Access]: <IconBarrier16 />,
  [ESecurityItemType.AroundTheClock]: <IconSecurity16 />,
  [ESecurityItemType.Camera]: <IconOutdoorCamera16 />,
  [ESecurityItemType.Concierge]: <IconReception16 />,
  [ESecurityItemType.Fire]: <IconFireExtinguisher16 />,
  [ESecurityItemType.Parking]: <IconSecurityParking16 />,
  [ESecurityItemType.Perimetr]: <IconCloseArea16 />,
  [ESecurityItemType.Signal]: <IconSignal16 />,
};
