import { IConfig } from '@cian/config/shared';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';

import { IFastFiltersUserResponseSchema } from '../../repositories/monolith-python/entities/schemas/FastFiltersUserResponseSchema';
import {
  fetchGetUserDataByRequest,
  IGetUserDataByRequestResponse200,
  IGetUserDataByRequestResponse400,
} from '../../repositories/monolith-python/v1/get-user-data-by-request';
import { assertUnknownError } from './assert';

const RESOLVE_USER_TIMEOUT_CONFIG_KEY = 'newbuilding-reviews-frontend.resolveUser.timeout';
export const LARGE_NETWORK_TIMEOUT = 1000;

interface IDependencies {
  subdomain: string;
  config: IConfig;
  httpApi: IHttpApi;
  logger: ILogger;
}

export async function resolveUser({
  subdomain,
  config,
  httpApi,
  logger,
}: IDependencies): Promise<IFastFiltersUserResponseSchema> {
  try {
    const timeout = config.get<number>(RESOLVE_USER_TIMEOUT_CONFIG_KEY) || LARGE_NETWORK_TIMEOUT;

    const result = await fetchGetUserDataByRequest<IGetUserDataByRequestResponse200, IGetUserDataByRequestResponse400>({
      httpApi,
      parameters: {},
      config: {
        subdomain,
        ecnhancersConfig: { timeout },
      },
    });

    assertUnknownError(result);

    return result.response.data;
  } catch (error) {
    logger.error(error, {
      comment: '[Degradation] Нет информации о пользователе, пользователь считается неавторизованным',
    });

    return {};
  }
}
