import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { useSelector } from 'react-redux';

import { IOffersKPNRowProps, OffersKPNRow } from '../../components/OffersList';
import { getNewbuildingId } from '../../selectors/newbuilding';
import { useApplicationContext } from '../../utils/applicationContext';
import { getNewbuildingSelectionsUrl } from '../../utils/config';
import { trackBannerClick, trackBannerShow } from './tracking';

type TOffersKPNRowContainerProps = Pick<IOffersKPNRowProps, 'title' | 'withMarginTop'>;

export const OffersKPNRowContainer: React.FC<TOffersKPNRowContainerProps> = props => {
  const { config } = useApplicationContext();
  const landingUrl = getNewbuildingSelectionsUrl(config);

  const newbuildingId = useSelector(getNewbuildingId);

  const handleClick = React.useCallback(() => trackBannerClick(newbuildingId), [newbuildingId]);
  const handleShow = React.useCallback(() => trackBannerShow(newbuildingId), [newbuildingId]);

  return (
    <ActionAfterViewObserver callback={handleShow} percentVisible={0.5} triggerOnce callbackDelay={1000}>
      <OffersKPNRow onClick={handleClick} url={landingUrl} {...props} />
    </ActionAfterViewObserver>
  );
};
