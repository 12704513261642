import { ESizeType, IMedia, TFirstPaintOptions } from '@cian/frontend-newbuilding-carousel-component';

export const firstPaintOptions: TFirstPaintOptions = {
  draggable: true,
  elementsWidth: {
    leftEar: {
      width: 9,
      type: ESizeType.PIXEL,
    },
    rightEar: {
      width: 9,
      type: ESizeType.PIXEL,
    },
    content: {
      width: 100,
      type: ESizeType.PERCENT,
    },
    gap: {
      width: 2,
      type: ESizeType.PIXEL,
    },
  },
  height: {
    value: 366,
    type: ESizeType.PIXEL,
  },
  slidesToShow: 2,
  defaultControls: [],
};

export const mediaSettings: IMedia[] = [
  {
    minWidth: 1,
    maxWidth: 744,
    settings: {
      draggable: true,
      elementsWidth: {
        leftEar: {
          width: 9,
          type: ESizeType.PIXEL,
        },
        rightEar: {
          width: 9,
          type: ESizeType.PIXEL,
        },
        content: {
          width: 100,
          type: ESizeType.PERCENT,
        },
        gap: {
          width: 2,
          type: ESizeType.PIXEL,
        },
      },
      height: {
        value: 228,
        type: ESizeType.PIXEL,
      },
      slidesToShow: 1,
      slidesToScroll: 1,
      defaultControls: [],
    },
  },
  {
    minWidth: 744,
    maxWidth: 968,
    settings: {
      draggable: true,
      elementsWidth: {
        leftEar: {
          width: 18,
          type: ESizeType.PIXEL,
        },
        rightEar: {
          width: 18,
          type: ESizeType.PIXEL,
        },
        content: {
          width: 100,
          type: ESizeType.PERCENT,
        },
        gap: {
          width: 2,
          type: ESizeType.PIXEL,
        },
      },
      height: {
        value: 228,
        type: ESizeType.PIXEL,
      },
      slidesToShow: 2,
      slidesToScroll: 1,
      defaultControls: [],
    },
  },
  {
    minWidth: 968,
    maxWidth: 1192,
    settings: {
      draggable: true,
      elementsWidth: {
        leftEar: {
          width: 18,
          type: ESizeType.PIXEL,
        },
        rightEar: {
          width: 18,
          type: ESizeType.PIXEL,
        },
        content: {
          width: 100,
          type: ESizeType.PERCENT,
        },
        gap: {
          width: 2,
          type: ESizeType.PIXEL,
        },
      },
      height: {
        value: 296,
        type: ESizeType.PIXEL,
      },
      slidesToShow: 2,
      slidesToScroll: 1,
      defaultControls: [],
    },
  },
  {
    minWidth: 1192,
    maxWidth: 1416,
    settings: {
      draggable: true,
      elementsWidth: {
        leftEar: {
          width: 18,
          type: ESizeType.PIXEL,
        },
        rightEar: {
          width: 18,
          type: ESizeType.PIXEL,
        },
        content: {
          width: 100,
          type: ESizeType.PERCENT,
        },
        gap: {
          width: 2,
          type: ESizeType.PIXEL,
        },
      },
      height: {
        value: 366,
        type: ESizeType.PIXEL,
      },
      slidesToShow: 2,
      slidesToScroll: 1,
      defaultControls: [],
    },
  },
  {
    minWidth: 1416,
    maxWidth: Infinity,
    settings: {
      draggable: true,
      elementsWidth: {
        leftEar: {
          width: 50,
          type: ESizeType.PERCENT,
        },
        rightEar: {
          width: 50,
          type: ESizeType.PERCENT,
        },
        content: {
          width: 1327,
          type: ESizeType.PIXEL,
        },
        gap: {
          width: 2,
          type: ESizeType.PIXEL,
        },
      },
      height: {
        value: 434,
        type: ESizeType.PIXEL,
      },
      slidesToShow: 2,
      slidesToScroll: 1,
      defaultControls: [],
    },
  },
];
