export interface ISpecificationsDetailed {
  /** Информация о парковке в ЖК **/
  parking: IParkingItem[];
  /** Информация о безопасности ЖК **/
  security: ISecurityItem[];
  /** Краткая информация об инфраструктуре **/
  shortInfrastructure: IShortInfrastructureItem[];
  /** Краткие характеристики ЖК **/
  shortSpecifications: IShortSpecificationItem[];
}

export interface IParkingItem {
  /** Значение **/
  title: string;
  /** Тип парковки **/
  type: EParkingItemType;
}

export enum EParkingItemType {
  /** Гостевая **/
  'Guest' = 'guest',
  /** Отдельная многоуровневая **/
  'Standalone' = 'standalone',
  /** Подземная **/
  'Underground' = 'underground',
}

export interface ISecurityItem {
  /** Значение **/
  title: string;
  /** Тип безопасности **/
  type: ESecurityItemType;
}

export enum ESecurityItemType {
  /** Пропускная система **/
  'Access' = 'access',
  /** Круглосуточная охрана **/
  'AroundTheClock' = 'aroundTheClock',
  /** Видеонаблюдение **/
  'Camera' = 'camera',
  /** Консьерж **/
  'Concierge' = 'concierge',
  /** Противопожарная система **/
  'Fire' = 'fire',
  /** Охраняемая парковка **/
  'Parking' = 'parking',
  /** Огороженный периметр **/
  'Perimetr' = 'perimetr',
  /** Сигнализация **/
  'Signal' = 'signal',
}

export interface IShortInfrastructureItem {
  /** Значение **/
  title: string;
  /** Тип безопасности **/
  type: EShortInfrastructureType;
}

export enum EShortInfrastructureType {
  /** Детские площадки **/
  'Children' = 'children',
  /** Коммерческие объекты **/
  'Commerce' = 'commerce',
  /** Площадки для выгула собак **/
  'Dogs' = 'dogs',
  /** Пункты бытового обслуживания **/
  'Domestic' = 'domestic',
  /** Фитнес-центр **/
  'Fitness' = 'fitness',
  /** Детский сад **/
  'Kindergarten' = 'kindergarten',
  /** Офисы **/
  'Offices' = 'offices',
  /** Поликлиника **/
  'Polyclinic' = 'polyclinic',
  /** Бассейн **/
  'Pool' = 'pool',
  /** Места для отдыха **/
  'Recreation' = 'recreation',
  /** Рестораны **/
  'Restaurant' = 'restaurant',
  /** Школа **/
  'School' = 'school',
  /** SPA-центо **/
  'Spa' = 'spa',
  /** Спортивные площадки **/
  'Sport' = 'sport',
  /** Супермаркет **/
  'Supermarket' = 'supermarket',
}

export interface IShortSpecificationItem {
  /** Наименование **/
  title: string;
  /** Значение **/
  value: string;
  /** Текст для тултипа **/
  valueTooltipText?: string;
}
