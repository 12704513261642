import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IConfig } from '@cian/config/shared';
import { ConsultantWidgetDesktop, prepareUserData, IUser } from '@cian/newbuilding-chat-component';

import { isNewbuildingChatOpenedSelector } from '../../selectors/newbuildingChat';
import { getUser } from '../../selectors/user';
import { chatToggleOpen } from '../../actions/nebuildingChat';
import { isNeedToShow, updateShownTimestamp } from '../../utils/newbuildingChat/periodicActions';
import { CHAT_OPEN_DELAY, CHAT_FORCE_OPEN_KEY, CHAT_FORCE_OPEN_INTERVAL } from '../../constants/newbuildingChat';
import { prepareMessengers } from './utils/prepareMessengers';
import { trackShowButton, trackClickChatOpen, trackClickChatClose } from './analytics';

interface INewbuildingChatContainerProps {
  config: IConfig;
}

export function NewbuildingChatContainer({ config }: INewbuildingChatContainerProps) {
  const dispatch = useDispatch();
  const [needForceOpen, setNeedForceOpen] = React.useState(false);

  const toggleOpen = React.useCallback(
    (isOpen: boolean) => {
      dispatch(chatToggleOpen(isOpen));

      if (isOpen) {
        setNeedForceOpen(false);
        trackClickChatOpen();
      } else {
        trackClickChatClose();
      }
    },
    [needForceOpen],
  );

  const isOpen = useSelector(isNewbuildingChatOpenedSelector);
  const user = useSelector(getUser);

  const accountName = config.get<string>('webim.accountName');
  const host = config.get<string>('webim.chat.host');
  const needForceOpenRS = config.get<boolean>('newbuildingChat.needForceOpen');

  const webimProps = React.useMemo(
    () => ({
      accountName: accountName as string,
      userData: prepareUserData(user as IUser),
      host: host || undefined,
    }),
    [],
  );
  const messengersLinks = React.useMemo(() => prepareMessengers(config), []);

  const onReady = React.useCallback(() => {
    if (!!accountName && needForceOpen) {
      setTimeout(() => {
        dispatch(chatToggleOpen(isOpen));
        setNeedForceOpen(false);
        updateShownTimestamp(CHAT_FORCE_OPEN_KEY, CHAT_FORCE_OPEN_INTERVAL);
      }, CHAT_OPEN_DELAY);
    }
  }, [!!accountName, needForceOpen]);

  React.useEffect(() => {
    if (needForceOpenRS && isNeedToShow(CHAT_FORCE_OPEN_KEY)) {
      setNeedForceOpen(true);
    }
  }, []);

  React.useEffect(() => {
    if (accountName) {
      trackShowButton();
    }
  }, [accountName]);

  if (!accountName) {
    return null;
  }

  return (
    <ConsultantWidgetDesktop
      webimProps={webimProps}
      chatOpened={isOpen}
      messengersLinks={messengersLinks}
      toggleOpen={toggleOpen}
      onReady={onReady}
    />
  );
}
