import * as React from 'react';
import { IconButton } from '@cian/frontend-reviews-components';
import { IconComment16 } from '@cian/ui-kit';
import { Link } from '../Link';
import { trackAnswersClick } from './tracking';

export interface IReviewAnswersProps {
  reviewId: string;
  fullUrl: string;
  answersCount: number;
}

export const ReviewAnswers: React.FC<IReviewAnswersProps> = ({ reviewId, fullUrl, answersCount }) => {
  return (
    <Link url={`${fullUrl}otzyvy/#${reviewId}`} target="blank" linkStyle={{ display: 'block' }}>
      <IconButton Icon={IconComment16} label={answersCount} onClick={() => trackAnswersClick('')} />
    </Link>
  );
};
