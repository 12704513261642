import { ca } from '@cian/analytics';

import { IPromoInfoSchema } from '../../types/promo';
import { getNewbuildingProduct } from '../../utils/analytics';
import { MORTGAGE_PROMO_TYPES } from '../../constants/promos';

export function trackShowPhoneClick(gaLabel: string, promo: IPromoInfoSchema, newbuildingId: number) {
  const { promoType, promoId } = promo;

  const isMortgage = MORTGAGE_PROMO_TYPES.includes(promo.promoType);

  ca('eventSite', {
    action: isMortgage ? 'Open_jk_mortgage' : 'Open_jk_promotions',
    category: 'Phones',
    label: gaLabel,
    name: 'oldevent',
    products: [getNewbuildingProduct({ newbuildingId, extra: { label: promoType, promotion_id: promoId } })],
  });
}
