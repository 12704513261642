import * as React from 'react';
import { ChartFactory, TChartFactoryProps } from '@cian/react-chartjs-component';

import * as styles from './styles.css';

interface IChartWrapperProps {
  chartCfg: TChartFactoryProps;
}

export const ChartWrapper: React.VFC<IChartWrapperProps> = ({ chartCfg }) => {
  return (
    <div className={styles['container']}>
      <ChartFactory {...chartCfg} />
    </div>
  );
};
