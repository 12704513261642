import * as React from 'react';

import { TPromoType, IBankSchema } from '../../../types/promo';
import { BankIcon } from '../components/BankIcon';
import {
  ActionIcon,
  DiscountIcon,
  MaternalCapitalIcon,
  MilitaryMortgageIcon,
  MortgageIcon,
} from '../components/PromoIcons';

export function getPromoIcon(type: TPromoType, bank?: IBankSchema | null): React.ReactElement {
  switch (type) {
    case 'discount':
      return <DiscountIcon />;
    case 'mortgage':
    case 'cianMortgage':
      return <MortgageIcon />;
    case 'bonus':
      return <ActionIcon />;
    case 'militaryMortgage':
      return <MilitaryMortgageIcon />;
    case 'maternalCapital':
      return <MaternalCapitalIcon />;
    case 'bank': {
      return <BankIcon bank={bank} />;
    }
  }
}
