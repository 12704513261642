import * as React from 'react';

import { Section } from '../Section';
import { SectionTitle } from '../SectionTitle';
import * as styles from './RecommendedNewbuildings.css';

interface IRecommendedNewbuildingsProps {
  compilations?: React.ReactNode;
  headerAside?: React.ReactNode;
  recommendations: React.ReactNode;
}

export const RecommendedNewbuildings: React.FC<IRecommendedNewbuildingsProps> = ({
  compilations,
  headerAside,
  recommendations,
}) => {
  return (
    <Section>
      <div className={styles['header']}>
        <SectionTitle>Похожие ЖК</SectionTitle>
        {headerAside && <div className={styles['header-aside']}>{headerAside}</div>}
      </div>

      {recommendations}

      {compilations && <div className={styles['compilations']}>{compilations}</div>}
    </Section>
  );
};
