import { INewbuildingRecommendation } from '../../types/microfrontends/newbuildingCallbackWidget';
import { IRecommendationContact, IRecommendationNewbuilding } from '../../types/recommendations';

export interface IRecommendationNewbuildingWithContact extends IRecommendationNewbuilding {
  contact: IRecommendationContact;
}

export const mapRecommendedNewbuildingToRecommendation = (
  newbuilding: IRecommendationNewbuildingWithContact,
): INewbuildingRecommendation => {
  const recommendation: INewbuildingRecommendation = {
    agentName: newbuilding.contact.name,
    agentPhone: newbuilding.contact.phone,
    agentType: newbuilding.contact.type || 'seller',
    buildingStatusInfo: newbuilding.status,
    formattedPrice: newbuilding.minPrice || '',
    newbuildingId: newbuilding.id,
    newbuildingName: newbuilding.name,
    photoUrl: newbuilding.media[0]?.url || null,
    type: 'newbuilding',
    underground: newbuilding.underground
      ? {
          color: newbuilding.underground.lineColor,
          name: newbuilding.underground.undergroundName,
          time: newbuilding.underground.distance,
          transportType: newbuilding.underground.transportType,
        }
      : null,
    url: newbuilding.url,
  };

  return recommendation;
};
