import { useApplicationContext } from '../../utils/applicationContext';

type TNewbuildingBrokerEntryLinks = {
  rewardTariffsLink: string;
  landingPageLink: string;
};

export const useBrokerLinks = () => {
  const { config } = useApplicationContext();

  const { landingPageLink, rewardTariffsLink } = config.get<TNewbuildingBrokerEntryLinks>(
    'newbuildingBrokerEntryLinks',
  ) ?? {
    landingPageLink: 'https://info.cian.ru/reward/',
    rewardTariffsLink: 'https://www.cian.ru/newbuilding-broker-reward-tariffs/',
  };

  return { landingPageLink, rewardTariffsLink };
};
