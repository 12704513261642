/* eslint-disable */
//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetInfrastructureMicrofrontendRequest,
  IMappers,
  TGetInfrastructureMicrofrontendModel,
  TGetInfrastructureMicrofrontendResponse,
} from './types';
import { IMicrofrontendManifest } from '@cian/mf-registry/shared';

const defaultConfig = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'map-search-frontend',
  pathApi: '/v1/get-infrastructure-microfrontend/',
  hostType: 'api',
} as TGetInfrastructureMicrofrontendModel;

function createGetInfrastructureMicrofrontendModel(
  parameters: TGetInfrastructureMicrofrontendRequest,
): TGetInfrastructureMicrofrontendModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetInfrastructureMicrofrontendOptions<TResponse200> {
  httpApi: IHttpApi;
  parameters: TGetInfrastructureMicrofrontendRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200>;
}

async function fetchGetInfrastructureMicrofrontend<TResponse200>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetInfrastructureMicrofrontendOptions<TResponse200>): Promise<
  TResponse200 | TGetInfrastructureMicrofrontendResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetInfrastructureMicrofrontendModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response.manifest as IMicrofrontendManifest);
    }
  }

  return { response, statusCode } as TGetInfrastructureMicrofrontendResponse;
}

export { defaultConfig, createGetInfrastructureMicrofrontendModel, fetchGetInfrastructureMicrofrontend };
