import * as React from 'react';
import { ModalWindow } from '@cian/ui-kit';
import * as styles from './HousesByTurnModal.css';
import { HousesByTurnTitle } from '../HousesByTurnTitle';
import { HousesByTurn } from '../HousesByTurn';
import { IHousesByTurnTitleProps } from '../FinishYearsTitle';

type THousesByTurnModalProps = Pick<IHousesByTurnTitleProps, 'housesByTurnDisplay'>;

export const HousesByTurnModal: React.FC<THousesByTurnModalProps> = props => {
  const { housesByTurnDisplay } = props;
  const [open, setOpen] = React.useState(false);

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <ModalWindow
        open={open}
        escape
        onClose={onClose}
        size="M"
        width={610}
        content={
          <div className={styles['modal-content']} data-testid="HousesByTurnModalContent">
            <HousesByTurn housesByTurnDisplay={housesByTurnDisplay} />
          </div>
        }
      />
      <HousesByTurnTitle onClick={onOpen} />
    </>
  );
};
