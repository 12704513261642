import * as React from 'react';

import { VerticalSliderFullscreen } from '../VerticalSlider';
import { AdvantageItem } from './components/AdvantageItem';

import { Section } from '../Section';
import { INewbuildingAdvantage } from '../../types/newbuilding/advantages';

interface IAdvantagesProps {
  advantages: INewbuildingAdvantage[];
  onItemClick(i: number): void;
}

export function Advantages({ advantages, onItemClick }: IAdvantagesProps) {
  return (
    <Section title="Особенности комплекса">
      <VerticalSliderFullscreen onItemClick={onItemClick}>
        {advantages.map(({ title, images }) => (
          <AdvantageItem key={title} title={title} imgUrl={images[0]?.url} />
        ))}
      </VerticalSliderFullscreen>
    </Section>
  );
}
