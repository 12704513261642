import { ArticleHeading4, Label } from '@cian/ui-kit';
import * as React from 'react';

import { IPromoInfoSchema } from '../../types/promo';

import * as styles from './styles.css';
import { PromoItemIcon } from '../PromoItemIcon';

interface IPromoItemProps {
  onClick(): void;
  promo: IPromoInfoSchema;
  subtitle: React.ReactNode;
}

export function PromoItem({ promo, subtitle, onClick }: IPromoItemProps) {
  const { name, isBlackFriday } = promo;

  return (
    <div className={styles['container']} onClick={onClick} data-testid="PromoItem">
      <div className={styles['icon-wrapper']}>
        <PromoItemIcon promo={promo} />
      </div>
      <div className={styles['description']}>
        <div className={styles['title']}>
          <ArticleHeading4 as="h4">{name}</ArticleHeading4>
        </div>
        <div className={styles['subtitle']}>
          {isBlackFriday && (
            <Label background="primary_100" color="white_100">
              Sale
            </Label>
          )}
          {subtitle}
        </div>
      </div>
    </div>
  );
}
