import * as React from 'react';
import { scrollToAnchor } from '@cian/newbuilding-utils';
import { IconEscrou40, IconWarningSymbol40 } from '@cian/ui-kit/icons/product/40';
import { SolidZhk40 } from '@cian/ui-kit/prev';
import { useSelector } from 'react-redux';

import { OffersReliableBlock, OffersReliableBlockLayout } from '../../components/OffersReliableBlock';
import {
  getIsReliableCurrentHouse,
  getHasProblemsCurrentHouse,
  getIsEscrowCurrentHouse,
} from '../../selectors/newbuilding';
import { getSelectedHouseId } from '../../selectors/offers';
import { trackOffersReliableLabelClick } from './tracking';
import { RightReliableBanner } from '../../components/RightReliableLabelRedesigned';
import { isReliabilityEnabledSelector } from '../../selectors/reliability/isReliabilityEnabledSelector';
import { reliabilitySelector } from '../../selectors/reliability/reliabilitySelector';

export function OffersReliableBlockContainer() {
  const isReliable = useSelector(getIsReliableCurrentHouse);
  const hasProblems = useSelector(getHasProblemsCurrentHouse);
  const isEscrow = useSelector(getIsEscrowCurrentHouse);
  const houseId = useSelector(getSelectedHouseId);
  const isReliabilityV2Enabled = useSelector(isReliabilityEnabledSelector);
  const reliability = useSelector(reliabilitySelector);

  if (!isReliable && !hasProblems && !isEscrow) {
    return null;
  }

  const reliableText = houseId ? 'Надежность корпуса проверена' : 'Есть корпуса, проверенные на надежность';
  const problemText = houseId ? 'Осторожно! Проблемный корпус' : 'Осторожно! Есть проблемные корпуса';
  const escrowText = houseId ? 'Сделка с эскроу-счётом' : 'Есть корпуса со сделкой с эскроу–счётом';

  const onOffersReliableLabelClick = (anchor: string, label: string) => (event: React.MouseEvent<HTMLElement>) => {
    scrollToAnchor({
      anchor,
      offset: 0,
      hasRelocation: false,
      hasAnimation: true,
    })(event);
    trackOffersReliableLabelClick(label);
  };

  return (
    <OffersReliableBlockLayout>
      {isReliable && !isReliabilityV2Enabled && (
        <OffersReliableBlock
          text={reliableText}
          icon={<SolidZhk40 color="primary_100" />}
          onClick={onOffersReliableLabelClick('#reliable', 'flats_reliable_dev')}
        />
      )}
      {hasProblems && !isReliabilityV2Enabled && (
        <OffersReliableBlock
          text={problemText}
          icon={<IconWarningSymbol40 color="error_100" />}
          styleName={'container-problem'}
          onClick={onOffersReliableLabelClick('#problem', 'flats_problem_building')}
        />
      )}
      {isReliabilityV2Enabled && (
        <RightReliableBanner
          reliability={reliability}
          onReliableClick={onOffersReliableLabelClick('#reliable', 'flats_reliable_dev')}
          onProblemClick={onOffersReliableLabelClick('#reliable', 'flats_problem_building')}
          isBoldTitle={false}
        />
      )}
      {isEscrow && (
        <OffersReliableBlock
          text={escrowText}
          icon={<IconEscrou40 color="primary_100" />}
          styleName={'container-escrow'}
          onClick={onOffersReliableLabelClick('#escrow', 'flats_escrow')}
        />
      )}
    </OffersReliableBlockLayout>
  );
}
