import * as React from 'react';
import * as styles from './style.css';

export interface IHCardProps {
  newbuildingName: string;
  locality: string;
  address: string;
  phone: string;
  url: string;
}
export const HCard = (props: IHCardProps) => {
  return (
    <div className={styles['card']}>
      <div className="vcard">
        <div>
          <span className="category">Жилой комплекс</span>
          <span className="fn org">{props.newbuildingName}</span>
        </div>
        <div className="adr">
          <span className="locality">{props.locality}</span>,<span className="street-address">{props.address}</span>
        </div>
        <div>
          Телефон: <span className="tel">{props.phone}</span>
        </div>
        <span className="url">
          <span className="value-title" title={props.url}></span>
        </span>
      </div>
    </div>
  );
};
