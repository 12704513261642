import { IApplicationState } from '../../types/redux';
import { getSellers } from './getSellers';

/**
 * Возвращает признак ЖК на КпН.
 * КпН - консультант по недвижимости, оно же "Фабрика Лидов"
 */
export function getIsLeadFactory(state: IApplicationState) {
  return getSellers(state).some(seller => seller.isLeadFactory);
}
