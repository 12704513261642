import * as React from 'react';
import { Button } from '@cian/ui-kit';

interface ICallbackButtonProps {
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  loading: boolean;
  text: string;
}

export const CallbackButton: React.FC<ICallbackButtonProps> = ({ onClick, text, loading }) => {
  return (
    <div data-testid="CallMeBackButton">
      <Button onClick={onClick} size="M" theme="fill_secondary" type="button" fullWidth loading={loading}>
        {text}
      </Button>
    </div>
  );
};
