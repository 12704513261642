import * as React from 'react';
import { ModalFullscreen } from '@cian/ui-kit';

export interface IFullscreenPopupyProps {
  isOpened: boolean;
  header: React.ReactNode;
  content: React.ReactNode;
  onClose(): void;
}

export function FullscreenPopup({ isOpened, header, content, onClose }: IFullscreenPopupyProps) {
  return (
    <ModalFullscreen open={isOpened} escape onClose={onClose} theme="white">
      {header}
      {content}
    </ModalFullscreen>
  );
}
