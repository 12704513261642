import * as React from 'react';
import { noop } from '@cian/newbuilding-utils';
import { ArticleHeading3, Image, ArticleParagraph2 } from '@cian/ui-kit';
import { ButtonLink } from '../../../ButtonLink';

import * as styles from './styles.css';

interface IAdvantageItemProps {
  title: string;
  shortDescription: string;
  imgUrl?: string;
}

export function InteriorDecorationItem({ title, imgUrl, shortDescription }: IAdvantageItemProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['image']}>
        {!!imgUrl && <Image src={imgUrl} width="100%" height="100%" objectFit="cover" borderRadius="4px" />}
      </div>
      <div className={styles['title']}>
        <ArticleHeading3 as="h3">{title}</ArticleHeading3>
      </div>
      {shortDescription && (
        <div className={styles['description']}>
          <ArticleParagraph2 as={'span'}>{shortDescription}</ArticleParagraph2>
        </div>
      )}
      <ButtonLink onClick={noop}>Узнать больше</ButtonLink>
    </div>
  );
}
