// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  ISetReviewReactionWebRequest,
  TSetReviewReactionWebModel,
  IMappers,
  TSetReviewReactionWebResponse,
  ISetReviewReactionWebResponse,
  ISetReviewReactionWebResponseError,
} from './types';

export const defaultConfig: TSetReviewReactionWebModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'newbuilding-reviews',
  pathApi: '/v1/set-review-reaction-web/',
  requestType: 'json',
} as TSetReviewReactionWebModel;

export function createSetReviewReactionWebModel(parameters: ISetReviewReactionWebRequest): TSetReviewReactionWebModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISetReviewReactionWebOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: ISetReviewReactionWebRequest;
}

export async function fetchSetReviewReactionWeb<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ISetReviewReactionWebOptions<TResponse200, TResponse400>): Promise<
  TSetReviewReactionWebResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createSetReviewReactionWebModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ISetReviewReactionWebResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as ISetReviewReactionWebResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TSetReviewReactionWebResponse;
}
