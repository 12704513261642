import * as React from 'react';
import * as styles from './RightPromoContainer.css';
import { PromoLabelContainer } from '../PromoLabel';

export const RightPromoContainer: React.FunctionComponent = () => {
  return (
    <div className={styles['container']}>
      <PromoLabelContainer />
    </div>
  );
};
