import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectRealtyValuationData,
  selectRealtyValuationDataFetching,
  selectSalesFiltersData,
  selectSalesFiltersState,
} from '../../../../selectors/realtyValuation';
import { getPreparedFiltersProps } from '../../utils/getPreparedFiltersProps';
import { updateRealtyValuationAction, setSalesFilterAction } from '../../../../actions/realtyValuation';
import { Filters, TabContent } from '../../../../components/RealtyValuation';
import { SalesChartContainer } from '../SalesChartContainer';
import { TThunkDispatch } from '../../../../types/redux/thunk';
import { EValuationTabs } from '../../constants/tabs';
import { trackRealtyValuationFilterChange } from '../../tracking';

export const SalesDynamicsContainer: React.VFC = () => {
  const dispatch = useDispatch<TThunkDispatch>();

  const isFetching = useSelector(selectRealtyValuationDataFetching);
  const realtyValuation = useSelector(selectRealtyValuationData);
  const salesFiltersData = useSelector(selectSalesFiltersData);
  const salesFiltersState = useSelector(selectSalesFiltersState);

  const filtersConfig = React.useMemo(() => {
    if (!salesFiltersData) {
      return null;
    }

    return getPreparedFiltersProps({
      onChange: async (filterName, filterValue) => {
        trackRealtyValuationFilterChange(filterName, filterValue);
        dispatch(setSalesFilterAction({ filterName, filterValue }));
        await dispatch(updateRealtyValuationAction(EValuationTabs.SalesDynamic));
      },
      filtersData: salesFiltersData,
      filtersState: salesFiltersState,
    });
    /** Так и должно быть */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, salesFiltersData]);

  if (!realtyValuation?.salesDynamics) {
    return null;
  }

  return (
    <TabContent
      filters={filtersConfig && <Filters filtersProps={filtersConfig} />}
      chart={<SalesChartContainer />}
      isFetching={isFetching}
      testId="valuation_sales_tab"
    />
  );
};
