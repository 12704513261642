import { TLikeStatus, IReviews } from '../types/newbuilding/reviews';
import { getNewReactionCount } from '../mappers/getNewReactionCount';

export type TChangeReviewReactionType = {
  reviews?: IReviews | null;
  payload: {
    reviewId: string;
    type: TLikeStatus;
  };
};

export const changeReviewReactionType = ({ reviews, payload }: TChangeReviewReactionType) => {
  if (!reviews) {
    return null;
  }

  return {
    ...reviews,
    reviews: reviews.reviews.map(review => {
      if (review.id === payload.reviewId) {
        return {
          ...review,
          like: {
            ...getNewReactionCount(review.like.status)[payload.type](review.like),
            status: payload.type,
          },
        };
      }

      return review;
    }),
  };
};
