import { TPricesFiltersPeriod } from '../../../types/realtyValuation';

const postfixes: Record<TPricesFiltersPeriod, string> = {
  allTime: 'все время',
  year: 'год',
  halfYear: 'полгода',
};

export const getPriceDiffTitle = (period: TPricesFiltersPeriod = 'allTime') =>
  `Изменение цены м² за ${postfixes[period]}`;
