import { IApplicationState } from '../../types/redux';

export function getBuilders(state: IApplicationState) {
  const {
    newbuilding: { builders },
  } = state;

  if (builders) {
    return builders;
  }

  return [];
}
