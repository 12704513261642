import { INewbuilding } from '../../types/newbuilding/newbuilding';
import { ENewbuildingActionTypes, TSetFlatViewOrder } from '../../actions/newbuilding';
import { EFavoritesActionTypes, IFavoritesAction } from '../../actions/favorites';
import { changeReviewReactionType } from '../../utils/changeReviewReactionType';
import { EReviewsActionTypes, TSetReviewReaction } from '../../actions/reviews';

export interface INewbuildingsAction {
  type: ENewbuildingActionTypes.SetNewbuilding;
  payload: INewbuilding;
}

type Action = INewbuildingsAction | IFavoritesAction | TSetFlatViewOrder | TSetReviewReaction;

// Warning флаг isFavorite нужен только при инициализации, в дальнейшнем он меняется в FavoriteBasic
export function newbuildingReduces(state: INewbuilding, action: Action) {
  switch (action.type) {
    case ENewbuildingActionTypes.SetNewbuilding:
      return action.payload;
    case EFavoritesActionTypes.SetIsFavorite:
      return {
        ...state,
        ...(action as IFavoritesAction).payload,
      };
    case ENewbuildingActionTypes.SetFlatViewOrder:
      return {
        ...state,
        flatViewOrder: {
          ...state.flatViewOrder,
          isEnabled: (state.flatViewOrder && state.flatViewOrder.isEnabled) || false,
          orderStatus: action.payload,
        },
      };
    case EReviewsActionTypes.SetReviewReaction: {
      const { payload } = action;

      return {
        ...state,
        reviews: changeReviewReactionType({
          reviews: state.reviews,
          payload,
        }),
      };
    }
    default:
      return { ...state };
  }
}
