import { ca } from '@cian/analytics';

export function trackMortgageBannerShow() {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'Promo',
    label: 'nb_card_morgage_banner',
    name: 'oldevent',
    useLastProducts: true,
  });
}
