/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';

interface IClientComponentProps {
  children?: React.ReactNode;
  replaceNode?: React.ReactNode;
}

export function ClientComponent({ replaceNode, children }: IClientComponentProps) {
  const [isBrowser, setIsBrowser] = React.useState(false);
  const isBrowserBuild = process.env.IS_BROWSER_BUILD;

  React.useEffect(() => {
    setIsBrowser(Boolean(isBrowserBuild));
  }, [isBrowserBuild]);

  return <>{isBrowser ? children : replaceNode}</>;
}
