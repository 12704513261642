import { IApplicationState } from '../../types/redux';

export function getNewbuildingCoordinates(state: IApplicationState) {
  const {
    newbuilding: {
      geo: { coordinates },
    },
  } = state;

  return coordinates;
}
