import * as React from 'react';
import { useSelector } from 'react-redux';
import { Documentation } from '../../components/Documentation';
import { getDocuments } from '../../selectors/newbuilding';
import { isReliabilityEnabledSelector } from '../../selectors/reliability/isReliabilityEnabledSelector';

export const DocumentationContainer = () => {
  const documentation = useSelector(getDocuments);
  const isReliabilityV2Enabled = useSelector(isReliabilityEnabledSelector);

  if (!documentation || isReliabilityV2Enabled) {
    return null;
  }

  return <Documentation documentation={documentation} />;
};
