import * as React from 'react';
import classNames from 'clsx';
import { throttle } from '@cian/newbuilding-utils';

import * as styles from './CollapsedBlock.css';
import { isExpanderVisible } from './utils/isExpanderVisible';
import { CollapsedButton, ICollapsedButtonProps } from './CollapsedButton';

interface ICollapsedBlockProps extends React.PropsWithChildren {
  opened?: boolean;
  collapsedHeight: number;
  className?: string;
  onChangeOpen?(opened: boolean): void;
  Button?: React.ComponentType<ICollapsedButtonProps>;
}

export const CollapsedBlock: React.FunctionComponent<ICollapsedBlockProps> = props => {
  const contentEl = React.useRef<HTMLDivElement>(null);
  const [opened, setOpened] = React.useState(!!props.opened);
  const [contentHeight, setContentHeight] = React.useState(props.collapsedHeight);

  /* istanbul ignore next */
  const throttledCalculateHeight = throttle(500, () => {
    if (!contentEl || !contentEl.current) {
      return;
    }

    setContentHeight(contentEl.current.offsetHeight);
  });

  React.useEffect(() => {
    /* istanbul ignore if */
    if (!contentEl || !contentEl.current) {
      return undefined;
    }

    setContentHeight(contentEl.current.offsetHeight);
    window.addEventListener('resize', throttledCalculateHeight);

    /* istanbul ignore next */
    return () => window.removeEventListener('resize', throttledCalculateHeight);
  }, [opened]);

  const { children, className, collapsedHeight, Button } = props;

  const toggle = () => {
    /* istanbul ignore else */
    if (props.onChangeOpen) {
      props.onChangeOpen(!opened);
    }

    // В момент нажатия кнопки она получает фокус, поэтому блок скроллится на позиции где кнопка, а не раскрывается вниз
    // Через ref сделать не получится, потому что кнопка может прийти как props
    if (!opened) {
      (document.activeElement as HTMLButtonElement).blur();
    }

    setOpened(!opened);
  };

  const withButton = isExpanderVisible({ contentHeight, maxHeight: props.collapsedHeight });
  const collapsed = withButton && !opened;
  const ButtonComponent = Button || CollapsedButton;

  return (
    <div className={className} data-name="CollapsedBlock">
      <div
        className={classNames(styles['content'])}
        style={{
          maxHeight: `${collapsed ? collapsedHeight : contentHeight}px`,
        }}
      >
        <div ref={contentEl}>{children}</div>
      </div>

      {withButton && <ButtonComponent opened={opened} onClick={toggle} />}
    </div>
  );
};
