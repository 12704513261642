import { combineReducers, compose, applyMiddleware, createStore as _createStore } from 'redux';
import thunk from 'redux-thunk';
import { newbuildingRecommendationsReducer, newbuildingReduces } from '../../reducers';
import { IApplicationState, TReduxActions, TReduxStore } from '../../types/redux';
import { abUseExperimentsReducer } from '../../reducers/abUseExperiments';
import { userReduces } from '../../reducers/userReduces';
import { articleUrlReduces } from '../../reducers/articleUrlReducer';
import { pathReduces } from '../../reducers/pathReducer';
import { offersReducer } from '../../reducers/offers';
import { commonReducer } from '../../reducers/commonReducer';
import { subdomainReduces } from '../../reducers/subdomainReducer';
import { newbuildingChatReducer } from '../../reducers/newbuildingChat';
import { noopReducer } from '../../reducers/noopReducer';
import { flatViewOrderReducer } from '../../reducers/flatViewOrder';
import { IApplicationContext } from '../../types/applicationContext';
import { flatTourBookingReducer } from '../../reducers/flatTourBookingReducer';
import { aerialViewReducer } from '../../reducers/aerialView';
import { realtyValuationReducer } from '../../reducers/realtyValuation';
import { promoInfoReducer } from '../../reducers/promoInfo';
import { floorPlanReducer } from '../../reducers/floorPlanReducer';

let storeReminder: TReduxStore;

/**
 * Хелпер для создания Redux стора
 * @param initialState IApplicationState - предзагруженное состояние
 */

export function createReduxStore(initialState: IApplicationState, context: IApplicationContext, debugEnabled: boolean) {
  const reducers = combineReducers<IApplicationState, TReduxActions>({
    abUseExperiments: abUseExperimentsReducer,
    aerialView: aerialViewReducer,
    articleUrl: articleUrlReduces,
    blackFriday: noopReducer(null),
    common: commonReducer,
    flatTourBooking: flatTourBookingReducer,
    flatViewOrder: flatViewOrderReducer,
    floorPlan: floorPlanReducer,
    newbuilding: newbuildingReduces,
    newbuildingChat: newbuildingChatReducer,
    newbuildingRecommendations: newbuildingRecommendationsReducer,
    offers: offersReducer,
    path: pathReduces,
    promoInfo: promoInfoReducer,
    realtyValuation: realtyValuationReducer,
    subdomain: subdomainReduces,
    user: userReduces,
    isBuilderLinkEnabled: noopReducer(false),
    isReliabilityV2Enabled: noopReducer(false),
    isDeveloperCardRedesignEnabled: noopReducer(false),
    reliability: noopReducer(null),
    fullUrl: noopReducer(''),
  });
  const composeEnhancers = debugEnabled ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

  const middlewares = [thunk.withExtraArgument(context)];

  const storeEnchancers = composeEnhancers(
    compose(applyMiddleware(...middlewares)),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;

  const createStore = (): TReduxStore =>
    _createStore<IApplicationState, TReduxActions, IApplicationState, {}>(reducers, initialState, storeEnchancers);

  if (process.env.NODE_ENV === 'development' && debugEnabled) {
    if (!storeReminder) {
      storeReminder = createStore();
    }

    storeReminder.replaceReducer(reducers);

    return storeReminder;
  }

  return createStore();
}
