import { connect } from 'react-redux';

import { trackShowPhone } from '../../actions/analytics';
import { LastHopePopup, ILastHopePopupProps } from '../../components/LastHopePopup';
import { LIMIT_IN_A_DAY } from '../../constants/lastHopePopup';
import { getBuilders } from '../../selectors/newbuilding';
import { isNewbuildingChatOpenedSelector } from '../../selectors/newbuildingChat';
import { IApplicationState } from '../../types/redux';
import { TThunkDispatch } from '../../types/redux/thunk';
import {
  isPopupHistoryExpired,
  resetPopupShowingsHistory,
  isUserOpenedPhoneInNewobject,
  isShowingsLimitReached,
} from '../../utils/businessLogic/lastHopePopup';

type TBuildersStateProps = Pick<ILastHopePopupProps, 'builderName' | 'isDefaultOpen' | 'newbuildingChatOpened'>;

export function mapStateToProps(state: IApplicationState): TBuildersStateProps {
  // Незамокать отсутствие window
  /* istanbul ignore next */
  if (typeof window === 'undefined') {
    return {
      isDefaultOpen: () => false,
      builderName: null,
      newbuildingChatOpened: false,
    };
  }

  const {
    newbuilding: { fromDevelopersPropsCount, id },
  } = state;
  const builders = getBuilders(state);
  const builderWithPhones = builders.find(builder => builder.phone && builder.phone.number);

  /* istanbul ignore next */
  if (isPopupHistoryExpired(24)) {
    resetPopupShowingsHistory();
  }

  return {
    builderName: builderWithPhones ? builderWithPhones.name : null,
    isDefaultOpen: () =>
      fromDevelopersPropsCount !== 0 &&
      !!builderWithPhones &&
      !isUserOpenedPhoneInNewobject(id) &&
      !isShowingsLimitReached(LIMIT_IN_A_DAY, 24),
    newbuildingChatOpened: isNewbuildingChatOpenedSelector(state),
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch) {
  return {
    onShowPhoneClick: () => {
      dispatch(trackShowPhone('Open_ask_builder_popup'));
    },
  };
}

export const LastHopePopupContainer = connect(mapStateToProps, mapDispatchToProps)(LastHopePopup);
