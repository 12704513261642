import { omit } from 'ramda';

import { IPriceDynamicsData, IRealtyValuationData, ISalesDynamicsData } from '../../../types/realtyValuation';
import { IGetNewbuildingValuationBlockWebResponse as TApiResponse } from '../../../repositories/newbuilding-estimate/v2/get-newbuilding-valuation-block-web';
import { EValuationTabs } from '../../../containers/RealtyValuation/constants/tabs';

const PRICE_DYNAMICS_EMPTY: IPriceDynamicsData = {
  avgPrice: null,
  avgPriceSqm: null,
  chart: null,
  priceDiff: null,
};

const SALES_DYNAMICS_EMPTY: ISalesDynamicsData = {
  chart: null,
};

/**
 * Подготавливает обновленные данные realtyValuation, забирая из ответа нужное поле
 * Если ответ api пустой, вернет подготовленные заглушки данных (для рендера варианта UI без данных)
 * */
export function prepareUpdatedData(apiResponse: TApiResponse, tab: EValuationTabs): Partial<IRealtyValuationData> {
  if (tab === EValuationTabs.PriceDynamic) {
    if (!apiResponse.valuation?.priceDynamics) {
      return { priceDynamics: PRICE_DYNAMICS_EMPTY };
    }

    return {
      priceDynamics: omit(['activeFilters', 'availableFilters'], apiResponse.valuation.priceDynamics),
    };
  }

  if (!apiResponse.valuation?.salesDynamics) {
    return { salesDynamics: SALES_DYNAMICS_EMPTY };
  }

  return {
    salesDynamics: omit(['activeFilters', 'availableFilters'], apiResponse.valuation.salesDynamics),
  };
}
