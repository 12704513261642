import * as React from 'react';
import { Stars, EStarSize, getPrettyFloat } from '@cian/frontend-reviews-components';
import { ArticleHeading5 } from '@cian/ui-kit';
import cn from 'clsx';
import * as style from './NewbuildingReviewsStat.css';
import { Link } from '../Link';

export interface INewbuildingReviewsStatProps {
  fullUrl: string;
  rating: number;
  reviewsCountText: string;
  reviewLinkClick: () => void;
}

export const NewbuildingReviewsStat: React.FC<INewbuildingReviewsStatProps> = ({
  fullUrl,
  rating,
  reviewsCountText,
  reviewLinkClick,
}) => {
  return (
    <Link url={`${fullUrl}otzyvy/`} target="blank" linkStyle={{ display: 'block' }} onClick={reviewLinkClick}>
      <div className={cn(style['review'], style['review--link'])}>
        <div className={style['review--link--rating']}>{getPrettyFloat(Number(rating))}</div>
        <div>
          <Stars value={Number(rating)} size={EStarSize.M} />
          <ArticleHeading5 color="white_100">{reviewsCountText}</ArticleHeading5>
        </div>
      </div>
    </Link>
  );
};
