import * as React from 'react';
import * as styles from './LayoutsLink.css';
import { Link } from '../Link';

interface ILayoutsLinkProps {
  url: string;
  onClick(): void;
}
export const LayoutsLink = (props: ILayoutsLinkProps) => {
  return (
    <div className={styles['container']}>
      <Link url={props.url} onClick={props.onClick} target="blank">
        Все планировки
      </Link>
    </div>
  );
};
