/* eslint-disable max-lines */
import * as React from 'react';

/* istanbul ignore next */
export const MaternalCapitalIcon = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={56} height={56} viewBox="0 0 56 56">
    <path
      fill="url(#maternal-capital__paint0_linear)"
      d="M25.8 36h4.4c6.6 0 11.9-5.2 11.9-11.8H28V12h-2.2C19.3 12 14 17.3 14 23.8v.4c0 6.6 5.3 11.9 11.8 11.9z"
    />
    <path fill="url(#maternal-capital__paint1_linear)" d="M42 24.2H14A12 12 0 0026 36h4a12 12 0 0012-12z" />
    <path
      fill="url(#maternal-capital__paint2_linear)"
      d="M25.8 36h4.4c6.6 0 11.9-5.2 11.9-11.8H28V12h-2.2C19.3 12 14 17.3 14 23.8v.4c0 6.6 5.3 11.9 11.8 11.9z"
    />
    <path fill="#FF7E00" d="M43.2 22a2 2 0 100-4.2 2 2 0 000 4.1z" />
    <path
      fill="url(#maternal-capital__paint3_linear)"
      d="M41.4 19.8c0-1 .8-1.8 1.8-1.8.6 0 1 .2 1.3.5l.2-.1c-.4-.4-1-.6-1.5-.6a2 2 0 00-2 2c0 .7.2 1.2.6 1.5l.1-.1c-.3-.4-.5-.8-.5-1.4z"
    />
    <path
      fill="#FF7E00"
      d="M21 37.1a5 5 0 100 10.2 5 5 0 005-5c0-3-2.2-5.2-5-5.2zm0 7.1a2 2 0 01-2-2c0-1.1 1-2 2-2s2 1 2 2c0 1.1-1 2-2 2zm15-7.1a5 5 0 100 10.2 5 5 0 005-5c0-3-2.3-5.2-5-5.2zm0 7.1a2 2 0 01-2-2c0-1.1.8-2 2-2 1 0 2 1 2 2 0 1.1-1 2-2 2z"
    />
    <path
      fill="url(#maternal-capital__paint4_linear)"
      d="M16.5 42.2a4.5 4.5 0 017.7-3l.4-.5c-1-1-2.2-1.6-3.6-1.6a5 5 0 00-3.5 8.7l.4-.4c-.9-.8-1.4-2-1.4-3.2z"
    />
    <path
      fill="url(#maternal-capital__paint5_linear)"
      d="M23 42.2c0 1.2-1 2-2 2-.6 0-1.1-.2-1.5-.6l-.2.2c.4.5 1 .7 1.7.7 1.2 0 2.3-1 2.3-2.3 0-.6-.3-1.2-.7-1.7l-.2.3c.3.4.6.9.6 1.4z"
    />
    <path
      fill="url(#maternal-capital__paint6_linear)"
      d="M31.4 42.2a4.5 4.5 0 017.7-3l.4-.5c-.9-1-2.2-1.6-3.6-1.6a5 5 0 00-3.5 8.7l.4-.4c-.9-.8-1.4-2-1.4-3.2z"
    />
    <path
      fill="url(#maternal-capital__paint7_linear)"
      d="M37.9 42.2c0 1.2-1 2-2 2-.6 0-1-.2-1.5-.6l-.1.2c.4.5 1 .7 1.6.7 1.2 0 2.3-1 2.3-2.3 0-.6-.2-1.2-.7-1.7l-.2.3c.3.3.6.9.6 1.4z"
    />
    <path
      fill="url(#maternal-capital__paint8_linear)"
      d="M25.5 42.2a4.5 4.5 0 01-7.7 3.1l-.4.5c1 .9 2.2 1.5 3.6 1.5a5 5 0 003.5-8.7l-.4.5c.9.7 1.4 1.9 1.4 3.1z"
    />
    <path
      fill="url(#maternal-capital__paint9_linear)"
      d="M40.5 42.2a4.5 4.5 0 01-7.7 3.1l-.5.5c1 .9 2.2 1.5 3.6 1.5a5 5 0 003.6-8.7l-.5.5c1 .7 1.5 1.9 1.5 3.1z"
    />
    <defs>
      <linearGradient
        id="maternal-capital__paint0_linear"
        x1={15.1}
        x2={36.3}
        y1={13.7}
        y2={34.9}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7E00" stopOpacity={0.6} />
        <stop offset={0} stopColor="#FF8003" stopOpacity={0.6} />
        <stop offset={0} stopColor="#FF9227" stopOpacity={0.6} />
        <stop offset={0} stopColor="#FFA247" stopOpacity={0.6} />
        <stop offset={0} stopColor="#FFB062" stopOpacity={0.7} />
        <stop offset={0} stopColor="#FFBB79" stopOpacity={0.7} />
        <stop offset={0.1} stopColor="#FFC489" stopOpacity={0.7} />
        <stop offset={0.1} stopColor="#FFCA95" stopOpacity={0.8} />
        <stop offset={0.1} stopColor="#FFCD9C" stopOpacity={0.8} />
        <stop offset={0.2} stopColor="#FFCE9E" />
        <stop offset={0.4} stopColor="#FFA853" />
        <stop offset={0.7} stopColor="#FF8A17" />
        <stop offset={0.8} stopColor="#FF7E00" />
      </linearGradient>
      <linearGradient
        id="maternal-capital__paint1_linear"
        x1={28.1}
        x2={28.1}
        y1={35.2}
        y2={24.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7E00" stopOpacity={0} />
        <stop offset={1} stopColor="#FF7E00" />
      </linearGradient>
      <linearGradient
        id="maternal-capital__paint2_linear"
        x1={15.1}
        x2={36.3}
        y1={13.7}
        y2={34.9}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7E00" stopOpacity={0.6} />
        <stop offset={0} stopColor="#FF8003" stopOpacity={0.6} />
        <stop offset={0} stopColor="#FF9227" stopOpacity={0.6} />
        <stop offset={0} stopColor="#FFA247" stopOpacity={0.6} />
        <stop offset={0} stopColor="#FFB062" stopOpacity={0.7} />
        <stop offset={0} stopColor="#FFBB79" stopOpacity={0.7} />
        <stop offset={0.1} stopColor="#FFC489" stopOpacity={0.7} />
        <stop offset={0.1} stopColor="#FFCA95" stopOpacity={0.8} />
        <stop offset={0.1} stopColor="#FFCD9C" stopOpacity={0.8} />
        <stop offset={0.2} stopColor="#FFCE9E" />
        <stop offset={0.4} stopColor="#FFA853" stopOpacity={0.6} />
        <stop offset={0.7} stopColor="#FF8A17" stopOpacity={0.2} />
        <stop offset={0.8} stopColor="#FF7E00" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="maternal-capital__paint3_linear"
        x1={41}
        x2={43.9}
        y1={20.6}
        y2={17.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1} stopColor="#FF7E00" stopOpacity={0.6} />
        <stop offset={0.3} stopColor="#FFAF62" stopOpacity={0.7} />
        <stop offset={0.5} stopColor="#FFEAD6" stopOpacity={0.6} />
        <stop offset={0.5} stopColor="#FFE2C6" stopOpacity={0.6} />
        <stop offset={0.6} stopColor="#FFD6AE" stopOpacity={0.7} />
        <stop offset={0.6} stopColor="#FFCD9D" stopOpacity={0.8} />
        <stop offset={0.7} stopColor="#FFC892" stopOpacity={0.9} />
        <stop offset={0.7} stopColor="#FFC68F" />
        <stop offset={0.8} stopColor="#FFB369" stopOpacity={0.9} />
        <stop offset={0.9} stopColor="#FF830A" stopOpacity={0.6} />
        <stop offset={0.9} stopColor="#FF7E00" stopOpacity={0.6} />
      </linearGradient>
      <linearGradient
        id="maternal-capital__paint4_linear"
        x1={15.6}
        x2={22.6}
        y1={44}
        y2={37}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1} stopColor="#FF7E00" stopOpacity={0.6} />
        <stop offset={0.3} stopColor="#FFAF62" stopOpacity={0.7} />
        <stop offset={0.5} stopColor="#FFEAD6" stopOpacity={0.6} />
        <stop offset={0.5} stopColor="#FFE2C6" stopOpacity={0.6} />
        <stop offset={0.6} stopColor="#FFD6AE" stopOpacity={0.7} />
        <stop offset={0.6} stopColor="#FFCD9D" stopOpacity={0.8} />
        <stop offset={0.7} stopColor="#FFC892" stopOpacity={0.9} />
        <stop offset={0.7} stopColor="#FFC68F" />
        <stop offset={0.8} stopColor="#FFB369" stopOpacity={0.9} />
        <stop offset={0.9} stopColor="#FF830A" stopOpacity={0.6} />
        <stop offset={0.9} stopColor="#FF7E00" stopOpacity={0.6} />
      </linearGradient>
      <linearGradient
        id="maternal-capital__paint5_linear"
        x1={23.3}
        x2={20.2}
        y1={41.4}
        y2={44.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1} stopColor="#FF7E00" stopOpacity={0.6} />
        <stop offset={0.3} stopColor="#FFAF62" stopOpacity={0.7} />
        <stop offset={0.5} stopColor="#FFEAD6" stopOpacity={0.6} />
        <stop offset={0.5} stopColor="#FFE2C6" stopOpacity={0.6} />
        <stop offset={0.6} stopColor="#FFD6AE" stopOpacity={0.7} />
        <stop offset={0.6} stopColor="#FFCD9D" stopOpacity={0.8} />
        <stop offset={0.7} stopColor="#FFC892" stopOpacity={0.9} />
        <stop offset={0.7} stopColor="#FFC68F" />
        <stop offset={0.8} stopColor="#FFB369" stopOpacity={0.9} />
        <stop offset={0.9} stopColor="#FF830A" stopOpacity={0.6} />
        <stop offset={0.9} stopColor="#FF7E00" stopOpacity={0.6} />
      </linearGradient>
      <linearGradient
        id="maternal-capital__paint6_linear"
        x1={30.5}
        x2={37.5}
        y1={44}
        y2={37}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1} stopColor="#FF7E00" stopOpacity={0.6} />
        <stop offset={0.3} stopColor="#FFAF62" stopOpacity={0.7} />
        <stop offset={0.5} stopColor="#FFEAD6" stopOpacity={0.6} />
        <stop offset={0.5} stopColor="#FFE2C6" stopOpacity={0.6} />
        <stop offset={0.6} stopColor="#FFD6AE" stopOpacity={0.7} />
        <stop offset={0.6} stopColor="#FFCD9D" stopOpacity={0.8} />
        <stop offset={0.7} stopColor="#FFC892" stopOpacity={0.9} />
        <stop offset={0.7} stopColor="#FFC68F" />
        <stop offset={0.8} stopColor="#FFB369" stopOpacity={0.9} />
        <stop offset={0.9} stopColor="#FF830A" stopOpacity={0.6} />
        <stop offset={0.9} stopColor="#FF7E00" stopOpacity={0.6} />
      </linearGradient>
      <linearGradient
        id="maternal-capital__paint7_linear"
        x1={38.2}
        x2={35.1}
        y1={41.4}
        y2={44.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1} stopColor="#FF7E00" stopOpacity={0.6} />
        <stop offset={0.3} stopColor="#FFAF62" stopOpacity={0.7} />
        <stop offset={0.5} stopColor="#FFEAD6" stopOpacity={0.6} />
        <stop offset={0.5} stopColor="#FFE2C6" stopOpacity={0.6} />
        <stop offset={0.6} stopColor="#FFD6AE" stopOpacity={0.7} />
        <stop offset={0.6} stopColor="#FFCD9D" stopOpacity={0.8} />
        <stop offset={0.7} stopColor="#FFC892" stopOpacity={0.9} />
        <stop offset={0.7} stopColor="#FFC68F" />
        <stop offset={0.8} stopColor="#FFB369" stopOpacity={0.9} />
        <stop offset={0.9} stopColor="#FF830A" stopOpacity={0.6} />
        <stop offset={0.9} stopColor="#FF7E00" stopOpacity={0.6} />
      </linearGradient>
      <linearGradient
        id="maternal-capital__paint8_linear"
        x1={26.4}
        x2={19.4}
        y1={40.4}
        y2={47.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1} stopColor="#FF7E00" stopOpacity={0.6} />
        <stop offset={0.3} stopColor="#F66800" stopOpacity={0.8} />
        <stop offset={0.5} stopColor="#F25D00" />
        <stop offset={0.7} stopColor="#F56500" stopOpacity={0.9} />
        <stop offset={0.9} stopColor="#FE7A00" stopOpacity={0.6} />
        <stop offset={0.9} stopColor="#FF7E00" stopOpacity={0.6} />
      </linearGradient>
      <linearGradient
        id="maternal-capital__paint9_linear"
        x1={41.3}
        x2={34.4}
        y1={40.4}
        y2={47.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1} stopColor="#FF7E00" stopOpacity={0.6} />
        <stop offset={0.3} stopColor="#F66800" stopOpacity={0.8} />
        <stop offset={0.5} stopColor="#F25D00" />
        <stop offset={0.7} stopColor="#F56500" stopOpacity={0.9} />
        <stop offset={0.9} stopColor="#FE7A00" stopOpacity={0.6} />
        <stop offset={0.9} stopColor="#FF7E00" stopOpacity={0.6} />
      </linearGradient>
    </defs>
  </svg>
);

MaternalCapitalIcon.displayName = 'MaternalCapitalIcon';
