import { IApplicationState } from '../../types/redux';

export function selectRealtyValuationData(state: IApplicationState) {
  return state.realtyValuation.data;
}

export function selectRealtyValuationDataFetching(state: IApplicationState) {
  return state.realtyValuation.isFetching;
}

export function selectRealtyValuationTabs(state: IApplicationState) {
  return state.realtyValuation.tabs;
}

export const selectPricesFiltersData = (state: IApplicationState) => {
  return state.realtyValuation.data?.pricesFiltersData;
};

export const selectSalesFiltersData = (state: IApplicationState) => {
  return state.realtyValuation.data?.salesFiltersData;
};

export const selectPricesFiltersState = (state: IApplicationState) => {
  return state.realtyValuation.pricesFiltersState;
};

export const selectSalesFiltersState = (state: IApplicationState) => {
  return state.realtyValuation.salesFiltersState;
};
