import * as React from 'react';
import { UIHeading4, UIText2 } from '@cian/ui-kit';

import * as styles from './styles.css';

interface IMasterReviewLegacyProps {
  title: string;
  subtitle: string;
  text: string;
}

export const MasterReviewLegacy = ({ title, subtitle, text }: IMasterReviewLegacyProps) => {
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <UIHeading4>{title}</UIHeading4>
        <UIText2 color="gray60_100">{subtitle}</UIText2>
      </div>

      <div className={styles['content']}>{text}</div>
    </div>
  );
};
