import { IConfig } from '@cian/config/shared';

import { trackTelegramClick, trackWhatsappClick } from '../analytics';

interface IMessengerLink {
  type: 'telegram' | 'whatsapp';
  url: string;
  onClick?(): void;
}

export function prepareMessengers(config: IConfig): IMessengerLink[] {
  const result: IMessengerLink[] = [];
  const whatsappLink = config.get<string>('newbuildingConsultant.whatsappLink');
  const telegramLink = config.get<string>('newbuildingConsultant.telegramLink');

  if (whatsappLink) {
    result.push({
      type: 'whatsapp',
      url: whatsappLink,
      onClick: trackWhatsappClick,
    });
  }

  if (telegramLink) {
    result.push({
      type: 'telegram',
      url: telegramLink,
      onClick: trackTelegramClick,
    });
  }

  return result;
}
