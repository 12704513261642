import { ca } from '@cian/analytics';

export const trackTransportAccessibilityEntryShown = () => trackTransportAccessibilityEntryEvent('show_sopr');
export const trackTransportAccessibilityEntryClick = () => trackTransportAccessibilityEntryEvent('click_sopr');

function trackTransportAccessibilityEntryEvent(action: 'show_sopr' | 'click_sopr') {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action,
    label: 'itd_entry_point',
    page: {
      pageType: 'CardJK',
      siteType: 'desktop',
      extra: {
        pageTypeJK: 'Normal',
      },
    },
    useLastProducts: true,
  });
}
