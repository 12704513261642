import * as React from 'react';
import { OffersCountLink } from '../..';
import { IPromoInfoSchema } from '../../../../types/promo';
import * as styles from './styles.css';
import classNames from 'clsx';

interface IRowsExpanderProps {
  error: JSX.Element;
  mortgageSeoLink: JSX.Element;
  content: (promo: IPromoInfoSchema[]) => JSX.Element;
  promos: IPromoInfoSchema[];
  onDisplayedOffersChange: (promo: IPromoInfoSchema[]) => void;
}

const VISIBLE_ROWS_COUNT = 5;

export const RowCountController: React.VFC<IRowsExpanderProps> = ({
  content,
  error,
  onDisplayedOffersChange,
  promos,
  mortgageSeoLink,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const displayedPromos = expanded ? promos : promos.slice(0, VISIBLE_ROWS_COUNT);
  const unvisibleRowsCount = promos.length - VISIBLE_ROWS_COUNT;
  const isCountLinkVisible = promos.length > VISIBLE_ROWS_COUNT;

  React.useEffect(() => {
    onDisplayedOffersChange(displayedPromos);
  }, [displayedPromos]);

  if (displayedPromos.length === 0) {
    return error;
  }

  return (
    <div className={styles['container']}>
      {content(displayedPromos)}
      <div className={classNames(styles['controls'], isCountLinkVisible && styles['controls-multiple'])}>
        {isCountLinkVisible && (
          <OffersCountLink expanded={expanded} count={unvisibleRowsCount} onClick={() => setExpanded(!expanded)} />
        )}
        {mortgageSeoLink}
      </div>
    </div>
  );
};
