import * as React from 'react';

/* istanbul ignore next */
export const MilitaryMortgageIcon = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={56} height={56} viewBox="0 0 56 56">
    <circle cx={28} cy={39.8} r={9.7} fill="#FFC42C" />
    <path
      fill="url(#military-mortage__paint0_linear)"
      d="M37.7 39.8a9.7 9.7 0 11-19.4 0 9.7 9.7 0 0119.4 0zm-18.8 0a9.1 9.1 0 1018.3 0 9.1 9.1 0 00-18.3 0z"
    />
    <path
      fill="url(#military-mortage__paint1_linear)"
      d="M33.7 34a8 8 0 11-11.3 11.4l.5-.5a7.2 7.2 0 1010.2-10.2l.6-.6z"
    />
    <path
      fill="url(#military-mortage__paint2_linear)"
      d="M22.4 45.4a8 8 0 0111.3-11.3l-.6.6A7.2 7.2 0 1023 44.9l-.5.5z"
    />
    <path fill="#3AC500" d="M16.7 9h22.6v15.8L28 29.3l-11.3-4.5V9z" />
    <path fill="#FFCE2C" d="M21.6 9h12.9v17.7L28 29.3l-6.4-2.6V9.1z" />
    <path fill="#3AC500" d="M25.6 9h4.9v19.3l-2.5 1-2.4-1V9z" />
    <path fill="#fff" d="M28 33l1.5 4.7h5l-4 2.8 1.5 4.7-4-2.9-4 2.9 1.6-4.7-4-2.8h5L28 33z" />
    <path fill="url(#military-mortage__paint3_linear)" d="M16.7 9h22.7v8.3H16.7z" />
    <defs>
      <linearGradient
        id="military-mortage__paint0_linear"
        x1={28}
        x2={21.2}
        y1={40.2}
        y2={33.3}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCE2C" stopOpacity={0} />
        <stop offset={1} stopColor="#FFEAA5" />
      </linearGradient>
      <linearGradient
        id="military-mortage__paint1_linear"
        x1={28}
        x2={33.7}
        y1={39.8}
        y2={45.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC42C" />
        <stop offset={1} stopColor="#FFE693" />
      </linearGradient>
      <linearGradient
        id="military-mortage__paint2_linear"
        x1={28}
        x2={22.4}
        y1={39.8}
        y2={34.1}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC42C" stopOpacity={0} />
        <stop offset={1} stopColor="#E7AC16" />
      </linearGradient>
      <linearGradient
        id="military-mortage__paint3_linear"
        x1={28}
        x2={28}
        y1={9}
        y2={17.3}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.2} />
        <stop offset={0.2} stopColor="#fff" stopOpacity={0.6} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

MilitaryMortgageIcon.displayName = 'MaternalCapitalIcon';
