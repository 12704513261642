import * as React from 'react';
import { IconSpecialLoanRate24, LinkButton } from '@cian/ui-kit';

import * as styles from './BankItem.css';
import { AuthorLabel } from '../AuthorLabel';

export interface IBankItemProps {
  label?: string | null;
  logoUrl?: string | null;
  mortgagePartnerLink?: string | null;
  name: string;
  onMortgageClick?(): void;
}

export const BankItem: React.FC<IBankItemProps> = ({ label, logoUrl, mortgagePartnerLink, name, onMortgageClick }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['info']}>
        <AuthorLabel name={name} label={label} logoIcon={<IconSpecialLoanRate24 />} logoUrl={logoUrl} />
      </div>

      {mortgagePartnerLink && (
        <div className={styles['link']}>
          <LinkButton href={mortgagePartnerLink} onClick={onMortgageClick} theme="fill_secondary" size="M" fullWidth>
            Получить ипотеку онлайн
          </LinkButton>
        </div>
      )}
    </div>
  );
};
