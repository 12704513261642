import { ca } from '@cian/analytics';

export const trackInteriorDecorationShowing = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Finishing',
    action: 'Show_sopr',
    useLastProducts: true,
  });
};
