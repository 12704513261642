import * as React from 'react';
import { useSelector } from 'react-redux';

import { ReliabilityHouses } from '../../components/Reliability/ReliabilityHouses';
import { getReliableHousesV2 } from '../../selectors/newbuilding';
import { trackGoToReliableHouseListing } from '../../analytics/trackReliableJK';

export function ReliabilityHousesContainer() {
  const reliableHouses = useSelector(getReliableHousesV2);

  if (reliableHouses.length === 0) {
    return null;
  }

  return <ReliabilityHouses houses={reliableHouses} onClick={trackGoToReliableHouseListing} />;
}
