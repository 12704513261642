import gazprom from './assets/gazprom.svg';
import otkritie from './assets/otkritie.svg';
import raiffeisen from './assets/raiffeisen.svg';
import rosbank from './assets/rosbank.svg';

export const PARTNER_BANKS_COUNT = '+4';

export const PARTNER_BANKS_ICONS = [
  { key: 'gazprom', source: gazprom },
  { key: 'otkritie', source: otkritie },
  { key: 'rosbank', source: rosbank },
  { key: 'raiffeisen', source: raiffeisen },
];
