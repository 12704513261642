import * as React from 'react';
import { Button, ArticleHeading3, ModalWindow, ArticleParagraph2 } from '@cian/ui-kit';

import * as cryingFaceImg from './assets/crying-face.png';
import * as styles from './UpcomingSaleFailModal.css';

interface IUpcomingSaleFailModalProps {
  open: boolean;
  onClose(): void;
}

export function UpcomingSaleFailModal({ open, onClose }: IUpcomingSaleFailModalProps) {
  return (
    <ModalWindow open={open} onClose={onClose}>
      <div className={styles['container']} data-testid="UpcomingSaleFailModal">
        <div className={styles['title']}>
          <ArticleHeading3 as="h3">
            Ой!
            <br />
            Оставить заявку не удалось
          </ArticleHeading3>
          <img className={styles['image']} src={cryingFaceImg.default} alt="" />
        </div>
        <div className={styles['content']}>
          <ArticleParagraph2>Попробуйте сделать это позже</ArticleParagraph2>
        </div>
        <div>
          <Button onClick={onClose} size="XS" theme="fill_primary" type="button">
            Понятно
          </Button>
        </div>
      </div>
    </ModalWindow>
  );
}
