import * as React from 'react';
import { Button, UIHeading3 } from '@cian/ui-kit';

import imgLeftSideCenter from './assets/img-left-side--center.png';
import imgLeftSideLeft from './assets/img-left-side--left.png';
import imgLeftSideRight from './assets/img-left-side--right.png';
import imgRightSideCenter from './assets/img-right-side--center.png';
import imgRightSideLeft from './assets/img-right-side--left.png';
import imgRightSideRight from './assets/img-right-side--right.png';
import * as styles from './BrokerBanner.css';

interface IBrokerBannerProps {
  onClick(): void;
}

export const BrokerBanner = ({ onClick }: IBrokerBannerProps) => {
  return (
    <div className={styles['banner']} data-testid="BrokerBanner">
      <img className={styles['img-left-side--left']} src={imgLeftSideLeft} />
      <img className={styles['img-left-side--center']} src={imgLeftSideCenter} />
      <img className={styles['img-left-side--right']} src={imgLeftSideRight} />
      <img className={styles['img-right-side--left']} src={imgRightSideLeft} />
      <img className={styles['img-right-side--center']} src={imgRightSideCenter} />
      <img className={styles['img-right-side--right']} src={imgRightSideRight} />
      <div className={styles['content']}>
        <div className={styles['header']}>
          <UIHeading3 color="white_100">Продайте квартиру от застройщика и получите комиссию за сделку</UIHeading3>
        </div>
        <div className={styles['button']} data-testid="BrokerBannerButton">
          <Button theme="fill_white_primary" onClick={onClick}>
            Хочу продать
          </Button>
        </div>
      </div>
    </div>
  );
};
