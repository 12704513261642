import * as React from 'react';

export const PinIcon = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg width="48" height="48" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27 0C16.507 0 8 8.507 8 19C8 30.6644 19.5164 38.5163 27 46C34.4076 38.5197 46 30.622 46 19C46 8.507 37.493 0 27 0Z"
      fill="#0468FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27 33C34.7317 33 41 26.7317 41 19C41 11.2683 34.7317 5 27 5C19.2683 5 13 11.2683 13 19C13 26.7317 19.2683 33 27 33Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.4665 19.5635V12.2368H30.2323V15.678L27.0001 11.7949L18 22.6073L20.9939 25.0992L27.0001 17.8836L33.0061 25.0992L36 22.6073L33.4665 19.5635Z"
      fill="#0468FF"
    />
    <circle cx="27" cy="50" r="3" fill="white" />
    <circle cx="27" cy="50" r="2" fill="#0468FF" />
  </svg>
);

PinIcon.displayName = 'PinIcon';
