import * as React from 'react';
import { pluralize } from '@cian/newbuilding-utils';
import { IconHouse16, UIText2 } from '@cian/ui-kit';

import * as styles from './styles.css';
import { createHouseLink } from './utils';
import { IHouse } from '../../types/newbuilding/house';

interface IEscrowHousesProps {
  houses: IHouse[];
  newbuildingId: number;
  onClick(): void;
}

export const EscrowHouses = ({ houses, newbuildingId, onClick }: IEscrowHousesProps) => {
  return (
    <div>
      {houses.map(house => {
        return (
          <div className={styles['row']} key={house.houseId}>
            <div className={styles['img']}>
              <IconHouse16 />
            </div>
            <div className={styles['name']}>{house.houseName}</div>
            <div className={styles['status']}>
              <UIText2 color="gray60_100">{house.finishStatus}</UIText2>
            </div>
            <div className={styles['offers']}>
              {house.fromDeveloperPropsCount ? (
                <a
                  className={styles['link']}
                  href={createHouseLink({ houseId: house.houseId, newbuildingId })}
                  onClick={onClick}
                >
                  {pluralize(house.fromDeveloperPropsCount, ['предложение', 'предложения', 'предложений'])}
                </a>
              ) : (
                <UIText2>нет предложений</UIText2>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
