import { IApplicationState } from '../../types/redux';

export function getOffers(state: IApplicationState) {
  return state.offers.data.total || {};
}

export function getSelectedHouseOffers(state: IApplicationState) {
  const houseId = state.offers.selectedHouseId || 'total';

  return state.offers.data[houseId];
}
