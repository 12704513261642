import * as React from 'react';
import { Image, InlineText2, LinkButton } from '@cian/ui-kit';
import * as styles from './ProblemItem.css';
import { pluralize } from '@cian/newbuilding-utils';
import { Link } from '../Link';
import { INewbuildingProblem } from '../../types/newbuilding/problem';

export interface IProblemItemProps {
  problem: INewbuildingProblem;
}

export function ProblemItem({
  problem: { reasonDescription, houseNames, source, sourceUrl, sourceLogoUrl },
}: IProblemItemProps) {
  if (!reasonDescription) {
    return null;
  }

  return (
    <div className={styles['problem']}>
      <InlineText2 color="white_100">
        {`${reasonDescription}
          ${
            houseNames && houseNames.length
              ? `по ${pluralize(houseNames.length, ['корпусу', 'корпусам', 'корпусам'], true)}:`
              : ''
          }`}
      </InlineText2>
      {houseNames && houseNames.length && (
        <div className={styles['houses']}>
          {houseNames.map(name => {
            return (
              <div className={styles['house']} key={`house-${name}`}>
                <LinkButton theme="fill_white_secondary" size="XS">
                  {name}
                </LinkButton>
              </div>
            );
          })}
        </div>
      )}
      {source && sourceUrl && (
        <div className={styles['problemLink']}>
          <Link url={sourceUrl} target="blank">
            <div className={styles['problemLinkText']}>
              <InlineText2 color="white_100">Источник: </InlineText2>
              {sourceLogoUrl && (
                <div className={styles['problemLogo']}>
                  <Image
                    src={sourceLogoUrl}
                    display={'block'}
                    width={24}
                    height={24}
                    objectFit={'contain'}
                    alt="Логотип источника"
                  />
                </div>
              )}
              <div className={styles['source']}>
                <InlineText2 color="white_100">{source}</InlineText2>
              </div>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
}
