import * as React from 'react';
import { ArticleParagraph2 } from '@cian/ui-kit';

import * as styles from './WorkTimeInfo.css';

interface IWorkTimeInfoProps {
  info: string;
}

export const WorkTimeInfo = ({ info }: IWorkTimeInfoProps) => {
  return (
    <div className={styles['workTime']}>
      <ArticleParagraph2 color={'black_60'} as={'span'}>
        {info}
      </ArticleParagraph2>
    </div>
  );
};
