import * as React from 'react';

import { TVerticalSliderProps, VerticalSlider } from './VerticalSlider';

import * as styles from './styles.css';

type TVerticalSliderFullscreenProps = Omit<TVerticalSliderProps, 'arrowOffset' | 'containerClass'>;

export function VerticalSliderFullscreen(props: TVerticalSliderFullscreenProps) {
  return <VerticalSlider {...props} containerClass={styles['container--fullscreen']} arrowOffset={-20} />;
}
