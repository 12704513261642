import * as React from 'react';
import { UIHeading4, UIText1 } from '@cian/ui-kit';

import { DETAILED_SPECIFICATIONS_ICON_MAP } from '../../../../constants/detailedSpecifications';

import * as styles from './styles.css';
import {
  ISecurityItem,
  IParkingItem,
  IShortInfrastructureItem,
} from '../../../../types/newbuilding/specificationsDetailed';

interface IDetailedSpecificationsProps {
  title?: string;
  items: ISecurityItem[] | IParkingItem[] | IShortInfrastructureItem[];
}

export function DetailedSpecifications({ title, items }: IDetailedSpecificationsProps) {
  return (
    <div className={styles['container']}>
      {title && (
        <div className={styles['details-title']}>
          <UIHeading4 as="h4">{title}</UIHeading4>
        </div>
      )}
      <div className={styles['items-container']}>
        {items.map(({ type, title }) => (
          <div key={type} className={styles['item']}>
            {DETAILED_SPECIFICATIONS_ICON_MAP[type]}
            <div className={styles['title']}>
              <UIText1>{title}</UIText1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
