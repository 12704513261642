import * as React from 'react';
import * as style from './Address.css';
import { Underground } from './parts/Underground';
import { Distance } from './parts/Distance';
import { InlineText1 } from '@cian/ui-kit';
import { IGeoRoadInfo, IGeoUndergroundInfo } from '../../../../types/newbuilding/geo';

export interface IAddressProps {
  /** ID региона **/
  regionId: number | null;
  /** Полный адрес ЖК **/
  fullAddress: string;
  /** Метро **/
  undergroundInfo: IGeoUndergroundInfo[] | null;
  /** Шоссе **/
  roadInfo: IGeoRoadInfo[] | null;
}

export const Address: React.FC<IAddressProps> = props => {
  const { regionId, fullAddress, undergroundInfo, roadInfo } = props;

  return (
    <div className={style['address']}>
      <div className={style['address-fulladdress']}>
        <InlineText1>{fullAddress}</InlineText1>
      </div>
      <div className={style['address-underground']}>
        <Underground regionId={regionId} underground={undergroundInfo} />
        <Distance roads={roadInfo} />
      </div>
    </div>
  );
};
