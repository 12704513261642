import { IApplicationState } from '../../types/redux';

export interface IAuthenticatedUserAnalytics {
  cianUserId: number;
  email: string;
  type: string;
  userId: number;
}

export interface IUnauthenticatedUserAnalytics {
  type: string;
}

export function getUser(state: IApplicationState): IAuthenticatedUserAnalytics | IUnauthenticatedUserAnalytics {
  const { user } = state;

  if (!user || !user.isAuthenticated || !user.id || !user.userId) {
    return {
      type: 'not_authorized',
    };
  }

  return {
    cianUserId: user.id,
    email: user.email ? user.email : '',
    type: user.userType ? user.userType : '',
    userId: user.userId,
  };
}
