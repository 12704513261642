import * as React from 'react';
import { ArticleParagraph2 } from '@cian/ui-kit';

import { SalesStartTooltip } from '../SalesStartTooltip';
import * as styles from './SalesStartInfo.css';

interface ISalesStartInfo {
  content: string;
}

export const SalesStartInfo = ({ content }: ISalesStartInfo) => {
  return (
    <ArticleParagraph2>
      {content}
      <span className={styles['tooltip']}>
        <SalesStartTooltip />
      </span>
    </ArticleParagraph2>
  );
};
