import * as React from 'react';
import * as style from './Sellers.css';

import { SectionTitle } from '../../../SectionTitle';
import { Seller } from '../Seller';
import { INewbuildingSeller } from '../../../../types/newbuilding/seller';
import { INewbuildingBuilder } from '../../../../types/newbuilding/builder';

export interface ISellersProps {
  newbuildingId: number;
  sellers?: INewbuildingSeller[] | null;
  trackActionOpenContactsCall(builder: INewbuildingBuilder | INewbuildingSeller): void;
  isPlural?: boolean | null;
}

export const Sellers: React.FC<ISellersProps> = props => {
  const { sellers, trackActionOpenContactsCall, isPlural, newbuildingId } = props;

  if (!sellers || sellers.length < 1) {
    return null;
  }

  const title = `Представители ${isPlural ? 'застройщиков' : 'застройщика'}`;

  return (
    <div className={style['sellers']} data-mark={'Sellers'}>
      <SectionTitle>{title}</SectionTitle>

      <div className={style['sellers-list']}>
        {sellers.map(seller => (
          <Seller
            newbuildingId={newbuildingId}
            key={seller.realtyUserId}
            eventTracker={trackActionOpenContactsCall}
            seller={seller}
          />
        ))}
      </div>
    </div>
  );
};
