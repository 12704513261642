import { CustomTooltip } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getPriceRelevance } from '../../selectors/newbuilding/priceRelevance';
import { trackHover } from './tracking';
import { PriceRelevanceWarningInfo, TooltipContent } from '../../components/PriceRelevance';
import { useApplicationContext } from '../../utils/applicationContext';

export const WarningTooltip = () => {
  const { config } = useApplicationContext();
  const anchorRef = React.useRef(null);
  const tooltip = useSelector(getPriceRelevance);
  const [open, setOpen] = React.useState(false);
  const [crossEnabled, setCrossEnabled] = React.useState(true);
  const isAutoOpenEnabled = config.get<boolean>('priceRelevanceWarningAutoopen.Enabled') || false;
  const autoOpenTimeout = config.get<number>('priceRelevanceWarningAutoopen.Timeout') || 5000;

  const onMouseEnter = React.useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onMouseLeave = React.useCallback(() => {
    setOpen(false);
    setCrossEnabled(false);
  }, [setOpen]);

  const onClose = React.useCallback(() => {
    setOpen(false);
    setCrossEnabled(false);
  }, [setOpen, setCrossEnabled]);

  const openTooltipEffect = React.useCallback(() => {
    if (isAutoOpenEnabled) {
      setOpen(true);
    }
  }, [isAutoOpenEnabled]);

  React.useEffect(() => {
    const id = setTimeout(openTooltipEffect, autoOpenTimeout);

    return () => clearTimeout(id);
  }, [isAutoOpenEnabled]);

  if (!tooltip) {
    return null;
  }

  return (
    <>
      <CustomTooltip
        placement="bottom"
        anchorRef={anchorRef}
        open={open}
        closeButton={isAutoOpenEnabled && crossEnabled}
        onClose={onClose}
        theme="white"
        header={tooltip.title}
        content={<TooltipContent description={tooltip.description} onVisible={trackHover} />}
      />
      <PriceRelevanceWarningInfo onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={anchorRef} />
    </>
  );
};
