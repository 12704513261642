import * as React from 'react';
import { Switch } from '@cian/ui-kit';
import * as styles from './styles.css';

interface IOffersToLayoutsSwitcherProps {
  defaultChecked: boolean;
  onChange(_: React.ChangeEvent<HTMLInputElement>, value: boolean): void;
}
export const OffersToLayoutsSwitcher = ({ onChange, defaultChecked }: IOffersToLayoutsSwitcherProps) => {
  return (
    <div data-testid="offers-to-layouts-switcher" className={styles['container']}>
      <label htmlFor="offers-to-layouts-switcher" className={styles['label']}>
        Показать планировки
      </label>
      <Switch id="offers-to-layouts-switcher" defaultChecked={defaultChecked} onChange={onChange} />
    </div>
  );
};
