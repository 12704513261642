import * as React from 'react';
import { LayoutsLink } from '../../components/LayoutsLink';
import { useApplicationContext } from '../../utils/applicationContext';
import { trackClickLayoutsSeoLink } from './tracking';

export const LayoutsLinkContainer = () => {
  const { config } = useApplicationContext();
  const isEnabled = config.get('Newbuilding.LayoutsSeoPage.Enabled');

  if (!isEnabled) {
    return null;
  }

  return <LayoutsLink url="planirovki/" onClick={trackClickLayoutsSeoLink} />;
};
