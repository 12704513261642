import { setReviewReactionAction } from '../../../shared/actions/reviews';
import {
  ISetReviewReactionWebResponse200,
  ISetReviewReactionWebResponse400,
  fetchSetReviewReactionWeb,
} from '../../../shared/repositories/newbuilding-reviews/v1/set-review-reaction-web';
import { TThunkAction } from '../../../shared/types/redux/thunk';
import { getLabelFromState, trackNewbuildingReviewsAnalytic } from '../../../shared/utils/analytics/reviewsAnalytics';
import { getAnalyticsUser } from '../../../shared/selectors/analytics';
import {
  getBuilders,
  getNewbuildingId,
  getNewbuildingReviewsStat,
  getReviewById,
} from '../../../shared/selectors/newbuilding';
import { getProductsData } from '../../../shared/selectors/analytics/builder';
import { ISetReviewReactionWebResponseSchema } from '../../../shared/repositories/newbuilding-reviews/entities/set_review_reaction/SetReviewReactionWebResponseSchema';
import { TLikeStatus } from '../../../shared/types/newbuilding/reviews';

interface IParams {
  reviewId: string;
  type: TLikeStatus;
}

export const setReviewReaction = ({ reviewId, type }: IParams): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { logger, httpApi } = context;

    try {
      const { response } = await fetchSetReviewReactionWeb<
        ISetReviewReactionWebResponse200,
        ISetReviewReactionWebResponse400
      >({
        httpApi,
        parameters: { reviewId, type },
      });

      dispatch(
        setReviewReactionAction({
          reviewId,
          type: (response as ISetReviewReactionWebResponseSchema).type,
        }),
      );

      const state = getState();

      const user = getAnalyticsUser(state);

      const builder = getBuilders(state)[0] || null;
      const id = getNewbuildingId(state);
      const products = [getProductsData(id, builder)];

      const review = getReviewById(reviewId)(state) || { like: { status: 'unknown' } };
      const label = getLabelFromState({ state: review.like.status, payload: type });

      const reviewsStat = getNewbuildingReviewsStat(state);

      const page = {
        pageType: 'CardJK',
        siteType: 'desktop',
        extra: {
          type: 'review',
          review_id: reviewId,
          rating: reviewsStat?.rating,
        },
      };

      trackNewbuildingReviewsAnalytic({
        action: 'Click_sopr',
        category: 'NewbuildingReviews',
        label,
        user,
        page,
        products,
      });
    } catch (error) {
      logger.error(error, {
        domain: 'setReviewReaction',
        message: error.message,
      });
    }
  };
};
