import {
  IGetUserDataByRequestResponse200,
  TGetUserDataByRequestResponse,
} from '../../../../shared/repositories/monolith-python/v1/get-user-data-by-request';
import { ResponseError } from '../../../utils/errors';

export function assertUnknownError(
  response: TGetUserDataByRequestResponse,
): asserts response is IGetUserDataByRequestResponse200 {
  if (response.statusCode === 200) {
    return;
  }

  const { message, errors } = response.response;

  throw new ResponseError({
    domain: 'node/services/user/assert#assertUnknownError()',
    message,
    details: { errors },
  });
}
