import { ca } from '@cian/analytics';

export function trackShowPhoneInBannerClick(gaLabel: string) {
  ca('eventSite', {
    action: 'Open_promotions_block',
    category: 'Phones',
    label: gaLabel,
    name: 'oldevent',
    useLastProducts: true,
  });
}
