import * as React from 'react';
import { TDirection } from '@cian/frontend-newbuilding-carousel-component';
import { IconChevronLeft16, IconChevronRight16 } from '@cian/ui-kit';
import classNames from 'clsx';
import * as styles from './Arrow.css';

interface IArrowProps {
  direction: TDirection;
  show: boolean;
  clickHandler?(direction: TDirection): void;
}

export const Arrow: React.FC<IArrowProps> = props => {
  const { direction, show, clickHandler } = props;

  return (
    <div
      className={classNames(
        styles['click-area'],
        !show && styles['click-area--hidden'],
        direction < 0 && styles['click-area--left'],
      )}
      onClick={() => {
        return clickHandler && clickHandler(direction);
      }}
      data-testid="Arrow"
    >
      <div className={styles['background']}>
        <div className={styles['icon-container']}>
          {direction < 0 ? <IconChevronLeft16 color={'white_100'} /> : <IconChevronRight16 color={'white_100'} />}
        </div>
      </div>
    </div>
  );
};
