import * as React from 'react';
import { useSelector } from 'react-redux';
import { getNearbyNewbuildingsMapUrl } from '../../selectors/newbuilding';
import { NearbyNewbuildingsLink } from '../../components/NearbyNewbuildingsLink';
import { trackOffersNearbyClick } from './tracking';

export function NearbyNewbuildingsLinkContainer() {
  const nearbyNewbuildingsMapUrl = useSelector(getNearbyNewbuildingsMapUrl);

  if (!nearbyNewbuildingsMapUrl) {
    return null;
  }

  return <NearbyNewbuildingsLink url={nearbyNewbuildingsMapUrl} onClick={trackOffersNearbyClick} />;
}
