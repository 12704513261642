import {
  fetchGetHouseStatsDesktop,
  IGetHouseStatsDesktopResponse200,
  IGetHouseStatsDesktopResponse400,
} from '../../repositories/newbuilding-card/v1/get-house-stats-desktop';
import { TThunkAction } from '../../types/redux/thunk';
import { selectHouseId, setHouseStats } from './actions';

export function fetchStats(houseId: number | undefined): TThunkAction {
  return async (dispatch, getState, { httpApi, logger }): Promise<void> => {
    try {
      const state = getState();

      if (houseId && state.offers && !state.offers.data[houseId]) {
        const parameters = {
          houseId,
          newbuildingId: state.newbuilding.id,
        };
        const response = await fetchGetHouseStatsDesktop<
          IGetHouseStatsDesktopResponse200,
          IGetHouseStatsDesktopResponse400
        >({
          httpApi,
          parameters,
        });

        if (response.statusCode === 400) {
          throw response.response.message;
        }

        const payload = {
          houseId,
          stats: response.response.stats || null,
        };

        dispatch(setHouseStats(payload));
      }

      dispatch(selectHouseId(houseId));
    } catch (error) {
      logger.error(error, {
        houseId,
        domain: 'fetchStats',
      });
    }
  };
}
