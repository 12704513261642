/* eslint-disable react/no-danger */
import * as React from 'react';

import { Accordion, IAccordionItemProps } from '@cian/ui-kit';
import * as styles from './SeoMarkedList.css';
import { IMarkedList } from '../../types/newbuilding/markedList';
import { LayoutBlock } from '../LayoutBlock';

interface ISeoMarkedListProps {
  items: IMarkedList[];
}

export const SeoMarkedList: React.VFC<ISeoMarkedListProps> = ({ items }) => (
  <LayoutBlock>
    <Accordion
      items={items.map(
        (item, index) =>
          ({
            id: index.toString(),
            title: <div className={styles['title']} dangerouslySetInnerHTML={{ __html: item.title }} />,
            content: <div className={styles['content']} dangerouslySetInnerHTML={{ __html: item.content }} />,
            defaultOpen: item.expanded,
          }) as unknown as IAccordionItemProps,
      )}
    />
  </LayoutBlock>
);
