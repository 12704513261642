import { mergeStyles } from '@cian/utils';
import * as React from 'react';
import { EPlayerIconType, PlayerIcon } from './Icons';
import * as styles from './VideoPreview.css';

export interface IVideoPreviewProps {
  url: string;
  iconType: EPlayerIconType;
  fit?: 'contain';
  onClick?(event: React.MouseEvent<HTMLDivElement>): void;
}

export function VideoPreview({ url, iconType, fit, onClick }: IVideoPreviewProps) {
  return (
    <div
      {...mergeStyles(styles['container'], fit === 'contain' && styles['container--contain'], {
        backgroundImage: `url(${url})`,
      })}
      onClick={onClick}
      data-testid="VideoPreview"
    >
      <PlayerIcon type={iconType} />
    </div>
  );
}
