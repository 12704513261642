import * as React from 'react';

/* istanbul ignore next */
export const ActionIcon = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={56} height={56} viewBox="0 0 56 56">
    <path
      fill="url(#action__paint0_linear)"
      d="M28.1 11.9a16.2 16.2 0 00-11.7 27.5l-.7.6A17.2 17.2 0 0128 11c5.3 0 10 2.4 13.2 6.1l-.8.7C37.4 14.2 33 12 28 12z"
    />
    <path
      fill="url(#action__paint1_linear)"
      d="M28.1 44.3a16.2 16.2 0 0012.4-26.6l.8-.7a17.2 17.2 0 01-25.6 23l.7-.6c3 3 7.1 5 11.7 5z"
    />
    <path
      stroke="#3AC400"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M28.1 46.2a18.1 18.1 0 100-36.2 18.1 18.1 0 000 36.2z"
    />
    <path
      fill="url(#action__paint2_linear)"
      d="M28 10a18.1 18.1 0 00-11.7 31.8l-.7.7a19 19 0 1127.6-26l-.8.5A18 18 0 0028 10z"
    />
    <path fill="#66E530" d="M19.2 26.2l-6 5.4.9-.2 5.6-5.1-.5-.1z" />
    <path fill="#45CA0D" d="M19.2 26.2h.5l-2.8-7-.7-.5 3 7.5z" />
    <path fill="#E1FFD4" d="M24.1 20l-8-1.3.8.5 7.4 1.2-.2-.4z" />
    <path fill="#97F56F" d="M28.1 12.9l-4 7.1.3.4 3.7-6.7V13z" />
    <path fill="#3EC605" d="M28.1 13.7V13l4 7-.2.5-3.8-6.7zm11.2 5.5L32 20.4l.2-.4 8-1.3-.8.5z" />
    <path fill="#4ACD13" d="M39.3 19.2l.7-.5-3 7.5h-.5l2.8-7z" />
    <path fill="#60D92D" d="M42.2 31.4l-5.7-5.1.5-.1 6 5.4-.8-.2z" />
    <path fill="#6EE13E" d="M35.3 33.9l7.7-2.3-.8-.2-7.3 2.1.4.4z" />
    <path fill="#7CE84F" d="M34.3 41.2l.6-7.7.4.4-.6 8-.4-.7z" />
    <path fill="#5FD82C" d="M28.1 37.4l6.6 4.6-.4-.8-6.2-4.4v.6z" />
    <path fill="#3CC502" d="M21.9 41.2l6.2-4.4v.6L21.5 42l.4-.8z" />
    <path fill="#3BC501" d="M21 34l.5 8 .4-.8-.6-7.7-.4.4z" />
    <path fill="#3BC501" d="M14 31.4l7.3 2.1-.4.4-7.7-2.3.9-.2z" />
    <path
      fill="#3AC500"
      d="M28.1 13.7l3.8 6.7 7.4-1.2-2.8 7 5.7 5.2-7.3 2.1-.6 7.7-6.2-4.4-6.2 4.4-.6-7.7-7.3-2.1 5.7-5.1-2.8-7.1 7.4 1.2 3.8-6.7z"
    />
    <path
      fill="url(#action__paint3_linear)"
      d="M28.1 13.7l3.8 6.7 7.4-1.2-2.8 7 5.7 5.2-7.3 2.1-.6 7.7-6.2-4.4-6.2 4.4-.6-7.7-7.3-2.1 5.7-5.1-2.8-7.1 7.4 1.2 3.8-6.7z"
      style={{
        mixBlendMode: 'lighten',
      }}
    />
    <defs>
      <linearGradient id="action__paint0_linear" x1={41.3} x2={16.1} y1={17} y2={40} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3AC400" />
        <stop offset={0.5} stopColor="#61E42A" />
        <stop offset={1} stopColor="#3AC400" />
      </linearGradient>
      <linearGradient id="action__paint1_linear" x1={39.7} x2={16.2} y1={16.3} y2={40} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3AC400" />
        <stop offset={0.6} stopColor="#7DF14C" />
        <stop offset={1} stopColor="#3AC400" />
      </linearGradient>
      <linearGradient id="action__paint2_linear" x1={43.2} x2={14.4} y1={16.3} y2={40.2} gradientUnits="userSpaceOnUse">
        <stop stopColor="#3AC400" />
        <stop offset={0.5} stopColor="#fff" />
        <stop offset={1} stopColor="#3AC400" />
      </linearGradient>
      <linearGradient id="action__paint3_linear" x1={16.1} x2={40.6} y1={18} y2={37.5} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#77F143" />
        <stop offset={0.5} stopColor="#50D717" />
        <stop offset={0.8} stopColor="#3AC400" />
      </linearGradient>
    </defs>
  </svg>
);

ActionIcon.displayName = 'ActionIcon';
