import * as React from 'react';
import { DesktopRating } from '@cian/frontend-reviews-components';
import { ArticleHeading2, LinkButton } from '@cian/ui-kit';
import * as style from './ManyReviewsTeaser.css';

export interface IReviewsTitleProps {
  fullUrl: string;
  rating: number;
  title: string;
}

export const ReviewsTitle: React.FC<IReviewsTitleProps> = ({ fullUrl, rating, title }) => {
  return (
    <div className={style['container']}>
      <div className={style['title']}>
        <ArticleHeading2>{title}</ArticleHeading2>
        <LinkButton size="XS" theme="stroke_primary" href={`${fullUrl}otzyvy/`} target="blank">
          Показать все
        </LinkButton>
      </div>
      <DesktopRating value={rating} />
    </div>
  );
};
