import { NewbuildingCarousel } from '@cian/frontend-newbuilding-carousel-component';
import { NoSsr } from '@cian/react-utils';
import { Image, RoundButton } from '@cian/ui-kit';
import { IconChevronLeft12, IconChevronRight12 } from '@cian/ui-kit/icons';
import * as React from 'react';

import { IMedia } from '../../../../types/recommendations';
import { PHOTO_CAROUSEL_FIRST_PAINT_OPTIONS, PHOTO_CAROUSEL_MEDIA_CONFIG } from './config';
import * as styles from './PhotoCarousel.css';

export interface IPhotoCarouselProps {
  media: IMedia[];
}

export const PhotoCarousel: React.FC<IPhotoCarouselProps> = ({ media }) => {
  const [currentSlide, setCurrentSlide] = React.useState(1);

  const slides = React.useMemo(
    () =>
      media.map(image => (
        <NoSsr key={image.url}>
          <Image src={image.url} borderRadius="0" height="100%" width="100%" objectFit="cover" />
        </NoSsr>
      )),
    [media],
  );

  const slidesCount = slides.length;
  const isControlsVisible = slidesCount > 1;

  const setPrevSlide = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();

      setCurrentSlide(prev => (prev <= 1 ? slidesCount : prev - 1));
    },
    [slidesCount],
  );

  const setNextSlide = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();

      setCurrentSlide(prev => (prev >= slidesCount ? 1 : prev + 1));
    },
    [slidesCount],
  );

  return (
    <div className={styles['container']}>
      <NewbuildingCarousel
        slides={slides}
        media={PHOTO_CAROUSEL_MEDIA_CONFIG}
        firstPaintOptions={PHOTO_CAROUSEL_FIRST_PAINT_OPTIONS}
        defaultCurrentSlide={currentSlide}
      />
      {isControlsVisible && (
        <>
          <div className={styles['arrow-left']}>
            <RoundButton
              onClick={setPrevSlide}
              theme="fill_light"
              size="XS"
              icon={<IconChevronLeft12 />}
              title="Предыдущее изображение"
            />
          </div>
          <div className={styles['arrow-right']}>
            <RoundButton
              onClick={setNextSlide}
              theme="fill_light"
              size="XS"
              icon={<IconChevronRight12 />}
              title="Следующее изображение"
            />
          </div>
        </>
      )}
    </div>
  );
};
