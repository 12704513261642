import * as React from 'react';

import { Sellers } from './parts/Sellers';
import { Builders } from './parts/Builders';
import { LayoutBlock } from '../LayoutBlock';
import { Section } from '../Section';
import { INewbuildingSeller } from '../../types/newbuilding/seller';
import { INewbuildingBuilder } from '../../types/newbuilding/builder';

export interface IBuildersInfoProps {
  newbuildingId: number;
  builders?: INewbuildingBuilder[] | null;
  sellers?: INewbuildingSeller[] | null;
  newbuildingDisplayName?: string;
  isLeadFactory?: boolean;
  isDevCardRedesignEnabled: boolean;
  trackActionOpenContactsCall(builder: INewbuildingBuilder | INewbuildingSeller): void;
}

export const BuildersInfo: React.FC<IBuildersInfoProps> = props => {
  const {
    builders,
    sellers,
    trackActionOpenContactsCall,
    newbuildingDisplayName,
    newbuildingId,
    isLeadFactory,
    isDevCardRedesignEnabled,
  } = props;

  const isBuildersHere = builders && builders.length > 0;
  const isSellersHere = sellers && sellers.length > 0;

  if (!isBuildersHere && !isSellersHere) {
    return null;
  }

  return (
    <LayoutBlock>
      <Section id="developer" data-data-nav-tab="developer/Про застройщика" data-mark="BuildersInfo">
        <Builders
          builders={builders}
          newbuildingDisplayName={newbuildingDisplayName}
          isDevCardRedesignEnabled={isDevCardRedesignEnabled}
        />

        {!isLeadFactory && (
          <Sellers
            newbuildingId={newbuildingId}
            isPlural={builders && builders.length > 1}
            sellers={sellers}
            trackActionOpenContactsCall={trackActionOpenContactsCall}
          />
        )}
      </Section>
    </LayoutBlock>
  );
};
