import * as React from 'react';
import { IconChevronRight16, ArticleHeading4, ArticleParagraph1 } from '@cian/ui-kit';

import * as styles from './CompilationItem.css';

export interface ICompilationItemProps {
  description: string;
  icon: string;
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void;
  title: string;
  url: string;
}

export const CompilationItem: React.FC<ICompilationItemProps> = ({ description, icon, title, url, onClick }) => {
  return (
    <a
      className={styles['compilation']}
      href={url}
      title={title}
      onClick={onClick}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles['icon']} style={{ backgroundImage: `url('${icon}')` }} />
      <div className={styles['content']}>
        <div className={styles['title']}>
          <ArticleHeading4 as="h3">
            <span className={styles['no-wrap']}>{title}</span>
          </ArticleHeading4>
        </div>

        <ArticleParagraph1 color="gray40_100">
          <span className={styles['no-wrap']}>{description}</span>
        </ArticleParagraph1>
      </div>
      <div className={styles['arrow']}>
        <IconChevronRight16 color="gray40_100" />
      </div>
    </a>
  );
};
