import * as React from 'react';
import { InformationTooltip } from '@cian/ui-kit';
import { IHousesByTurnTitleProps } from '../FinishYearsTitle';
import { HousesByTurnTitle } from '../HousesByTurnTitle';
import { HousesByTurn } from '../HousesByTurn';

type THousesByTurnTooltipProps = Pick<IHousesByTurnTitleProps, 'housesByTurnDisplay'>;

export const HousesByTurnTooltip: React.FC<THousesByTurnTooltipProps> = props => {
  const { housesByTurnDisplay } = props;

  return (
    <InformationTooltip
      content={<HousesByTurn housesByTurnDisplay={housesByTurnDisplay} />}
      placement={'right'}
      theme={'white'}
    >
      <span data-testid="InformationTooltipContent">
        <HousesByTurnTitle />
      </span>
    </InformationTooltip>
  );
};
