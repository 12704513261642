import { IRefsSet } from '../Documentation';

export const getShutterHeight = (refs: IRefsSet, isTwoColumn: boolean) => {
  const len = refs.length;
  const numberOfElements = isTwoColumn ? 3 : 4;
  let height = 0;

  for (let i = 0; i < len && i < numberOfElements; i += 1) {
    if (isTwoColumn && i % 2) {
      continue;
    }

    const ref = refs[i];

    if (ref) {
      height += ref.offsetHeight;
    }
  }

  return height;
};
