import * as React from 'react';
import { IconSpecialLoanRate24 } from '@cian/ui-kit';

import { IBankSchema } from '../../../../types/promo';
import * as styles from './BankIcon.css';

interface IBankIconProps {
  bank?: IBankSchema | null;
}

export function BankIcon({ bank }: IBankIconProps) {
  const bankLogo = bank?.secondSquareImageUrl || bank?.logoUrl;
  if (bankLogo) {
    return <img className={styles['logo']} src={bankLogo} alt="" />;
  }

  return (
    <div className={styles['icon']}>
      <IconSpecialLoanRate24 />
    </div>
  );
}
