import * as React from 'react';
import { throttle } from '@cian/newbuilding-utils';

export const useContentHeight = (opened: boolean) => {
  const contentRef = React.useRef<HTMLDivElement>(null);

  const [animated, setAnimated] = React.useState(false);
  const [height, setHeight] = React.useState(0);

  const updateHeight = React.useCallback((expanded: boolean, animate: boolean) => {
    /* istanbul ignore if */
    if (!contentRef || !contentRef.current) {
      return;
    }

    const contentElement = contentRef.current;
    const firstContentChild = contentRef.current.firstElementChild as HTMLElement;

    setAnimated(animate);
    setHeight(expanded ? contentElement.scrollHeight : firstContentChild.offsetHeight);
  }, []);

  React.useEffect(() => {
    /* istanbul ignore if */
    if (!contentRef || !contentRef.current) {
      return;
    }

    const firstContentChild = contentRef.current.firstElementChild as HTMLElement;

    if (firstContentChild) {
      setHeight(firstContentChild.offsetHeight);
    }
  }, []);

  React.useEffect(() => {
    const throttledCalculateHeight = throttle(300, () => updateHeight(opened, false));

    window.addEventListener('resize', throttledCalculateHeight);

    /* istanbul ignore next */
    return () => window.removeEventListener('resize', throttledCalculateHeight);
  }, [opened]);

  return {
    contentRef,
    animated,
    height,
    updateHeight,
  };
};
