import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import {
  fetchGetNewbuildingValuationBlockWeb,
  IGetNewbuildingValuationBlockWebResponse200 as IRes200,
  IGetNewbuildingValuationBlockWebResponse400 as IRes400,
  IGetNewbuildingValuationBlockWebRequest,
  IGetNewbuildingValuationBlockWebResponse,
} from '../../repositories/newbuilding-estimate/v2/get-newbuilding-valuation-block-web';
import { IApplicationContext } from '../../types/applicationContext';

interface IParams extends IGetNewbuildingValuationBlockWebRequest {
  context: IApplicationContext;
}

export async function fetchRealtyValuation({
  context,
  newbuildingId,
  pricesFilters,
  salesFilters,
}: IParams): Promise<IGetNewbuildingValuationBlockWebResponse> {
  try {
    const res = await fetchGetNewbuildingValuationBlockWeb<IRes200, IRes400>({
      httpApi: context.httpApi,
      parameters: { newbuildingId, pricesFilters, salesFilters },
    });

    if (res.statusCode !== 200) {
      throw new HttpBadStatusCodeError({
        statusCode: res.statusCode,
        domain: 'fetchRealtyValuation.ts',
        message: res.response.message as string,
        details: { errors: res.response.errors },
      });
    }

    return res.response;
  } catch (e) {
    if (!(e instanceof HttpBadStatusCodeError)) {
      context.logger.error(e);
    } else {
      context.logger.debug(e.message, { details: e.printDetails() });
    }
  }

  return {};
}
