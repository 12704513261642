import { EPromoInfoActionTypes } from '../../actions/promoInfo';
import { IPromoInfoState } from '../../types/promo';
import { TReduxActions } from '../../types/redux';

const initialState: IPromoInfoState = {
  isOpen: false,
  promo: null,
};

export function promoInfoReducer(state: IPromoInfoState = initialState, action: TReduxActions): IPromoInfoState {
  switch (action.type) {
    case EPromoInfoActionTypes.OpenPromoInfo:
      return {
        ...state,
        isOpen: true,
        promo: action.payload.promo,
      };

    case EPromoInfoActionTypes.ClosePromoInfo:
      return {
        ...state,
        isOpen: false,
        promo: null,
      };

    default:
      return state;
  }
}
