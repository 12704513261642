import { IApplicationState } from '../../types/redux';
import { INewbuildingFromTheBuilder } from '../../types/newbuilding/newbuildingFromBuilder';

export function getNewbuldingFromTheBuilder({ newbuilding }: IApplicationState): INewbuildingFromTheBuilder[] {
  if (newbuilding && newbuilding.newbuildingsFromTheBuilder && newbuilding.newbuildingsFromTheBuilder.length) {
    return newbuilding.newbuildingsFromTheBuilder.slice(0, 4);
  }

  return [];
}
