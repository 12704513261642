import * as React from 'react';
import { InlineText1, InlineText2, TooltipDesktop } from '@cian/ui-kit';
import { Link } from '../Link';
import { DOM_RF_LINK } from '../../constants/externalLinks';
import { NBSP } from '../../constants/symbols';
import { ReliablePointIcon, ReliableFaqIcon } from './ReliabilityIcons';
import * as styles from './ReliabilityChecks.css';

const reliableChecks = [
  { text: 'Не выявлено фактов банкротства застройщика' },
  {
    text: 'Полный комплект документов на ЕИСЖС',
    tooltipText: (
      <>
        Застройщик обязан опубликовать все документы в открытый доступ на сайте ЕИСЖС{' '}
        <Link url={DOM_RF_LINK} target="blank">
          <InlineText2 color="black_100">наш.дом.рф</InlineText2>
        </Link>
      </>
    ),
  },
  { text: 'Компании нет в реестре проблемных застройщиков' },
  { text: 'Актуальная версия проектной декларации' },
  { text: 'Имеется действующее разрешение на строительство' },
  { text: 'Актуальные фотографии хода строительства' },
  { text: `Перенос плановых сроков строительства — не${NBSP}более 2${NBSP}раз на${NBSP}срок до${NBSP}2${NBSP}месяцев` },
];

export function ReliabilityChecks() {
  return (
    <div className={styles['list']}>
      {reliableChecks.map((item, idx) => (
        <div key={idx} className={styles['list-item']}>
          <div className={styles['list-icon']}>
            <ReliablePointIcon />
          </div>
          <InlineText1 color="white_100">{item.text}</InlineText1>
          {item.tooltipText && (
            <TooltipDesktop
              title={<div className={styles['tooltip']}>{item.tooltipText}</div>}
              placement="right"
              theme="white"
            >
              <span className={styles['faq-icon']}>
                <ReliableFaqIcon />
              </span>
            </TooltipDesktop>
          )}
        </div>
      ))}
    </div>
  );
}
