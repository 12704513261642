import { ca } from '@cian/analytics';
export function trackHousesWithEscrowLinkClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'Click',
    label: 'escrow_to_flats',
    useLastProducts: true,
  });
}

export const trackReliableBlockShow = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'show_sopr',
    label: `reliability_block`,
    useLastProducts: true,
  });
};
