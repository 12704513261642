import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { EPageTypes, EPlatformTypes } from '@cian/frontend-dynamic-calltracking-component';
import { SimpleCall } from '@cian/frontend-newbuilding-call-component';
import { useDispatch, useSelector } from 'react-redux';

import { trackShowPhone } from '../../actions/analytics';
import { BuilderCallReasons } from '../../components/BuilderCallReasons';
import { LayoutBlock } from '../../components/LayoutBlock';
import { getBuilders, getNewbuildingId, getSellers } from '../../selectors/newbuilding';
import { TThunkDispatch } from '../../types/redux/thunk';
import { useApplicationContext } from '../../utils/applicationContext';
import { setUserOpenedPhoneInNewobject } from '../../utils/businessLogic/lastHopePopup';
import { trackBuilderCallReasonsShowing } from './tracking';

const CALL_BUTTON_TEXT = 'Позвонить застройщику';

export const BuilderCallReasonsContainer: React.FC = () => {
  const { httpApi, logger } = useApplicationContext();

  const dispatch: TThunkDispatch = useDispatch();

  const builders = useSelector(getBuilders);
  const sellers = useSelector(getSellers);
  const newbuildingId = useSelector(getNewbuildingId);

  const handleShowing = React.useCallback(() => {
    trackBuilderCallReasonsShowing();
  }, []);

  const handleShowPhone = React.useCallback(() => {
    setUserOpenedPhoneInNewobject(newbuildingId);
    dispatch(trackShowPhone('Open_ask_builder'));
  }, [dispatch]);

  return (
    <LayoutBlock>
      <ActionAfterViewObserver callback={handleShowing} triggerOnce callbackDelay={1000}>
        <BuilderCallReasons
          contactsButton={
            <SimpleCall
              builders={builders}
              sellers={sellers}
              openContactsStyles={{ size: 'XS' as const }}
              showPhoneStyles={{ size: 'XS' as const }}
              httpApi={httpApi}
              logger={logger}
              onShowPhoneClick={handleShowPhone}
              pageType={EPageTypes.NewbuildingCard}
              platformType={EPlatformTypes.WebDesktop}
              placeType="blockBuilderCallReasons"
              singlePhoneButtonText={CALL_BUTTON_TEXT}
              multiplePhoneButtonText={CALL_BUTTON_TEXT}
              withoutModal={true}
            />
          }
        />
      </ActionAfterViewObserver>
    </LayoutBlock>
  );
};
