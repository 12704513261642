import { connect } from 'react-redux';
import { About, IAboutProps } from '../../components/About';
import { getDescription, getNewbuildingDisplayName } from '../../selectors/newbuilding';
import { IApplicationState } from '../../types/redux';
import { trackOpenInfoAboutJK } from '../../actions/analytics';
import { TThunkDispatch } from '../../types/redux/thunk';

type TAboutStateProps = Pick<IAboutProps, 'description' | 'displayName'>;

type TAboutDispatchProps = Pick<IAboutProps, 'trackActionOpenInfoAboutJK'>;

export function mapStateToProps(state: IApplicationState): TAboutStateProps {
  return {
    description: getDescription(state),
    displayName: getNewbuildingDisplayName(state),
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TAboutDispatchProps {
  return {
    trackActionOpenInfoAboutJK: () => {
      return dispatch(trackOpenInfoAboutJK());
    },
  };
}

export const AboutContainer = connect(mapStateToProps, mapDispatchToProps)(About);
