import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { TLikeStatus } from '../../types/newbuilding/reviews';

export enum EReviewsActionTypes {
  SetReviewReaction = 'reviews/setReviewReaction',
}

export type TSetReviewReactionActionPayload = {
  reviewId: string;
  type: TLikeStatus;
};

export type TSetReviewReaction = ITypedReduxAction<
  EReviewsActionTypes.SetReviewReaction,
  TSetReviewReactionActionPayload
>;

/**
 * Redux action, который записывает в store новый лайк/дизлайк пользователя
 */
export const setReviewReactionAction: (payload: TSetReviewReactionActionPayload) => TSetReviewReaction =
  actionGenerator<EReviewsActionTypes.SetReviewReaction, TSetReviewReactionActionPayload>(
    EReviewsActionTypes.SetReviewReaction,
  );
