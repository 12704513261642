import * as React from 'react';
import * as style from './ReviewsReactions.css';

export interface IReviewsReactionsProps {
  like: React.ReactElement;
  dislike: React.ReactElement;
}

export const ReviewsReactions: React.FC<IReviewsReactionsProps> = ({ like, dislike }) => {
  return (
    <div className={style['container']}>
      <div className={style['reaction']}>{like}</div>

      <div className={style['reaction']}>{dislike}</div>
    </div>
  );
};
