import { ESizeType, TFirstPaintOptions, IMedia } from '@cian/frontend-newbuilding-carousel-component';

export const firstPaintOptions: TFirstPaintOptions = {
  draggable: true,
  elementsWidth: {
    leftEar: {
      width: 0,
      type: ESizeType.PIXEL,
    },
    rightEar: {
      width: 0,
      type: ESizeType.PIXEL,
    },
    content: {
      width: 100,
      type: ESizeType.PERCENT,
    },
    gap: {
      width: 0,
      type: ESizeType.PIXEL,
    },
  },
  height: {
    value: 100,
    type: ESizeType.PERCENT,
  },
  slidesToShow: 1,
  defaultControls: [],
};

export const mediaSettings: IMedia[] = [
  {
    minWidth: 1,
    maxWidth: Infinity,
    settings: {
      draggable: true,
      elementsWidth: {
        leftEar: {
          width: 0,
          type: ESizeType.PIXEL,
        },
        rightEar: {
          width: 0,
          type: ESizeType.PIXEL,
        },
        content: {
          width: 100,
          type: ESizeType.PERCENT,
        },
        gap: {
          width: 0,
          type: ESizeType.PIXEL,
        },
      },
      height: {
        value: 100,
        type: ESizeType.PERCENT,
      },
      slidesToShow: 1,
      slidesToScroll: 1,
      defaultControls: [],
    },
  },
];
