import * as React from 'react';
import * as style from './Documentation.css';
import { Link } from '../Link';
import { Shutter } from '../Shutter';
import { throttle } from '@cian/newbuilding-utils';
import { getShutterHeight, getBlockWidth, checkIfTwoColomns } from './helpers';
import { trackDocumentClick } from '../../analytics/trackDocumentClick';
import { LayoutBlock } from '../LayoutBlock';
import { Section } from '../Section';
import { IDocument } from '../../types/newbuilding/document';

export interface IDocumentationProps {
  documentation: IDocument[];
}

interface IDocumentationState {
  open: boolean;
  height: number;
}

export interface IRefsSet {
  [index: number]: HTMLLIElement | null;
  length: number;
  push(elem?: HTMLLIElement | null): void;
}

export class Documentation extends React.PureComponent<IDocumentationProps, IDocumentationState> {
  public elemRefs: IRefsSet = [];
  public blockRef: HTMLElement | null;

  public constructor(props: IDocumentationProps) {
    super(props);
    this.state = {
      open: false,
      height: 0,
    };
  }

  public componentDidMount() {
    this.computeDimensions();

    window.addEventListener('resize', this.throttledResizeHandler);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResizeHandler);
  }

  public throttledResizeHandler = throttle(400, () => {
    this.computeDimensions();
  });

  public computeDimensions = () => {
    const blockWidth = getBlockWidth(this.blockRef);

    if (blockWidth > 0) {
      const isTwoColomns = checkIfTwoColomns(blockWidth);
      this.setState({ height: getShutterHeight(this.elemRefs, isTwoColomns) });
    }
  };

  public handleChange = (open: boolean) => {
    this.setState({ open });
  };

  public render() {
    const { documentation } = this.props;
    if (!documentation || !documentation.length) {
      return null;
    }
    const { height } = this.state;

    return (
      <LayoutBlock>
        <Section title="Проектная документация" data-mark="Documents">
          <Shutter defaultMaxHeight={height} onChange={this.handleChange} openLabel={`Все документы`}>
            <ul ref={ref => (this.blockRef = ref)} className={style['documentation-list']}>
              {documentation.map((document, i) => {
                if (!document.url || !document.name) {
                  return null;
                }

                return (
                  <li
                    ref={ref => this.elemRefs.push(ref)}
                    className={style['documentation-listItem']}
                    key={`${document.url}${i}`}
                    onClick={trackDocumentClick}
                  >
                    <Link url={document.url} linkStyle={style['documentation-link']} target="blank">
                      {document.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Shutter>
        </Section>
      </LayoutBlock>
    );
  }
}
