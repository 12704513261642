// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetHouseStatsDesktopRequest,
  TGetHouseStatsDesktopModel,
  IMappers,
  TGetHouseStatsDesktopResponse,
  IGetHouseStatsDesktopResponse,
  IGetHouseStatsDesktopResponseError,
} from './types';

export const defaultConfig: TGetHouseStatsDesktopModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'newbuilding-card',
  pathApi: '/v1/get-house-stats-desktop/',
  requestType: 'json',
} as TGetHouseStatsDesktopModel;

export function createGetHouseStatsDesktopModel(parameters: IGetHouseStatsDesktopRequest): TGetHouseStatsDesktopModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetHouseStatsDesktopOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetHouseStatsDesktopRequest;
}

export async function fetchGetHouseStatsDesktop<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetHouseStatsDesktopOptions<TResponse200, TResponse400>): Promise<
  TGetHouseStatsDesktopResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetHouseStatsDesktopModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetHouseStatsDesktopResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetHouseStatsDesktopResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetHouseStatsDesktopResponse;
}
