import * as React from 'react';
import { Section } from '../Section';
import { LayoutBlock } from '../LayoutBlock';

export interface INewbuildingReviewsTeaserProps {
  reviews: React.ReactElement;
}

export const NewbuildingReviewsTeaser: React.FC<INewbuildingReviewsTeaserProps> = ({ reviews }) => (
  <LayoutBlock>
    <Section id="reviews" data-nav-tab="reviews/Отзывы и вопросы" data-mark={'NewbuildingReviews'}>
      {reviews}
    </Section>
  </LayoutBlock>
);
