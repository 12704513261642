import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EFavoritesActionTypes {
  SetIsFavorite = 'isFavorite/set',
}

interface IFavoritesActionPayload {
  isFavorite: boolean;
}

export interface IFavoritesAction {
  type: EFavoritesActionTypes;
  payload: IFavoritesActionPayload;
}

export type TSetFavorites = ITypedReduxAction<EFavoritesActionTypes.SetIsFavorite, IFavoritesActionPayload>;

export const setIsFavorite: (payload: IFavoritesActionPayload) => TSetFavorites = actionGenerator<
  EFavoritesActionTypes.SetIsFavorite,
  IFavoritesActionPayload
>(EFavoritesActionTypes.SetIsFavorite);
