import * as React from 'react';
import { useSelector } from 'react-redux';
import { PromosBanner as PromosBannerComponent } from '../../components/PromosBanner';

import { useApplicationContext } from '../../utils/applicationContext';
import { getDiscountMortgage, getNewbuildingId } from '../../selectors/newbuilding';
import { LeadFactoryCallButtonContainer } from '../LeadFactoryCallButton';
import { LayoutBlock } from '../../components/LayoutBlock';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { trackPromoOffersShow } from './trackPromoOffersShow';

export const LeadFactoryPromosBanner: React.VFC = () => {
  const { config } = useApplicationContext();
  const newbuildingId = useSelector(getNewbuildingId);
  const text = config.getStrict<string>('Newbuilding.DiscountMortgageBanner.Text');
  const isDiscountMortgageValid = config.getStrict<boolean>('Newbuilding.DiscountMortgageBanner.Enabled');
  const isDiscountMortgage = useSelector(getDiscountMortgage);

  const onBlockShow = React.useCallback(() => {
    trackPromoOffersShow(newbuildingId);
  }, [newbuildingId]);

  if (!isDiscountMortgageValid || !isDiscountMortgage) {
    return null;
  }

  return (
    <LayoutBlock>
      <ActionAfterViewObserver callback={onBlockShow} triggerOnce>
        <PromosBannerComponent
          text={text}
          contactsButton={
            <LeadFactoryCallButtonContainer
              placeType="promosBanner"
              trackAction="Open_promotions_block"
              size={'XS'}
              theme="fill_secondary"
            />
          }
        />
      </ActionAfterViewObserver>
    </LayoutBlock>
  );
};
