import * as React from 'react';
import * as styles from './SecondaryContactButtons.css';

export interface ISecondaryContactButtonsProps {
  callbackButton: React.ReactNode | null;
  flatViewButton: React.ReactNode | null;
}

export const SecondaryContactButtons: React.FC<ISecondaryContactButtonsProps> = ({
  callbackButton,
  flatViewButton,
}) => {
  return (
    <div className={styles['buttons-row']}>
      {flatViewButton && <div className={styles['button-col']}>{flatViewButton}</div>}
      {callbackButton && <div className={styles['button-col']}>{callbackButton}</div>}
    </div>
  );
};

SecondaryContactButtons.displayName = 'SecondaryContactButtons';
