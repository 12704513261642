import * as React from 'react';
import * as style from './NewbuildingReviewsStat.css';
import { EStarSize, Stars } from '@cian/frontend-reviews-components';
import { ArticleHeading5 } from '@cian/ui-kit';

export const NewbuildingEmptyReviewsStat: React.FC = () => {
  return (
    <div className={style['review']}>
      <Stars value={0} size={EStarSize.M} />
      <ArticleHeading5 color="white_100">Нет отзывов</ArticleHeading5>
    </div>
  );
};
