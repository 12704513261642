import * as React from 'react';
import { ModalWindow, ArticleHeading2, ArticleParagraph3 } from '@cian/ui-kit';

import { trackPopupShow, trackPopupClose } from '../../analytics';
import { CallButtonContainer } from '../../containers/CallButton';
import { LAST_HOPE_SHOWED_DELAY } from '../../constants/lastHopePopup';
import { incrementCountPopupShowed } from '../../utils/businessLogic/lastHopePopup';
import * as styles from './LastHopePopup.css';

const QUESTIONS = [
  'Какие есть варианты отделки?',
  'Когда сдаётся дом?',
  'Может, на других этажах есть квартиры дешевле?',
  'С каким банками работаете?',
  'Есть акции и скидки?',
];

/**
 * @description Интерфейс попапа последней надежды
 * @param defaultOpen {boolean} открыт ли попап по умолчанию
 * @param builderName {string | null} имя застройщика
 * */
export interface ILastHopePopupProps {
  isDefaultOpen(): boolean;
  builderName: string | null;
  newbuildingChatOpened: boolean;
  onShowPhoneClick: () => void;
}

export const LastHopePopup: React.FC<ILastHopePopupProps> = ({
  builderName,
  isDefaultOpen,
  newbuildingChatOpened,
  onShowPhoneClick,
}) => {
  const [open, setOpen] = React.useState(false);

  const timerRef = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (!isDefaultOpen() || newbuildingChatOpened) {
        return;
      }

      setOpen(true);
      trackPopupShow();
      incrementCountPopupShowed();
    }, LAST_HOPE_SHOWED_DELAY);

    return () => clearTimeout(timerRef.current);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
    trackPopupClose();
  }, []);

  return (
    <ModalWindow escape fixed={false} open={open} onClose={handleClose}>
      <div className={styles['container']} data-testid="LastHopePopupWithCallReasons">
        <div className={styles['content']}>
          <div className={styles['left']}>
            <ArticleHeading2>Спросите у застройщика</ArticleHeading2>
            <div className={styles['reasons']}>
              {QUESTIONS.map((question, index) => (
                <div key={index} className={styles['reason']}>
                  {question}
                </div>
              ))}
            </div>
          </div>
          <div className={styles['right']} />
        </div>

        <div className={styles['button']}>
          <CallButtonContainer
            openContactsStyles={{ size: 'XS', theme: 'fill_primary' }}
            showPhoneStyles={{ size: 'XS', theme: 'fill_primary' }}
            onlyBuilderPhone
            onShowPhoneClick={onShowPhoneClick}
            placeType="lastHopePopup"
            SinglePhoneComponent={null}
          />
        </div>
        {builderName && <ArticleParagraph3 color="gray60_100">Застройщик {builderName}</ArticleParagraph3>}
      </div>
    </ModalWindow>
  );
};
