import * as React from 'react';
import { UIText2, Tooltip } from '@cian/ui-kit';
import { Faq16 } from '@cian/ui-kit/prev';

import * as styles from './actual.css';

interface IActualProps {
  label: string;
  title: string;
  description: React.ReactNode;
}
export const Actual = ({ label, title, description }: IActualProps) => {
  return (
    <div className={styles['actual']} data-testid="Actual">
      <div className={styles['label']}>
        <UIText2 color="gray60_100">{label}</UIText2>
      </div>
      <Tooltip theme="white" header={title} placement="right" title={description}>
        <Faq16 color="primary_100" />
      </Tooltip>
    </div>
  );
};

Actual.displayName = 'Actual';
