import * as React from 'react';
import { Price16, PriceUp16 } from '@cian/ui-kit/prev';

import { TPriceDiff } from '../../../types/realtyValuation';

interface IPriceDiffProps {
  diff: TPriceDiff;
}

export const PriceDiff: React.VFC<IPriceDiffProps> = ({ diff }) => {
  return diff === 'increase' ? (
    <PriceUp16 display="block" color="error_100" />
  ) : (
    <Price16 display="block" color="success_100" />
  );
};
