import { IApplicationState } from '../../types/redux';
import { getGaGeoLabel, getRegionId } from '../../selectors/newbuilding';
import { isMoscowOrMO } from '../../utils/businessLogic/region';

export function getAnalyticsRegionId(state: IApplicationState): number | string | null {
  const regionId = getRegionId(state);
  if (!regionId) {
    return null;
  }

  if (isMoscowOrMO(regionId)) {
    return getGaGeoLabel(state);
  }

  return regionId;
}
