import * as React from 'react';
import { Tabs } from '@cian/ui-kit/prev';
import { useSelector, useDispatch } from 'react-redux';

import { EValuationTabs } from '../../constants/tabs';
import { trackRealtyValuationTabsChange } from '../../tracking';
import { selectRealtyValuationTabs } from '../../../../selectors/realtyValuation';
import { setActiveTab } from '../../../../actions/realtyValuation';

interface ITabsContainerProps {
  pricesDynamics: React.ReactElement;
  salesDynamics: React.ReactElement;
}

export const TabsContainer: React.VFC<ITabsContainerProps> = ({ salesDynamics, pricesDynamics }) => {
  const dispatch = useDispatch();
  const tabs = useSelector(selectRealtyValuationTabs);

  const handleSetTab = React.useCallback(
    (tab: EValuationTabs) => {
      dispatch(setActiveTab(tab));
      trackRealtyValuationTabsChange(tab);
    },
    [dispatch],
  );

  if (!tabs) {
    return null;
  }

  return (
    <>
      <Tabs activeTab={tabs.activeTab} onChange={handleSetTab} tabs={tabs.tabs} />
      <div style={{ marginTop: 24 }}>
        {tabs.activeTab === EValuationTabs.SalesDynamic && salesDynamics}
        {tabs.activeTab === EValuationTabs.PriceDynamic && pricesDynamics}
      </div>
    </>
  );
};
