import * as React from 'react';

import { BankListItem } from './components/BankListItem';

import * as styles from './AccreditedBanks.css';
import { IBank } from '../../types/newbuilding/bank';

export interface IAccreditedBanksProps {
  banks: IBank[];
  onBankClick?(bank: IBank): void;
}

export const AccreditedBanks: React.FC<IAccreditedBanksProps> = ({ banks, onBankClick }) => {
  return (
    <ul className={styles['list']} data-testid="AccreditedBanks">
      {banks.map(bank => (
        <li key={bank.id}>
          <BankListItem bank={bank} onLinkClick={onBankClick} />
        </li>
      ))}
    </ul>
  );
};
