import * as React from 'react';
import { Label } from '@cian/ui-kit';
import * as style from './styles.css';
import { EType } from '../../types/newbuilding/reliableLabel';

interface IReliableLabelV2Props {
  onReliableClick(event: React.MouseEvent<HTMLElement>): void;
  onProblemClick(event: React.MouseEvent<HTMLElement>): void;
  label: string;
  reliableType: EType;
}

enum BACKGROUND_COLOR {
  Reliable = '#EBF9E6',
  Unreliable = 'var(--decorative__theme__red, #DB1F36)',
}

enum TEXT_COLOR {
  Reliable = 'var(--decorative__theme__green, #34AC0A)',
  Unreliable = 'var(--white_100, #FFF)',
}

export const ReliableLabel = ({ onReliableClick, onProblemClick, reliableType, label }: IReliableLabelV2Props) => {
  const isReliable = reliableType === EType.Reliable;
  const onClick = React.useMemo(() => {
    return isReliable ? onReliableClick : onProblemClick;
  }, [isReliable]);

  return (
    <div onClick={onClick} data-testid="ReliableLabel" className={style['label']}>
      <Label
        background={isReliable ? BACKGROUND_COLOR.Reliable : BACKGROUND_COLOR.Unreliable}
        color={isReliable ? TEXT_COLOR.Reliable : TEXT_COLOR.Unreliable}
      >
        {label}
      </Label>
    </div>
  );
};
