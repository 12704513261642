import * as React from 'react';
import * as style from './NewbuildingItem.css';
import { Image } from '@cian/ui-kit';
import { UndergroundInfo, Address, UndergroundTimeTo } from './parts';

interface INewbuildingItemProps {
  /** Адрес ЖК **/
  address?: string | null;
  /** Список названий застройщиков **/
  builderNames?: string[] | null;
  /** Статус сдачи ЖК **/
  buildingStatusInfo?: string | null;
  /** Полный адрес страницы ЖК **/
  fullUrl?: string | null;
  /** Ссылка на фото ЖК **/
  imageUrl?: string | null;
  /** Название ЖК **/
  name?: string | null;
  /** Минимальная цена квартир (форматированная строка) **/
  priceFromDisplay?: string | null;
  /** Цвет линии метро **/
  undergroundColor?: string | null;
  /** Название станции метро **/
  undergroundName?: string | null;
  /** Время до метро, строка **/
  undergroundTimeTo?: string | null;

  onClick?(): void;
}

export class NewbuildingItem extends React.PureComponent<INewbuildingItemProps> {
  public render() {
    const {
      name,
      fullUrl,
      onClick,
      builderNames,
      priceFromDisplay,
      imageUrl,
      buildingStatusInfo,
      undergroundName,
      undergroundColor,
      undergroundTimeTo,
      address,
    } = this.props;

    if (!name) {
      return null;
    }

    return (
      <a
        title={name}
        href={fullUrl || ''}
        className={style['container']}
        data-mark={'NewbuildingItem'}
        onClick={onClick}
      >
        <div className={style['scalable-container']}>
          <div className={style['image']}>
            <Image height={'120px'} src={imageUrl || ''} />
          </div>
        </div>
        <div className={style['content']}>
          {!!builderNames && !!builderNames.length && (
            <div className={style['builderName']}>
              {builderNames.length > 1 ? 'Застройщики ' : 'Застройщик '}
              {builderNames.join(', ')}
            </div>
          )}
          <div className={style['title']}>ЖК «{name}»</div>
          {!!priceFromDisplay && <div className={style['price']}>{priceFromDisplay}</div>}
          {!!buildingStatusInfo && <div className={style['deadline']}>{buildingStatusInfo}</div>}
          <UndergroundInfo undergroundName={undergroundName} undergroundColor={undergroundColor} />
          <Address address={address} undergroundName={undergroundName} />
          <UndergroundTimeTo time={undergroundTimeTo} />
        </div>
      </a>
    );
  }
}
