import * as React from 'react';
import { IAdFoxTemplateParams } from '@cian/adfox-templates';
import * as styles from './styles.css';

export interface IBuilderLinkTemplateProps extends IAdFoxTemplateParams {
  type: 'link_banner';

  url: string;
}
export function BuilderLinkTemplate({ url }: IBuilderLinkTemplateProps) {
  return (
    <a
      href={url}
      target="_blank"
      rel="nofollow noopener noreferrer"
      className={styles['container']}
      data-testid="BuilderLinkTemplate"
    >
      Перейти на сайт ЖК
    </a>
  );
}
