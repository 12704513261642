import { Tooltip } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './SalesLeader.css';
import { SalesLeaderIcon } from '../../../SalesLeaderIcon';

export const SalesLeader: React.FC = () => {
  return (
    <Tooltip placement="right" title="Лидер продаж">
      <span className={style['icon']} aria-label="Лидер продаж">
        <SalesLeaderIcon />
      </span>
    </Tooltip>
  );
};
