import { INewbuildingFromTheBuilder } from '../../../types/newbuilding/newbuildingFromBuilder';

export function getJKInfo(jk: INewbuildingFromTheBuilder, position: number, list: string) {
  if (!jk) {
    return {};
  }

  return {
    id: jk.newbuildingId,
    offerType: 'JK',
    position,
    extra: {
      name: jk.name,
      brand: `${jk.builderIds && jk.builderIds.join(',')}/developer_company`,
      category: 'sale/JK',
      list,
    },
  };
}
