import { pathOr } from 'ramda';
import { IApplicationState } from '../../types/redux';
import { getUser } from './getUser';

export const getUserPhone = (state: IApplicationState) => {
  const user = getUser(state);
  const phone = pathOr(null, ['phones', 0, 'phone'], user);

  return phone ? `+${phone}` : null;
};
