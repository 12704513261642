import { IConfig } from '@cian/config/shared';
import { IPromoInfoSchema } from '../../../types/promo';
import { getCianMortgagePromoUrl } from '../../../utils/config';

export const getMortgagePartnerLink = (promo: IPromoInfoSchema | null, config: IConfig) => {
  if (promo?.bank?.isPartner && promo?.promoType !== 'maternalCapital') {
    return getCianMortgagePromoUrl(config);
  }

  return null;
};
