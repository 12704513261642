import { ErrorLogComponent } from '@cian/error-log-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { LayoutBlock } from '../../components/LayoutBlock';
import { getIsMapVisible } from '../../selectors/common';
import { useApplicationContext } from '../../utils/applicationContext';
import { InfrastructureMicrofrontendContainer } from '../InfrastructureMicrofrontendContainer';
import { NearbyNewbuildingsLinkContainer } from '../NearbyNewbuildingsLink';
import { SpecialPromoLinkContainer } from '../SpecialPromoLink';

export const InfrastructureContainer = () => {
  const { logger } = useApplicationContext();

  const isMapVisible = useSelector(getIsMapVisible);

  if (!isMapVisible) {
    return null;
  }

  return (
    <LayoutBlock>
      <ErrorLogComponent logger={logger}>
        <InfrastructureMicrofrontendContainer
          headerLinks={
            <>
              <SpecialPromoLinkContainer />
              <NearbyNewbuildingsLinkContainer />
            </>
          }
        />
      </ErrorLogComponent>
    </LayoutBlock>
  );
};
