import * as React from 'react';
import cn from 'clsx';

import { Summary } from '../Summary';
import { TPriceDiff } from '../../../types/realtyValuation';
import * as styles from './styles.css';
import { PriceDiff } from '../PriceDiff';

interface IRealtyValuationSummarriesProps {
  avgPrice: string;
  avgPriceSqm: string;
  priceDiffTitle: string;
  priceDiff?: {
    diff: TPriceDiff;
    value: string;
  } | null;
}

export function Summaries({ avgPrice, avgPriceSqm, priceDiff, priceDiffTitle }: IRealtyValuationSummarriesProps) {
  return (
    <div className={styles['container']}>
      <Summary label="Средняя цена за квартиру" testId="valuation_summary_avgPrice">
        {avgPrice}
      </Summary>
      <Summary label="Средняя цена м²" testId="valuation_summary_avgPriceSqm">
        {avgPriceSqm}
      </Summary>
      {priceDiff && (
        <Summary label={priceDiffTitle} testId="valuation_summary_priceDiff">
          <span
            className={cn(
              styles['price-diff'],
              styles[priceDiff.diff === 'increase' ? 'price-diff--red' : 'price-diff--green'],
            )}
          >
            <PriceDiff diff={priceDiff.diff} />
            {priceDiff.value}
          </span>
        </Summary>
      )}
    </div>
  );
}
