import * as React from 'react';
import { CloseButton } from '../CloseButton';
import * as styles from './styles.css';

export interface IFullscreenGalleryHeaderProps {
  onClose(): void;
  favorite?: React.ReactNode;
  tabs?: React.ReactNode;
  call?: React.ReactNode;
}

export function Header({ onClose, favorite, tabs, call }: IFullscreenGalleryHeaderProps) {
  return (
    <div className={styles['container']}>
      {tabs}
      <div className={styles['aside']}>
        {call}
        {favorite}
        <div className={styles['close']}>
          <CloseButton onClick={onClose} />
        </div>
      </div>
    </div>
  );
}
