import * as React from 'react';

import { Section } from '../../Section';
import { LayoutBlock } from '../../LayoutBlock';

interface ISectionWrapperProps {
  children: React.ReactNode | React.ReactNode[];
}

export const SectionWrapper = React.forwardRef<HTMLDivElement, ISectionWrapperProps>(({ children }, ref) => {
  return (
    <LayoutBlock ref={ref}>
      <Section data-nav-tab="realty-valuation/Динамика цен" id="realty-valuation">
        {children}
      </Section>
    </LayoutBlock>
  );
});

SectionWrapper.displayName = 'SectionWrapper';
