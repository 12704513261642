import { IApplicationState } from '../../types/redux';
import { IReviewWeb } from '../../types/newbuilding/reviews';

export const getReviewById = (id: string) => (state: IApplicationState) => {
  const reviews = state.newbuilding.reviews?.reviews;

  const review = reviews?.find(item => item.id === id) || null;

  return review as IReviewWeb;
};
