import * as React from 'react';
import { ArticleParagraph2 } from '@cian/ui-kit';
import classNames from 'clsx';
import { Link } from '../Link';

import * as styles from './OffersReliableBlock.css';

interface IReliableBlockProps {
  text: string;
  icon: React.ReactNode;
  styleName?: string;
  onClick(event: React.MouseEvent<HTMLElement>): void;
}

export function OffersReliableBlock({ text, icon, styleName, onClick }: IReliableBlockProps) {
  return (
    <div className={classNames(styles['container'], styleName && styles[styleName])} data-testid="OffersReliableBlock">
      <div className={styles['icon']}>{icon}</div>
      <div>
        <ArticleParagraph2>{text}</ArticleParagraph2>
        <Link url={''} onClick={onClick}>
          Узнать больше
        </Link>
      </div>
    </div>
  );
}
