import { ca } from '@cian/analytics';
import { getNewbuildingProduct } from '../../utils/analytics';

export function trackBannerShow(newbuildingId: number): void {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'newbuilding_banner',
    label: 'KPN',
    event: 'oldevent',
    products: [getNewbuildingProduct({ newbuildingId })],
  });
}

export function trackBannerClick(newbuildingId: number): void {
  ca('eventSite', {
    action: 'click',
    category: 'newbuilding_banner',
    label: 'KPN',
    name: 'oldevent',
    products: [getNewbuildingProduct({ newbuildingId })],
  });
}
