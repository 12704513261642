import * as React from 'react';

import { VerticalSlider } from '../VerticalSlider';
import { Arrow } from './parts/Arrow';
import { InteriorDecorationItem } from './parts/InteriorDecorationItem';

import * as styles from './styles.css';
import { Section } from '../Section';
import { IInteriorDecoration } from '../../types/newbuilding/decoration';

interface IInteriorDecorationProps {
  items: IInteriorDecoration[];
  onItemClick(i: number): void;
}

export function InteriorDecoration({ items, onItemClick }: IInteriorDecorationProps) {
  return (
    <Section title="Варианты отделки" id="decorations">
      <VerticalSlider containerClass={styles['slider']} onItemClick={onItemClick} arrow={Arrow}>
        {items.map(({ title, images, shortDescription }) => {
          const { thumbnailUrl, url } = images[0];

          return (
            <InteriorDecorationItem
              key={title}
              title={title}
              imgUrl={thumbnailUrl || url}
              shortDescription={shortDescription}
            />
          );
        })}
      </VerticalSlider>
    </Section>
  );
}
