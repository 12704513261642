import { IApplicationState } from '../../types/redux';
import { INewbuildingProblem } from '../../types/newbuilding/problem';

export function getProblems(state: IApplicationState): INewbuildingProblem[] {
  const {
    newbuilding: { problems },
  } = state;

  if (!problems) {
    return [];
  }

  return problems;
}
