import { ca } from '@cian/analytics';
import { getPageData, getJKInfo } from './helpers';
import { getGaLabel, getUser } from '../../selectors/analytics';
import { getAnalyticsRegionId } from './getAnalyticsRegionID';
import { getBuilders, getNewbuldingFromTheBuilder } from '../../selectors/newbuilding';
import { TThunkAction } from '../../types/redux/thunk';

export function trackNewbuildingFromTheBuilderBlockAppearance(): TThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const builders = getBuilders(state);
    const newbuildings = getNewbuldingFromTheBuilder(state);

    ca('eventSite', {
      name: 'oldevent',
      category: 'similar_objects',
      action: 'show_others_JK',
      label: '',
      ds: 'desktop',
      products: newbuildings.map((newbuilding, position) => getJKInfo(newbuilding, position + 1, 'others_JK')),
      page: {
        ...getPageData(builders[0]),
        customPageURL: getGaLabel(state),
        region: getAnalyticsRegionId(state),
      },
      user: {
        ...getUser(state),
      },
      modelVersion: '1',
    });
  };
}

export function trackTransferToNewbuildingFromTheBuilder(
  // Для получения элемента по которому был сделан клик в блоке ЖК от застройщика и его позиции в этом блоке
  params: { index: number },
): TThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const builders = getBuilders(state);
    const newbuildings = getNewbuldingFromTheBuilder(state);
    const { index } = params;

    ca('eventSite', {
      name: 'oldevent',
      category: 'similar_objects',
      action: 'click_others_JK',
      label: '',
      ds: 'desktop',
      products: [getJKInfo(newbuildings[index], index + 1, 'others_JK')],
      page: {
        ...getPageData(builders[0]),
        customPageURL: getGaLabel(state),
        region: getAnalyticsRegionId(state),
      },
      user: {
        ...getUser(state),
      },
      modelVersion: '1',
    });
  };
}
