import * as React from 'react';
import { Spinner } from '@cian/ui-kit';

import * as styles from './styles.css';

interface IPricesDynamicsProps {
  filters?: React.ReactNode;
  summaries?: React.ReactNode;
  chart?: React.ReactNode;
  isFetching?: boolean;
  testId?: string;
}

export const TabContent: React.VFC<IPricesDynamicsProps> = props => {
  const { filters, chart, summaries, isFetching, testId } = props;

  return (
    <div className={styles['container']} data-testid={testId}>
      {filters && <div>{filters}</div>}
      <div className={styles['content']}>
        <div>{summaries}</div>
        <div>{chart}</div>
        {isFetching && (
          <div className={styles['spinner']} data-testid="valuation_spinner">
            <Spinner size={32} display="block" />
          </div>
        )}
      </div>
    </div>
  );
};
