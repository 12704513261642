import * as React from 'react';
import * as styles from './styles.css';

export function MortgageOffersTable({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className={styles['container']} data-testid="MortgageOffersTable">
      {children}
    </div>
  );
}
