import { IApplicationState } from '../../types/redux';

export function getSellers(state: IApplicationState) {
  const {
    newbuilding: { sellers },
  } = state;

  if (sellers) {
    return sellers;
  }

  return [];
}
