import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import { NoImage } from './NoImage';
import { Spinner } from '../FullscreenGallery/Spinner';
import * as style from './Image.css';

export type TImageObjectFit = 'cover' | 'contain';

export enum ELoadingStatus {
  Loading = 0,
  Loaded = 1,
  Error = 2,
}

export interface IImageError {
  text?: string;
  img?: string;
}

export interface IImageState {
  status: ELoadingStatus;
}

export interface IImageProps {
  alt?: string;
  src: string;
  error?: IImageError;
  fit?: TImageObjectFit;
  spinnerSize?: number;
  showSpinner?: boolean;
  icon?: React.ReactNode;
  onError?: VoidFunction;
}

export class Image extends React.Component<IImageProps, IImageState> {
  public static defaultProps: Partial<IImageProps> = {
    showSpinner: true,
  };

  public state = {
    status: ELoadingStatus.Loading,
  };

  public render() {
    const { alt = '', src, error, fit = 'contain', spinnerSize, showSpinner } = this.props;
    const { status } = this.state;

    const isLoading = spinnerSize && status === ELoadingStatus.Loading;
    const isError = status === ELoadingStatus.Error;

    return (
      <div {...mergeStyles(style['image'], style[`image--${fit}`])}>
        {!isError && (
          <div className={style['imageContainer']}>
            <img alt={alt} src={src} onLoad={this.onLoad} onError={this.onError} />
          </div>
        )}
        {showSpinner && isLoading && <Spinner size={spinnerSize} />}
        {!isLoading && isError && error && <NoImage {...error} />}
        {this.renderIcon()}
      </div>
    );
  }

  private renderIcon = () => {
    const { icon } = this.props;
    const { status } = this.state;
    if (!icon || status === ELoadingStatus.Loading) {
      return null;
    }

    return <div className={style['iconContainer']}>{icon}</div>;
  };

  public onLoad = () => {
    this.setState({ status: ELoadingStatus.Loaded });
  };

  public onError = () => {
    this.setState({ status: ELoadingStatus.Error });

    this.props.onError?.();
  };
}
