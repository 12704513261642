import * as React from 'react';
import { Faq16 } from '@cian/ui-kit/prev';
import { TooltipDesktop } from '@cian/ui-kit/tooltip';
import { InlineText2 } from '@cian/ui-kit/typography';
import * as style from './SpecificationTooltip.css';

export interface ISpecificationTooltipProps {
  text: string;
}

export const SpecificationTooltip: React.FC<ISpecificationTooltipProps> = props => {
  const { text } = props;

  return (
    <TooltipDesktop
      placement="top"
      theme={'white'}
      title={
        <div className={style['tooltip']}>
          <InlineText2>{text}</InlineText2>
        </div>
      }
    >
      <div className={style['faq-icon']}>
        <Faq16 color={'primary_100'} />
      </div>
    </TooltipDesktop>
  );
};
