import { ca } from '@cian/analytics';
import { ISpecialPromo } from '../../types/newbuilding/specialPromo';

export const trackSpecialPromoLinkClick = (specialPromo: ISpecialPromo | null) => () => {
  const isPik =
    specialPromo && specialPromo.showNewbuildingsLabel && specialPromo.showNewbuildingsLabel.search('ПИК') !== -1;

  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'jk_on_map',
    label: isPik ? 'pik' : 'special_promo',
    useLastProducts: true,
  });
};
