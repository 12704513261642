import { IApplicationState } from '../../types/redux';
import { IGeoRoadInfo } from '../../types/newbuilding/geo';

export function getRoadInfo(state: IApplicationState): IGeoRoadInfo[] | null {
  const {
    newbuilding: { geo },
  } = state;

  if (geo && geo.roadInfo && geo.roadInfo.length) {
    return geo.roadInfo;
  }

  return null;
}
