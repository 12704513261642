import * as React from 'react';

export function VideoSmallIcon() {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0V15.9818L15.3072 8.01818L0 0Z" fill="#EAEAEA" />
    </svg>
  );
}
