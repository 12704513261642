import { IApplicationState } from '../../types/redux';

export function getGaLabel(state: IApplicationState): string {
  const { newbuilding } = state;

  if (!newbuilding || !newbuilding.gaLabel) {
    return '';
  }

  return newbuilding.gaLabel;
}
