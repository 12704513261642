import * as React from 'react';
import * as style from './StatItem.css';
import { Link } from '../../../Link';
import { InlineText2 } from '@cian/ui-kit';

interface IStatsProps {
  qs?: string | null;
  text?: string | null;
  statusLabel: string;
}

export const StatItem: React.FC<IStatsProps> = props => {
  const { qs, text, statusLabel } = props;

  if (!qs || !text) {
    return null;
  }

  return (
    <div className={style['statItem']}>
      <Link url={qs} target={'blank'}>
        <InlineText2 color={'gray60_100'}>
          {statusLabel} {text}
        </InlineText2>
      </Link>
    </div>
  );
};
