import { ca } from '@cian/analytics';

export function trackHousesWithEscrouLabelClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'Click',
    label: 'escrow_to_listing',
    useLastProducts: true,
  });
}

export function trackHousesWithEscrouLinkClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'Click',
    label: 'escrow_to_flats',
    useLastProducts: true,
  });
}
