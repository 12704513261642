import * as React from 'react';
import classNames from 'clsx';
import { ArticleHeading4, ArticleParagraph2 } from '@cian/ui-kit/typography';

import * as styles from './styles.css';

interface IFlatsErrorProps {
  image: 'not-found' | 'start';
  title: string;
  text: React.ReactElement | string | null;
}

export function FlatsError({ image, text, title }: IFlatsErrorProps) {
  return (
    <div className={styles['container']}>
      <div className={classNames(styles['image'], styles[image])} />
      <div className={styles['title']}>
        <ArticleHeading4 color="black_100">{title}</ArticleHeading4>
      </div>
      {text && (
        <ArticleParagraph2 color={'gray60_100'} textAlign={'center'}>
          {text}
        </ArticleParagraph2>
      )}
    </div>
  );
}
