import * as React from 'react';
import { useApplicationContext } from '../../../../utils/applicationContext';
import { getCianMortgageBannerUrl } from '../../../../utils/config';
import { CianMortgageText } from '../../../../components/MortgageOffersTable';

export const CianMortgageTextContainer: React.VFC = () => {
  const { config } = useApplicationContext();
  const cianMortgageUrl = getCianMortgageBannerUrl(config);

  return <CianMortgageText cianMortgageUrl={cianMortgageUrl} />;
};
