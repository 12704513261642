import { connect } from 'react-redux';
import { IApplicationState } from '../../types/redux';
import { ISpecificationsDetailed } from '../../types/newbuilding/specificationsDetailed';
import { getDetailedSpecifications } from '../../selectors/newbuilding';
import { SpecificationsDetailed } from '../../components/SpecificationsDetailed';

export function mapStateToProps(state: IApplicationState): ISpecificationsDetailed {
  return getDetailedSpecifications(state);
}

export const SpecificationsDetailedContainer = connect(mapStateToProps)(SpecificationsDetailed);
