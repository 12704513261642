import * as React from 'react';
import { IconWarning16, IconEscrou16, InlineText1, InlineText2 } from '@cian/ui-kit';
import classNames from 'clsx';

import { SalesStartTooltip } from '../../../SalesStartTooltip';

import * as styles from './styles.css';

interface IHouseItemProps {
  active: boolean;
  title: string;
  finishStatus?: string;
  hasProblems?: boolean;
  isEscrow?: boolean;
  isSalesStart?: boolean;
}

export function HouseItem({
  active,
  title,
  finishStatus,
  hasProblems = false,
  isEscrow = false,
  isSalesStart = false,
}: IHouseItemProps) {
  return (
    <div className={classNames(styles['container'], active && styles['active'])}>
      <div className={styles['title']}>
        <InlineText1>{title}</InlineText1>
        <div className={styles['labels']}>
          {hasProblems && <IconWarning16 color="error_100" />}
          {isEscrow && <IconEscrou16 color="primary_100" />}
          {isSalesStart && <SalesStartTooltip />}
        </div>
      </div>
      {finishStatus && <InlineText2 color="gray60_100">{finishStatus}</InlineText2>}
    </div>
  );
}
