import * as React from 'react';
import { NoImageIcon } from './icon';
import * as styles from './NoImage.css';

export const NoImage = (props: { text?: string }) => {
  const { text = 'Нет фото' } = props;

  return (
    <div className={styles['noImage']}>
      <div className={styles['noImage-icon']}>
        <NoImageIcon />
      </div>
      <span className={styles['noImage-text']}>{text}</span>
    </div>
  );
};
