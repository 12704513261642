import { omit } from 'ramda';

import { TThunkAction } from '../../types/redux/thunk';
import { fetchRealtyValuation } from './fetchRealtyValuation';
import { failureAction, requestAction, successAction } from './actions';
import { getJKid } from '../../selectors/newbuilding';
import { selectPricesFiltersState, selectSalesFiltersState } from '../../selectors/realtyValuation';
import { prepareUpdatedData } from './utils/prepareUpdatedData';
import { EValuationTabs } from '../../containers/RealtyValuation/constants/tabs';
import { IGetNewbuildingValuationBlockWebRequest } from '../../repositories/newbuilding-estimate/v2/get-newbuilding-valuation-block-web';

export const updateRealtyValuationAction =
  (fromTab: EValuationTabs): TThunkAction<Promise<void>> =>
  async (dispatch, getState, context) => {
    const state = getState();
    const newbuildingId = getJKid(state);
    const pricesFilters = selectPricesFiltersState(state);
    const salesFilters = selectSalesFiltersState(state);

    dispatch(requestAction());

    const parameters = prepareParameters({ newbuildingId, pricesFilters, salesFilters }, fromTab);

    const res = await fetchRealtyValuation({ context, ...parameters });

    if (res instanceof Error) {
      dispatch(failureAction());
    } else {
      const data = prepareUpdatedData(res, fromTab);

      dispatch(successAction(data));
    }
  };

/** Подготавливает параметры запроса. В запросе должно присутствовать что-то одно из фильтров, либо ничего */
export function prepareParameters(
  rawParameters: IGetNewbuildingValuationBlockWebRequest,
  fromTab: EValuationTabs,
): IGetNewbuildingValuationBlockWebRequest {
  return omit([fromTab === EValuationTabs.PriceDynamic ? 'salesFilters' : 'pricesFilters'], rawParameters);
}
