import * as React from 'react';
import { InlineText1 } from '@cian/ui-kit/typography';

import { Link } from '../../../Link';
import { OfferLayoutRow } from '../OfferLayoutRow';

import * as styles from './styles.css';
import { ILayoutRoomResponse } from '../../../../types/newbuilding/stats';

interface IOffersLayoutListProps {
  offersUrl: string;
  expanded: boolean;
  layouts: ILayoutRoomResponse[];
  displayHouseName?: boolean;
  fromDeveloperPropsCount: number;
  onOfferLinkClick(offerId: number, gaLabel: string): void;
  onAllOffersLinkClick(): void;
}

/* Постоянная высота блока с несколькими предложениями */
const PLURAL_BLOCK_HEIGHT = 94;
/* Постоянная высота блока с несколькими предложениями */
const SINGLE_BLOCK_HEIGHT = 8;
/* Высота строки с планировкой */
const LAYOUT_ROW_HEIGHT = 55;

export function OffersLayoutList({
  expanded,
  layouts,
  offersUrl,
  displayHouseName,
  fromDeveloperPropsCount,
  onOfferLinkClick,
  onAllOffersLinkClick,
}: IOffersLayoutListProps) {
  const pluralOffers = layouts.length > 1 || fromDeveloperPropsCount > 1;
  const staticHeight = pluralOffers ? PLURAL_BLOCK_HEIGHT : SINGLE_BLOCK_HEIGHT;
  const height = staticHeight + LAYOUT_ROW_HEIGHT * layouts.length;

  return (
    <div
      className={styles['expanded-container']}
      style={{ height: expanded ? height : '0' }}
      data-testid="OffersLayoutList"
    >
      <div className={styles['container']}>
        {layouts.map(({ offerId, gaLabel, houseName, ...layoutRow }) => (
          <OfferLayoutRow
            key={offerId}
            houseName={displayHouseName ? houseName : undefined}
            {...layoutRow}
            onLinkClick={() => onOfferLinkClick(offerId, gaLabel)}
          />
        ))}
        {pluralOffers && (
          <div className={styles['link']}>
            <Link url={offersUrl} target="blank" onClick={onAllOffersLinkClick}>
              <InlineText1 color="primary_100">Смотреть все предложения</InlineText1>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
