import * as React from 'react';
import { RadioButtonGroup, IControlGroupProps, UIText2 } from '@cian/ui-kit';

import * as styles from './styles.css';

export interface IFiltersProps {
  filtersProps: IFilterProps[];
  testId?: string;
}

export interface IFilterProps extends IControlGroupProps {
  label: string;
}

export const Filters: React.VFC<IFiltersProps> = ({ filtersProps, testId }) => {
  return (
    <div className={styles['container']} data-testid={testId}>
      {filtersProps.map(({ label, ...rest }, i) => (
        <div key={i} className={styles['item']}>
          <div className={styles['label']}>
            <UIText2>{label}</UIText2>
          </div>
          <RadioButtonGroup {...rest} />
        </div>
      ))}
    </div>
  );
};
