import * as React from 'react';
import { UIText2, IconWarningSign16 } from '@cian/ui-kit';

import * as styles from './warning.css';

interface IActualProps {
  label: string;
  tooltip: React.ReactNode;
}
export const Warning = ({ label, tooltip }: IActualProps) => {
  return (
    <div className={styles['warning']} data-testid="Warning">
      <div className={styles['label']}>
        <div className={styles['warn']}>
          <IconWarningSign16 color="error_100" />
        </div>
        <UIText2 color="error_100">{label}</UIText2>
      </div>
      {tooltip}
    </div>
  );
};

Warning.displayName = 'Warning';
