import * as React from 'react';
import { ArticleHeading3 } from '@cian/ui-kit';
import * as style from './CommentPart.css';

export interface ICommentPartProps {
  title?: string;
  message: string;
}

export const CommentPart: React.FC<ICommentPartProps> = ({ title, message }) => {
  return (
    <div className={style['item']}>
      {title && (
        <div className={style['title']}>
          <ArticleHeading3>{title}</ArticleHeading3>
        </div>
      )}
      <p>{message}</p>
    </div>
  );
};
