import { INewbuildingRecommendationsState } from '../../types/recommendations';
import { noopReducer } from '../noopReducer';

export const INITIAL_STATE: INewbuildingRecommendationsState = {
  allRecommendationsUrl: '',
  compilations: [],
  modelVersion: '',
  newbuildings: [],
};

export const newbuildingRecommendationsReducer = noopReducer(INITIAL_STATE);
