import * as React from 'react';
import * as style from './Seller.css';

import { BuilderLogo } from '../../../BuilderLogo';
import { CallBuilder } from '../../../CallBuilder';
import { INewbuildingSeller } from '../../../../types/newbuilding/seller';
import { INewbuildingBuilder } from '../../../../types/newbuilding/builder';

export interface ISellerProps {
  newbuildingId: number;
  seller: INewbuildingSeller;
  eventTracker(builder: INewbuildingBuilder | INewbuildingSeller): void;
}

export const Seller = (props: ISellerProps) => (
  <div className={style['seller']}>
    <BuilderLogo
      customStyles={style['seller-logo']}
      src={props.seller.avatarUrl}
      name={props.seller.name}
      website={null}
    />
    <div className={style['seller-info']}>
      <span className={style['seller-title']}>{props.seller.name}</span>
      <span className={style['seller-subtitle']}>Агентство недвижимости</span>
      <CallBuilder
        dynamicCalltrackingPlaceType="buildersInfo"
        newbuildingId={props.newbuildingId}
        isBuilder={false}
        isSecondary={true}
        target={props.seller}
        XXL={false}
        trackActionOpenContactsCall={props.eventTracker}
        customTitle="Контакты представителя"
      />
    </div>
  </div>
);
