import * as React from 'react';
import {
  Button,
  ArticleHeading2,
  FormField,
  Input,
  MaskedInput,
  ArticleParagraph1,
  ArticleParagraph3,
} from '@cian/ui-kit';

import { ClientComponent } from '../ClientComponent';
import { IValidationErrors, IUpcomingSaleRequest } from '../../types/upcomingSaleRequest';
import { prepareFormValues, validateFormValues } from './utils';

import * as styles from './UpcomingSaleRequestForm.css';

interface IUpcomingSaleRequestFormProps {
  onSubmit(data: IUpcomingSaleRequest): boolean | Promise<boolean>;
}

const initialValues = {
  email: '',
  phone: '',
  userName: '',
};

export function UpcomingSaleRequestForm({ onSubmit }: IUpcomingSaleRequestFormProps) {
  const [values, setValues] = React.useState<IUpcomingSaleRequest>({ ...initialValues });
  const [errors, setErrors] = React.useState<IValidationErrors>({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleChange = React.useCallback((name: string, value: string) => {
    setValues(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: undefined }));
  }, []);

  const handleTextInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    handleChange(name, value);
  }, []);

  const handlePhoneChange = React.useCallback((value: string) => {
    handleChange('phone', value);
  }, []);

  const handleSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const preparedValues = prepareFormValues(values);
      const validationErrors = validateFormValues(preparedValues);

      if (Object.values(validationErrors).some(err => err)) {
        setErrors(validationErrors);

        return;
      }

      setIsSubmitting(true);
      const isSuccess = await onSubmit(preparedValues);
      setIsSubmitting(false);

      if (isSuccess) {
        setValues({ ...initialValues });
      }
    },
    [values],
  );

  return (
    <div className={styles['container']}>
      <div className={styles['title']}>
        <ArticleHeading2 as="h3">Заявка</ArticleHeading2>
      </div>
      <div className={styles['block']}>
        <ArticleParagraph1>
          Застройщик свяжется с вами, как только у него появится дополнительная информация по старту продаж в ЖК
        </ArticleParagraph1>
      </div>
      <form onSubmit={handleSubmit} data-testid="UpcomingSaleRequestFormElement">
        <div className={styles['block']}>
          <FormField label="Имя фамилия" errorMessage={errors.userName} withoutMargin>
            <Input
              name="userName"
              onChange={handleTextInputChange}
              value={values.userName}
              invalid={!!errors.userName}
              disabled={isSubmitting}
              placeholder="Иван Иванов"
              size="M"
            />
          </FormField>
        </div>
        <div className={styles['block']}>
          <FormField label="Телефон" errorMessage={errors.phone} withoutMargin>
            <ClientComponent
              replaceNode={
                <Input name="phone" onChange={handleTextInputChange} value={values.phone} placeholder="+7" size="M" />
              }
            >
              <MaskedInput
                name="phone"
                mask="+7 000 000-00-00"
                onChange={handlePhoneChange}
                value={values.phone}
                invalid={!!errors.phone}
                disabled={isSubmitting}
                placeholder="+7"
                size="M"
                lazy
              />
            </ClientComponent>
          </FormField>
        </div>
        <div className={styles['block']}>
          <FormField label="E-mail" errorMessage={errors.email} withoutMargin>
            <Input
              name="email"
              onChange={handleTextInputChange}
              value={values.email}
              invalid={!!errors.email}
              disabled={isSubmitting}
              placeholder="example@mail.com"
              size="M"
            />
          </FormField>
        </div>
        <div className={styles['submit']}>
          <Button loading={isSubmitting} type="submit" theme="fill_primary">
            Отправить
          </Button>
        </div>
      </form>
      <ArticleParagraph3 color="gray40_100">
        Нажимая на кнопку, вы соглашаетесь на обработку персональных данных в соответствии{' '}
        <a className={styles['link']} href="/legal-documents/politika_konfidencialnosti_0/">
          политикой конфиденциальности
        </a>
      </ArticleParagraph3>
    </div>
  );
}
