import { getHttpApi } from '@cian/http-api/browser';
import {
  fetchDeleteOfferFromFavorites,
  IDeleteOfferFromFavoritesRequest,
} from '../../../shared/repositories/favorites/v1/delete-offer-from-favorites';
import { IAddFavoriteRequest, fetchAddFavorite } from '../../../shared/repositories/favorites/v1/add-favorite';
import {
  trackingActionAddFromCardJG,
  trackingActionRemoveFromCardJK,
} from '../../../shared/actions/analytics/trackFavorite';
import { TThunkAction } from '../../../shared/types/redux/thunk';
import { setIsFavorite } from '../../../shared/actions/favorites';

export function removeFavorite(newbuildingId: number, gaLabel: string): TThunkAction {
  return async dispatch => {
    const httpApi = getHttpApi();

    const parameters: IDeleteOfferFromFavoritesRequest = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dealType: 'sale' as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      entityType: 'newbuilding' as any,
      entityId: newbuildingId,
    };

    const trackActionRemove = trackingActionRemoveFromCardJK(gaLabel, newbuildingId);
    await trackActionRemove();

    const response: { statusCode: number } = await fetchDeleteOfferFromFavorites({ httpApi, parameters });

    if (response.statusCode === 200) {
      dispatch(setIsFavorite({ isFavorite: false }));
    }
  };
}

export function addFavorite(newbuildingId: number, gaLabel: string): TThunkAction {
  return async dispatch => {
    const httpApi = getHttpApi();
    const parameters: IAddFavoriteRequest = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dealType: 'sale' as any,
      entityId: newbuildingId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      entityType: 'newbuilding' as any,
    };
    const trackActionAdd = trackingActionAddFromCardJG(gaLabel, newbuildingId);
    await trackActionAdd();

    const response: { statusCode: number } = await fetchAddFavorite({ httpApi, parameters });

    if (response.statusCode === 200) {
      dispatch(setIsFavorite({ isFavorite: true }));
    }
  };
}
