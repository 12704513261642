import * as React from 'react';
import { useSelector } from 'react-redux';

import { AgentsOffersRow } from '../../components/AgentsOffersRow';
import { OffersFooter } from '../../components/OffersFooter';
import { OffersList, OffersRow } from '../../components/OffersList';
import { FlatsError } from '../../components/FlatsError';
import { getDisclaimerText, getIsCPL, getNewbuildingId } from '../../selectors/newbuilding';
import { getSelectedHouseId, getSelectedHouseOffers, isHouseNameVisible } from '../../selectors/offers';
import { OffersKPNRowContainer } from '../OffersKPNRow';
import {
  trackAgentFlatsLinkClick,
  trackAllFlatsLinkClick,
  trackOffersQuantityLinkClick,
  trackOpenFlat,
  trackRoomTypeLinkClick,
} from './tracking';
import { getFlatsErrorTitle } from './utils/getFlatsErrorTitle';

export function OffersContainer() {
  const offers = useSelector(getSelectedHouseOffers);
  const displayHouseName = useSelector(isHouseNameVisible);
  const disclaimerText = useSelector(getDisclaimerText);
  const selectedHouseId = useSelector(getSelectedHouseId);
  const newbuildingId = useSelector(getNewbuildingId);
  const KPNRowVisible = useSelector(getIsCPL) === false;

  const onOffersQuantityLinkClick = React.useCallback((roomType: string) => {
    trackOffersQuantityLinkClick(newbuildingId, roomType);
  }, []);

  if (!offers) {
    return null;
  }

  const { fromAgentsPropsCountDisplay, fromAgentsPropsCountUrl, propsCountDisplay, propsCountUrl, fromDeveloperRooms } =
    offers;

  if (fromDeveloperRooms && fromDeveloperRooms.length > 0) {
    return (
      <div data-testid="offers-stats">
        <OffersList>
          {fromDeveloperRooms.map(flatTypeBlock => (
            <OffersRow
              key={flatTypeBlock.roomTypeDisplay}
              {...flatTypeBlock}
              displayHouseName={displayHouseName}
              onRoomTypeLinkClick={trackRoomTypeLinkClick}
              onOfferLinkClick={trackOpenFlat}
              onOffersQuantityLinkClick={onOffersQuantityLinkClick}
            />
          ))}
        </OffersList>
        {KPNRowVisible && <OffersKPNRowContainer title="Подберите квартиру в этом или похожих ЖК" />}
        <OffersFooter
          fromAgentsPropsCountDisplay={fromAgentsPropsCountDisplay}
          fromAgentsPropsCountUrl={fromAgentsPropsCountUrl}
          propsCountDisplay={propsCountDisplay as string}
          propsCountUrl={propsCountUrl as string}
          isHouseSelected={!!selectedHouseId}
          disclaimerText={disclaimerText}
          onAgentOffersLinkClick={trackAgentFlatsLinkClick}
          onAllOffersLinkClick={trackAllFlatsLinkClick}
        />
      </div>
    );
  }

  return (
    <>
      {KPNRowVisible ? (
        <OffersKPNRowContainer
          title={<span>Нет предложений от застройщика. Мы подберём квартиры в&nbsp;похожих&nbsp;ЖК</span>}
          withMarginTop
        />
      ) : (
        <FlatsError
          image="not-found"
          title={getFlatsErrorTitle(!!fromAgentsPropsCountDisplay, !!selectedHouseId)}
          text={selectedHouseId ? 'Выберите другой корпус или уточните информацию у застройщика' : null}
        />
      )}
      {fromAgentsPropsCountDisplay && (
        <AgentsOffersRow
          fromAgentsPropsCountDisplay={fromAgentsPropsCountDisplay}
          fromAgentsPropsCountUrl={fromAgentsPropsCountUrl as string}
        />
      )}
    </>
  );
}
