import * as React from 'react';

import { CarouselOverlayPanel } from '../../components/CarouselOverlayPanel';
import { DoubleColumnLayout } from '../../components/DoubleColumnLayout';
import { FixedHeader } from '../../components/FixedHeader';
import { GenplanNavContainer } from '../../components/Genplan/components/GenplanNavContainer';
import { PageLayout } from '../../components/PageLayout';
import { PageRowLayout } from '../../components/PageRowLayout';
import { useApplicationContext } from '../../utils/applicationContext';
import { AboutContainer } from '../About';
import { ActionButtonContainer } from '../ActionButton';
import { AdvantagesContainer } from '../Advantages';
import { AerialViewContainer } from '../AerialView';
import { BasicInfoContainer } from '../BasicInfo';
import { BuilderCallReasonsContainer } from '../BuilderCallReasons';
import { BreadCrumbsContainer } from '../BreadCrumbs';
import { BuildersInfoContainer } from '../BuildersInfo';
import { CarouselContainer } from '../Carousel';
import { CompanySeoCard } from '../CompanySeoCard';
import { DocumentationContainer } from '../Documentation';
import { EscrowContainer } from '../Escrow';
import { FeaturesContainer } from '../Features';
import { FlatViewOrderTooltipContainer } from '../FlatViewOrderTooltip';
import { ImageSources } from '../ImageSources';
import { InteriorDecorationContainer } from '../InteriorDecoration';
import { LabelsContainer } from '../Labels';
import { LastHopePopupContainer } from '../LastHopePopup';
import { MortgageSectionContainer } from '../MortgageSection';
import { InfrastructureContainer } from '../InfrastructureContainer';
import { NewbuildingChatContainer } from '../NewbuildingChat';
import { NewbuildingFromTheBuilderContainer } from '../NewbuildingFromTheBuilder';
import { NewbuildingReviewsTeaserContainer } from '../NewbuildingReviewsTeaser';
import { OffersBlockContainer } from '../OffersBlock';
import { PromoDetailsContainer } from '../PromoDetails';
import { PromosSectionContainer } from '../PromosSection';
import { RealtyValuationContainer } from '../RealtyValuation';
import { RecommendedNewbuildingsContainer } from '../RecommendedNewbuildings';
import { ReliableBlockContainer } from '../ReliableBlock';
import { RightColumnContainer } from '../RightColumn';
import { SpecificationsDetailedContainer } from '../SpecificationsDetailed';
import { FloorPlanLoadable } from '../FloorPlan/FloorPlanLoadable';
import { GenplanLoadable } from '../Genplan/GenplanLoadable';
import { OffersStatsContainer } from '../OffersStats';
import { ReliabilityV2Container } from '../ReliabilityV2';
import { BrokerBannerContainer } from '../BrokerBanner';
import { SeoMarkedListContainer } from '../SeoMarkedList';

/**
 * Страница для ЖК на CPL и ЖК "Скоро в продаже".
 */
export const StandardPage: React.FC = () => {
  const { config } = useApplicationContext();

  return (
    <>
      <PageLayout>
        <FixedHeader
          actionButton={
            <ActionButtonContainer
              dynamicCalltrackingPlaceType="blockStickyTop"
              analyticOnOpenActionName="Open_jk_card_blockStickytop"
            />
          }
        />
        <BreadCrumbsContainer />
        <CarouselOverlayPanel />
        <CarouselContainer />
        <FlatViewOrderTooltipContainer />

        <PageRowLayout>
          <DoubleColumnLayout
            rightColumn={<RightColumnContainer dynamicCalltrackingPlaceType="blockStickyRightTop" />}
            topContent
          >
            <LabelsContainer />
            <BasicInfoContainer />
            <BrokerBannerContainer />
            <BuilderCallReasonsContainer />
            <GenplanNavContainer>
              <GenplanLoadable />
            </GenplanNavContainer>
            <FloorPlanLoadable />
            <AerialViewContainer />
            <OffersBlockContainer />
            <PromosSectionContainer />
            <MortgageSectionContainer />
            <RealtyValuationContainer />
            <AboutContainer />
            <AdvantagesContainer />
            <NewbuildingReviewsTeaserContainer />
          </DoubleColumnLayout>

          <InfrastructureContainer />

          <DoubleColumnLayout
            rightColumn={<RightColumnContainer dynamicCalltrackingPlaceType="blockStickyRightBottom" />}
          >
            <SpecificationsDetailedContainer />
            <InteriorDecorationContainer />
            <BuildersInfoContainer />
            <NewbuildingFromTheBuilderContainer />
            <FeaturesContainer />
            <ReliableBlockContainer />
            <ReliabilityV2Container />
            <EscrowContainer />
            <OffersStatsContainer />
            <DocumentationContainer />
            <RecommendedNewbuildingsContainer />
            <ImageSources />
            <SeoMarkedListContainer />
          </DoubleColumnLayout>
        </PageRowLayout>
      </PageLayout>

      <NewbuildingChatContainer config={config} />
      <LastHopePopupContainer />
      <PromoDetailsContainer />
      <CompanySeoCard />
    </>
  );
};
