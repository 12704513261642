import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { useSelector } from 'react-redux';

import { RecommendationsList } from '../../../components/RecommendedNewbuildings';
import { getNewbuildingId } from '../../../selectors/newbuilding';

import { selectModelVersion } from '../../../selectors/newbuildingRecommendations';
import { IRecommendationNewbuilding } from '../../../types/recommendations';
import { trackClickSimilarNewbuilding, trackShowSimilarNewbuilding } from '../tracking';

interface IRecommendationsListContainerProps {
  newbuildings: IRecommendationNewbuilding[];
}

export const RecommendationsListContainer: React.VFC<IRecommendationsListContainerProps> = ({ newbuildings }) => {
  const newbuildingId = useSelector(getNewbuildingId);
  const modelVersion = useSelector(selectModelVersion);

  const handleRecommendationsShow = React.useCallback(() => {
    trackShowSimilarNewbuilding({
      newbuildingId,
      modelVersion,
      recommendations: newbuildings,
    });
  }, [newbuildingId, modelVersion, newbuildings]);

  const handleRecommendationClick = React.useCallback(
    (index: number) => {
      trackClickSimilarNewbuilding({
        index,
        newbuildingId,
        modelVersion,
        recommendation: newbuildings[index],
      });
    },
    [newbuildingId, modelVersion, newbuildings],
  );

  return (
    <ActionAfterViewObserver callback={handleRecommendationsShow} triggerOnce>
      <RecommendationsList newbuildings={newbuildings} onItemClick={handleRecommendationClick} />
    </ActionAfterViewObserver>
  );
};
