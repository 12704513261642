import * as React from 'react';
import * as style from './InfoItem.css';
import { Link } from '../../../Link';
import { InlineText1, InlineText2 } from '@cian/ui-kit';

interface IInfoItemProps {
  url?: string | null;
  text?: string | null;
  label: string;
}

export const InfoItem = ({ url, text, label }: IInfoItemProps) => {
  if (!text) {
    return null;
  }

  return (
    <div className={style['item']}>
      <InlineText2 color={'gray60_100'}>{label}</InlineText2>
      {url ? (
        <Link url={url} target={'blank'} underline>
          <InlineText1 fontWeight={'bold'}>{text}</InlineText1>
        </Link>
      ) : (
        <InlineText1 fontWeight={'bold'}>{text}</InlineText1>
      )}
    </div>
  );
};

InfoItem.displayName = 'InfoItem';
