import { ca } from '@cian/analytics';

export function trackViewMorePromosClick() {
  ca('eventSite', {
    action: 'click_view_more',
    category: 'Promo',
    name: 'oldevent',
    label: 'more',
    useLastProducts: true,
  });
}
