import * as React from 'react';
import * as style from './ProblemLabel.css';
import { IconWarning24, UIHeading4, UIText1 } from '@cian/ui-kit';
import { IReliabilityBanner } from '../../types/reliability/banner';

interface IProblemLabelProps {
  reliabilityBanner: IReliabilityBanner;
  onClick(event: React.MouseEvent<HTMLElement>): void;
}

export const ProblemBanner = ({ reliabilityBanner, onClick }: IProblemLabelProps) => {
  return (
    <div className={style['container']} onClick={onClick} data-testid="ProblemBanner">
      <div className={style['logo-block']}>
        <IconWarning24 color="error_100" />
      </div>
      <div className={style['info-block']}>
        <UIHeading4 as="h4">{reliabilityBanner.title}</UIHeading4>
        <UIText1>{reliabilityBanner.label}</UIText1>
      </div>
    </div>
  );
};
