import * as React from 'react';
import { ArticleHeading1, ArticleParagraph1 } from '@cian/ui-kit';
import * as style from './EmptyReviewsTeaser.css';
import { ReviewsFooter } from '../ManyReviewsTeaser/ReviewsFooter';

export interface IEmptyReviewsTeaserProps {
  name: string;
  fullUrl: string;
}

export const EmptyReviewsTeaser: React.FC<IEmptyReviewsTeaserProps> = ({ name, fullUrl }) => (
  <div className={style['container']}>
    <ArticleHeading1>
      Ваш отзыв или вопрос о {name} <br /> будет первым
    </ArticleHeading1>
    <ArticleParagraph1>Оцените ЖК — помогите другим с выбором</ArticleParagraph1>
    <ReviewsFooter fullUrl={fullUrl} />
  </div>
);
