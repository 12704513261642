import * as React from 'react';

import { CarouselOverlayPanel } from '../../components/CarouselOverlayPanel';
import { DoubleColumnLayout } from '../../components/DoubleColumnLayout';
import { FixedHeader } from '../../components/FixedHeader';
import { PageLayout } from '../../components/PageLayout';
import { PageRowLayout } from '../../components/PageRowLayout';
import { useApplicationContext } from '../../utils/applicationContext';
import { AboutContainer } from '../About';
import { AdvantagesContainer } from '../Advantages';
import { AerialViewContainer } from '../AerialView';
import { BasicInfoContainer } from '../BasicInfo';
import { BreadCrumbsContainer } from '../BreadCrumbs';
import { BuildersInfoContainer } from '../BuildersInfo';
import { CarouselContainer } from '../Carousel';
import { CompanySeoCard } from '../CompanySeoCard';
import { DocumentationContainer } from '../Documentation';
import { EscrowContainer } from '../Escrow';
import { FeaturesContainer } from '../Features';
import { ImageSources } from '../ImageSources';
import { InfrastructureContainer } from '../InfrastructureContainer';
import { InteriorDecorationContainer } from '../InteriorDecoration';
import { LabelsContainer } from '../Labels';
import { LeadFactoryCallButtonContainer } from '../LeadFactoryCallButton';
import { NewbuildingChatContainer } from '../NewbuildingChat';
import { NewbuildingReviewsTeaserContainer } from '../NewbuildingReviewsTeaser';
import { OffersBlockContainer } from '../OffersBlock';
import { RealtyValuationContainer } from '../RealtyValuation';
import { RecommendedNewbuildingsContainer } from '../RecommendedNewbuildings';
import { ReliableBlockContainer } from '../ReliableBlock';
import { RightColumnContainer } from '../RightColumn';
import { SpecificationsDetailedContainer } from '../SpecificationsDetailed';
import { OffersStatsContainer } from '../OffersStats';
import { ReliabilityV2Container } from '../ReliabilityV2';
import { LeadFactoryPromosBanner } from '../LeadFactoryPromosBanner';
import { BrokerBannerContainer } from '../BrokerBanner';
import { SeoMarkedListContainer } from '../SeoMarkedList';

/**
 * Страница для ЖК на КпН.
 */
export const LeadFactoryPage: React.FC = () => {
  const { config } = useApplicationContext();

  return (
    <>
      <PageLayout>
        <FixedHeader actionButton={<LeadFactoryCallButtonContainer placeType="blockStickyTop" size="XS" />} />
        <BreadCrumbsContainer />
        <CarouselOverlayPanel />
        <CarouselContainer />

        <PageRowLayout>
          <DoubleColumnLayout
            rightColumn={<RightColumnContainer dynamicCalltrackingPlaceType="blockStickyRightTop" />}
            topContent
          >
            <LabelsContainer />
            <BasicInfoContainer />
            <BrokerBannerContainer />
            <OffersBlockContainer />
            <LeadFactoryPromosBanner />
            <RecommendedNewbuildingsContainer />
            <AerialViewContainer />
            <RealtyValuationContainer />
            <AboutContainer />
            <AdvantagesContainer />
            <NewbuildingReviewsTeaserContainer />
          </DoubleColumnLayout>

          <InfrastructureContainer />

          <DoubleColumnLayout
            rightColumn={<RightColumnContainer dynamicCalltrackingPlaceType="blockStickyRightBottom" />}
          >
            <SpecificationsDetailedContainer />
            <InteriorDecorationContainer />
            <BuildersInfoContainer />
            <FeaturesContainer />
            <ReliableBlockContainer />
            <ReliabilityV2Container />
            <EscrowContainer />
            <OffersStatsContainer />
            <DocumentationContainer />
            <ImageSources />
            <SeoMarkedListContainer />
          </DoubleColumnLayout>
        </PageRowLayout>
      </PageLayout>

      <NewbuildingChatContainer config={config} />
      <CompanySeoCard />
    </>
  );
};
