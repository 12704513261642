import * as React from 'react';
import * as styles from './HousesByTurn.css';
import { ITurnInfo } from '../../../../types/newbuilding/turn';

interface IHousesByTurnProps {
  housesByTurnDisplay: ITurnInfo[];
}

export const HousesByTurn: React.FC<IHousesByTurnProps> = props => {
  const { housesByTurnDisplay } = props;

  return (
    <>
      {housesByTurnDisplay.map(turn => (
        <div className={styles['item']} key={`${turn.title}`}>
          <div className={styles['title']}>{turn.title}</div>
          {turn.finishDates &&
            turn.finishDates.map(date => (
              <div className={styles['finish-date']} key={`${date}`}>
                {date}
              </div>
            ))}
        </div>
      ))}
    </>
  );
};
