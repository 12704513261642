import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { TUser } from '../../types/user';

export enum EGetUserStatus {
  Initial = 'USER/INITIAL',
  Loading = 'USER/LOADING',
  Succeed = 'USER/SUCCEED',
  Failed = 'USER/FAILED',
}

export enum EUserActionTypes {
  SetUser = 'user/set',
}

export type TGetUserLoading = ITypedReduxAction<EGetUserStatus.Loading>;
export type TGetUserSucceed = ITypedReduxAction<EGetUserStatus.Succeed, TUser>;
export type TGetUserFailed = ITypedReduxAction<EGetUserStatus.Failed>;

export type TUserActions = TGetUserLoading | TGetUserSucceed | TGetUserFailed;

export type TSetUser = ITypedReduxAction<EUserActionTypes.SetUser, TUser>;

/**
 * Redux action, который записывает в store фичи
 * @param features IConfigFeatures
 */
export const setUser: (user: TUser) => TSetUser = actionGenerator<EUserActionTypes.SetUser, TUser>(
  EUserActionTypes.SetUser,
);
