import { ca } from '@cian/analytics';
import { TThunkAction } from '../../types/redux/thunk';

export function trackOpenInfoAboutJK(): TThunkAction {
  return async () => {
    ca('eventSite', {
      name: 'oldevent',
      category: 'Card_JK',
      action: 'Click',
      label: 'open_info_about_JK',
      useLastProducts: true,
    });
  };
}
