import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EFlatViewOrderActionTypes {
  ToggleBanner = 'flatViewOrder/toggleBanner',
}

export type TToggleFlatViewOrderBanner = ITypedReduxAction<EFlatViewOrderActionTypes.ToggleBanner, boolean>;

export const toggleFlatViewOrderBanner = actionGenerator<EFlatViewOrderActionTypes.ToggleBanner, boolean>(
  EFlatViewOrderActionTypes.ToggleBanner,
);
