import * as React from 'react';
import * as styles from './MapWithPin.css';
import { Image, TImageObjectFit } from '../Image';
import { PinIcon } from './parts/PinIcon';
import { useApplicationContext } from '../../utils/applicationContext';
import { useMapPreviewUrl } from './hooks';

export interface IMapProps {
  alt?: string;
  src: string;
  fit?: TImageObjectFit;
}

export const MapWithPin = (props: IMapProps) => {
  const { telemetry } = useApplicationContext();
  const { alt, fit } = props;

  const mapPreviewUrl = useMapPreviewUrl();

  /* istanbul ignore next */
  React.useEffect(() => {
    telemetry.increment('static-maps.request');
  }, [telemetry]);

  /* istanbul ignore next */
  const handleError = React.useCallback(() => {
    telemetry.increment('static-maps.error');
  }, [telemetry]);

  /* istanbul ignore next */
  if (!mapPreviewUrl) {
    return null;
  }

  return (
    <div className={styles['map']}>
      <div className={styles['map-pin']}>
        <PinIcon />
      </div>
      <Image fit={fit} alt={alt} src={mapPreviewUrl} onError={handleError} />
    </div>
  );
};
