/* eslint-disable  @typescript-eslint/no-explicit-any */
import { IEvent, IConsumer, createConsumer, createSubscriber, getGlobalEventsLogs } from '@cian/events-log';
// eslint-disable-next-line import/no-restricted-paths
import { getUserAction } from '../../../browser/services/user';
import { TReduxActions } from '../../types/redux';
import { TThunkAction } from '../../types/redux/thunk';

export enum EAlertType {
  Favorites = 'favorites',
  Chats = 'chats',
  Hide = 'hide',
}

export interface IRequestAuthenticationParameters {
  alert?: EAlertType;
  onSuccess?(): TThunkAction | TReduxActions;
  onCloseNoAuth?(): TThunkAction | TReduxActions;
}

interface IAuthenticateEvent {
  autoRefresh?: boolean;
}

const TOPIC_USER = 'user';

type TConsumerSingleton = (() => IConsumer) & { _instance?: IConsumer };

const getUserConsumer: TConsumerSingleton = (): IConsumer => {
  if (!getUserConsumer._instance) {
    getUserConsumer._instance = createConsumer({ topic: TOPIC_USER });
  }

  return getUserConsumer._instance;
};

export function requestAuthentication(parameters?: IRequestAuthenticationParameters): TThunkAction {
  return async dispatch => {
    /* istanbul ignore else */
    if (typeof window !== 'undefined') {
      const globalLog = getGlobalEventsLogs();
      const consumer = getUserConsumer();

      const userSubscriber = createSubscriber(async (event: IEvent<unknown>) => {
        if (event.type === 'authenticated') {
          await Promise.all([dispatch(getUserAction())]);

          /* istanbul ignore else */
          if ((window as any).__reloadHeader__) {
            (window as any).__reloadHeader__();
          }

          /* istanbul ignore else */
          if (parameters && parameters.onSuccess) {
            dispatch(parameters.onSuccess());
          }

          consumer.unsubscribe(userSubscriber);

          return;
        }

        if (event.type === 'authentication_modal_closed') {
          /* istanbul ignore else */
          if (parameters && parameters.onCloseNoAuth) {
            dispatch(parameters.onCloseNoAuth());
          }

          consumer.unsubscribe(userSubscriber);
        }
      });

      consumer.subscribe(userSubscriber);

      globalLog.produce<Required<IAuthenticateEvent>>(TOPIC_USER, {
        type: 'authenticate',
        value: { autoRefresh: false },
      });
    }
  };
}
