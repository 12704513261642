import * as React from 'react';
import classNames from 'clsx';
import { TDirection } from '@cian/frontend-newbuilding-carousel-component';
import { IconChevronLeft16, IconChevronRight16 } from '@cian/ui-kit';

import * as styles from './styles.css';

export interface IVerticalArrowProps {
  direction: TDirection;
  offset?: number;
  containerClass?: string;
  onClick?(): void;
}

const DEFAULT_OFFSET = -20;

export function VerticalArrow({ direction, offset = DEFAULT_OFFSET, containerClass, onClick }: IVerticalArrowProps) {
  const ArrowIcon = direction < 0 ? IconChevronLeft16 : IconChevronRight16;
  const style = direction < 0 ? { left: `${offset}px` } : { right: `${offset}px` };

  return (
    <div
      className={classNames(styles['arrow'], containerClass)}
      style={style}
      onClick={onClick}
      data-testid={`VerticalArrow-${direction}`}
    >
      <ArrowIcon color={'gray_icons_100'} />
    </div>
  );
}
