import { ca } from '@cian/analytics';

export const trackDocumentClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'download_doc',
    label: '',
    useLastProducts: true,
  });
};
