import { isStorageEnable } from '../../sessionStorage';

export function existNewbuildingIdInStorage(key: string, value: number): boolean {
  if (!value || !isStorageEnable()) {
    return false;
  }

  try {
    const item = window.sessionStorage.getItem(key) || '';
    const json: number[] | void = JSON.parse(item);

    return Array.isArray(json) && json.indexOf(value) >= 0;
  } catch (err) {
    window.sessionStorage.removeItem(key);

    return false;
  }
}
