import * as React from 'react';
import classNames from 'clsx';
import * as styles from './styles.css';

export interface ILinkButtonProps {
  children: React.ReactNode;
  className?: string;
  onClick(e: React.MouseEvent<HTMLElement>): void;
}

export function ButtonLink({ children, onClick, className }: ILinkButtonProps) {
  return (
    <button className={classNames(styles['link'], className)} onClick={onClick}>
      {children}
    </button>
  );
}
