import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectBlackFridayPromoDatesText } from '../../../selectors/blackFriday';
import { IPromoInfoSchema } from '../../../types/promo';

interface IPromoSubtitleProps {
  promo: IPromoInfoSchema;
}

export const PromoSubtitle: React.FC<IPromoSubtitleProps> = ({ promo }) => {
  const { isBlackFriday, subtitle } = promo;

  const promoDatesText = useSelector(selectBlackFridayPromoDatesText);

  if (isBlackFriday) {
    return <UIText2>{promoDatesText}</UIText2>;
  }

  if (subtitle) {
    return <UIText2 color="gray60_100">{promo.subtitle}</UIText2>;
  }

  return null;
};
