import { connect } from 'react-redux';
import { Features, IFeaturesProps } from '../../components/Features';
import { getHasProgressOfConstruction } from '../../selectors/newbuilding';
import { IApplicationState } from '../../types/redux';
import { TThunkDispatch } from '../../types/redux/thunk';
import { trackOpenProgressOfConstruction } from '../../actions/analytics';

type TFeaturesDispatchProps = Pick<IFeaturesProps, 'trackOpenProgressOfConstruction'>;

type TFeaturesStateProps = Pick<IFeaturesProps, 'hasProgressOfConstruction' | 'path'>;

export function mapStateToProps(state: IApplicationState): TFeaturesStateProps {
  const { isNewbuildingSubdomain } = state.common;

  return {
    hasProgressOfConstruction: getHasProgressOfConstruction(state),
    path: isNewbuildingSubdomain ? '/' : state.path,
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TFeaturesDispatchProps {
  return {
    trackOpenProgressOfConstruction: () => {
      return dispatch(trackOpenProgressOfConstruction());
    },
  };
}

export const FeaturesContainer = connect(mapStateToProps, mapDispatchToProps)(Features);
