import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FlatViewOrderSinglePageDesktop, ICallbackParams } from '@cian/newbuilding-flat-view-order-component';

import { FlatViewOrderButton } from '../../components/FlatViewOrderButton';
// eslint-disable-next-line import/no-restricted-paths
import { addFlatTourBooking } from '../../../browser/services/flatTourBooking/addFlatTourBooking';
import { getIsFlatViewOrderEnabled, getNewbuildingDisplayName } from '../../selectors/newbuilding';
import { getIsFlatTourAvailable, getFlatTourSchedule } from '../../selectors/flatTourBooking';
import { TThunkDispatch } from '../../types/redux/thunk';
import { trackFlatViewOrderPopupOpen, trackFlatViewOrderSuccess } from './tracking';

export function FlatViewOrderButtonContainer() {
  const [isPopupOpened, setPopupVisibility] = React.useState(false);

  const dispatch: TThunkDispatch = useDispatch();
  const isEnabled = useSelector(getIsFlatViewOrderEnabled);
  const isTourAvailable = useSelector(getIsFlatTourAvailable);
  const newbuildingName = useSelector(getNewbuildingDisplayName);
  const schedule = useSelector(getFlatTourSchedule);

  const handleOpenModal = React.useCallback(() => {
    trackFlatViewOrderPopupOpen();
    setPopupVisibility(true);
  }, [setPopupVisibility]);

  const handleCloseModal = React.useCallback(() => {
    setPopupVisibility(false);
  }, [setPopupVisibility]);

  const handleSubmit = React.useCallback(
    async (params: ICallbackParams) => {
      try {
        const bookingParams = {
          fullName: params.name,
          phone: params.phone,
          tourTime: `${params.date} ${params.time}`,
        };

        await dispatch(addFlatTourBooking(bookingParams));

        return true;
      } catch {
        return false;
      }
    },
    [dispatch],
  );

  const handleSuccessSubmit = React.useCallback((params: ICallbackParams) => {
    trackFlatViewOrderSuccess(params);
  }, []);

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <FlatViewOrderButton onClick={handleOpenModal} disabled={!isTourAvailable} />
      {schedule && (
        <FlatViewOrderSinglePageDesktop
          isOpen={isPopupOpened}
          onClose={handleCloseModal}
          onSuccess={handleSuccessSubmit}
          onSubmit={handleSubmit}
          newbuildingName={newbuildingName}
          nextDate={schedule.nextDate}
          nextNextDate={schedule.nextNextDate}
          availableDates={schedule.availableDates}
          disabledDays={schedule.disabledDays}
          minDate={schedule.minDate}
          maxDate={schedule.maxDate}
        />
      )}
    </>
  );
}
