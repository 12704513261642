import * as React from 'react';
import { UIText3 } from '@cian/ui-kit';

import * as styles from './styles.css';

interface IGetOnlineButtonProps {
  href: string;
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void;
}

export const GetOnlineButton: React.FC<IGetOnlineButtonProps> = ({ href, onClick }) => {
  return (
    <a
      href={href}
      onClick={onClick}
      target="_blank"
      rel="noreferrer"
      className={styles['container']}
      data-testid="GetOnlineButton"
    >
      <UIText3>Получить онлайн</UIText3>
    </a>
  );
};
