import * as React from 'react';
import { useSelector } from 'react-redux';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { Tabs } from '@cian/ui-kit/prev';

import { trackMortgageOffersTabClick, trackPromoOffersShow } from '../../analytics/promos';
import { LayoutBlock } from '../../components/LayoutBlock';
import { MortgageSection } from '../../components/MortgageSection';
import { IPromoInfoSchema } from '../../types/promo';
import { getHasBanks, getNewbuildingId } from '../../selectors/newbuilding';
import { selectHasMortgagePromos } from '../../selectors/newbuildingMortgageCalculator';
import { EMortgageOffersTabs } from '../../types/mortgageOffers';
import { AccreditedBanksContainer } from '../AccreditedBanks';
import { MortgageOffersContainer } from '../MortgageOffers';

import { getInitialTab, getSectionTabs, getSectionTitle } from './helpers';

export const MortgageSectionContainer: React.FC = () => {
  const hasMortgageOffers = useSelector(selectHasMortgagePromos);
  const hasBanks = useSelector(getHasBanks);
  const newbuildingId = useSelector(getNewbuildingId);

  const { tabs, title } = React.useMemo(
    () => ({
      tabs: getSectionTabs({ hasBanks, hasMortgageOffers }),
      title: getSectionTitle({ hasBanks, hasMortgageOffers }),
    }),
    [hasBanks, hasMortgageOffers],
  );

  const [activeTab, setActiveTab] = React.useState(getInitialTab(tabs));

  const displayedOffersRef = React.useRef<IPromoInfoSchema[]>([]);

  const handleDisplayedOffersChange = React.useCallback((displayedOffers: IPromoInfoSchema[]) => {
    displayedOffersRef.current = displayedOffers;
  }, []);

  const handleShow = React.useCallback(() => {
    trackPromoOffersShow(displayedOffersRef.current, newbuildingId, true);
  }, [newbuildingId]);

  const handleTabChange = React.useCallback((tab: EMortgageOffersTabs) => {
    setActiveTab(tab);
    trackMortgageOffersTabClick(tab);
  }, []);

  const hasTabs = tabs.length > 0;
  const isTabsNodeVisible = tabs.length > 1;

  if (!hasTabs) {
    return null;
  }

  return (
    <LayoutBlock>
      <ActionAfterViewObserver callback={handleShow} triggerOnce>
        <MortgageSection
          title={title}
          tabs={isTabsNodeVisible && <Tabs activeTab={activeTab} onChange={handleTabChange} tabs={tabs} />}
        >
          {activeTab === EMortgageOffersTabs.Mortgage && (
            <MortgageOffersContainer onDisplayedOffersChange={handleDisplayedOffersChange} />
          )}
          {activeTab === EMortgageOffersTabs.Banks && <AccreditedBanksContainer />}
        </MortgageSection>
      </ActionAfterViewObserver>
    </LayoutBlock>
  );
};
