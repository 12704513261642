import { IApplicationState } from '../../types/redux';
import { ITurnInfo } from '../../types/newbuilding/turn';

export function getHousesByTurnDisplay(state: IApplicationState): ITurnInfo[] {
  const {
    newbuilding: { housesByTurnDisplay },
  } = state;

  if (housesByTurnDisplay && housesByTurnDisplay.length) {
    return housesByTurnDisplay;
  }

  return [];
}
