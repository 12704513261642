import { IApplicationState } from '../../types/redux';

export function getSpecialPromo(state: IApplicationState) {
  const {
    newbuilding: { specialPromo },
  } = state;

  if (specialPromo) {
    return specialPromo;
  }

  return null;
}
