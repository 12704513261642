import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import { trackMortgageBannerClick, trackMortgageBannerShow } from '../../analytics/promos';
import { CianMortgageBanner } from '../../components/CianMortageBanner';
import { useApplicationContext } from '../../utils/applicationContext';
import { getCianMortgageBannerUrl } from '../../utils/config';
import { formatPayment } from './helpers';

export interface ICianMortgageBannerContainerProps {
  monthlyPayment: number;
}

export const CianMortgageBannerContainer: React.FC<ICianMortgageBannerContainerProps> = ({ monthlyPayment }) => {
  const { config } = useApplicationContext();
  const bannerUrl = getCianMortgageBannerUrl(config);

  return (
    <ActionAfterViewObserver callback={trackMortgageBannerShow} triggerOnce>
      <CianMortgageBanner
        onClick={trackMortgageBannerClick}
        monthlyPayment={formatPayment(monthlyPayment)}
        url={bannerUrl}
      />
    </ActionAfterViewObserver>
  );
};
