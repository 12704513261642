import { mergeStyles } from '@cian/utils';
import * as React from 'react';

import * as style from './PageRowLayout.css';

export interface IPageRowLayoutProps extends React.PropsWithChildren {
  fullwidth?: boolean;
  dataMark?: string;
}

export const PageRowLayout: React.FC<IPageRowLayoutProps> = props => {
  const { fullwidth = false, dataMark = 'Layout' } = props;

  return (
    <section data-mark={dataMark} {...mergeStyles(fullwidth ? style['layout--fullwidth'] : style['layout'])}>
      {props.children}
    </section>
  );
};
