import * as React from 'react';

export const DiscountIcon = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={56} height={56} viewBox="0 0 56 56">
    <path fill="#FF1F34" d="M37.4 42.5a5 5 0 100-10.2 5 5 0 000 10.2z" />
    <path
      fill="url(#discount__paint0_angular)"
      d="M42.5 37.4a5 5 0 11-10.2 0 5 5 0 0110.2 0zm-9.5 0a4.4 4.4 0 108.8 0 4.4 4.4 0 00-8.8 0z"
    />
    <path fill="#FF1F34" d="M37.5 14.4L14.4 37.5l4 4.1 23.2-23.1-4-4.1z" />
    <path fill="url(#discount__paint1_linear)" d="M37.5 14.4L14.4 37.5l2 2.2 23.3-23.2-2.2-2.1z" />
    <path fill="#FF1F34" d="M18.6 23.7a5 5 0 100-10.2 5 5 0 000 10.2z" />
    <defs>
      <radialGradient
        id="discount__paint0_angular"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="rotate(90 0 37.4) scale(5.08405)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1} stopColor="#FF2035" />
        <stop offset={0.4} stopColor="#fff" />
        <stop offset={0.6} stopColor="#FF1F34" />
      </radialGradient>
      <linearGradient
        id="discount__paint1_linear"
        x1={26.1}
        x2={27.8}
        y1={26.1}
        y2={27.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" stopOpacity={0.5} />
        <stop offset={0.2} stopColor="#fff" stopOpacity={0.8} />
        <stop offset={0.4} stopColor="#fff" stopOpacity={0.7} />
        <stop offset={0.8} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

DiscountIcon.displayName = 'DiscountIcon';
