import * as React from 'react';
import { UIText1 } from '@cian/ui-kit/typography';
import * as style from './style.css';
import { Link } from '../Link';

interface ISeoMortgageLinkProps {
  url: string;
  onClick(): void;
  title: string;
  testId: string;
}

export const SeoMortgageLink = ({ url, onClick, title, testId }: ISeoMortgageLinkProps) => {
  return (
    <div className={style['container']} data-testid={testId}>
      <div className={style['link']}>
        <UIText1>
          <Link url={url} onClick={onClick} target="blank">
            {title}
          </Link>
        </UIText1>
      </div>
    </div>
  );
};
