import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { useSelector } from 'react-redux';
import { BrokerBanner } from '../../components/BrokerBanner';
import {
  selectIsBrokerAvailable,
  selectIsPartnerRewardsRegistrationStatusAvailable,
} from '../../selectors/newbuildingBroker';
import { LayoutBlock } from '../../components/LayoutBlock';
import { useBrokerLinks } from '../../hooks/broker';
import { trackBannerClick, trackBannerShown } from './tracking';
import { getAnalyticsUser } from '../../selectors/analytics';

export function BrokerBannerContainer() {
  const { landingPageLink } = useBrokerLinks();
  const user = useSelector(getAnalyticsUser);
  const isRegistrationStatusAvailable = useSelector(selectIsPartnerRewardsRegistrationStatusAvailable);
  const isBrokerAvailable = useSelector(selectIsBrokerAvailable);
  const isBrokerBannerVisible = isRegistrationStatusAvailable || isBrokerAvailable;

  const handleButtonClick = React.useCallback(() => {
    trackBannerClick(user);
    window.open(landingPageLink, '_blank', 'noopener');
  }, [landingPageLink, user]);

  const handleBannerShown = React.useCallback(() => trackBannerShown(user), [user]);

  if (!isBrokerBannerVisible) {
    return null;
  }

  return (
    <LayoutBlock>
      <ActionAfterViewObserver callback={handleBannerShown} triggerOnce>
        <BrokerBanner onClick={handleButtonClick} />
      </ActionAfterViewObserver>
    </LayoutBlock>
  );
}
