import * as React from 'react';
import { Microfrontend } from '@cian/mf-react';
import { useBooleanState } from '@cian/react-utils';
import { useNewbuildingCallbackWidget } from './hooks';
import { NewbuildingCallbackWidgetContext } from './NewbuildingCallbackWidgetContext';
import { TOpenWidgetResultHandler } from './types';
import { CallbackFallbackModal } from '../../components/CallbackFallbackModal';
import { IOpenWidgetParams } from '../../types/microfrontends/newbuildingCallbackWidget';

interface INewbuildingCallbackWidgetProviderProps {
  children: React.ReactNode;
}

/**
 * Компонент, обеспечивающий загрузку микрофронта модалок ОЗ на клиенте.
 *
 * 1. Пользователь кликает на кнопку, и вызывается `handleWidgetOpeningStart`.
 * 2. Если пользователь подходящий, то регистрируем манифест, загружаем ассеты
 * 3. Сеттим в React-стейт манифест. Ждем сеттинга с помощью useEffect.
 * 4. По стейту отрисовывается <Microfrontend />
 * 5. Инициализуруется реф на <Microfrontend />, содержащий локальное API.
 * 6. Вызываем на рефе локальное API, и открывается модалка.
 */
export const NewbuildingCallbackWidgetProvider: React.FC<INewbuildingCallbackWidgetProviderProps> = ({ children }) => {
  const paramsRef = React.useRef<IOpenWidgetParams>();
  const { state: isFallbackModalVisible, setTrue: showFallbackModal, setFalse: closeFallbackModal } = useBooleanState();
  const [isLoading, setLoading] = React.useState(false);

  const handleWidgetOpeningFinish = React.useCallback<TOpenWidgetResultHandler>(
    ({ isError }) => {
      if (isError) {
        if (!isFallbackModalVisible) {
          showFallbackModal();
        }
      } else if (isFallbackModalVisible && !isError) {
        closeFallbackModal();
      }
    },
    [closeFallbackModal, isFallbackModalVisible, showFallbackModal],
  );

  const { openWidget, widgetManifest, widgetRef } = useNewbuildingCallbackWidget(handleWidgetOpeningFinish);

  const handleWidgetOpeningStart = React.useCallback(
    async (params: IOpenWidgetParams) => {
      // Сохраняем в реф для работы с теми же параметрами на случай, если произойдет ошибка.
      paramsRef.current = params;

      await openWidget(params);
    },
    [openWidget],
  );

  const handleRetryClick = React.useCallback(async () => {
    setLoading(true);
    await openWidget(paramsRef.current as IOpenWidgetParams);
    setLoading(false);
  }, [openWidget]);

  return (
    <NewbuildingCallbackWidgetContext.Provider value={handleWidgetOpeningStart}>
      {children}
      {widgetManifest && (
        <Microfrontend
          ref={widgetRef}
          name={widgetManifest.microfrontendName}
          image={widgetManifest.imageVersion}
          runtime={widgetManifest.runtimeName}
          instance={widgetManifest.instanceId}
        />
      )}
      {isFallbackModalVisible && (
        <CallbackFallbackModal
          isLoading={isLoading}
          isOpen={isFallbackModalVisible}
          onClose={closeFallbackModal}
          onRetry={handleRetryClick}
        />
      )}
    </NewbuildingCallbackWidgetContext.Provider>
  );
};
