import { TLikeStatus, ILike } from '../types/newbuilding/reviews';

export const getNewReactionCount = (currentState: TLikeStatus) => {
  switch (currentState) {
    case 'disliked':
      return {
        disliked: (like: ILike) => ({ dislikesCount: like.dislikesCount, likesCount: like.likesCount }),
        liked: (like: ILike) => ({
          dislikesCount: Math.max(0, like.dislikesCount - 1),
          likesCount: like.likesCount + 1,
        }),
        unknown: (like: ILike) => ({
          dislikesCount: Math.max(0, like.dislikesCount - 1),
          likesCount: like.likesCount,
        }),
      };

    case 'liked':
      return {
        disliked: (like: ILike) => ({
          dislikesCount: like.dislikesCount + 1,
          likesCount: Math.max(0, like.likesCount - 1),
        }),
        liked: (like: ILike) => ({ dislikesCount: like.dislikesCount, likesCount: like.likesCount }),
        unknown: (like: ILike) => ({
          dislikesCount: like.dislikesCount,
          likesCount: Math.max(0, like.likesCount - 1),
        }),
      };
    default:
      return {
        disliked: (like: ILike) => ({
          dislikesCount: like.dislikesCount + 1,
          likesCount: like.likesCount,
        }),
        liked: (like: ILike) => ({
          dislikesCount: like.dislikesCount,
          likesCount: like.likesCount + 1,
        }),
        unknown: (like: ILike) => ({ dislikesCount: like.dislikesCount, likesCount: like.likesCount }),
      };
  }
};
