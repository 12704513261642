import * as React from 'react';
import { useSelector } from 'react-redux';

import { RecommendationsLink } from '../../../components/RecommendedNewbuildings';
import { selectAllRecommendationsUrl } from '../../../selectors/newbuildingRecommendations';

export const RecommendationsLinkContainer: React.VFC = () => {
  const allRecommendationsUrl = useSelector(selectAllRecommendationsUrl);

  return <RecommendationsLink url={allRecommendationsUrl} />;
};
