import * as React from 'react';
import { throttle } from '@cian/newbuilding-utils';
import { getWindowHeight } from '../../utils/dom/getWindowHeight';
import { STICKY_HEADER_HEIGHT } from '../../constants/elements';

export interface IVisionCheckWrapperProps {
  children: React.ReactNode;
  className?: string;
  makeWhenVisible?(): void;
}

export class VisionCheckWrapper extends React.Component<IVisionCheckWrapperProps> {
  private ref: HTMLElement | null;

  public componentDidMount() {
    window.addEventListener('scroll', this.onScrollHandler, false);
  }

  public componentWillUnmount() {
    this.unsubscribe();
  }

  public onScrollHandler = throttle(300, () => {
    this.checkIsItVisible();
  });

  public unsubscribe = () => {
    window.removeEventListener('scroll', this.onScrollHandler, false);
  };

  public checkIsItVisible = async () => {
    if (!this.ref) {
      return null;
    }

    const elementHeight = this.ref.offsetHeight;
    const elemRect = this.ref.getBoundingClientRect();
    const elemTop = elemRect.top;
    const windowHeight = getWindowHeight();

    if (elemTop > STICKY_HEADER_HEIGHT && elemTop < windowHeight - elementHeight) {
      if (this.props.makeWhenVisible) {
        this.props.makeWhenVisible();
        this.unsubscribe();
      }
    }

    return null;
  };

  public render() {
    const { children, className } = this.props;

    return (
      <div ref={ref => (this.ref = ref)} className={className}>
        {children}
      </div>
    );
  }
}
