import * as React from 'react';
import * as style from './Builder.css';

import { BuilderLogo } from '../../../BuilderLogo';
import { trackTransferToDeveloperCard } from '../../../../analytics/trackTransferToDeveloperCard';
import { Link } from '../../../Link';
import { ArticleHeading2, InlineText2 } from '@cian/ui-kit';
import { InfoItem } from '../InfoItem';
import { INewbuildingBuilder } from '../../../../types/newbuilding/builder';

export interface IBuilderProps {
  builder: INewbuildingBuilder;
  isDevCardRedesignEnabled: boolean;
}

export const Builder = ({ builder, isDevCardRedesignEnabled }: IBuilderProps) => {
  const { name, logoUrl, yearOfFoundation, stats, description, profileUrl, housesBuiltInTime } = builder;

  const housesInTime = React.useMemo(
    () => (isDevCardRedesignEnabled ? housesBuiltInTime : undefined),
    [isDevCardRedesignEnabled, housesBuiltInTime],
  );

  return (
    <div className={style['builder']}>
      <div className={style['builder-card']}>
        <BuilderLogo
          customStyles={style['builder-logo']}
          src={logoUrl}
          name={name}
          website={profileUrl}
          onClick={() => trackTransferToDeveloperCard(profileUrl)}
        />
        <a className={style['builder-link']} href={profileUrl} target="_blank" rel="noreferrer">
          <ArticleHeading2 color="current_color" as="h2">{`Застройщик ${name}`}</ArticleHeading2>
        </a>
        <div className={style['builder-stats']}>
          {yearOfFoundation && <InfoItem url={''} text={`в ${yearOfFoundation} году`} label={'Год основания'} />}
          {stats?.done && <InfoItem url={stats.done.qs} text={stats.done.text} label={'Сдано'} />}
          {stats?.inProgress && (
            <InfoItem url={stats.inProgress?.qs} text={stats.inProgress?.text} label={'Строится'} />
          )}
          {housesInTime && <InfoItem url={''} text={housesInTime} label={'Сдано вовремя'} />}
        </div>
        {description && (
          <div className={style['builder-description']}>
            <InlineText2 color={'gray60_100'}>{description}</InlineText2>
            <Link
              target="blank"
              linkStyle={style['description-moreButton']}
              url={profileUrl}
              onClick={() => trackTransferToDeveloperCard(profileUrl)}
            >
              Подробное описание
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
