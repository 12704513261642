import * as React from 'react';
import classNames from 'clsx';
import { InlineText1, InlineText2 } from '@cian/ui-kit';
import { cropPhone } from '../../utils/businessLogic/phone';
import { DynamicCalltrackingButton, EPageTypes, EPlatformTypes } from '@cian/frontend-dynamic-calltracking-component';
import * as styles from './CallBuilder.css';
import { INewbuildingSeller } from '../../types/newbuilding/seller';
import { INewbuildingBuilder } from '../../types/newbuilding/builder';

export interface ICallBuilderProps {
  fullWidth?: boolean;
  newbuildingId: number;
  isBuilder: boolean;
  isSecondary?: boolean;
  customTitle?: string;
  XXL?: boolean;
  hasPhoneHorizontalPadding?: boolean;
  dynamicCalltrackingPlaceType: string;
  target: INewbuildingBuilder | INewbuildingSeller;

  trackActionOpenContactsCall(builder: INewbuildingBuilder | INewbuildingSeller): void;
}

export interface ICallBuilderState {
  isOpen: boolean;
}

export class CallBuilder extends React.Component<ICallBuilderProps, ICallBuilderState> {
  public constructor(props: ICallBuilderProps) {
    super(props);

    this.state = { isOpen: false };
  }

  public handleClick = async () => {
    if (!this.state.isOpen) {
      this.setState({ isOpen: true });
    }
    this.props.trackActionOpenContactsCall(this.props.target);
  };

  public render(): React.ReactNode {
    const {
      target,
      isBuilder,
      hasPhoneHorizontalPadding = false,
      XXL = false,
      customTitle,
      dynamicCalltrackingPlaceType,
      isSecondary,
      fullWidth,
    } = this.props;
    const { isOpen } = this.state;
    const phone = cropPhone({ phone: target.phone, isFullPhone: true });
    const buttonLabel = customTitle || (isBuilder ? 'Контакты застройщика' : 'Показать контакты');

    if (!phone) {
      return null;
    }

    const ButtonLabel = XXL ? InlineText1 : InlineText2;

    return (
      <DynamicCalltrackingButton
        platformType={EPlatformTypes.WebDesktop}
        pageType={EPageTypes.NewbuildingCard}
        isOpen={isOpen}
        blockId={target.dynamicCalltracking?.siteBlockId}
        className={classNames(
          styles['show-phone'],
          XXL && styles['phone-xxl'],
          !isSecondary && styles['show-phone-primary'],
          fullWidth && styles['fullWidth'],
        )}
        openPhoneClassName={classNames(
          styles['phone'],
          XXL && styles['phone-xxl'],
          hasPhoneHorizontalPadding && styles['phone-padding'],
        )}
        phone={cropPhone({ phone: target.phone, isFullPhone: true })}
        placeType={dynamicCalltrackingPlaceType}
        onClick={this.handleClick}
      >
        <ButtonLabel whiteSpace={'nowrap'} fontWeight={'bold'} color={isSecondary ? 'primary_100' : 'white_100'}>
          {buttonLabel}
        </ButtonLabel>
      </DynamicCalltrackingButton>
    );
  }
}
