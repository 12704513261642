import * as React from 'react';
import * as styles from './Distance.css';
import { IconCar16 } from '@cian/ui-kit';
import { IGeoRoadInfo } from '../../../../../../types/newbuilding/geo';

interface IDistanceProps {
  roads?: IGeoRoadInfo[] | null;
}

export const Distance: React.FC<IDistanceProps> = props => {
  const { roads } = props;

  if (!(roads && roads.length)) {
    return null;
  }

  return (
    <>
      {roads
        .filter(road => road.distance)
        .map(roadItem => (
          <div className={styles['distance']} key={`${roadItem.name}`}>
            <div className={styles['distance-icon']}>
              <IconCar16 color={'gray_icons_100'} />
            </div>
            <span className={styles['distance-text']}>{`${roadItem.name}, ${roadItem.distance} км от МКАД`}</span>
          </div>
        ))}
    </>
  );
};
