import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openPromoInfo } from '../../../actions/promoInfo';
import { getAllPromos } from '../../../selectors/newbuilding';

const UNIQUE_PROMO_ID_QUERY_PARAM = 'uniquePromoId';

export const usePromoDetailsModalTrigger = () => {
  const allPromos = useSelector(getAllPromos);
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    const uniquePromoId = searchParams.get(UNIQUE_PROMO_ID_QUERY_PARAM);

    if (uniquePromoId) {
      const promo = allPromos.find(promo => promo.uniquePromoId === uniquePromoId);

      if (promo) {
        dispatch(openPromoInfo({ promo }));
      }
    }
  }, [allPromos, dispatch]);
};
