export const findLeftElement = (
  childCollection: HTMLCollection,
  leftBorder: number,
  offset: number,
): HTMLElement | null => {
  const childList = Array.from(childCollection);

  const nextIdx = childList.findIndex((child: HTMLElement) => {
    const leftX = child.offsetLeft - offset;

    return leftX >= leftBorder;
  });

  return nextIdx > 0 ? (childList[nextIdx - 1] as HTMLElement) : null;
};
