import * as React from 'react';
import * as style from './Description.css';
import { Shutter } from '../../../Shutter';
import { SectionTitle } from '../../../SectionTitle';
import { InlineText1 } from '@cian/ui-kit';

interface IDescriptionProps {
  description?: string | null;
  newbuildingDisplayName?: string | null;

  trackActionOpenInfoAboutJK(): void;
}

interface IDescriptionState {
  open: boolean;
}

export class Description extends React.PureComponent<IDescriptionProps, IDescriptionState> {
  public constructor(props: IDescriptionProps) {
    super(props);
    this.state = { open: false };
  }

  public timer: number;

  public componentWillUnmount() {
    window.clearTimeout(this.timer);
  }

  private getHTML = (html: string) => {
    return { __html: html };
  };

  public handleChangeTimeout = (open: boolean) => {
    if (open) {
      this.handleChange(open)();
      this.props.trackActionOpenInfoAboutJK();
    } else {
      this.timer = window.setTimeout(this.handleChange(open), 500);
    }
  };

  public handleChange = (open: boolean) => () => {
    this.setState({ open });
  };

  public render() {
    const { description, newbuildingDisplayName } = this.props;

    if (!description) {
      return null;
    }

    const title = `О жилом комплексе${newbuildingDisplayName ? ` ${newbuildingDisplayName}` : ''}`;

    return (
      <div data-mark="Description" className={style['description']}>
        <div className={style['header']}>
          <SectionTitle>{title}</SectionTitle>
        </div>
        <Shutter defaultMaxHeight={88} onChange={this.handleChangeTimeout}>
          <InlineText1>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={style['description-htmlContent']} dangerouslySetInnerHTML={this.getHTML(description)} />
          </InlineText1>
        </Shutter>
      </div>
    );
  }
}
