import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  trackClickPromoBlock,
  trackGetOnlineClick,
  trackDownPaymentFilterChange,
  trackTermFilterChange,
} from '../../analytics/promos';
import {
  HeaderRow,
  MortgageOfferRow,
  MortgageOffersTable,
  RowCountController,
} from '../../components/MortgageOffersTable';
import { FlatsError } from '../../components/FlatsError';
import { MortgageOffersFilters } from '../../components/MortgageOffersFilters';
import { FILTERS } from '../../constants/mortgage';
import { CianMortgageBannerContainer } from '../CianMortgageBanner';
import { openPromoInfo } from '../../actions/promoInfo';
import { getNewbuildingId } from '../../selectors/newbuilding';
import { getOffers } from '../../selectors/offers';
import { selectMortgagePromos } from '../../selectors/newbuildingMortgageCalculator';
import { IPromoInfoSchema } from '../../types/promo';
import { useApplicationContext } from '../../utils/applicationContext';
import { getCianMortgagePromoUrl } from '../../utils/config';
import { calculateMonthlyPayment } from '../../utils/motgageCalculator';
import { CianMortgageTextContainer } from './parts/CianMortgageTextContainer';
import { calculateBannerMonthlyPayment, debounceTracking, filterMortgageOffers } from './utils';
import { SeoMortgageLink } from '../SeoMortgageLink';

interface IMortgageOffersContainerProps {
  onDisplayedOffersChange: (promo: IPromoInfoSchema[]) => void;
}

export const MortgageOffersContainer: React.VFC<IMortgageOffersContainerProps> = ({ onDisplayedOffersChange }) => {
  const { fromDeveloperMinPrice, fromDeveloperMaxPrice } = useSelector(getOffers);
  const newbuildingId = useSelector(getNewbuildingId);

  const initialPrice = fromDeveloperMinPrice || FILTERS.DEFAULT_MIN_PRICE;
  const initialDownPayment = Math.round(initialPrice * FILTERS.DEFAULT_DOWN_PAYMENT_RATE);
  const maxFilterPrice = fromDeveloperMaxPrice || FILTERS.DEFAULT_MAX_PRICE;

  const [price, setPrice] = React.useState(initialPrice);
  const [downPayment, setDownPayment] = React.useState(initialDownPayment);
  const [term, setTerm] = React.useState(FILTERS.DEFAULT_LOAN_TERM);

  const allMortgagePromos = useSelector(selectMortgagePromos);
  const filteredMortgagePromos = React.useMemo(
    () => filterMortgageOffers(allMortgagePromos, price, downPayment, term),
    [allMortgagePromos, price, downPayment, term],
  );

  const bannerMonthlyPayment = calculateBannerMonthlyPayment({
    price,
    downPayment,
    loanTerm: term,
    initialPrice,
    initialDownPayment,
    filteredMortgagePromos,
  });

  const dispatch = useDispatch();

  const { config } = useApplicationContext();
  const mortgagePartnerLink = getCianMortgagePromoUrl(config);

  const trackDownPaymentFilterChangeDebounced = React.useCallback(debounceTracking(trackDownPaymentFilterChange), []);
  const trackTermFilterChangeDebounced = React.useCallback(debounceTracking(trackTermFilterChange), []);

  const handleRowClick = React.useCallback(
    (promo: IPromoInfoSchema) => {
      dispatch(openPromoInfo({ promo }));
      trackClickPromoBlock(promo, newbuildingId);
    },
    [dispatch, newbuildingId],
  );

  const handleGetOnlineClick = React.useCallback(
    (promo: IPromoInfoSchema) => {
      const index = filteredMortgagePromos.findIndex(item => item.uniquePromoId === promo.uniquePromoId);
      trackGetOnlineClick(promo, index + 1, newbuildingId);
    },
    [filteredMortgagePromos, newbuildingId],
  );

  const handleDownPaymentChange = React.useCallback(
    (value: number) => {
      setDownPayment(value);
      trackDownPaymentFilterChangeDebounced();
    },
    [trackDownPaymentFilterChangeDebounced],
  );

  const handleTermChange = React.useCallback(
    (value: number) => {
      setTerm(value);
      trackTermFilterChangeDebounced();
    },
    [trackTermFilterChangeDebounced],
  );

  return (
    <div data-testid="MortgageOffers">
      <MortgageOffersFilters
        price={price}
        downPayment={downPayment}
        term={term}
        maxPrice={maxFilterPrice}
        onPriceChanged={setPrice}
        onDownPaymentChanged={handleDownPaymentChange}
        onTermChanged={handleTermChange}
        onDownPaymentMouseDown={trackDownPaymentFilterChange}
        onTermMouseDown={trackTermFilterChange}
      />
      <MortgageOffersTable>
        <RowCountController
          promos={filteredMortgagePromos}
          onDisplayedOffersChange={onDisplayedOffersChange}
          mortgageSeoLink={<SeoMortgageLink />}
          content={promos => (
            <>
              <HeaderRow />
              {promos.map(promo => (
                <MortgageOfferRow
                  key={promo.uniquePromoId}
                  promoInfo={promo}
                  onClick={handleRowClick}
                  onGetOnlineClick={handleGetOnlineClick}
                  monthlyPayment={calculateMonthlyPayment({
                    initialPayment: downPayment,
                    interestRate: promo?.mortgageParams?.interestRate,
                    loanTerm: term,
                    price,
                  })}
                  mortgagePartnerLink={mortgagePartnerLink}
                />
              ))}
            </>
          )}
          error={
            <FlatsError
              image="not-found"
              title="Нет подходящих предложений от банков и застройщиков"
              text={<CianMortgageTextContainer />}
            />
          }
        />
      </MortgageOffersTable>
      <CianMortgageBannerContainer monthlyPayment={bannerMonthlyPayment} />
    </div>
  );
};
