import { ca } from '@cian/analytics';

export function trackClickPromosSeoLink() {
  ca('eventSite', {
    action: 'click_block',
    category: 'Promo',
    label: 'seo_url',
    name: 'oldevent',
    useLastProducts: true,
  });
}
