import * as React from 'react';
import { IconMetroMsk16, IconMetroSpb16 } from '@cian/ui-kit/icons';
import { isMoscowOrMO, isSpbOrLO } from '../../../../utils/region';
import * as styles from './UndergroundIcon.css';

interface IUndergroundIconProps {
  regionId: number | null;
  lineColor: string;
}

export const UndergroundIcon: React.FC<IUndergroundIconProps> = props => {
  const { regionId, lineColor } = props;

  if (regionId) {
    let Icon;

    if (isMoscowOrMO(regionId)) {
      Icon = IconMetroMsk16;
    }

    if (isSpbOrLO(regionId)) {
      Icon = IconMetroSpb16;
    }

    if (Icon) {
      return (
        <div className={styles['underground-icon']} style={{ color: `#${lineColor}` }}>
          <Icon color={'current_color'} />
        </div>
      );
    }
  }

  return <div className={styles['underground-icon--regions']} style={{ color: `#${lineColor}` }} />;
};
