interface IExtra {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface IGetNewbuildingProductParams {
  newbuildingId: number;
  position?: number;
  extra?: IExtra;
}

interface INewbuildingProduct {
  id: number;
  position: number;
  offerType: 'JK';
  extra?: IExtra;
}

export const getNewbuildingProduct = ({
  extra,
  newbuildingId,
  position,
}: IGetNewbuildingProductParams): INewbuildingProduct => {
  return {
    id: newbuildingId,
    position: position || 1,
    offerType: 'JK',
    extra,
  };
};
