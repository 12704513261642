// Проверяем можно ли показывать баннер
// например, не превышает ли текущее количество показов 2 в сутки
import { getOrCreateHistoryPopupShowedCookie } from './getOrCreateHistoryPopupShowedCookie';

export function isShowingsLimitReached(countLimit: number, hoursLimit: number) {
  const { count, timeStarted } = getOrCreateHistoryPopupShowedCookie();
  const hoursBetween = (Date.now() - timeStarted) / 36e5;

  return count >= countLimit && hoursBetween < hoursLimit;
}
