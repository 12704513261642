import * as React from 'react';
import { UIText2, UIHeading3 } from '@cian/ui-kit';

import * as styles from './styles.css';

interface ISummaryProps {
  label: React.ReactNode;
  testId?: string;
}

export function Summary({ label, testId, children }: React.PropsWithChildren<ISummaryProps>) {
  return (
    <div className={styles['container']} data-testid={testId}>
      <UIText2 color="gray60_100" whiteSpace="nowrap">
        {label}
      </UIText2>
      <UIHeading3>{children}</UIHeading3>
    </div>
  );
}
