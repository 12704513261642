import * as React from 'react';
import { UIHeading4, UIText2 } from '@cian/ui-kit';

import { Label } from './parts/Label';
import * as styles from './styles.css';

interface IMasterReviewProps {
  title: string;
  subtitle: string;
  pros: string;
  cons: string;
}

export const MasterReview = ({ title, subtitle, pros, cons }: IMasterReviewProps) => {
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <UIHeading4>{title}</UIHeading4>
        <UIText2 color="gray60_100">{subtitle}</UIText2>
      </div>

      <div className={styles['content']}>
        <div className={styles['item']}>
          <div className={styles['title']}>
            <Label type="pros" />
            Плюсы
          </div>
          {pros}
        </div>
        <div className={styles['item']}>
          <div className={styles['title']}>
            <Label type="cons" />
            Минусы
          </div>
          {cons}
        </div>
      </div>
    </div>
  );
};
