import * as React from 'react';
import { TOnSlideChangeParams } from '@cian/frontend-fullscreen-carousel-component';
import { EPlayerIconType } from '../VideoPreview/Icons/PlayerIcon';
import { VideoPreview } from '../VideoPreview';
import * as style from './Carousel.css';

import { trackOpenFullscreenGallery } from '../../analytics/fullscreenGallery';
import { NoImage } from './parts/NoImage';
import { NewbuildingCarousel, TDirection } from '@cian/frontend-newbuilding-carousel-component';
import { firstPaintOptions, mediaSettings } from './helpers/carouselSettings';
import { Image } from './parts/Image';
import { MapWithPin } from '../MapWithPin';
import { FullscreenGallery } from '../FullscreenGallery';
import { ArrowControls } from './parts/ArrowControls';
import { Counter } from './parts/Counter';
import { PageRowLayout } from '../PageRowLayout';
import { EMediaType, IMediaItem } from '../../types/newbuilding/media';

export interface IFullscreenSlide {
  node: React.ReactNode;
  thumbnailNode: React.ReactNode;
}

export interface ICarouselProps {
  media: IMediaItem[] | null;
  title?: string;
}

export interface ICarouselState {
  slides: React.ReactNode[] | null;
  clickedSlideIndex: number | null;
  currentSlide: number;
}

export class Carousel extends React.Component<ICarouselProps, ICarouselState> {
  public constructor(props: ICarouselProps) {
    super(props);

    const { media } = this.props;

    this.state = {
      slides: media && this.prepareSlides(media.slice(0, 10)),
      clickedSlideIndex: null,
      currentSlide: 2,
    };
  }

  public componentDidMount() {
    const { media } = this.props;
    this.setState({ slides: media && this.prepareSlides(media) });
  }

  private handleClickSlide = (slideIndex: number) => {
    this.setState({ clickedSlideIndex: slideIndex });
  };

  private handleCloseFullscrennGallery = () => {
    this.setState({ clickedSlideIndex: null });
  };

  private changeCurrentSlide = (params: TOnSlideChangeParams) => {
    this.setState({ currentSlide: params.currentSlide });
  };

  private onChangeFullscreenGallerySlide = (params: TOnSlideChangeParams) => {
    this.setState({ currentSlide: params.currentSlide });
  };

  public prepareSlides = (mediaData: IMediaItem[]): React.ReactNode[] => {
    const slideRenderers = {
      [EMediaType.Image]: (media: IMediaItem) => {
        return <Image src={media.fullUrl} alt={media.alt} />;
      },
      [EMediaType.Video]: (media: IMediaItem) => {
        return <VideoPreview url={media.thumbnailUrl} iconType={EPlayerIconType.Small} />;
      },
      [EMediaType.Map]: (media: IMediaItem) => {
        return <MapWithPin fit="cover" src={media.fullUrl} alt={media.alt} />;
      },
    };

    const slides = mediaData
      .map(media => {
        const renderFn = slideRenderers[media.mediaType];

        if (typeof renderFn === 'function') {
          return renderFn(media);
        }

        return false;
      })
      .filter(Boolean) as React.ReactNode[];

    if (slides.length === 1) {
      slides.unshift(<NoImage />);
    }

    return slides;
  };

  public render() {
    const { slides, clickedSlideIndex, currentSlide } = this.state;
    const { media, title } = this.props;

    if (!slides || !slides.length) {
      return (
        <div className={style['carousel']}>
          <NoImage />
        </div>
      );
    }

    const fullscreenGallerySlideIndex = clickedSlideIndex;
    const isFullscreenGalleryVisible = fullscreenGallerySlideIndex !== null;

    return (
      <>
        <div className={style['carousel']}>
          <NewbuildingCarousel
            media={mediaSettings}
            firstPaintOptions={firstPaintOptions}
            slides={slides}
            onSlideClick={this.handleClickSlide}
            onSlideChange={this.changeCurrentSlide}
            defaultCurrentSlide={currentSlide}
          />
          {slides.length > 2 && (
            <ArrowControls
              showLeft={currentSlide > 2}
              showRight={currentSlide < slides.length}
              clickHandler={this.changeSlide}
            />
          )}
          <PageRowLayout>
            <Counter currentSlide={currentSlide} totalSlides={slides.length} />
          </PageRowLayout>
        </div>

        {isFullscreenGalleryVisible && (
          <FullscreenGallery
            onClose={this.handleCloseFullscrennGallery}
            defaultSlideIndex={fullscreenGallerySlideIndex as number}
            onSlideChange={this.onChangeFullscreenGallerySlide}
            title={title}
            images={media as IMediaItem[]}
            onOpen={trackOpenFullscreenGallery}
          />
        )}
      </>
    );
  }

  private changeSlide = (direction: TDirection) => {
    this.setState({
      currentSlide: this.state.currentSlide + direction * 1,
    });
  };
}
