import { IApplicationState } from '../../types/redux';

export function getHasProblems(state: IApplicationState): boolean {
  const {
    newbuilding: { hasProblems },
  } = state;

  if (!hasProblems) {
    return false;
  }

  return hasProblems;
}
