import * as React from 'react';
import { InlineText1 } from '@cian/ui-kit';

import * as style from './Price.css';

export interface IPriceProps {
  priceForObjectFromDeveloperDisplay?: string | null;
  priceForMeterFromDeveloperDisplay?: string | null;
  relevanceIcon: React.ReactElement;
}

export class Price extends React.PureComponent<IPriceProps> {
  public render() {
    const { priceForObjectFromDeveloperDisplay, priceForMeterFromDeveloperDisplay, relevanceIcon } = this.props;

    if (!priceForObjectFromDeveloperDisplay) {
      return null;
    }

    return (
      <div className={style['container']} data-mark={'Price'}>
        <div className={style['mainPrice']} data-mark={'MainPrice'}>
          <div className={style['mainPriceText']}>
            {Price.getPriceArray(priceForObjectFromDeveloperDisplay).map((str: string) => {
              const isFrom = str === 'от';
              const isTo = str === 'до';

              return (
                <React.Fragment key={str}>
                  {isTo ? '\u00A0' : ''}
                  <span className={style['mainPriceText-big']}>{str}</span>
                  {isFrom || isTo ? '\u00A0' : ''}

                  {isTo ? <wbr /> : ''}
                </React.Fragment>
              );
            })}
            <div className={style['mainPriceWarning']}>{relevanceIcon}</div>
          </div>
        </div>

        {priceForMeterFromDeveloperDisplay ? (
          <div className={style['secondPrice']} data-mark={'SecondPrice'}>
            <InlineText1>{priceForMeterFromDeveloperDisplay}</InlineText1>
          </div>
        ) : null}
      </div>
    );
  }

  public static getPriceArray = (price?: string | null) => {
    if (!price) {
      return [];
    }

    const priceArray: string[] = price.replace(/(от|до)/gi, '*$1*').split(/\*/gi);
    const hasPretext = priceArray.length >= 3;

    if (hasPretext) {
      priceArray.shift();
    }

    return priceArray;
  };
}
