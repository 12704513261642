import { IAnalyticsUser } from '../../types/analytics';
import { IApplicationState } from '../../types/redux';
import { IAuthenticatedUser } from '../../types/user';

export function getAnalyticsUser(state: IApplicationState): IAnalyticsUser {
  const { user } = state;

  return {
    userId: (user as IAuthenticatedUser).userId || undefined,
  };
}
