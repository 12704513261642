import * as React from 'react';
import { ProgressBar } from '@cian/frontend-reviews-components';
import { UIHeading4 } from '@cian/ui-kit';
import * as style from './ReviewsSections.css';

export interface IReviewsSectionsProps {
  name: string;
  value: number;
}

export const ReviewsSections: React.FC<IReviewsSectionsProps> = ({ name, value }) => {
  return (
    <div className={style['item']}>
      <div className={style['label']}>
        <UIHeading4>{name}</UIHeading4>
      </div>
      <div className={style['rating']}>
        <ProgressBar value={value} />
      </div>
    </div>
  );
};
