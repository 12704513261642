import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackShowPhoneInBannerClick } from '../../../analytics/promos';
import { PromosBanner as PromosBannerComponent } from '../../../components/PromosBanner';
import { NBSP } from '../../../constants/symbols';
import { getGaLabel } from '../../../selectors/analytics';
import { getDiscountMortgage } from '../../../selectors/newbuilding';
import { useApplicationContext } from '../../../utils/applicationContext';
import { CallButtonContainer } from '../../CallButton';

export const PromosBanner: React.FC = () => {
  const { config } = useApplicationContext();
  const defaultText = `Узнайте про персональные условия и дополнительные скидки при${NBSP}покупке квартиры у застройщика`;
  const discountMortgageText = config.getStrict<string>('Newbuilding.DiscountMortgageBanner.Text');
  const isDiscountMortgageValid = config.getStrict<boolean>('Newbuilding.DiscountMortgageBanner.Enabled');
  const isDiscountMortgage = useSelector(getDiscountMortgage);
  const text = isDiscountMortgageValid && isDiscountMortgage ? discountMortgageText : defaultText;
  const gaLabel = useSelector(getGaLabel);

  const trackShowPhone = React.useCallback(() => {
    trackShowPhoneInBannerClick(gaLabel);
  }, [gaLabel]);

  return (
    <PromosBannerComponent
      text={text}
      contactsButton={
        <CallButtonContainer
          placeType="promosBanner"
          onShowPhoneClick={trackShowPhone}
          openContactsStyles={{ size: 'XS' as const, theme: 'fill_secondary' }}
          showPhoneStyles={{ size: 'XS' as const, theme: 'fill_secondary' }}
          SinglePhoneComponent={null}
          singlePhoneButtonText="Показать контакты"
        />
      }
    />
  );
};
