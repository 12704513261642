// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  ICreateUpcomingSaleRequestRequest,
  TCreateUpcomingSaleRequestModel,
  IMappers,
  TCreateUpcomingSaleRequestResponse,
  ICreateUpcomingSaleRequestResponseError,
} from './types';

export const defaultConfig: TCreateUpcomingSaleRequestModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'newbuilding-flat-view-order',
  pathApi: '/v1/create-upcoming-sale-request/',
  requestType: 'json',
} as TCreateUpcomingSaleRequestModel;

export function createCreateUpcomingSaleRequestModel(
  parameters: ICreateUpcomingSaleRequestRequest,
): TCreateUpcomingSaleRequestModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICreateUpcomingSaleRequestOptions<TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse400>;
  parameters: ICreateUpcomingSaleRequestRequest;
}

export async function fetchCreateUpcomingSaleRequest<TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICreateUpcomingSaleRequestOptions<TResponse400>): Promise<TCreateUpcomingSaleRequestResponse | TResponse400> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createCreateUpcomingSaleRequestModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 400) {
      return mappers[400](response as ICreateUpcomingSaleRequestResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TCreateUpcomingSaleRequestResponse;
}
