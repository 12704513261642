import { EFlatTourBookingActionTypes } from '../../actions/flatTourBooking';
import { IFlatTourBooking } from '../../types/flatTourBooking';
import { TReduxActions } from '../../types/redux';

const initialState = null;

export const flatTourBookingReducer = (
  state: IFlatTourBooking | null = initialState,
  action: TReduxActions,
): IFlatTourBooking | null => {
  switch (action.type) {
    case EFlatTourBookingActionTypes.SetScheduleData:
      return action.payload;
    default:
      return state;
  }
};
