import * as React from 'react';

/* istanbul ignore next */
export const MortgageIcon = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={56} height={56} viewBox="0 0 56 56">
    <path
      fill="url(#mortgage__paint0_linear)"
      d="M44 43a2 2 0 01-2 2H14a2 2 0 01-2-2V23.6c0-.6.3-1.2.7-1.5l14-12a2 2 0 012.6 0l14 12c.4.3.7.9.7 1.5V43z"
    />
    <mask id="mortgage__a" width={32} height={36} x={12} y={9} maskUnits="userSpaceOnUse">
      <path
        fill="url(#mortgage__paint1_linear)"
        d="M44 43a2 2 0 01-2 2H14a2 2 0 01-2-2V23.6c0-.6.3-1.2.7-1.5l14-12a2 2 0 012.6 0l14 12c.4.3.7.9.7 1.5V43z"
      />
    </mask>
    <g mask="url(#mortgage__a)">
      <path fill="url(#mortgage__paint2_linear)" d="M13.5 23.5l-1.5-.8L28 9v2L13.5 23.4z" />
      <path fill="url(#mortgage__paint3_linear)" d="M13.5 23.5l-1.5-.8V45h1.5V23.5z" />
    </g>
    <path
      fill="#fff"
      d="M22.5 29c1.4 0 2.5-1.1 2.5-2.5S24 24 22.5 24a2.5 2.5 0 00-2.5 2.5c0 1.4 1.1 2.5 2.5 2.5zM33 39.5c1.5 0 2.6-1.1 2.6-2.5s-1.1-2.5-2.5-2.5a2.5 2.5 0 00-2.5 2.5c0 1.4 1.1 2.5 2.5 2.5zm.5-15.5L20 37.4l2.1 2.1 13.5-13.4-2.1-2.1z"
    />
    <defs>
      <linearGradient
        id="mortgage__paint0_linear"
        x1={17.2}
        x2={35.5}
        y1={13.6}
        y2={34.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.3} stopColor="#FC944F" />
        <stop offset={0.7} stopColor="#FF7E00" />
      </linearGradient>
      <linearGradient
        id="mortgage__paint1_linear"
        x1={17.2}
        x2={35.5}
        y1={13.6}
        y2={34.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.3} stopColor="#FC944F" />
        <stop offset={0.7} stopColor="#FF7E00" />
      </linearGradient>
      <linearGradient
        id="mortgage__paint2_linear"
        x1={15.4}
        x2={29.3}
        y1={14.9}
        y2={31.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#FF7E00" stopOpacity={0.7} />
        <stop offset={0.2} stopColor="#FFE3C8" />
        <stop offset={0.4} stopColor="#FF7F01" />
      </linearGradient>
      <linearGradient id="mortgage__paint3_linear" x1={12.8} x2={12.8} y1={23.2} y2={45} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#FFB064" />
        <stop offset={0.8} stopColor="#FF7F01" />
      </linearGradient>
    </defs>
  </svg>
);

MortgageIcon.displayName = 'MortgageIcon';
