import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';
import { IUIRuntime } from '@cian/mf-registry/browser';
import { IMicrofrontendManifest, IRegistry } from '@cian/mf-registry/shared';

import { fetchGetNewbuildingBrokerWidget } from '../../repositories/newbuilding-broker-widget-frontend/v1/get-widget';

interface IParams {
  httpApi: IHttpApi;
  logger: ILogger;
  microfrontendsRegistry: IRegistry;
}

export const registerNewbuildingBrokerWidgetManifest = async (
  params: IParams,
): Promise<IMicrofrontendManifest | null> => {
  const { httpApi, logger, microfrontendsRegistry } = params;

  try {
    const { response } = await fetchGetNewbuildingBrokerWidget({ httpApi });

    const image = microfrontendsRegistry.register(response);

    if (typeof window !== 'undefined') {
      const runtime = image.runtimeStrict<IUIRuntime>(response.runtimeName);
      await runtime.ensure();
    }

    return response;
  } catch (error) {
    logger.error(error, { domain: 'services#registerNewbuildingBrokerWidgetManifest()' });

    return null;
  }
};
