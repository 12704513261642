import { IconPercent16, IconFavoriteOn16, IconMortgage16 } from '@cian/ui-kit';
import { TPromoType } from '../types/promo';

export const MORTGAGE_PROMO_TYPES: TPromoType[] = ['mortgage', 'bank', 'cianMortgage'];

export type TIconColor = 'E84260' | 'FF9D00' | '2E9E00';
export type TIconType = typeof IconMortgage16 | typeof IconPercent16 | typeof IconFavoriteOn16;
export type TIconTypeClass = 'discount' | 'mortgage' | 'bonus';
export type TIconClassName = 'promoIconDiscount' | 'promoIconMortgage' | 'promoIconBonus';

export interface IIconStyle {
  iconColor: TIconColor;
  iconType: TIconType;
  iconTypeClass: TIconTypeClass;
  iconClassName: TIconClassName;
}
export const QUANTITY_PROMOS_IN_ROW = 2;
export const PROMO_TYPES_MAP: { [index: string]: IIconStyle } = {
  bank: {
    iconColor: 'E84260',
    iconType: IconPercent16,
    iconTypeClass: 'discount',
    iconClassName: 'promoIconDiscount',
  },
  discount: {
    iconColor: 'E84260',
    iconType: IconPercent16,
    iconTypeClass: 'discount',
    iconClassName: 'promoIconDiscount',
  },
  mortgage: {
    iconColor: 'FF9D00',
    iconType: IconMortgage16,
    iconTypeClass: 'mortgage',
    iconClassName: 'promoIconMortgage',
  },
  bonus: {
    iconColor: '2E9E00',
    iconType: IconFavoriteOn16,
    iconTypeClass: 'bonus',
    iconClassName: 'promoIconBonus',
  },
};
