import { IApplicationState } from '../../types/redux';

export function getFirstPromoIds(state: IApplicationState) {
  const {
    newbuilding: { promoInfos },
  } = state;
  if (promoInfos && promoInfos.length) {
    return {
      promoId: promoInfos[0].promoId,
      uniquePromoId: promoInfos[0].uniquePromoId,
    };
  }

  return {
    promoId: null,
    uniquePromoId: null,
  };
}
