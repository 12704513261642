import * as React from 'react';
import { useSelector } from 'react-redux';
import { SeoMortgageLink as SeoMortgageLinkComponent } from '../../components/SeoMortgageLink';
import { promosSeoPageUrlSelector } from '../../selectors/seoPages';
import { trackClickPromosSeoLink } from './tracking';

export const SeoPromosLink = () => {
  const url = useSelector(promosSeoPageUrlSelector);

  return (
    <SeoMortgageLinkComponent
      url={url}
      onClick={trackClickPromosSeoLink}
      title="Акции и скидки"
      testId="seo_promos_link"
    />
  );
};
