import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleFlatViewOrderBanner } from '../../actions/flatViewOrder';
import { FlatViewOrderTooltip } from '../../components/FlatViewOrderTooltip';
import { getFlatViewOrderDetails, showOrderTooltipSelector } from '../../selectors/flatViewOrder';

export function FlatViewOrderTooltipContainer() {
  const dispatch = useDispatch();
  const showTooltip = useSelector(showOrderTooltipSelector);
  const details = useSelector(getFlatViewOrderDetails);

  const close = React.useCallback(() => {
    dispatch(toggleFlatViewOrderBanner(false));
  }, [dispatch]);

  return <FlatViewOrderTooltip isOpen={showTooltip} onClose={close} details={details} />;
}
