import * as React from 'react';
import { useSelector } from 'react-redux';
import { isWebGLSupported } from '../../utils/webgl';
import { randomInteger } from '../../utils/random';
import { getAerialViewContent } from '../../selectors/experiments';
import { getAerialViewLink, getAerialViewPlaceholders } from '../../selectors/aerialView';
import { AerialView } from '../../components/AerialView';
import { AerialViewModalContainer } from '../AerialViewModal';
import { trackAerialViewOpen, trackEachAppearance } from './tracking';
import { LayoutBlock } from '../../components/LayoutBlock';

export const AerialViewContainer: React.FC<{}> = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [webGLSupported, setWebGLSupported] = React.useState(false);
  const aerialViewUrl = useSelector(getAerialViewLink);
  const placeholders = useSelector(getAerialViewPlaceholders);
  const { title } = useSelector(getAerialViewContent);
  const randomIndex = randomInteger({ max: placeholders.length - 1 });
  const image = React.useMemo(() => placeholders[randomIndex], [placeholders, randomIndex]);

  const handleClick = React.useCallback(() => {
    setOpen(true);
    trackAerialViewOpen();
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleAppear = React.useCallback(() => {
    trackEachAppearance();
  }, []);

  React.useEffect(() => {
    setWebGLSupported(isWebGLSupported());
  }, []);

  if (!webGLSupported || aerialViewUrl == null) {
    return null;
  }

  return (
    <LayoutBlock>
      <AerialView text={title} image={image} onClick={handleClick} onAppear={handleAppear} />
      <AerialViewModalContainer isOpen={open} source={aerialViewUrl} onClose={handleClose} />
    </LayoutBlock>
  );
};
