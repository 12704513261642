import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import { CompilationsList } from '../../../components/RecommendedNewbuildings';

import { IRecommendationCompilation } from '../../../types/recommendations';
import { trackClickSimilarCollections, trackShowSimilarCollections } from '../tracking';

interface ICompilationsListContainerProps {
  compilations: IRecommendationCompilation[];
}

export const CompilationsListContainer: React.VFC<ICompilationsListContainerProps> = ({ compilations }) => {
  return (
    <ActionAfterViewObserver callback={trackShowSimilarCollections} triggerOnce>
      <CompilationsList compilations={compilations} onItemClick={trackClickSimilarCollections} />
    </ActionAfterViewObserver>
  );
};
