export function getRightScrollOffset(container: HTMLUListElement) {
  const childrenList = Array.from(container.children);
  const containerOffset = container.offsetLeft;
  const rightBorderPosition = container.offsetWidth + container.scrollLeft;

  const rightHiddenChild = childrenList.find((child: HTMLLIElement) => {
    const leftX = child.offsetLeft - containerOffset;
    const rightX = leftX + child.offsetWidth;

    return rightX > rightBorderPosition;
  });

  if (!rightHiddenChild) {
    return null;
  }

  const { offsetLeft, offsetWidth } = rightHiddenChild as HTMLLIElement;

  return offsetLeft + offsetWidth - containerOffset - container.offsetWidth;
}

export function getLeftScrollOffset(container: HTMLUListElement) {
  const childrenList = Array.from(container.children);
  const containerOffset = container.offsetLeft;
  const leftBorderPosition = container.scrollLeft;

  const leftHiddenChildIndex = childrenList.findIndex((child: HTMLLIElement) => {
    const leftX = child.offsetLeft - containerOffset;

    return leftX >= leftBorderPosition;
  });
  const leftHiddenChild = leftHiddenChildIndex > 0 ? childrenList[leftHiddenChildIndex - 1] : null;

  if (!leftHiddenChild) {
    return null;
  }

  const { offsetLeft } = leftHiddenChild as HTMLLIElement;

  return offsetLeft;
}
