import { pick } from 'ramda';

import { IFastFiltersUserResponseSchema } from '../../shared/repositories/monolith-python/entities/schemas/FastFiltersUserResponseSchema';
import { TUser, TAgentAccountType, IAuthenticatedUser, IPhone } from '../../shared/types/user';

export const prepareUser = (userResponse: IFastFiltersUserResponseSchema): TUser => {
  if (!userResponse.user || !userResponse.user.isAuthenticated) {
    return {
      isAuthenticated: false,
    };
  }

  const { user } = userResponse;

  const userRequiredFields = pick(
    [
      'accountType',
      'avatarUrl',
      'balance',
      'canPublishOffer',
      'displayName',
      'id',
      'isAgent',
      'isBuilder',
      'isCianPartner',
      'isModerator',
      'isNew',
      'isProfi',
      'isSubscriptions',
      'permissions',
      'publicProfile',
      'userId',
      'userTrustLevel',
      'userType',
    ],
    // Обязательные поля авторизованного пользователя
    user as unknown as IAuthenticatedUser,
  );

  return {
    ...userRequiredFields,
    agentAccountType: user.agentAccountType ? (user.agentAccountType as TAgentAccountType) : null,
    criteoEmail: user.criteoEmail || null,
    email: user.email || null,
    firstName: user.firstName || null,
    isAuthenticated: true,
    kpnWebimChecksum: user.kpnWebimChecksum || null,
    lastName: user.lastName || null,
    phones: (user.phones || []) as IPhone[],
    tariff: user.tariff || [],
  };
};
