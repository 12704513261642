import * as React from 'react';
import { ArticleHeading1, ArticleParagraph1, IconClose16 } from '@cian/ui-kit';

import * as styles from './AsideContent.css';

export interface IAsideContentProps {
  callbackButton?: React.ReactNode;
  contactsButton?: React.ReactNode;
  description: string;
  newbuildingName: string;
  onClose(): void;
  priceForObjectFromDeveloperDisplay?: string | null;
  priceForMeterFromDeveloperDisplay?: string | null;
}

export const AsideContent: React.FC<IAsideContentProps> = ({
  callbackButton,
  contactsButton,
  description,
  newbuildingName,
  onClose,
  priceForObjectFromDeveloperDisplay,
  priceForMeterFromDeveloperDisplay,
}) => {
  return (
    <>
      <button className={styles['close-button']} type="button" onClick={onClose} data-testid="close-button">
        <IconClose16 color="gray60_100" />
      </button>
      <div className={styles['aside-info']}>
        <ArticleParagraph1>{newbuildingName}</ArticleParagraph1>
        <div className={styles['object-price']}>
          <ArticleHeading1 as="p">{priceForObjectFromDeveloperDisplay}</ArticleHeading1>
        </div>
        <ArticleParagraph1>{priceForMeterFromDeveloperDisplay}</ArticleParagraph1>
      </div>
      <div className={styles['more-info']}>
        <ArticleParagraph1>{description}</ArticleParagraph1>
      </div>
      <div className={styles['call-button']}>{contactsButton}</div>
      {callbackButton}
    </>
  );
};
