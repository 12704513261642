import { ca } from '@cian/analytics';

const enum ETrackingFilterType {
  MortgageAmount = 'mortgage_amount',
  DownPayment = 'initial_fee',
  Term = 'mortgage_term',
}

export const trackPriceFilterChange = () => trackFilterChange(ETrackingFilterType.MortgageAmount);
export const trackDownPaymentFilterChange = () => trackFilterChange(ETrackingFilterType.DownPayment);
export const trackTermFilterChange = () => trackFilterChange(ETrackingFilterType.Term);

function trackFilterChange(filterType: ETrackingFilterType) {
  ca('eventSite', {
    action: 'click_sopr',
    category: 'Promo',
    label: filterType,
    name: 'oldevent',
    useLastProducts: true,
  });
}
