import * as React from 'react';

import { CompanyLabel } from './parts';
import * as styles from './AsideCianContacts.css';

export const AsideCianContacts: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles['container']}>
      <CompanyLabel>КОНСУЛЬТАНТ</CompanyLabel>
      <div className={styles['info']}>
        <strong className={styles['title']}>Циан</strong>
        <p className={styles['description']}>Основан в 2001 году</p>
      </div>
      <div className={styles['buttons']}>{children}</div>
    </div>
  );
};

AsideCianContacts.displayName = 'AsideCianContacts';
