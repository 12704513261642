import { ca } from '@cian/analytics';

export const trackBuilderCallReasonsShowing = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ask_builder',
    action: 'show_sopr',
    useLastProducts: true,
  });
};
