export interface ILayoutsInfo {
  /** Текст c информацией о количестве планировок **/
  countText: string;
  /** Дефолтная комнатность **/
  defaultRoomValue: EDefaultRoomValue;
  /** Признак наличия пранировки с туром **/
  hasTour: boolean;
  /** Список планировок для дефолтной комнатности **/
  layouts: ILayout[];
  /** Список комнат с доступными планировками **/
  roomsCountsByHouse: IRoomsCountsByHouse[];
}

export enum EDefaultRoomValue {
  'FourPlusRoom' = 'fourPlusRoom',
  'OneRoom' = 'oneRoom',
  'Studio' = 'studio',
  'ThreeRoom' = 'threeRoom',
  'TwoRoom' = 'twoRoom',
}

export interface ILayout {
  /** Изображение планировки **/
  image: ILayoutImage;
  /** Текст с информацией о комнатности и цене за квадратный метр **/
  roomsSquareMeterPriceText: string;
  /** Ссылка на выдачу **/
  searchButtonLink: string;
  /** Площадь **/
  totalArea: string;
  /** Текст с информацией о площади и цене **/
  totalAreaPriceText: string;
  /** Ссылка на тур vr net **/
  tourUrl?: string;
}

export interface ILayoutImage {
  /** URL исходного изображения **/
  fullUrl: string;
  /** URL превью **/
  thumbnailUrl: string;
}

export interface IRoomsCountsByHouse {
  /** ID корпуса **/
  houseId?: number;
  /** Название корпуса **/
  houseName: string;
  /** Список возможных вариантов комнатности **/
  roomsCountList: ILayoutRoomCount[];
}

export interface ILayoutRoomCount {
  /** Есть ли квартиры с данной комнатностью **/
  isAvailable?: boolean;
  /** Значение для отрисовки на фронте (например '1') **/
  roomTitle: string;
  /** Значение для отправки на бэкенд (напримем oneRoom) **/
  roomValue: ERoomValue;
}

export enum ERoomValue {
  'FourPlusRoom' = 'fourPlusRoom',
  'OneRoom' = 'oneRoom',
  'Studio' = 'studio',
  'ThreeRoom' = 'threeRoom',
  'TwoRoom' = 'twoRoom',
}

export enum ELayoutTypes {
  Layout = 'layout',
  Tour = 'tour',
}
