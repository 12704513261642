import { ca } from '@cian/analytics';

/* istanbul ignore next */
export function trackDeveloperFlatsLinkClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'click_developersFlats',
    label: 'all',
    useLastProducts: true,
  });
}

/* istanbul ignore next */
export function trackOffersToLayoutsSwitcherClick({ isActive }: { isActive: boolean }) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Layout',
    action: isActive ? 'turn_on_sopr' : 'turn_off_sopr',
    label: 'jk_layouts',
    useLastProducts: true,
  });
}

export function trackOffersBlockShown() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'show_sopr',
    label: 'offers_in_jk',
    useLastProducts: true,
  });
}
