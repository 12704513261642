interface IMortgageConditions {
  initialPayment?: number | null | undefined;
  interestRate?: number | null | undefined;
  loanTerm?: number | null | undefined;
  price?: number | null | undefined;
}

export const calculateMonthlyPayment = (conditions: IMortgageConditions): number => {
  const { initialPayment, interestRate, loanTerm, price } = conditions;

  if (!initialPayment || !loanTerm || !price || (!interestRate && interestRate !== 0)) {
    return 0;
  }

  if (initialPayment >= price) {
    return 0;
  }

  if (interestRate === 0) {
    if (loanTerm === 0) {
      return 0;
    }

    return Math.round((price - initialPayment) / (loanTerm * 12));
  }

  const monthlyRate = interestRate / (12 * 100);
  const loanTermInMonths = loanTerm * 12;
  const totalRate = (1 + monthlyRate) ** loanTermInMonths;
  const loanSum = price - initialPayment;
  const monthlyPayment = (loanSum * monthlyRate * totalRate) / (totalRate - 1);

  if (isNaN(monthlyPayment)) {
    return 0;
  }

  return Math.round(monthlyPayment);
};
