import * as React from 'react';
import * as style from './PromoLabel.css';
import { PROMO_TYPES_MAP } from '../../constants/promos';
import classNames from 'clsx';
import { scrollToAnchor } from '@cian/newbuilding-utils';
import { TPromoType } from '../../types/promo';
import { TooltipLink } from '../TooltipLink';

export interface IPromoLabelProps {
  promoLabelType?: TPromoType | string | null;
  promoId: number | null;
  uniquePromoId: string | null | undefined;
  newbuildingId: number;
}

export const PromoLabel: React.FunctionComponent<IPromoLabelProps> = props => {
  const { promoLabelType } = props;

  if (!promoLabelType || !PROMO_TYPES_MAP[promoLabelType]) {
    return null;
  }

  const iconStyle = PROMO_TYPES_MAP[promoLabelType];
  const PromoIconComponent = iconStyle.iconType;
  const anchor = '#promoList';

  const onPromoLabelClick = (event: React.MouseEvent<HTMLElement>) => {
    scrollToAnchor({ anchor, hasRelocation: false, hasAnimation: true })(event);

    if (history.pushState) {
      history.pushState(null, '', anchor);
    } else {
      setTimeout(() => {
        window.location.hash = anchor;
      }, 500);
    }
  };

  return (
    <div className={style['promoLabel-wrapper']}>
      <div className={style['promoLabel-container']}>
        <TooltipLink anchor={'#promoList'} text={'К списку акций'} width={115} showBottom={true}>
          <div
            className={classNames(style['promoLabel-iconContainer'], [
              style[`promoLabel-iconContainer--${iconStyle.iconTypeClass}`],
            ])}
          >
            <a className={style['promoLabel-iconLink']} href={anchor} onClick={onPromoLabelClick}>
              <PromoIconComponent color={'white_100'} />
            </a>
          </div>
        </TooltipLink>
      </div>
    </div>
  );
};
