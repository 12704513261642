import * as React from 'react';
import { scrollToAnchor } from '@cian/newbuilding-utils';
import { useSelector } from 'react-redux';
import { IconWarningSymbol40 } from '@cian/ui-kit/icons/product/40';
import { SolidZhk40 } from '@cian/ui-kit/prev';

import { getIsReliable, getHasProblems } from '../../selectors/newbuilding';
import { trackRightReliableLabelClick } from './tracking';
import { RightReliableLabel } from '../../components/RightReliableLabel';
import { isReliabilityEnabledSelector } from '../../selectors/reliability/isReliabilityEnabledSelector';
import { RightReliableBanner } from '../../components/RightReliableLabelRedesigned';
import { reliabilitySelector } from '../../selectors/reliability/reliabilitySelector';

export function RightReliableLabelContainer() {
  const isReliable = useSelector(getIsReliable);
  const hasProblems = useSelector(getHasProblems);
  const isReliabilityV2Enabled = useSelector(isReliabilityEnabledSelector);
  const reliability = useSelector(reliabilitySelector);

  if (!isReliable && !hasProblems) {
    return null;
  }

  const title = isReliable ? 'Есть надёжные корпуса' : 'Осторожно! Есть проблемные корпуса';
  const text = isReliable ? 'Они соответствуют всем' : 'У застройщика выявлены нарушения.';
  const linkText = isReliable ? 'критериям надежности' : 'Подробнее';
  const icon = isReliable ? <SolidZhk40 color="primary_100" /> : <IconWarningSymbol40 color="error_100" />;

  const onReliableLabelClick = (anchor: string, label: string) => (event: React.MouseEvent<HTMLElement>) => {
    scrollToAnchor({
      anchor,
      offset: 0,
      hasRelocation: false,
      hasAnimation: true,
    })(event);
    trackRightReliableLabelClick(label);
  };

  if (isReliabilityV2Enabled) {
    return (
      <RightReliableBanner
        reliability={reliability}
        onProblemClick={onReliableLabelClick('#reliable', 'floating_block_problem_building')}
        onReliableClick={onReliableLabelClick('#reliable', 'floating_block_reliable_dev')}
      />
    );
  }

  return (
    <RightReliableLabel
      icon={icon}
      title={title}
      text={text}
      linkText={linkText}
      hasProblems={hasProblems}
      onClick={
        isReliable
          ? onReliableLabelClick('#reliable', 'floating_block_reliable_dev')
          : onReliableLabelClick('#problem', 'floating_block_problem_building')
      }
    />
  );
}
