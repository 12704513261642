import { actionGenerator } from '../../utils/redux/actionGenerator';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { IRealtyValuationData } from '../../types/realtyValuation';
import { IPricesFiltersSchema } from '../../repositories/newbuilding-estimate/entities/valuation_filters/PricesFiltersSchema';
import { ISalesFiltersSchema } from '../../repositories/newbuilding-estimate/entities/valuation_filters/SalesFiltersSchema';
import { EValuationTabs } from '../../containers/RealtyValuation/constants/tabs';

export enum EActionTypes {
  Request = 'realtyValuation/Request',
  Success = 'realtyValuation/Success',
  Failure = 'realtyValuation/Failure',
  SetPriceFilter = 'realtyValuation/SetPriceFilter',
  SetSalesFilter = 'realtyValuation/SetSalesFilter',
  SetActiveTab = 'realtyValuation/SetActiveTab',
}

type TSuccessActionPayload = Partial<IRealtyValuationData>;

export const requestAction = actionGenerator<EActionTypes.Request>(EActionTypes.Request);
export const successAction = actionGenerator<EActionTypes.Success, TSuccessActionPayload | null>(EActionTypes.Success);
export const failureAction = actionGenerator<EActionTypes.Failure>(EActionTypes.Failure);
export const setActiveTab = actionGenerator<EActionTypes.SetActiveTab, EValuationTabs>(EActionTypes.SetActiveTab);

export function setPriceFilterAction<Key extends keyof IPricesFiltersSchema>(
  payload: ISetPriceFilterPayload<Key>,
): ITypedReduxAction<EActionTypes.SetPriceFilter, ISetPriceFilterPayload<Key>> {
  return {
    type: EActionTypes.SetPriceFilter,
    payload,
  };
}

export function setSalesFilterAction<Key extends keyof ISalesFiltersSchema>(
  payload: ISetSalesFilterPayload<Key>,
): ITypedReduxAction<EActionTypes.SetSalesFilter, ISetSalesFilterPayload<Key>> {
  return {
    type: EActionTypes.SetSalesFilter,
    payload,
  };
}

interface ISetPriceFilterPayload<Key extends keyof IPricesFiltersSchema> {
  filterName: Key;
  filterValue: IPricesFiltersSchema[Key];
}

interface ISetSalesFilterPayload<Key extends keyof ISalesFiltersSchema> {
  filterName: Key;
  filterValue: ISalesFiltersSchema[Key];
}

export type TRealtyValuationActions =
  | ReturnType<typeof requestAction>
  | ReturnType<typeof successAction>
  | ReturnType<typeof failureAction>
  | ReturnType<typeof setPriceFilterAction>
  | ReturnType<typeof setSalesFilterAction>
  | ReturnType<typeof setActiveTab>;
