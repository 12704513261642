import { IApplicationState } from '../../types/redux';
import { pathOr } from 'ramda';
import { IReliableHouse } from '../../types/newbuilding/reliableLabel';

export function getReliableHouses(state: IApplicationState): string[] {
  return pathOr([], ['newbuilding', 'reliableHouses'], state);
}

export function getReliableHousesV2(state: IApplicationState): IReliableHouse[] {
  return pathOr([], ['newbuilding', 'reliableHousesV2'], state);
}
