import * as React from 'react';

import * as styles from './styles.css';
import { SectionTitle } from '../../SectionTitle';

interface ITitle {
  children: React.ReactChild;
}

export const Title: React.VFC<ITitle> = ({ children }) => {
  return (
    <div className={styles['container']}>
      <SectionTitle>{children}</SectionTitle>
    </div>
  );
};
