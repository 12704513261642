import * as React from 'react';
import { useSelector } from 'react-redux';
import { getNewbuildingReviewsStat } from '../../selectors/newbuilding';
import { Star, MobileRating, EStarSize, EStarTheme } from '@cian/frontend-reviews-components';

export const HeaderRatingStat: React.FC = () => {
  const reviewStat = useSelector(getNewbuildingReviewsStat);

  if (!reviewStat?.reviewsCount) {
    return <Star size={EStarSize.M} theme={EStarTheme.NotFilled} />;
  }

  return <MobileRating value={reviewStat.rating} short />;
};
