import * as React from 'react';
import { ArticleHeading4, ArticleParagraph3 } from '@cian/ui-kit';

import * as styles from './AuthorLabel.css';

export interface IAuthorLabelProps {
  label?: string | null;
  logoIcon: React.ReactNode;
  logoUrl?: string | null;
  name: string;
}

export const AuthorLabel: React.FC<IAuthorLabelProps> = ({ label, logoIcon, logoUrl, name }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['logo']}>
        {logoUrl ? (
          <img className={styles['logo-image']} src={logoUrl} alt={name} />
        ) : (
          <div className={styles['logo-icon']}>{logoIcon}</div>
        )}
      </div>
      <div className={styles['content']}>
        {label && <ArticleParagraph3 color="gray60_100">{label}</ArticleParagraph3>}
        <ArticleHeading4>
          <span className={styles['nowrap-text']}>{name}</span>
        </ArticleHeading4>
      </div>
    </div>
  );
};
