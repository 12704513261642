import * as React from 'react';
import { IconChevronDownSmall16, IconChevronUpSmall16 } from '@cian/ui-kit/icons';
import { ArticleHeading4, UIText1 } from '@cian/ui-kit/typography';

import { HoveredLink } from '../../../HoveredLink';
import { OffersLayoutList } from '../OffersLayoutList';

import * as styles from './styles.css';
import { IRoomStatsResponseSchema } from '../../../../repositories/newbuilding-card/entities/stats/RoomStatsResponseSchema';

interface IOffersRowProps extends IRoomStatsResponseSchema {
  displayHouseName?: boolean;
  onRoomTypeLinkClick(label: string): void;
  onOfferLinkClick(offerId: number, gaLabel: string): void;
  onOffersQuantityLinkClick(roomType: string): void;
}

export function OffersRow({
  roomTypeDisplay,
  totalAreaMinDisplay,
  price2Range,
  fromDeveloperPropsCountDisplay,
  fromDeveloperPropsCount,
  offersUrl,
  layouts,
  displayHouseName,
  roomType,
  onRoomTypeLinkClick,
  onOfferLinkClick,
  onOffersQuantityLinkClick,
}: IOffersRowProps) {
  const [expanded, changeExpandedState] = React.useState(false);
  const toggleExpand = React.useCallback(() => {
    changeExpandedState(!expanded);
    onOffersQuantityLinkClick(roomType);
  }, [expanded]);
  const ArrowIcon = expanded ? IconChevronUpSmall16 : IconChevronDownSmall16;
  const showLink = Boolean(fromDeveloperPropsCount && offersUrl);

  if (!layouts) {
    return null;
  }

  return (
    <div className={styles['container']}>
      <div className={styles['row']}>
        <div className={styles['room-type']}>
          {showLink ? (
            <HoveredLink
              url={offersUrl as string}
              childNode={ArticleHeading4}
              as="h4"
              color="black_100"
              onClick={() => onRoomTypeLinkClick(roomType)}
            >
              {roomTypeDisplay}
            </HoveredLink>
          ) : (
            <UIText1 fontWeight="bold">{roomTypeDisplay}</UIText1>
          )}
        </div>
        {showLink && (
          <div>
            <UIText1 color="gray60_100">{totalAreaMinDisplay}</UIText1>
          </div>
        )}
        <div className={styles['price']}>
          <UIText1 color="black_100">{price2Range}</UIText1>
        </div>
        {showLink && (
          <div className={styles['props-count']}>
            <div onClick={toggleExpand}>
              <UIText1 color="primary_100">{fromDeveloperPropsCountDisplay}</UIText1>
              <div className={styles['arrow']}>
                <ArrowIcon color="primary_100" />
              </div>
            </div>
          </div>
        )}
      </div>
      {showLink && (
        <OffersLayoutList
          expanded={expanded}
          offersUrl={offersUrl as string}
          layouts={layouts}
          displayHouseName={displayHouseName}
          fromDeveloperPropsCount={fromDeveloperPropsCount as number}
          onAllOffersLinkClick={() => onRoomTypeLinkClick(roomType)}
          onOfferLinkClick={onOfferLinkClick}
        />
      )}
    </div>
  );
}
