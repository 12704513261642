import * as React from 'react';
import { UIHeading2 } from '@cian/ui-kit';
import { UIText1 } from '@cian/ui-kit/typography';
import * as style from './style.css';

interface IBuilderOffersNotAvailableBlockProps {
  contactButton: React.ReactElement;
}

export const BuilderOffersNotAvailableBlock = ({ contactButton }: IBuilderOffersNotAvailableBlockProps) => {
  return (
    <div className={style['container']} data-testid="BuilderOffersNotAvailableBlock">
      <div className={style['image']}>
        <div className={style['logo']} />
      </div>
      <div className={style['controls']}>
        <div className={style['description']}>
          <UIHeading2>В этом ЖК подходящих предложений от&nbsp;застройщика нет</UIHeading2>
          <UIText1>Узнайте об остатках у застройщика</UIText1>
        </div>
        <div>{contactButton}</div>
      </div>
    </div>
  );
};
