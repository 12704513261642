import * as React from 'react';
import { NewbuildingEmptyReviewsStat, NewbuildingReviewsStat } from '../../components/NewbuildingReviewsStat';
import { useSelector } from 'react-redux';
import { getBuilders, getNewbuildingId, getNewbuildingReviewsStat } from '../../selectors/newbuilding';
import { getUrlPath } from '../../selectors/path';
import { trackNewbuildingReviewsAnalytic } from '../../utils/analytics/reviewsAnalytics';
import { getAnalyticsUser } from '../../selectors/analytics';
import { getProductsData } from '../../selectors/analytics/builder';

export const NewbuildingReviewsStatContainer: React.FC = () => {
  const reviewStat = useSelector(getNewbuildingReviewsStat);
  const fullUrl = useSelector(getUrlPath);

  const user = useSelector(getAnalyticsUser);

  const builder = useSelector(getBuilders)[0] || null;
  const id = useSelector(getNewbuildingId);
  const products = [getProductsData(id, builder)];

  const page = {
    pageType: 'CardJK',
    siteType: 'desktop',
    extra: {
      type: 'review',
      rating: reviewStat?.rating,
    },
  };

  const trackingReviewLinkClick = React.useCallback(() => {
    trackNewbuildingReviewsAnalytic({
      action: 'Click_sopr',
      category: 'Card_JK',
      label: 'Rating',
      user,
      page,
      products,
    });
  }, [user]);

  if (!reviewStat?.reviewsCount || !fullUrl) {
    return <NewbuildingEmptyReviewsStat />;
  }

  return (
    <NewbuildingReviewsStat
      fullUrl={fullUrl}
      reviewsCountText={reviewStat.reviewsCountText}
      rating={reviewStat.rating}
      reviewLinkClick={trackingReviewLinkClick}
    />
  );
};
