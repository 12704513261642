import { IApplicationState } from '../../types/redux';
import { getHouses } from './getHouses';

export function getIsReliableCurrentHouse(state: IApplicationState) {
  const houses = getHouses(state);
  const selectedHouseId = state.offers.selectedHouseId;

  if (selectedHouseId) {
    const selectedHouse = houses.find(house => house.houseId === selectedHouseId);

    return selectedHouse?.isReliable || false;
  }

  return state.newbuilding.isReliableV2;
}
