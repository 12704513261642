import * as React from 'react';
import { useSelector } from 'react-redux';
import { PriceRelevanceIcon } from '../../components/PriceRelevanceIcon';
import { getPriceRelevance } from '../../selectors/newbuilding/priceRelevance';
import { EType } from '../../types/newbuilding/priceRelevance';

export const PriceRelevanceIconContainer = () => {
  const tooltip = useSelector(getPriceRelevance);

  if (!tooltip || tooltip.type !== EType.Warning) {
    return null;
  }

  return <PriceRelevanceIcon description={tooltip.description} />;
};
