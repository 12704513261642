import * as React from 'react';
import { useSelector } from 'react-redux';
import { OffersStats, OffersStatsItem } from '../../components/OffersStats';
import { getOffers } from '../../selectors/offers';
import { useApplicationContext } from '../../utils/applicationContext';
import { Section } from '../../components/Section';
import { LayoutBlock } from '../../components/LayoutBlock';
import { UIHeading2 } from '@cian/ui-kit';

export const OffersStatsContainer = () => {
  const offers = useSelector(getOffers);
  const { config } = useApplicationContext();
  const isEnabled = config.get('Newbuilding.LayoutsWidget.Enabled') || false;

  if (!offers.fromDeveloperRooms?.length || !isEnabled) {
    return null;
  }

  return (
    <LayoutBlock>
      <Section data-testid="offers-stats-seo">
        <UIHeading2>Квартиры в ЖК</UIHeading2>
        <OffersStats>
          {offers.fromDeveloperRooms.map(offer => {
            return (
              <OffersStatsItem
                key={offer.roomTypeDisplay}
                roomType={offer.roomTypeDisplay || ''}
                price2Range={offer.price2Range || ''}
                totalAreaMinDisplay={offer.totalAreaMinDisplay || ''}
                fromDeveloperPropsCount={offer.fromDeveloperPropsCount || 0}
                offersUrl={offer.offersUrl || ''}
              />
            );
          })}
        </OffersStats>
      </Section>
    </LayoutBlock>
  );
};
