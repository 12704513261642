import * as React from 'react';
import { throttle } from '@cian/newbuilding-utils';

import { FixedBlock } from '../FixedBlock';
import { HeaderContainer } from '../../containers/Header';
import { getCurrentScrollOffset } from '../../utils/dom/getCurrentScrollOffset';
import { STICKY_HEADER_TOP } from '../../constants/elements';

export interface IFixedHeaderProps {
  actionButton: React.ReactNode;
}

export interface IFixedHeaderState {
  isHeaderFixed: boolean;
}

const HEADER_HIDE_ZONE = 370;

export class FixedHeader extends React.Component<IFixedHeaderProps, IFixedHeaderState> {
  public constructor(props: IFixedHeaderProps) {
    super(props);

    this.state = {
      isHeaderFixed: false,
    };
  }

  public componentDidMount(): void {
    this.fixHeader();
    window.addEventListener('scroll', this.onScrollHandler, false);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('scroll', this.onScrollHandler, false);
  }

  public onScrollHandler = throttle(100, () => {
    this.fixHeader();
  });

  public fixHeader(): void {
    const yOffser = getCurrentScrollOffset();

    if (yOffser > HEADER_HIDE_ZONE && !this.state.isHeaderFixed) {
      this.setState({ isHeaderFixed: true });
    } else if (yOffser < HEADER_HIDE_ZONE && this.state.isHeaderFixed) {
      this.setState({ isHeaderFixed: false });
    }
  }

  public render() {
    const { actionButton } = this.props;
    const { isHeaderFixed } = this.state;

    return (
      <FixedBlock top={isHeaderFixed ? 0 : -STICKY_HEADER_TOP}>
        <HeaderContainer actionButton={actionButton} />
      </FixedBlock>
    );
  }
}
