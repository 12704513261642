import { getFullAddress } from './getFullAddress';
import { IApplicationState } from '../../types/redux';

// Этот селектор только для seo эксперимента NB-1385, после его завершения он удалится
/* istanbul ignore next */
export const getSchemaOrgAddress = (state: IApplicationState) => {
  const addres = getFullAddress(state);

  const parts = addres.split(', ');
  const streetAddress = parts.join(', ') || '';
  const addressLocality = parts.shift() || '';
  const addressRegion = parts.shift() || '';

  return { streetAddress, addressLocality, addressRegion };
};
