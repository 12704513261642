import { ca } from '@cian/analytics';

/** Событие для кнопки перехода на сайт застройщика в рамках проекта https://jira.cian.tech/browse/NB-26 */
export function trackGoToDeveloperSite(url: string) {
  ca('eventSite', {
    action: 'cardJK_webview',
    category: 'GoToDeveloperSite',
    label: url,
    name: 'oldevent',
    useLastProducts: true,
  });
}
