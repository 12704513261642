interface IPhone {
  code: string;
  countryCode: string;
  number: string;
}

interface IAgent {
  phone?: IPhone | null;
  showPhone: boolean;
}

export const hasValidPhone = <Agent extends IAgent>(agent: Agent): agent is Agent & { phone: IPhone } => {
  return Boolean(agent.showPhone && agent.phone && agent.phone.number);
};
