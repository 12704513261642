import { shortenNumber } from '@cian/utils';

export function shortenMaxCredit(maxCredit: number) {
  return shortenNumber(maxCredit, {
    billionPostfix: 'млрд',
    fractionalLength: 1,
    millionPostfix: 'млн',
    separator: ',',
    thousandPostfix: 'тыс',
    withoutPostfix: false,
  });
}
