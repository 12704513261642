import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { registry as getRegistry } from '@cian/mf-registry/browser';
import { IApplicationState } from '../../../shared/types/redux';
import { TThunkDispatch } from '../../../shared/types/redux/thunk';
import { createReduxStore } from '../../../shared/utils/redux';
import { getFlatTourSchedule } from '../flatTourBooking';
import { getTelemetry } from '@cian/telemetry/browser';

export function createApplicationState() {
  const config = getConfig();
  const httpApi = getHttpApi();
  const logger = getLogger();
  const microfrontendsRegistry = getRegistry();
  const telemetry = getTelemetry();

  const initialState = config.getStrict<IApplicationState>('initialState');

  const reduxContext = {
    config,
    httpApi,
    logger,
    microfrontendsRegistry,
    telemetry,
  };

  const store = createReduxStore(initialState, reduxContext, true);
  (store.dispatch as TThunkDispatch)(getFlatTourSchedule());

  return store;
}
