import { ca } from '@cian/analytics';

import { IPromoInfoSchema } from '../../types/promo';
import { getNewbuildingProduct } from '../../utils/analytics';

export function trackPromoOffersShow(promos: IPromoInfoSchema[], newbuildingId: number, isMortgage = false) {
  const promoId = promos.map(({ promoId, promoType }, index) => ({
    promo_type: promoType,
    promo_id: promoId,
    position: index + 1,
  }));

  ca('eventSite', {
    action: 'show_sopr',
    category: 'Promo',
    label: isMortgage ? 'mortgage_offers' : 'promo_offers',
    name: 'oldevent',
    products: [getNewbuildingProduct({ newbuildingId, extra: { promoId } })],
  });
}
