import { getBuilders } from './getBuilders';
import { getSellers } from './getSellers';
import { IApplicationState } from '../../types/redux';
import { hasValidPhone } from '../../utils/agent';
import { phoneToString } from '../../utils/businessLogic/phone/phoneToString';

export const getFirstAvailablePhone = (state: IApplicationState): string => {
  const builders = getBuilders(state);
  const sellers = getSellers(state);

  const buildersWithPhones = builders.filter(hasValidPhone);
  const sellersWithPhones = sellers.filter(hasValidPhone);
  const buildersPhones = buildersWithPhones.map(builder => builder.phone);
  const sellersPhones = sellersWithPhones.map(seller => seller.phone);

  const phones = [...buildersPhones, ...sellersPhones];

  return phoneToString(phones[0]);
};
