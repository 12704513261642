import * as React from 'react';
import { UIText1 } from '@cian/ui-kit';
import { ImagesEmpty40 } from '@cian/ui-kit/prev';

import * as styles from './NoImage.css';

export const NoImage: React.FC = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['icon']}>
        <ImagesEmpty40 display="block" />
      </div>
      <UIText1 color="gray60_100">Нет фото</UIText1>
    </div>
  );
};
