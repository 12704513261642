import { ca } from '@cian/analytics';

export function trackHover() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'Hover',
    label: 'RelevantPriceIcon',
    useLastProducts: true,
  });
}
