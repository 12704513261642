import * as React from 'react';

import { LayoutBlock } from '../../LayoutBlock';
import { Section } from '../../Section';
import { SectionTitle } from '../../SectionTitle';
import * as styles from './InfrastructureWrapper.css';

export interface IInfrastructureWrapperProps {
  headerLinks: React.ReactNode;
}

export const InfrastructureWrapper: React.FC<React.PropsWithChildren<IInfrastructureWrapperProps>> = ({
  headerLinks,
  children,
}) => {
  return (
    <LayoutBlock>
      <Section data-nav-tab="infrastructure/Расположение" id="infrastructure">
        <div className={styles['header']}>
          <SectionTitle>Расположение</SectionTitle>
          <span className={styles['links']}>{headerLinks}</span>
        </div>
        {children}
      </Section>
    </LayoutBlock>
  );
};
