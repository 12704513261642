import * as React from 'react';
import * as style from './Builders.css';

import { Builder } from '../Builder';
import { INewbuildingBuilder } from '../../../../types/newbuilding/builder';

export interface IBuildersProps {
  builders?: INewbuildingBuilder[] | null;
  newbuildingDisplayName?: string;
  isDevCardRedesignEnabled: boolean;
}

export const Builders: React.FC<IBuildersProps> = props => {
  const { builders, isDevCardRedesignEnabled } = props;

  if (!builders || builders.length < 1) {
    return null;
  }

  return (
    <div className={style['builders']} data-mark={'Builders'}>
      {builders.map(builder => [
        <Builder key={builder.id} builder={builder} isDevCardRedesignEnabled={isDevCardRedesignEnabled} />,
      ])}
    </div>
  );
};
