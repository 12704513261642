import { ca } from '@cian/analytics';

export function trackOpenFullscreenGallery() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'open_fullscreen_gallery',
    label: '',
  });
}
