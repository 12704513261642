import { Label } from '@cian/ui-kit';
import * as React from 'react';

import { GetOnlineButton } from '../GetOnlineButton';

interface IPaymentColumnBottomProps {
  isBlackFridayPromo: boolean | undefined;
  isGetOnlineButtonAvailable: boolean;
  mortgagePartnerLink: string;
  onGetOnlineButtonClick: (event: React.MouseEvent) => void;
}

export const PaymentColumnBottom: React.FC<IPaymentColumnBottomProps> = ({
  isBlackFridayPromo,
  isGetOnlineButtonAvailable,
  mortgagePartnerLink,
  onGetOnlineButtonClick,
}) => {
  if (isBlackFridayPromo) {
    return (
      <Label background="primary_100" color="white_100" data-testid="MortgageOfferBlackFridayLabel">
        Sale
      </Label>
    );
  }

  if (isGetOnlineButtonAvailable) {
    return <GetOnlineButton href={mortgagePartnerLink} onClick={onGetOnlineButtonClick} />;
  }

  return null;
};
