import { ca } from '@cian/analytics';

export const trackReliableIconClick = (GKId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'JK_problems_icon_click',
    label: `green/${GKId}`,
    useLastProducts: true,
  });
};

export const trackProblemsIconClick = (GKId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'JK_problems_icon_click',
    label: `red/${GKId}`,
    useLastProducts: true,
  });
};

export const trackReliableIconHover = (GKId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'JK_problems_icon_hover',
    label: `green/${GKId}`,
    useLastProducts: true,
  });
};

export const trackProblemsIconHover = (GKId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'JK_problems_icon_hover',
    label: `red/${GKId}`,
    useLastProducts: true,
  });
};

export const trackReliableMoreClick = (GKId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'JK_problems_more_click',
    label: `green/${GKId}`,
    useLastProducts: true,
  });
};

export const trackReliableBlockShow = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'show_sopr',
    label: `reliability_block`,
    useLastProducts: true,
  });
};

export const trackGoToReliableHouseListing = (houseId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'click_reliability_block',
    label: houseId,
    useLastProducts: true,
  });
};
