import { FILTERS, MORTGAGE_BANNER_INTEREST_RATE } from '../../../constants/mortgage';
import { IPromoInfoSchema } from '../../../types/promo';
import { calculateMonthlyPayment } from '../../../utils/motgageCalculator';

type BannerMonthlyPaymentParams = {
  price: number;
  downPayment: number;
  loanTerm: number;

  initialPrice: number;
  initialDownPayment: number;

  filteredMortgagePromos: IPromoInfoSchema[];
};

export function calculateBannerMonthlyPayment({
  price,
  downPayment,
  loanTerm,
  initialPrice,
  initialDownPayment,
  filteredMortgagePromos,
}: BannerMonthlyPaymentParams) {
  const monthlyPayment = calculateMonthlyPayment({
    initialPayment: downPayment,
    interestRate: MORTGAGE_BANNER_INTEREST_RATE,
    loanTerm,
    price,
  });

  if (monthlyPayment === 0 || price < downPayment || filteredMortgagePromos.length === 0) {
    return calculateMonthlyPayment({
      initialPayment: initialDownPayment,
      interestRate: MORTGAGE_BANNER_INTEREST_RATE,
      loanTerm: FILTERS.DEFAULT_LOAN_TERM,
      price: initialPrice,
    });
  }

  return monthlyPayment;
}
