import * as React from 'react';
import { SectionTitle } from '../SectionTitle';
import * as styles from './Section.css';

interface ISectionProps {
  'data-mark'?: string;
  'data-nav-tab'?: string;
  'data-name'?: string;
  'data-testid'?: string;
  children: React.ReactNode;
  id?: string;
  title?: string;
}

export const Section = React.forwardRef<HTMLDivElement, ISectionProps>(({ children, title, ...props }, ref) => {
  return (
    <section {...props} ref={ref} className={props['data-nav-tab'] ? styles['section--anchor'] : undefined}>
      {title && (
        <div className={styles['title']}>
          <SectionTitle>{title}</SectionTitle>
        </div>
      )}
      <div>{children}</div>
    </section>
  );
});

Section.displayName = 'Section';
