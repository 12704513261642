import { Image } from '@cian/ui-kit';
import * as React from 'react';

import { IPromoInfoSchema } from '../../types/promo';
import { getPromoIcon } from './utils/getPromoIcon';

import * as styles from './PromoItemIcon.css';

interface IPromoItemIconProps {
  promo: IPromoInfoSchema;
}

export function PromoItemIcon({ promo }: IPromoItemIconProps) {
  const { authorLogoUrl, promoType, bank, isBlackFriday } = promo;
  const isAuthorLogoVisible = isBlackFriday && authorLogoUrl;

  return (
    <div className={styles['container']}>
      <div className={styles['icon']}>
        {isAuthorLogoVisible ? (
          <Image src={authorLogoUrl} alt="logo" borderRadius="50%" objectFit="contain" />
        ) : (
          getPromoIcon(promoType, bank)
        )}
      </div>
    </div>
  );
}
