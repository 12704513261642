import { Microfrontend } from '@cian/mf-react';
import { JsonObject } from '@cian/mf-react/shared/types/json';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { useInfrastructureMicrofrontend } from './useInfrastructureMicrofrontend';
import { getMapApiKey, getSuggestApiKey } from '../../selectors/common';
import {
  InfrastructureError,
  InfrastructureLoader,
  InfrastructureWrapper,
} from '../../components/InfrastructureMicrofrontend';

interface IInfrastureMicrofrontendContainerProps {
  headerLinks?: React.ReactNode;
}

export function InfrastructureMicrofrontendContainer({ headerLinks }: IInfrastureMicrofrontendContainerProps) {
  const { manifest, fetchManifest, status } = useInfrastructureMicrofrontend();

  const yandexApiKey = useSelector(getMapApiKey);
  const yandexSuggestApiKey = useSelector(getSuggestApiKey);

  const microfrontendParams = React.useMemo<JsonObject>(
    () => ({
      yandexApiKey,
      yandexSuggestApiKey,
      profile: 'newbuilding',
    }),
    [yandexApiKey, yandexSuggestApiKey],
  );

  return (
    <InfrastructureWrapper headerLinks={headerLinks}>
      {status === 'loading' && <InfrastructureLoader />}
      {status === 'error' && <InfrastructureError retry={fetchManifest} />}
      {status === 'success' && manifest && (
        <Microfrontend
          name={manifest.microfrontendName}
          image={manifest.imageVersion}
          runtime={manifest.runtimeName}
          parameters={microfrontendParams}
        />
      )}
    </InfrastructureWrapper>
  );
}
