import { IApplicationState } from '../../types/redux';

export function getRegionId(state: IApplicationState): number | null {
  const {
    newbuilding: { geo },
  } = state;

  if (geo && geo.regionId) {
    return geo.regionId;
  }

  return null;
}
