import { ca } from '@cian/analytics';

export function trackClickLayoutsSeoLink() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'click_sopr',
    label: 'layout_seo_url',
    useLastProducts: true,
  });
}
