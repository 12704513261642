import * as React from 'react';
import { AsideBuilderContacts } from '../AsideBuilderContacts';
import { AsideCianContacts } from '../AsideCianContacts';
import { PageRowLayout } from '../PageRowLayout';
import { PriceContainer } from '../../containers/Price';
import { BuilderContainer } from '../../containers/Builder';
import { StickyBlock } from '../StickyBlock';
import { LeadFactoryCallButtonContainer } from '../../containers/LeadFactoryCallButton';
import { RightPromoContainer } from '../../containers/RightPromoContainer';
import { RightReliableLabelContainer } from '../../containers/RightReliableLabel';
import { SecondaryContactButtonsContainer } from '../../containers/SecondaryContactButtons';
import { SelectionsButtonContainer } from '../../containers/SelectionsButtonContainer';
import { WorkTimeInfoContainer } from '../../containers/WorkTimeInfo';
import { UpcomingSaleRequestFormContainer } from '../../containers/UpcomingSaleRequestFormContainer';
import { ActionButtonContainer } from '../../containers/ActionButton';
import { BrokerBlockContainer } from '../../containers/BrokerBlock';
import { PageRowItem } from '../PageRowItem';

const STICKY_BLOCK_TOP = 102;

export interface IRightColumnProps {
  dynamicCalltrackingPlaceType: string;
  isLeadFactory?: boolean;
  isUpcomingSale?: boolean;
}

export const RightColumn: React.FunctionComponent<IRightColumnProps> = ({
  dynamicCalltrackingPlaceType,
  isLeadFactory,
  isUpcomingSale,
}) => {
  /* istanbul ignore next */
  if (isUpcomingSale) {
    return (
      <StickyBlock top={STICKY_BLOCK_TOP}>
        <PageRowLayout fullwidth>
          <PageRowItem>
            <UpcomingSaleRequestFormContainer />
          </PageRowItem>
        </PageRowLayout>
      </StickyBlock>
    );
  }

  if (isLeadFactory) {
    return (
      <StickyBlock top={STICKY_BLOCK_TOP}>
        <PageRowLayout fullwidth>
          <PageRowItem>
            <PriceContainer />
            <BuilderContainer />
            <AsideCianContacts>
              <LeadFactoryCallButtonContainer placeType={dynamicCalltrackingPlaceType} size="M" />
              <SelectionsButtonContainer analyticsType="sidebar" fullWidth secondary XXL />
              <WorkTimeInfoContainer />
            </AsideCianContacts>
            <RightReliableLabelContainer />
          </PageRowItem>
        </PageRowLayout>
        <PageRowLayout fullwidth>
          <BrokerBlockContainer />
        </PageRowLayout>
      </StickyBlock>
    );
  }

  return (
    <StickyBlock top={STICKY_BLOCK_TOP}>
      <PageRowLayout fullwidth>
        <PageRowItem>
          <RightPromoContainer />
          <PriceContainer />
          <BuilderContainer />
          <AsideBuilderContacts>
            <ActionButtonContainer
              dynamicCalltrackingPlaceType={dynamicCalltrackingPlaceType}
              analyticOnOpenActionName="Open_jk_card_blockJK"
              fullWidth
              XXL
            />
            <SecondaryContactButtonsContainer />
            <WorkTimeInfoContainer />
          </AsideBuilderContacts>
          <RightReliableLabelContainer />
        </PageRowItem>
      </PageRowLayout>
      <PageRowLayout fullwidth>
        <BrokerBlockContainer />
      </PageRowLayout>
    </StickyBlock>
  );
};
