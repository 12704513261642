import { connect } from 'react-redux';
import {
  trackNewbuildingFromTheBuilderBlockAppearance,
  trackTransferToNewbuildingFromTheBuilder,
} from '../../actions/analytics/trackNewbuildingFromDeveloper';
import { INewbuildingFromTheBuilderProps, NewbuildingFromTheBuilder } from '../../components/NewbuildingFromTheBuilder';
import { getNewbuldingFromTheBuilder, getNewbuldingFromTheBuilderLink } from '../../selectors/newbuilding';
import { IApplicationState } from '../../types/redux';
import { TThunkDispatch } from '../../types/redux/thunk';

type TBuildersStateProps = Pick<INewbuildingFromTheBuilderProps, 'newbuildings' | 'newbuildingsLink'>;

export function mapStateToProps(state: IApplicationState): TBuildersStateProps {
  return {
    newbuildings: getNewbuldingFromTheBuilder(state),
    newbuildingsLink: getNewbuldingFromTheBuilderLink(state),
  };
}

type TSimilarNewbuildingsDispatchProps = Pick<
  INewbuildingFromTheBuilderProps,
  'trackNewbuildingFromTheBuilderBlockAppearance' | 'trackTransferToNewbuildingFromTheBuilder'
>;

export function mapDispatchToProps(dispatch: TThunkDispatch): TSimilarNewbuildingsDispatchProps {
  return {
    trackNewbuildingFromTheBuilderBlockAppearance: () => {
      dispatch(trackNewbuildingFromTheBuilderBlockAppearance());
    },
    trackTransferToNewbuildingFromTheBuilder: (index: number) => {
      dispatch(trackTransferToNewbuildingFromTheBuilder({ index }));
    },
  };
}

export const NewbuildingFromTheBuilderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewbuildingFromTheBuilder);
