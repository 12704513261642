import { getCookie } from '../../cookie';
import { createCountPopupShowedCookie } from './createCountPopupShowedCookie';
import { LAST_HOPE_SHOWED_COOKIE_KEY } from '../../../constants/lastHopePopup';

export function getOrCreateHistoryPopupShowedCookie() {
  const cookieValue = getCookie(LAST_HOPE_SHOWED_COOKIE_KEY);
  const isValid = cookieValue && /\d:\d+/.test(cookieValue);

  if (cookieValue && isValid) {
    const pair = cookieValue.split(':');

    return {
      count: Number(pair[0]),
      timeStarted: Number(pair[1]),
    };
  }

  const count = 0;
  const timeStarted = Date.now();

  createCountPopupShowedCookie(count, timeStarted);

  return { count, timeStarted };
}
