import * as React from 'react';
import { Description } from './parts/Description';
import { Section } from '../Section';
import { LayoutBlock } from '../LayoutBlock';

export interface IAboutProps {
  description?: string | null;
  displayName?: string | null;

  trackActionOpenInfoAboutJK(): void;
}

export const About: React.FC<IAboutProps> = props => {
  const { description, displayName, trackActionOpenInfoAboutJK } = props;

  const isDescriptionHere = !!description;

  if (!isDescriptionHere) {
    return null;
  }

  return (
    <LayoutBlock>
      <Section data-nav-tab="info/Про жилой комплекс" id="info" data-mark="About">
        <Description
          description={description}
          newbuildingDisplayName={displayName}
          trackActionOpenInfoAboutJK={trackActionOpenInfoAboutJK}
        />
      </Section>
    </LayoutBlock>
  );
};
