/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  defineDataset,
  EDatasetColors,
  EDatasetColorTypes,
  filterTicksVarA,
  getTooltipTitleVarA,
  TChartFactoryProps,
} from '@cian/react-chartjs-component';
import { useSelector } from 'react-redux';
import { isEmpty, path } from 'ramda';

import { selectRealtyValuationData } from '../../../../../selectors/realtyValuation';
import { EValuationTabs } from '../../../constants/tabs';
import { trackRealtyValuationChartTooltipShow } from '../../../tracking';
import { doOnceDecorator } from '../../../utils/doOnceDecorator';

export const useDynamicSalesChartCfg = (): TChartFactoryProps | null => {
  const realtyValuation = useSelector(selectRealtyValuationData);
  const trackTooltipShow = React.useMemo(() => doOnceDecorator(trackRealtyValuationChartTooltipShow), []);

  return React.useMemo(() => {
    if (!realtyValuation?.salesDynamics?.chart) {
      return null;
    }

    const {
      maxChartValue,
      minChartValue,
      labelStep,
      data: { values, labels },
    } = realtyValuation.salesDynamics.chart;

    const dataset = defineDataset({
      data: values,
      color: EDatasetColors.BLUE,
      colorType: EDatasetColorTypes.SOLID,
    });

    return {
      datasetCfgCreators: [dataset],
      chartCfgParams: {
        chartType: 'bar',
        labelsData: labels,
        yMaxVal: maxChartValue,
        yMinVal: minChartValue,
        yValStep: labelStep,
        tooltipTitleCb: getTooltipTitleVarA,
        xAfterTickToLabelConversion: filterTicksVarA,
        onHover: (_, activeElements) => {
          if (!isEmpty(activeElements)) {
            trackTooltipShow(EValuationTabs.SalesDynamic);
          }
        },
        tooltipLabelCb: (tooltipItem, data) => {
          try {
            const originValue = path(['datasets', tooltipItem.datasetIndex, 'data', tooltipItem.index], data) as number;

            return `${originValue} продано`;
          } catch (e) {
            return '';
          }
        },
      },
      directChartCfg: {
        options: {
          scales: {
            x: {
              gridLines: {
                display: false,
                color: 'rgba(21, 34, 66, 0.06)',
              },
            },
          },
        },
      },
      chartHeight: 264,
    };
  }, [realtyValuation, trackTooltipShow]);
};
