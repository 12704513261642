import * as React from 'react';

import { PageRowLayout } from '../PageRowLayout';
import { FavoriteButtonContainer } from '../../containers/FavoriteButton';
import * as style from './CarouselOverlayPanel.css';
import { NewbuildingReviewsStatContainer } from '../../containers/NewbuildingReviewsStat';

export const CarouselOverlayPanel = () => {
  return (
    <PageRowLayout>
      <div className={style['rating']}>
        <NewbuildingReviewsStatContainer />
      </div>
      <div className={style['list']}>
        <div className={style['item']}>
          <FavoriteButtonContainer />
        </div>
      </div>
    </PageRowLayout>
  );
};
