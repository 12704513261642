import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectPricesFiltersData,
  selectPricesFiltersState,
  selectRealtyValuationData,
  selectRealtyValuationDataFetching,
} from '../../../../selectors/realtyValuation';
import { getPreparedFiltersProps } from '../../utils/getPreparedFiltersProps';
import { updateRealtyValuationAction, setPriceFilterAction } from '../../../../actions/realtyValuation';
import { Filters, Summaries, TabContent } from '../../../../components/RealtyValuation';
import { PricesChartContainer } from '../PricesChartContainer';
import { getPriceDiffTitle } from '../../utils/getPriceDiffTitle';
import { TThunkDispatch } from '../../../../types/redux/thunk';
import { EValuationTabs } from '../../constants/tabs';
import { trackRealtyValuationFilterChange } from '../../tracking';

export const PricesDynamicsContainer: React.VFC = () => {
  const dispatch = useDispatch<TThunkDispatch>();

  const isFetching = useSelector(selectRealtyValuationDataFetching);
  const realtyValuation = useSelector(selectRealtyValuationData);
  const pricesFiltersData = useSelector(selectPricesFiltersData);
  const pricesFiltersState = useSelector(selectPricesFiltersState);

  const filtersProps = React.useMemo(() => {
    if (!pricesFiltersData) {
      return null;
    }

    return getPreparedFiltersProps({
      onChange: async (filterName, filterValue) => {
        trackRealtyValuationFilterChange(filterName, filterValue);
        dispatch(setPriceFilterAction({ filterName, filterValue }));
        await dispatch(updateRealtyValuationAction(EValuationTabs.PriceDynamic));
      },
      filtersData: pricesFiltersData,
      filtersState: pricesFiltersState,
    });
    /** Так и должно быть */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pricesFiltersData]);

  const priceDiffTitle = React.useMemo(() => {
    const { period } = pricesFiltersState;

    return getPriceDiffTitle(period);
  }, [pricesFiltersState]);

  if (!realtyValuation?.priceDynamics) {
    return null;
  }

  const { priceDynamics } = realtyValuation;

  return (
    <TabContent
      filters={filtersProps && <Filters filtersProps={filtersProps} testId="valuation_prices_filters" />}
      summaries={
        <Summaries
          avgPrice={priceDynamics.avgPrice || '—'}
          avgPriceSqm={priceDynamics.avgPriceSqm || '—'}
          priceDiff={priceDynamics.priceDiff}
          priceDiffTitle={priceDiffTitle}
        />
      }
      chart={<PricesChartContainer />}
      isFetching={isFetching}
      testId="valuation_prices_tab"
    />
  );
};
