import * as React from 'react';
import * as style from '../../NewbuildingItem.css';

interface IUndergroundInfoProps {
  undergroundName?: string | null;
  undergroundColor?: string | null;
}

export const UndergroundInfo: React.FC<IUndergroundInfoProps> = props => {
  const { undergroundName, undergroundColor } = props;

  if (!undergroundName) {
    return null;
  }

  return (
    <div className={style['underground']}>
      {undergroundColor && (
        <div style={{ backgroundColor: `#${undergroundColor}` }} className={style['undergroundColor']} />
      )}
      {undergroundName}
    </div>
  );
};
