import * as React from 'react';
import {
  connect as adFoxConnect,
  IAdFoxBannerOnLoadData,
  TAdFoxBannerProps as TAdFoxBannerComponentProps,
} from '@cian/adfox-component';
import { OwnTemplates } from './components/OwnTemplates';

const AdfoxComponent = adFoxConnect(OwnTemplates);

type TAdFoxContainerProps = {
  disableYan?: boolean;
} & TAdFoxBannerComponentProps;

export const AdfoxContainer: React.FC<TAdFoxContainerProps> = props => {
  const [hidden, setHidden] = React.useState(false);
  const { disableYan, onLoad, ...sharedProps } = props;

  const handleLoad = React.useCallback(
    (data: IAdFoxBannerOnLoadData) => {
      const { bundleName } = data;

      if (disableYan && bundleName === 'banner.direct') {
        setHidden(true);
      }

      if (onLoad) {
        onLoad(data);
      }
    },
    [disableYan],
  );

  if (disableYan && hidden) {
    return null;
  }

  return <AdfoxComponent {...sharedProps} onLoad={handleLoad} />;
};
