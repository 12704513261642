import * as React from 'react';
import { IconMetroMsk16, IconMetroSpb16 } from '@cian/ui-kit';
import * as styles from './UndergroundIcon.css';
import { isMoscowOrMO, isSpbOrLO } from '../../../../../../../../utils/businessLogic/region';

interface IUndergroundIconProps {
  regionId: number | null;
  lineColor?: string | null;
}

export const UndergroundIcon: React.FC<IUndergroundIconProps> = props => {
  const { regionId, lineColor } = props;

  if (!lineColor) {
    return null;
  }

  if (regionId && isMoscowOrMO(regionId)) {
    return (
      <div className={styles['undergroundIcon']} style={{ color: `#${lineColor}` }}>
        <IconMetroMsk16 color={'current_color'} />
      </div>
    );
  }

  if (regionId && isSpbOrLO(regionId)) {
    return (
      <div className={styles['undergroundIcon']} style={{ color: `#${lineColor}` }}>
        <IconMetroSpb16 color={'current_color'} />
      </div>
    );
  }

  return <div className={styles['undergroundIcon--icon']} style={{ color: `#${lineColor}` }} />;
};
