import * as React from 'react';
import { Button, ArticleHeading5, LinkButton } from '@cian/ui-kit';
import * as styles from './ReliabilityHouses.css';
import { IReliableHouse } from '../../types/newbuilding/reliableLabel';

export interface IReliableHousesProps {
  houses: IReliableHouse[];
  onClick(houseId: number): void;
}

export function ReliabilityHouses({ houses, onClick }: IReliableHousesProps) {
  const [showEllipsis, toggleEllipsis] = React.useState(houses.length > 5);

  const onEllipsisClick = React.useCallback(() => toggleEllipsis(false), []);
  const visibleHouses = showEllipsis ? houses.slice(0, 5) : houses;

  return (
    <div className={styles['houses']}>
      <div className={styles['houses-title']}>
        <ArticleHeading5 color="white_100">Статус надёжности присвоен корпусам:</ArticleHeading5>
      </div>
      <div className={styles['houses-list']}>
        {visibleHouses.map(house => (
          <div className={styles['house-link']} key={`house_${house.houseId}`}>
            <LinkButton
              href={house.url}
              target="_blank"
              theme="fill_white_secondary"
              size="XS"
              onClick={() => onClick(house.houseId)}
            >
              {house.name}
            </LinkButton>
          </div>
        ))}
        {showEllipsis && (
          <div className={styles['house-link']}>
            <Button theme="fill_white_secondary" size="XS" onClick={onEllipsisClick}>
              ...
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
