import * as React from 'react';
import { IconArrowUpRight16, IconHouse16 } from '@cian/ui-kit';
import * as styles from './NearbyNewbuildingsLink.css';
import { Link } from '../Link';

export interface INearbyNewbuildingsLinkProps {
  url: string;
  onClick(): void;
}

export const NearbyNewbuildingsLink: React.FC<INearbyNewbuildingsLinkProps> = (props: INearbyNewbuildingsLinkProps) => {
  const { url, onClick } = props;

  return (
    <Link url={url} target="blank" linkStyle={styles['link']} onClick={onClick}>
      <div className={styles['logo']}>
        <IconHouse16 color={'primary_100'} />
      </div>
      Другие ЖК рядом
      <IconArrowUpRight16 color={'primary_100'} />
    </Link>
  );
};
