import * as React from 'react';
import { ArticleHeading2, InlineText1 } from '@cian/ui-kit';

import * as styles from './styles.css';
import { LayoutBlock } from '../LayoutBlock';

interface IEscrowProps {
  description: React.ReactElement;
  houses: React.ReactElement;
}

export const Escrow = ({ description, houses }: IEscrowProps) => {
  return (
    <LayoutBlock>
      <div className={styles['escrow']} id="escrow">
        <div className={styles['content']}>
          <div className={styles['title']}>
            <ArticleHeading2>Безопасная сделка с эскроу-счётом</ArticleHeading2>
          </div>
          <InlineText1>
            Покупатель квартиры в строящемся доме после заключения договора с застройщиком открывает эскроу-счет в
            банке. Застройщик получит деньги только после завершения строительства. Если этого не произойдёт —
            покупатель получает деньги назад.
          </InlineText1>
          <div className={styles['controls']}>
            <div className={styles['control']}>{houses}</div>
            <div className={styles['control']}>{description}</div>
          </div>
        </div>
      </div>
    </LayoutBlock>
  );
};
