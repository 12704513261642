import { ca } from '@cian/analytics';

export const trackBuilderLinkEvent = ({
  newbuildingId,
  action,
}: {
  developerId?: number;
  newbuildingId: number;
  action: 'Show' | 'Click';
}) => {
  ca('teaser', {
    product: {
      name: `LinkToDeveloperSite${action}`,
      id: newbuildingId,
      extra: {
        place: 'default_cardJK_header_block',
        eventDate: Date.now(),
      },
    },
  });
};
