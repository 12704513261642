import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackCianMortgageClick } from '../../analytics/promos';
import { closePromoInfo } from '../../actions/promoInfo';
import { PromoDetailsModal } from '../../components/PromoDetailsModal';
import { getHasContacts, getNewbuildingId } from '../../selectors/newbuilding';
import { selectPromoInfo } from '../../selectors/promoInfo';
import { IPromoInfoSchema } from '../../types/promo';
import { useApplicationContext } from '../../utils/applicationContext';
import { getAuthorDescription, getAuthorLabel, getAuthorName, getMortgagePartnerLink, getSubtitle } from './helpers';
import { ActionButtonContainer } from './parts/ActionButtonContainer';
import { usePromoDetailsModalTrigger } from './hooks';

export const PromoDetailsContainer: React.FC = () => {
  const promo = useSelector(selectPromoInfo);
  const newbuildingId = useSelector(getNewbuildingId);
  const hasContacts = useSelector(getHasContacts);
  const dispatch = useDispatch();

  const { config } = useApplicationContext();
  const mortgagePartnerLink = getMortgagePartnerLink(promo, config);

  usePromoDetailsModalTrigger();

  const handleClose = React.useCallback(() => {
    dispatch(closePromoInfo());
  }, [dispatch]);

  const handleMortgageClick = React.useCallback(() => {
    trackCianMortgageClick(promo as IPromoInfoSchema, newbuildingId);
  }, [promo, newbuildingId]);

  if (!promo) {
    return null;
  }

  return (
    <PromoDetailsModal
      authorDescription={getAuthorDescription(promo, hasContacts)}
      authorLogoUrl={promo.authorLogoUrl}
      authorLabel={getAuthorLabel(promo)}
      authorName={getAuthorName(promo)}
      contactsButton={
        <ActionButtonContainer hasContacts={hasContacts} onMortgageClick={handleMortgageClick} promo={promo} />
      }
      bank={promo.bank}
      banks={promo.banks}
      description={promo.description}
      features={promo.features}
      mortgagePartnerLink={mortgagePartnerLink}
      subtitle={getSubtitle(promo)}
      title={promo.name}
      onClose={handleClose}
      onMortgageClick={handleMortgageClick}
      open
    />
  );
};
