import { IPlaceholderImage } from '../../types/aerialView';
import placeholder from './assets/placeholder.png';
import placeholder2x from './assets/placeholder@2x.png';

export const PLACEHOLDERS: IPlaceholderImage[] = [
  {
    src: placeholder,
    src2x: placeholder2x,
  },
];
