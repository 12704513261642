import * as React from 'react';
import { ArticleParagraph1 } from '@cian/ui-kit';
import { IconChevronDownSmall16, IconChevronUpSmall16 } from '@cian/ui-kit/icons';
import * as styles from './styles.css';

interface IOffersCountLinkProps {
  expanded: boolean;
  count: number;
  onClick: () => void;
}

export const OffersCountLink: React.VFC<IOffersCountLinkProps> = ({ count, expanded, onClick }) => {
  return (
    <div onClick={onClick} role="button" className={styles['container']} data-testid="OffersCountLink">
      {expanded ? (
        <>
          <ArticleParagraph1 color="primary_100">Скрыть {count} предложений</ArticleParagraph1>
          <span className={styles['arrow']}>
            <IconChevronUpSmall16 color="primary_100" />
          </span>
        </>
      ) : (
        <>
          <ArticleParagraph1 color="primary_100">Показать еще {count} предложений</ArticleParagraph1>
          <span className={styles['arrow']}>
            <IconChevronDownSmall16 color="primary_100" />
          </span>
        </>
      )}
    </div>
  );
};
