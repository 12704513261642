import * as React from 'react';
import { IconSpecialLoanRate24, UIHeading4, UIText1 } from '@cian/ui-kit';

import * as styles from './BankItem.css';

export interface IBankItemProps {
  logoUrl?: string | null;
  name: string;
  onLinkClick?(event: React.MouseEvent<HTMLAnchorElement>): void;
  url?: string | null;
}

export const BankItem: React.FC<IBankItemProps> = ({ logoUrl, name, onLinkClick, url }) => {
  return (
    <div className={styles['container']} data-testid="BankItem">
      <div className={styles['logo']}>
        {logoUrl ? (
          <img className={styles['logo-image']} src={logoUrl} alt={name} />
        ) : (
          <div className={styles['logo-icon']}>
            <IconSpecialLoanRate24 />
          </div>
        )}
      </div>
      <div className={styles['info']}>
        <UIHeading4>{name}</UIHeading4>
        {url && (
          <UIText1>
            <a className={styles['link']} href={url} onClick={onLinkClick}>
              Посмотреть ЖК
            </a>
          </UIText1>
        )}
      </div>
    </div>
  );
};
