import { connect } from 'react-redux';
import { IFavoriteButtonProps, FavoriteButton } from '../../components/FavoriteButton';
import { getIsFavorite } from '../../selectors/newbuilding';
import { IApplicationState } from '../../types/redux';
import { getNewbuildingId } from '../../selectors/newbuilding/getNewduildingId';
import { getGALabel } from '../../selectors/newbuilding/getGALabel';
// eslint-disable-next-line import/no-restricted-paths
import { addFavorite, removeFavorite } from '../../../browser/services/favorite';
import { TThunkDispatch } from '../../types/redux/thunk';

type TFavoriteButtonStateProps = Pick<IFavoriteButtonProps, 'isFavorite' | 'newbuildingId' | 'gaLabel'>;

type TCompDispatchProps = Pick<IFavoriteButtonProps, 'actionAddFavorite' | 'actionRemoveFavorite'> &
  TFavoriteButtonStateProps;

interface IFavoriteButtonDispatchProps {
  dispatchAddFavorite(newbuildingId: number, gaLabel: string): void;
  dispatchRemoveFavorite(newbuildingId: number, gaLabel: string): void;
}

export function mapStateToProps(state: IApplicationState): TFavoriteButtonStateProps {
  return {
    isFavorite: getIsFavorite(state),
    newbuildingId: getNewbuildingId(state),
    gaLabel: getGALabel(state),
  };
}

export const mapDispatchToProps = (dispatch: TThunkDispatch): IFavoriteButtonDispatchProps => {
  return {
    dispatchAddFavorite: (newbuildingId: number, gaLabel: string) => {
      dispatch(addFavorite(newbuildingId, gaLabel));
    },
    dispatchRemoveFavorite: (newbuildingId: number, gaLabel: string) => {
      dispatch(removeFavorite(newbuildingId, gaLabel));
    },
  };
};

export function mergeProps(
  stateProps: TFavoriteButtonStateProps,
  dispatchProps: IFavoriteButtonDispatchProps,
): TCompDispatchProps {
  const { newbuildingId, gaLabel } = stateProps;

  return {
    actionAddFavorite: () => dispatchProps.dispatchAddFavorite(newbuildingId, gaLabel),
    actionRemoveFavorite: () => dispatchProps.dispatchRemoveFavorite(newbuildingId, gaLabel),
    ...stateProps,
  };
}

export const FavoriteButtonContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(FavoriteButton);
