import { ca } from '@cian/analytics';

import { IPromoInfoSchema } from '../../types/promo';
import { getNewbuildingProduct } from '../../utils/analytics';

export function trackCianMortgageClick(promo: IPromoInfoSchema, newbuildingId: number) {
  const { promoType, promoId, uniquePromoId } = promo;

  ca('eventSite', {
    action: 'click_morgage',
    category: 'Promo',
    label: promoType,
    name: 'oldevent',
    products: [getNewbuildingProduct({ newbuildingId, extra: { promoId, promoCianMortgage: uniquePromoId } })],
  });
}
