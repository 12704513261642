export interface ISpecificationItem {
  /** Тип характеристики **/
  itemType: EItemType;
  /** Название характеристики **/
  title: string;
  /** Дополнительная информация для тултипа **/
  tooltipText?: string | null;
  /** Значение **/
  value?: string | null;
}

export enum EItemType {
  /** Высота потолков **/
  CeilingHeight = 'ceilingHeight',
  /** Отделка **/
  Decorations = 'decorations',
  /** Срок сдачи **/
  FinishDate = 'finishDate',
  /** Этажи **/
  Floors = 'floors',
  /** Корпуса **/
  Houses = 'houses',
  /** Тип домов (материал) **/
  MaterialType = 'materialType',
  /** Класс ЖК **/
  NewbuildingClass = 'newbuildingClass',
  /** Парковка **/
  Parking = 'parking',
  /** Статус жилоых помещений **/
  'ResidentialStatus' = 'residentialStatus',
}
