import { ITransportAccessibility } from '../../types/newbuilding/transportAccessibility';
import { IApplicationState } from '../../types/redux';

export const getTransportAccessibility = (state: IApplicationState): ITransportAccessibility | null => {
  const { transportAccessibility } = state.newbuilding;

  if (!transportAccessibility) {
    return null;
  }

  return transportAccessibility.transport || null;
};
