import * as React from 'react';
import { ReliabilityDetails, ReliabilityDesktop } from '@cian/frontend-newbuilding-reliability-widget';
import { EscrowDescription } from '@cian/frontend-newbuilding-reliability-widget/es/components/EscrowDescription';
import { NewbuildingReliabilityContext } from '@cian/frontend-newbuilding-reliability-widget/es/shared/context/NewbuildingReliabilityContext';
import { useSelector } from 'react-redux';
import { EscrowHouses } from '../../components/EscrowHouses';
import { DOM_RF_LINK } from '../../constants/externalLinks';
import { getNewbuildingId } from '../../selectors/newbuilding';
import { getEscrowHouses } from '../../selectors/newbuilding/getEscrowHouses';
import { LayoutBlock } from '../../components/LayoutBlock';
import { useApplicationContext } from '../../utils/applicationContext';
import { isReliabilityEnabledSelector } from '../../selectors/reliability/isReliabilityEnabledSelector';
import { reliabilitySelector } from '../../selectors/reliability/reliabilitySelector';
import { trackHousesWithEscrowLinkClick, trackReliableBlockShow } from './tracking';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

export const ReliabilityV2Container = () => {
  const newbuildingId = useSelector(getNewbuildingId);
  const houses = useSelector(getEscrowHouses);
  const reliability = useSelector(reliabilitySelector);
  const reliabilityEnabled = useSelector(isReliabilityEnabledSelector);
  const [isDetaisOpen, setIsDetailsOpen] = React.useState<boolean>(false);
  const [isEscrowOpen, setEscrowOpen] = React.useState<boolean>(false);

  const closeDetails = React.useCallback(() => setIsDetailsOpen(false), [setIsDetailsOpen]);
  const openDetails = React.useCallback(() => setIsDetailsOpen(true), [setIsDetailsOpen]);

  const closeEscrow = React.useCallback(() => setEscrowOpen(false), [setEscrowOpen]);
  const openEscrow = React.useCallback(() => setEscrowOpen(true), [setEscrowOpen]);

  const { httpApi, logger } = useApplicationContext();

  const contextValue = React.useMemo(() => ({ httpApi, logger }), [httpApi, logger]);

  if (!reliability || !reliabilityEnabled) {
    return null;
  }

  return (
    <LayoutBlock data-testid="reliablev2">
      <ActionAfterViewObserver callback={trackReliableBlockShow} triggerOnce>
        <div id="reliable">
          <ReliabilityDesktop
            title={reliability.checkStatus.title}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            status={reliability.checkStatus.status as any}
            date={reliability.checkStatus.date}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            details={reliability.details as any}
            domrfLink={DOM_RF_LINK}
            onEscrowButtonClick={openEscrow}
            onDetailsButtonClick={openDetails}
            actions={reliability.actions}
          />
          <NewbuildingReliabilityContext.Provider value={contextValue}>
            <ReliabilityDetails
              onClose={closeDetails}
              open={isDetaisOpen}
              onEscrowIconClick={openEscrow}
              newbuildingId={newbuildingId}
            />
          </NewbuildingReliabilityContext.Provider>

          <EscrowDescription
            onClose={closeEscrow}
            isOpen={isEscrowOpen}
            houses={
              <EscrowHouses onClick={trackHousesWithEscrowLinkClick} houses={houses} newbuildingId={newbuildingId} />
            }
          />
        </div>
      </ActionAfterViewObserver>
    </LayoutBlock>
  );
};
