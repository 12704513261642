import * as React from 'react';
import {
  defineDataset,
  EDatasetColors,
  EDatasetColorTypes,
  filterTicksVarA,
  getTooltipTitleVarA,
  TChartFactoryProps,
} from '@cian/react-chartjs-component';
import { useSelector } from 'react-redux';
import { getPrettyPrice } from '@cian/valuation-utils-component';
import { isEmpty, path } from 'ramda';

import { ROUBLE_SIGN } from '../../../../../constants/symbols';
import { selectRealtyValuationData } from '../../../../../selectors/realtyValuation';
import { trackRealtyValuationChartTooltipShow } from '../../../tracking';
import { EValuationTabs } from '../../../constants/tabs';
import { doOnceDecorator } from '../../../utils/doOnceDecorator';

export const useDynamicPricesChartCfg = (): TChartFactoryProps | null => {
  const realtyValuation = useSelector(selectRealtyValuationData);
  const priceUnit = ROUBLE_SIGN;
  const trackTooltipShow = React.useMemo(() => doOnceDecorator(trackRealtyValuationChartTooltipShow), []);

  return React.useMemo(() => {
    if (!realtyValuation?.priceDynamics?.chart) {
      return null;
    }

    const chartData = realtyValuation.priceDynamics.chart;

    const {
      data: { values, labels },
      maxChartValue,
      minChartValue,
      labelStep,
    } = chartData;

    const line = defineDataset({
      data: values,
      color: EDatasetColors.BLUE,
      colorType: EDatasetColorTypes.GRADIENT,
    });

    return {
      chartHeight: 220,
      datasetCfgCreators: [line],
      chartCfgParams: {
        chartType: 'line',
        labelsData: labels,
        yMaxVal: maxChartValue,
        yMinVal: minChartValue,
        yValStep: labelStep,
        xAfterTickToLabelConversion: filterTicksVarA,
        xGridLinesColor: 'transparent',
        tooltipTitleCb: getTooltipTitleVarA,
        onHover: (_, activeElements) => {
          if (!isEmpty(activeElements)) {
            trackTooltipShow(EValuationTabs.PriceDynamic);
          }
        },
        tooltipLabelCb: (tooltipItem, data) => {
          try {
            const originValue = path(['datasets', tooltipItem.datasetIndex, 'data', tooltipItem.index], data) as number;

            return `Средняя цена ${getPrettyPrice(originValue, { unit: priceUnit })}`;
          } catch (e) {
            return '';
          }
        },
        yTicksCb: val =>
          getPrettyPrice(Number(val), {
            showUnit: false,
            showPostfix: false,
          }),
      },
    };
  }, [realtyValuation, priceUnit, trackTooltipShow]);
};
