import { ca } from '@cian/analytics';

export function trackPopupShow() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'newbuilding_popup',
    action: 'show_sopr',
    label: 'ask_builder',
    useLastProducts: true,
  });
}

export function trackPopupClose() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'newbuilding_popup',
    action: 'close_sopr',
    label: 'ask_builder',
    useLastProducts: true,
  });
}
