import { ca } from '@cian/analytics';

import { IBrokerAnalyticsUser } from '../../../types/newbuildingBroker';

export const trackBannerShown = (user: IBrokerAnalyticsUser) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'CardJK',
    action: 'Show_sopr',
    label: 'BrokerDealBanner',
    user,
  });
};
