import { isCorrectPhoneObject } from './isCorrectPhoneObject';
import { IPhone } from '../../../types/newbuilding/builder';

interface ICropPhone {
  phone?: IPhone | null;
  isFullPhone?: boolean;
}

export const cropPhone = ({ phone, isFullPhone }: ICropPhone): string => {
  if (!phone || !isCorrectPhoneObject(phone)) {
    return '';
  }
  let numeric = phone.number as string;
  let countryCode = phone.countryCode as string;

  countryCode = countryCode === '7' || countryCode === '8' ? '+7' : countryCode;
  numeric = numeric.length === 7 ? numeric.replace(/(^\d{3})(\d{2})(\d{2}$)/, '$1 $2 $3') : numeric;
  numeric = isFullPhone ? numeric : numeric.replace(/\s?\d{2}$/, ' …');

  return `${countryCode} ${phone.code} ${numeric}`;
};
