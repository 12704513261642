import * as React from 'react';
import classNames from 'clsx';
import { InlineText2 } from '@cian/ui-kit';
import { Close } from './parts/Close';

import * as styles from './styles.css';

interface IFlatViewOrderTooltipProps {
  details: string;
  isOpen: boolean;
  onClose(): void;
}

export function FlatViewOrderTooltip({ onClose, details, isOpen }: IFlatViewOrderTooltipProps) {
  return (
    <div className={classNames(styles['container'], isOpen && styles['open'])}>
      <div className={styles['wrapper']}>
        <div className={styles['tooltip']}>
          <div className={styles['calendar']} />
          <InlineText2 fontWeight="bold">{details}</InlineText2>
          <Close onClick={onClose} />
        </div>
      </div>
    </div>
  );
}
