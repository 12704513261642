import { TReduxActions } from '../../types/redux/actions';
import { EActionTypes } from '../../actions/realtyValuation';
import { IRealtyValuationData, IRealtyValuationState, ITabs } from '../../types/realtyValuation';

export const defaultState: IRealtyValuationState = {
  isFetching: false,
  isError: false,
  data: null,
  pricesFiltersState: {} as IRealtyValuationState['pricesFiltersState'],
  salesFiltersState: {} as IRealtyValuationState['salesFiltersState'],
  tabs: null,
};

export function realtyValuationReducer(state = defaultState, action: TReduxActions): IRealtyValuationState {
  switch (action.type) {
    case EActionTypes.Request:
      return {
        ...state,
        isFetching: true,
      };

    case EActionTypes.Success:
      return {
        ...state,
        isFetching: false,
        isError: false,
        data: {
          ...(state.data as IRealtyValuationData),
          ...action.payload,
        },
      };

    case EActionTypes.Failure:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    case EActionTypes.SetPriceFilter:
      return {
        ...state,
        pricesFiltersState: {
          ...state.pricesFiltersState,
          [action.payload.filterName]: action.payload.filterValue,
        },
      };

    case EActionTypes.SetSalesFilter:
      return {
        ...state,
        salesFiltersState: {
          ...state.salesFiltersState,
          [action.payload.filterName]: action.payload.filterValue,
        },
      };

    case EActionTypes.SetActiveTab:
      return {
        ...state,
        tabs: {
          ...(state.tabs as ITabs),
          activeTab: action.payload,
        },
      };

    default:
      return state;
  }
}
