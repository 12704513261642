import * as React from 'react';
import { Button, ArticleHeading2, ModalWindow, ArticleParagraph2 } from '@cian/ui-kit';

import * as handsClapImg from './assets/hands-clap.png';
import * as styles from './UpcomingSaleSuccessModal.css';

interface IUpcomingSaleSuccessModalProps {
  open: boolean;
  onClose(): void;
}

export function UpcomingSaleSuccessModal({ open, onClose }: IUpcomingSaleSuccessModalProps) {
  return (
    <ModalWindow open={open} onClose={onClose}>
      <div className={styles['container']} data-testid="UpcomingSaleSuccessModal">
        <img className={styles['image']} src={handsClapImg.default} alt="Hands clap" />
        <div className={styles['title']}>
          <ArticleHeading2 as="h3" textAlign="center">
            Вы оставили заявку!
          </ArticleHeading2>
        </div>
        <div className={styles['content']}>
          <ArticleParagraph2 color="gray60_100" textAlign="center">
            Застройщик свяжется с вами,
            <br />
            как только у него появится дополнительная
            <br />
            информация по старту продаж в ЖК
          </ArticleParagraph2>
        </div>
        <div>
          <Button onClick={onClose} size="XS" theme="fill_primary" type="button">
            Понятно
          </Button>
        </div>
      </div>
    </ModalWindow>
  );
}
