import * as React from 'react';

import { ChartWrapper } from '../../../../components/RealtyValuation';
import { useDynamicPricesChartCfg } from './utils/useDynamicPricesChartCfg';

export function PricesChartContainer() {
  const [key, setKey] = React.useState(1);
  const chartCfg = useDynamicPricesChartCfg();

  /** Полностью ремаунтит график при изменении конфига. Нужно, потому что анимация обновления графика работает криво */
  React.useEffect(() => setKey(Math.random()), [chartCfg]);

  if (!chartCfg) {
    return null;
  }

  return <ChartWrapper key={key} chartCfg={chartCfg} />;
}
