import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { Button, ArticleHeading2, Image } from '@cian/ui-kit';
import { Section } from '../Section';
import { IPlaceholderImage } from '../../types/aerialView';
import icon from './assets/icon.svg';
import * as styles from './AerialView.css';

interface IAerialViewProps {
  text: string;
  image: IPlaceholderImage;
  onClick(): void;
  onAppear(): void;
}

export const AerialView: React.FC<IAerialViewProps> = ({ text, image, onClick, onAppear }) => {
  const { src, src2x } = image;

  return (
    <ActionAfterViewObserver callback={onAppear} triggerOnce={false}>
      <Section>
        <div className={styles['holder']}>
          <Image src={src} borderRadius={'4px'} height={400} sources={[{ srcset: `${src}, ${src2x} 2x` }]} />
          <div className={styles['content']}>
            <div className={styles['icon-holder']}>
              <Image src={icon} width={24} />
            </div>
            <div className={styles['header']}>
              <ArticleHeading2 color="white_100">{text}</ArticleHeading2>
            </div>
            <Button theme="fill_white_secondary" onClick={onClick}>
              Смотреть
            </Button>
          </div>
        </div>
      </Section>
    </ActionAfterViewObserver>
  );
};
