import { IPromoInfoSchema } from '../../../types/promo';

export const getAuthorLabel = (promo: IPromoInfoSchema): string | null => {
  const isCianMortgage = promo.promoType === 'cianMortgage';

  if (isCianMortgage) {
    return null;
  }

  return promo.isFromBuilder ? 'Застройщик' : 'Представитель застройщика';
};
