import * as React from 'react';
import { Agency24 } from '@cian/ui-kit/prev';

import { AuthorLabel } from '../AuthorLabel';
import * as styles from './AuthorItem.css';

export interface IAuthorItemProps {
  contactsButton?: React.ReactNode;
  name: string;
  label?: string | null;
  logoUrl?: string | null;
}

export const AuthorItem: React.FC<IAuthorItemProps> = ({ contactsButton, name, label, logoUrl }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['info']}>
        <AuthorLabel name={name} label={label} logoIcon={<Agency24 />} logoUrl={logoUrl} />
      </div>

      {contactsButton && <div className={styles['button']}>{contactsButton}</div>}
    </div>
  );
};
