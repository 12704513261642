import { numberToPrettyString } from '@cian/utils';
import { IPromoInfoSchema } from '../../../../../types/promo';

export const getInterestRate = (promo: IPromoInfoSchema): string => {
  const interestRate = promo.mortgageParams?.interestRate;

  if (!interestRate && interestRate !== 0) {
    return '—';
  }

  const roundedNumber = Math.round(interestRate * 100) / 100;

  return `${numberToPrettyString(roundedNumber)}%`;
};
