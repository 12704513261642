import * as React from 'react';
import { IAdFoxBannerOnErrorError } from '@cian/adfox-component';
import { GenericError } from '@cian/peperrors/shared';
import { useSelector } from 'react-redux';
import { trackBuilderLinkEvent } from './tracking';

import { getNewbuildingId } from '../../selectors/newbuilding';

import { AdfoxContainer } from '../Adfox';
import { useApplicationContext } from '../../utils/applicationContext';
import { selectBuilderLinkExperimentActive } from '../../selectors/experiments';

export function BuilderLinkContainer() {
  const { logger } = useApplicationContext();

  const newbuildingId = useSelector(getNewbuildingId);
  const active = useSelector(selectBuilderLinkExperimentActive);

  const [hidden, setHidden] = React.useState(false);

  const handleClick = React.useCallback(() => {
    trackBuilderLinkEvent({
      newbuildingId,
      action: 'Click',
    });
  }, [newbuildingId]);

  const handleLoad = React.useCallback(() => {
    trackBuilderLinkEvent({
      newbuildingId,
      action: 'Show',
    });
  }, [newbuildingId]);

  const handleStub = React.useCallback(() => {
    setHidden(true);
  }, [setHidden]);

  const handleError = React.useCallback(
    (error: IAdFoxBannerOnErrorError) => {
      setHidden(true);

      logger.error(
        new GenericError({
          domain: 'AdfoxBuilderLink',
          type: 'Adfox',
          message: error.type,
          details: { newbuildingId },
        }),
      );
    },
    [setHidden, logger, newbuildingId],
  );

  if (!active || hidden) {
    return null;
  }

  return (
    <div onClick={handleClick}>
      <AdfoxContainer
        params={{ p1: 'czyqz', p2: 'iqzd', puid40: String(newbuildingId) }}
        minWidth="1px"
        minHeight="1px"
        onStub={handleStub}
        onLoad={handleLoad}
        onError={handleError}
        lazyLoad={{
          fetchMargin: 50,
          mobileScaling: 1,
        }}
      />
    </div>
  );
}
