import { EFlatViewOrderActionTypes } from '../../actions/flatViewOrder';
import { IFlatViewOrder } from '../../types/flatViewOrder';
import { TReduxActions } from '../../types/redux';

const DEFAULT_FLAT_VIEW_ORDER_STATE = {
  showTooltip: false,
};

export function flatViewOrderReducer(state: IFlatViewOrder = DEFAULT_FLAT_VIEW_ORDER_STATE, action: TReduxActions) {
  switch (action.type) {
    case EFlatViewOrderActionTypes.ToggleBanner:
      return { showTooltip: action.payload };
    default:
      return state;
  }
}
