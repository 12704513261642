import { IApplicationState } from '../../types/redux';

export function getFirstBuilder(state: IApplicationState) {
  const {
    newbuilding: { builders },
  } = state;

  if (builders && builders[0]) {
    return builders[0];
  }

  return null;
}
