import * as React from 'react';
import { Label } from '@cian/ui-kit';
import { ILabel } from '../../types/newbuilding/reviews';

export interface IReviewLabelsProps {
  labels: ILabel[];
}

export const ReviewLabels: React.FC<IReviewLabelsProps> = ({ labels }) => {
  return <Label background={labels[0].color}>{labels[0].text}</Label>;
};
