import { ESizeType, IMedia, TFirstPaintOptions } from '@cian/frontend-newbuilding-carousel-component';

export const PHOTO_CAROUSEL_MEDIA_CONFIG: IMedia[] = [
  {
    minWidth: 1,
    maxWidth: Infinity,
    settings: {
      draggable: false,
      elementsWidth: {
        leftEar: {
          width: 0,
          type: ESizeType.PIXEL,
        },
        rightEar: {
          width: 0,
          type: ESizeType.PIXEL,
        },
        content: {
          width: 100,
          type: ESizeType.PERCENT,
        },
        gap: {
          width: 0,
          type: ESizeType.PIXEL,
        },
      },
      height: {
        value: 100,
        type: ESizeType.PERCENT,
      },
      slidesToShow: 1,
      slidesToScroll: 1,
      defaultControls: [],
    },
  },
];

export const PHOTO_CAROUSEL_FIRST_PAINT_OPTIONS: TFirstPaintOptions = {
  defaultControls: [],
  draggable: false,
  elementsWidth: {
    leftEar: {
      width: 0,
      type: ESizeType.PIXEL,
    },
    rightEar: {
      width: 0,
      type: ESizeType.PIXEL,
    },
    content: {
      width: 100,
      type: ESizeType.PERCENT,
    },
    gap: {
      width: 0,
      type: ESizeType.PIXEL,
    },
  },
  height: {
    value: 100,
    type: ESizeType.PERCENT,
  },
  slidesToShow: 1,
};
