import * as React from 'react';

import { VerticalSlider } from '../VerticalSlider';
import { HouseItem } from './components/HouseItem';

import * as styles from './styles.css';
import { IHouse } from '../../types/newbuilding/house';

interface IHousesFilterProps {
  selectedHouseId: number | undefined;
  houses: IHouse[];
  onHouseClick(houseId: number | undefined): void;
}

export function HousesFilter({ selectedHouseId, houses, onHouseClick }: IHousesFilterProps) {
  const handleHouseClick = React.useCallback(
    (i: number) => {
      let houseId: number | undefined;

      if (i > 0) {
        houseId = houses[i - 1].houseId;
      }

      onHouseClick(houseId);
    },
    [houses, onHouseClick],
  );

  return (
    <div className={styles['container']}>
      <VerticalSlider onItemClick={handleHouseClick}>
        <HouseItem active={selectedHouseId === undefined} title="Все корпуса" />
        {houses.map(({ houseId, houseName, finishStatus, hasProblems, isEscrow, isSalesStart }) => (
          <HouseItem
            key={houseId}
            active={selectedHouseId === houseId}
            title={houseName}
            finishStatus={finishStatus}
            hasProblems={hasProblems}
            isEscrow={isEscrow}
            isSalesStart={isSalesStart}
          />
        ))}
      </VerticalSlider>
    </div>
  );
}
