import * as React from 'react';
import * as style from './ReviewItem.css';

export interface IReviewItemProps {
  author: React.ReactElement;
  comment: React.ReactElement;
  label: React.ReactElement | false;
  answers: React.ReactElement | false;
  readFullReviewLink: React.ReactElement;
  reactions: React.ReactElement;
}

export const ReviewItem: React.FC<IReviewItemProps> = ({
  author,
  comment,
  label,
  answers,
  readFullReviewLink,
  reactions,
}) => {
  return (
    <div className={style['wrapper']} data-testid="ReviewItem">
      <div className={style['header']}>
        {author}
        {label}
      </div>

      <div className={style['content']}>{comment}</div>
      <div className={style['link']}>{readFullReviewLink}</div>

      <div className={style['footer']}>
        {reactions}
        {answers}
      </div>
    </div>
  );
};
