import * as React from 'react';
import classNames from 'clsx';
import { Button } from '@cian/ui-kit';

import * as styles from './SelectionsButton.css';

export interface ISelectionsButtonProps {
  onClick: () => void;
  href: string;
  XXL?: boolean;
  secondary?: boolean;
  fullWidth?: boolean;
}

export function SelectionsButton(props: ISelectionsButtonProps) {
  const { onClick, href, XXL = false, secondary = false, fullWidth = false } = props;

  return (
    <a
      href={href}
      title={'Подобрать другой ЖК'}
      className={classNames(styles['button'], fullWidth && styles['fullWidth'])}
      data-testid="SelectionsButton"
    >
      <Button
        onClick={onClick}
        theme={secondary ? 'fill_secondary' : 'fill_primary'}
        size={XXL ? 'M' : 'XS'}
        fullWidth={fullWidth}
      >
        Подобрать другой ЖК
      </Button>
    </a>
  );
}
