import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum ENewbuildingChatActionTypes {
  ToggleOpen = 'newbuildingChat/toggleOpen',
}

export type TNewbuildingChatActions = ITypedReduxAction<ENewbuildingChatActionTypes.ToggleOpen, boolean>;

export const chatToggleOpen = actionGenerator<ENewbuildingChatActionTypes.ToggleOpen, boolean>(
  ENewbuildingChatActionTypes.ToggleOpen,
);
