import * as React from 'react';

// tslint:disable:max-line-length
export const NoImageIcon = () => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg xmlns="http://www.w3.org/2000/svg" width="107" height="103">
      <g fill="#E4E4E4" fillRule="evenodd">
        <path d="M71.928 63.7l16.509 3.772c.92.21 1.83-.386 2.035-1.33l10.38-47.85c.205-.944-.375-1.88-1.295-2.09l-43.29-9.891c-.92-.21-1.832.385-2.037 1.329l-2.063 9.511-5.526 1.308 3.707-17.088c.204-.944 1.115-1.539 2.035-1.329l53.281 12.175c.921.21 1.5 1.145 1.295 2.089l-14.087 64.94c-.205.944-1.115 1.54-2.035 1.33l-15.935-3.642L71.928 63.7z" />
        <path d="M73 34.306c2.62.63 5.325-.45 6.178-3.106.897-2.802-.796-5.153-3.274-5.849-2.866-.807-5.433.538-6.28 3.056-.822 2.444.75 5.141 3.376 5.899m-4.803 15.359c-.455-1.788-.87-3.407-1.28-5.027-.88-3.471-1.783-6.939-2.628-10.418-.394-1.624-.915-3.23-.83-4.931.165-3.248 1.612-5.875 4.35-7.8 2.197-1.543 4.73-2.252 7.525-1.974 4.029.403 7.006 2.319 8.881 5.662 1.148 2.047 1.468 4.232.97 6.538-.523 2.42-1.796 4.354-3.706 6.007-2.302 1.994-4.528 4.066-6.79 6.103-2.112 1.904-4.228 3.803-6.492 5.84m-53.322 51.14L.067 35.406c-.323-1.423.553-2.85 1.95-3.177l54.066-12.702c.193-.045.389-.068.586-.068 1.218 0 2.258.846 2.534 2.055L74.01 86.912a2.68 2.68 0 0 1-.329 2 2.597 2.597 0 0 1-1.621 1.177L17.994 102.79c-.192.044-.39.067-.586.067l-2.533-2.052zm49.194-22.433c.933-.219 1.515-1.167 1.3-2.118L54.458 28.066c-.215-.95-1.147-1.544-2.08-1.325L8.45 37.061c-.932.22-1.515 1.167-1.3 2.12l10.91 48.185c.215.952 1.146 1.544 2.079 1.325l43.929-10.32z" />
        <path d="M32.302 44.418c2.946 1.81 5.935 3.644 9.12 5.598-.145-1.326-.49-2.388-.725-3.468-.231-1.07-.494-2.132-.756-3.25l7.034-1.6c.1.356.2.661.272.97.48 2.032.94 4.07 1.436 6.097.512 2.084 1.07 4.155 1.58 6.238.112.454.379.714.757.939 1.936 1.153 3.868 2.316 5.799 3.48.279.169.544.36.796.53-.182.713-4.265 7.31-5.671 9.184-.928-.44-1.774-1.056-2.664-1.587-.904-.542-1.795-1.104-2.692-1.656-.922-.57-1.846-1.14-2.768-1.707l-2.69-1.66-2.692-1.66c-.895-.55-1.79-1.101-2.755-1.697-.687.855-1.177 1.775-1.742 2.64-.572.879-1.123 1.769-1.685 2.654l-1.73 2.729c-.56.885-1.12 1.77-1.682 2.654l-1.682 2.655-1.623 2.557-9.077-5.593 15.84-25.047" />
      </g>
    </svg>
  );
};
