import { validateEmail, validatePhone, validateRequiredText } from '../../utils/validators';
import { IValidationErrors, IUpcomingSaleRequest } from '../../types/upcomingSaleRequest';

export const prepareFormValues = (values: IUpcomingSaleRequest): IUpcomingSaleRequest => {
  const { email, phone, userName } = values;

  return {
    email: email && email.trim(),
    phone: phone && `+7${phone.replace(/[^\d]/g, '')}`,
    userName: userName && userName.trim(),
  };
};

export const validateFormValues = (values: IUpcomingSaleRequest): IValidationErrors => {
  const { email, phone, userName } = values;

  return {
    email: (email && !validateEmail(email) && 'Введите корректный email') || undefined,
    phone:
      (!validateRequiredText(phone) && 'Введите телефон') ||
      (!validatePhone(phone) && 'Введите корректный телефон') ||
      undefined,
    userName: validateRequiredText(userName) ? undefined : 'Введите имя',
  };
};
