import { ca } from '@cian/analytics';
import { ICallbackParams } from '@cian/newbuilding-flat-view-order-component';

export function trackFlatViewOrderPopupOpen() {
  ca('eventSite', {
    action: 'Click',
    category: 'Card_JK',
    label: 'personal_tour_floatingBlock',
    name: 'oldevent',
    useLastProducts: true,
  });
}

export function trackFlatViewOrderSuccess(params: ICallbackParams) {
  const { date, time, phone, name } = params;

  ca('eventSite', {
    action: 'Success',
    category: 'PersonalTour',
    label: 'Submit',
    name: 'oldevent',
    page: {
      extra: {
        date,
        name,
        PageTypeJK: 'Normal',
        phone,
        time,
      },
      pageType: 'CardJK',
      siteType: 'desktop',
    },
    useLastProducts: true,
  });
}
