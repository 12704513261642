import * as React from 'react';
import { EPageTypes, EPlatformTypes } from '@cian/frontend-dynamic-calltracking-component';
import { SimpleCall } from '@cian/frontend-newbuilding-call-component';
import { useSelector } from 'react-redux';
import { BuilderOffersNotAvailableBlock } from '../../components/BuilderOffersNotAvailableBlock';
import { getBuilders, getIsCPL, getNewbuildingId, getSellers } from '../../selectors/newbuilding';
import { setUserOpenedPhoneInNewobject } from '../../utils/businessLogic/lastHopePopup';
import { useApplicationContext } from '../../utils/applicationContext';
import { OffersKPNRowContainer } from '../OffersKPNRow';

const CALL_BUTTON_TEXT = 'Контакты застройщика';

export const BuilderOffersNotAvailable = () => {
  const { httpApi, logger } = useApplicationContext();

  const isCPL = useSelector(getIsCPL);

  const builders = useSelector(getBuilders);
  const sellers = useSelector(getSellers);
  const newbuildingId = useSelector(getNewbuildingId);

  const handleShowPhone = React.useCallback(() => {
    setUserOpenedPhoneInNewobject(newbuildingId);
  }, [newbuildingId]);

  if (!isCPL) {
    return (
      <OffersKPNRowContainer
        title={<span>Нет предложений от застройщика. Мы подберём квартиры в&nbsp;похожих&nbsp;ЖК</span>}
        withMarginTop
      />
    );
  }

  return (
    <BuilderOffersNotAvailableBlock
      contactButton={
        <SimpleCall
          builders={builders}
          sellers={sellers}
          openContactsStyles={{ size: 'XS' as const }}
          showPhoneStyles={{ size: 'XS' as const }}
          httpApi={httpApi}
          logger={logger}
          onShowPhoneClick={handleShowPhone}
          pageType={EPageTypes.NewbuildingCard}
          platformType={EPlatformTypes.WebDesktop}
          placeType="blockBuilderCallReasons"
          singlePhoneButtonText={CALL_BUTTON_TEXT}
          multiplePhoneButtonText={CALL_BUTTON_TEXT}
          withoutModal={true}
        />
      }
    />
  );
};
