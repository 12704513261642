import * as React from 'react';
import { useSelector } from 'react-redux';

import { MasterReview } from '../../components/MasterReview';
import { MasterReviewLegacy } from '../../components/MasterReviewLegacy';
import { getMlReviews } from '../../selectors/newbuilding/getMlReviews';

export const MasterReviewContainer = () => {
  const masterReview = useSelector(getMlReviews);

  if (!masterReview) {
    return null;
  }

  if (masterReview.pluses && masterReview.minuses) {
    return (
      <MasterReview
        title={masterReview.title}
        subtitle={masterReview.subtitle}
        pros={masterReview.pluses}
        cons={masterReview.minuses}
      />
    );
  }

  if (masterReview.legacy) {
    return (
      <MasterReviewLegacy title={masterReview.title} subtitle={masterReview.subtitle} text={masterReview.legacy} />
    );
  }

  return null;
};
