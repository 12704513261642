import { connect } from 'react-redux';
import { IApplicationState } from '../../types/redux';
import { Header, IHeaderProps } from '../../components/Header';
import { getNewbuildingDisplayName } from '../../selectors/newbuilding';

type THeaderStateProps = Pick<IHeaderProps, 'name'>;

export function mapStateToProps(state: IApplicationState): THeaderStateProps {
  return {
    name: getNewbuildingDisplayName(state),
  };
}

export const HeaderContainer = connect(mapStateToProps)(Header);
