import * as React from 'react';
import { useSelector } from 'react-redux';
import { Escrow } from '../../components/Escrow';
import { getIsEscrow } from '../../selectors/newbuilding';
import { EscrowDescriptionContainer } from '../EscrowDescription';
import { EscrowHousesContainer } from '../EscrowHouses';

export const EscrowContainer = () => {
  const isEscrow = useSelector(getIsEscrow);

  return isEscrow ? <Escrow description={<EscrowDescriptionContainer />} houses={<EscrowHousesContainer />} /> : null;
};

EscrowContainer.displayName = 'EscrowContainer';
