import * as React from 'react';
import { ArticleHeading4, UIText1 } from '@cian/ui-kit';
import { IMedia, IUnderground } from '../../../../types/recommendations';
import { Underground } from '../../../Underground';
import { NoImage } from '../NoImage';
import { PhotoCarousel } from '../PhotoCarousel';
import { PhotoLabel } from '../PhotoLabel';
import * as styles from './NewbuildingCard.css';

export interface INewbuildingCardProps {
  address: string;
  forOffer: string | null;
  fromPrice: string | null;
  label: string | null;
  media: IMedia[];
  name: string;
  underground: IUnderground | null;
  url: string;
}

export const NewbuildingCard: React.FC<INewbuildingCardProps> = ({
  address,
  forOffer,
  fromPrice,
  label,
  name,
  media,
  underground,
  url,
}) => {
  const isPriceVisible = Boolean(forOffer && fromPrice);
  const displayName = `ЖК «${name}»`;

  return (
    <a className={styles['container']} href={url} target="_blank" rel="noopener noreferrer" aria-label={displayName}>
      <div className={styles['photo-slider']}>
        {media.length ? <PhotoCarousel media={media} /> : <NoImage />}

        {label && (
          <div className={styles['photo-label']}>
            <PhotoLabel label={label} />
          </div>
        )}
      </div>
      <div className={styles['content']}>
        <div className={styles['content-block']}>
          <div className={styles['content-row']}>
            <ArticleHeading4 as="h3">
              <span className={styles['nowrap-text']}>{displayName}</span>
            </ArticleHeading4>
          </div>
          {isPriceVisible && (
            <div className={styles['content-row']} data-testid="recommended-price">
              <UIText1 display="inline" fontWeight="bold">
                {fromPrice}
              </UIText1>
              <UIText1 display="inline" color="gray60_100">
                {' '}
                {forOffer}
              </UIText1>
            </div>
          )}
        </div>
        <div className={styles['content-block']}>
          {underground && (
            <div className={styles['content-row']}>
              <Underground
                lineColor={underground.lineColor}
                name={underground.undergroundName}
                timeTo={underground.distance}
                regionId={underground.regionId}
                transportType={underground.transportType}
              />
            </div>
          )}
          <div className={styles['content-row']}>
            <UIText1 color="gray60_100">{address}</UIText1>
          </div>
        </div>
      </div>
    </a>
  );
};
