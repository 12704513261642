import * as React from 'react';
import { useSelector } from 'react-redux';
import { getSpecialPromo } from '../../selectors/newbuilding';
import { SpecialPromoLink } from '../../components/SpecialPromoLink';
import { trackSpecialPromoLinkClick } from './tracking';

export function SpecialPromoLinkContainer() {
  const specialPromo = useSelector(getSpecialPromo);

  if (!specialPromo) {
    return null;
  }

  const { showNewbuildingsLabel, showNewbuildingsUrl, imageUrl } = specialPromo;

  if (!showNewbuildingsUrl || !showNewbuildingsLabel) {
    return null;
  }

  return (
    <SpecialPromoLink
      url={showNewbuildingsUrl}
      label={showNewbuildingsLabel}
      logoUrl={imageUrl}
      trackSpecialPromoClick={trackSpecialPromoLinkClick(specialPromo)}
    />
  );
}
