import * as React from 'react';

import { IconChevronRightSmall16 } from '@cian/ui-kit';
import * as styles from './OffersStatsItem.css';

import { Link } from '../Link';

interface IOffersStatsItem {
  roomType: string;
  totalAreaMinDisplay: string;
  price2Range: string;
  offersUrl: string;
  fromDeveloperPropsCount: number;
}
export const OffersStatsItem = (props: IOffersStatsItem) => {
  return (
    <div className={styles['offer']}>
      <div className={styles['room']}>
        <div>{props.roomType}</div>
      </div>
      <div className={styles['description']}>
        <div className={styles['area']}>{props.totalAreaMinDisplay}</div>
        <div className={styles['price']}>{props.price2Range}</div>
        <div className={styles['offers']}>
          <Link target="blank" url={props.offersUrl}>
            <div className={styles['link']}>
              <div className={styles['props-count']}>{props.fromDeveloperPropsCount}</div>
              <IconChevronRightSmall16 color="primary_100" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
