import * as React from 'react';
import { IconButton } from '@cian/frontend-reviews-components';
import { IconDislike16, IconLike16 } from '@cian/ui-kit';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-restricted-paths
import { setReviewReaction } from '../../../browser/services/reviews/setReviewReaction';
import { requestAuthentication } from '../../actions/authentication';
import { ReviewsReactions } from '../../components/ReviewsReactions';
import { getIsAuthenticated } from '../../selectors/user';
import { ILike } from '../../types/newbuilding/reviews';

export interface IReviewsReactionsContainerProps {
  reviewId: string;
  reactions: ILike;
}

export const ReviewsReactionsContainer: React.FC<IReviewsReactionsContainerProps> = ({ reviewId, reactions }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const setAnswerLike = React.useCallback(() => setReviewReaction({ reviewId, type: 'liked' }), []);
  const setAnswerDislike = React.useCallback(() => setReviewReaction({ reviewId, type: 'disliked' }), []);

  const onLikeClick = React.useCallback(() => {
    if (isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(setAnswerLike() as any);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(requestAuthentication({ onSuccess: setAnswerLike }) as any);
    }
  }, [dispatch, isAuthenticated, setAnswerLike, requestAuthentication]);

  const onDislikedClick = React.useCallback(() => {
    if (isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(setAnswerDislike() as any);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(requestAuthentication({ onSuccess: setAnswerDislike }) as any);
    }
  }, [dispatch, isAuthenticated, setAnswerDislike, requestAuthentication]);

  return (
    <ReviewsReactions
      like={
        <IconButton
          Icon={IconLike16}
          active={reactions.status === 'liked'}
          onClick={onLikeClick}
          label={reactions.likesCount}
        />
      }
      dislike={
        <IconButton
          Icon={IconDislike16}
          active={reactions.status === 'disliked'}
          onClick={onDislikedClick}
          label={reactions.dislikesCount}
        />
      }
    />
  );
};
