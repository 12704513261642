import * as React from 'react';
import { EPageTypes, EPlatformTypes } from '@cian/frontend-dynamic-calltracking-component';
import {
  SimpleCall,
  ISimpleCallProps,
  prepareSimpleCallPropsByRealtyUserId,
} from '@cian/frontend-newbuilding-call-component';
import { useSelector } from 'react-redux';

import { SinglePhoneComponent as SinglePhone } from '../../components/SinglePhoneComponent';
import { getBuilders, getNewbuildingId, getSellers } from '../../selectors/newbuilding';
import { hasValidPhone } from '../../utils/agent';
import { useApplicationContext } from '../../utils/applicationContext';
import { setUserOpenedPhoneInNewobject } from '../../utils/businessLogic/lastHopePopup';

export interface IShowPhoneParams {
  realtyUserId: number;
  phone: string;
}

type TSimpleCallProps = Pick<ISimpleCallProps, 'onOpenContactsClick' | 'openContactsStyles' | 'showPhoneStyles'>;

export interface ICallButtonContainerProps extends TSimpleCallProps {
  onlyBuilderPhone?: boolean;
  onShowPhoneClick?(params: IShowPhoneParams): void;
  placeType: string;
  realtyUserIdToCall?: number | null;
  singlePhoneButtonText?: string;
  SinglePhoneComponent?: React.ComponentType<{
    phone: string;
    realtyUserId: number;
    siteBlockId: number | null;
  }> | null;
}

export const CallButtonContainer: React.FC<ICallButtonContainerProps> = ({
  placeType,
  onlyBuilderPhone,
  onShowPhoneClick,
  onOpenContactsClick,
  openContactsStyles,
  realtyUserIdToCall,
  showPhoneStyles,
  singlePhoneButtonText,
  SinglePhoneComponent = SinglePhone,
}) => {
  const { httpApi, logger } = useApplicationContext();
  const builders = useSelector(getBuilders);
  const sellers = useSelector(getSellers);
  const newbuildingId = useSelector(getNewbuildingId);

  const displayBuilders = React.useMemo(() => {
    const buildersWithPhones = builders.filter(hasValidPhone);

    return onlyBuilderPhone ? buildersWithPhones.slice(0, 1) : buildersWithPhones;
  }, [builders, onlyBuilderPhone]);

  const displaySellers = React.useMemo(() => {
    return onlyBuilderPhone ? [] : sellers.filter(hasValidPhone);
  }, [sellers, onlyBuilderPhone]);

  const displayAgentsProps = React.useMemo(() => {
    return typeof realtyUserIdToCall === 'number'
      ? prepareSimpleCallPropsByRealtyUserId(realtyUserIdToCall, displayBuilders, displaySellers)
      : { builders: displayBuilders, sellers: displaySellers, singlePhoneButtonText };
  }, [displayBuilders, displaySellers, realtyUserIdToCall, singlePhoneButtonText]);

  const handleShowPhoneClick = React.useCallback(
    (params: IShowPhoneParams) => {
      setUserOpenedPhoneInNewobject(newbuildingId);

      if (onShowPhoneClick) {
        onShowPhoneClick(params);
      }
    },
    [newbuildingId, onShowPhoneClick],
  );

  const pageType = EPageTypes.NewbuildingCard;
  const platformType = EPlatformTypes.WebDesktop;
  const contactsCount = displayAgentsProps.builders.length + displayAgentsProps.sellers.length;

  if (!contactsCount) {
    return null;
  }

  return (
    <div data-testid={`CallButtonBlock-${placeType}`}>
      <SimpleCall
        {...displayAgentsProps}
        SinglePhoneComponent={SinglePhoneComponent || undefined}
        onOpenContactsClick={onOpenContactsClick}
        onShowPhoneClick={handleShowPhoneClick}
        openContactsStyles={openContactsStyles}
        showPhoneStyles={showPhoneStyles}
        httpApi={httpApi}
        logger={logger}
        pageType={pageType}
        platformType={platformType}
        placeType={placeType}
      />
    </div>
  );
};
