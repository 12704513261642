import { ca } from '@cian/analytics';

import { IBrokerAnalyticsUser } from '../../../types/newbuildingBroker';

export const trackBannerClick = (user: IBrokerAnalyticsUser) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'CardJK',
    action: 'Click',
    label: 'BrokerDealBanner',
    user,
  });
};
