import { ca } from '@cian/analytics';

export function trackShowSimilarCollections() {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'Card_JK',
    label: 'similar_collections',
    name: 'oldevent',
    useLastProducts: true,
  });
}
