import { ca } from '@cian/analytics';
import { IAnalyticsUser, IProduct } from '../../types/analytics';
import { TLikeStatus } from '../../types/newbuilding/reviews';

export type TAction = 'Click_sopr' | 'Show_sopr';
export type TCategory = 'Card_JK' | 'NewbuildingReviews';
export type TLabel =
  | 'Rating'
  | 'NewbuildingReviews'
  | 'All_Reviews'
  | 'Write'
  | 'Like'
  | 'Dislike'
  | 'Undo_Like'
  | 'Undo_Dislike'
  | 'read_more';

export type TPage = {
  pageType: string;
  siteType: string;
  extra: {
    type: string;
    rating?: number | null;
    review_id?: string;
  };
};

interface IProps {
  action: TAction;
  category: TCategory;
  label: TLabel;
  user: IAnalyticsUser;
  page: TPage;
  products: IProduct[];
}

type TLabelMap = { [key in TLikeStatus]: { [key in TLikeStatus]: TLabel } };

export function getLabelFromState({ state, payload }: { state: TLikeStatus; payload: TLikeStatus }): TLabel {
  const map: TLabelMap = {
    disliked: {
      liked: 'Like',
      unknown: 'Undo_Dislike',
      disliked: 'Dislike',
    },
    liked: {
      disliked: 'Dislike',
      unknown: 'Undo_Like',
      liked: 'Like',
    },
    unknown: {
      disliked: 'Dislike',
      liked: 'Like',
      unknown: 'Like',
    },
  };

  return map[state][payload];
}

export function trackNewbuildingReviewsAnalytic({ action, category, label, user, page, products }: IProps) {
  ca('eventSite', {
    name: 'oldevent',
    useLastProducts: true,
    action,
    category,
    label,
    user,
    page,
    products,
  });
}
