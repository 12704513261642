import { ca } from '@cian/analytics';

export function trackConstructionProgressShow() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'FeaturesJK',
    action: 'Show_sopr',
    label: 'BuildingProgress',
    useLastProducts: true,
  });
}
