import { ca } from '@cian/analytics';

import { getNewbuildingProduct } from '../../utils/analytics';
import { IBank } from '../../types/newbuilding/bank';

export function trackAccreditedBanksToListingClick(bank: IBank, newbuildingId: number) {
  ca('eventSite', {
    action: 'click_accredited_banks',
    category: 'Promo',
    label: 'to_listingjk',
    name: 'oldevent',
    products: [
      getNewbuildingProduct({
        newbuildingId,
        extra: {
          bankId: bank.id,
          bank_name: bank.name,
        },
      }),
    ],
  });
}
