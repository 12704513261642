import { ca } from '@cian/analytics';
import { getGaLabel } from '../../selectors/analytics/getGaLabel';
import { TThunkAction } from '../../types/redux/thunk';

export function trackShowPhone(action: string = 'Open_jk_card'): TThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const gaLabel = getGaLabel(state);

    ca('eventSite', {
      action,
      category: 'Phones',
      label: gaLabel,
      name: 'oldevent',
      useLastProducts: true,
    });
  };
}

export function trackOpenContacts(): TThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const gaLabel = getGaLabel(state);

    ca('eventSite', {
      action: 'open_contacts',
      category: 'CardJK',
      label: gaLabel,
      name: 'oldevent',
      useLastProducts: true,
    });
  };
}
