import { IFlatTourBooking } from '../../types/flatTourBooking';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EFlatTourBookingActionTypes {
  SetScheduleData = 'flatTourBooking/setScheduleData',
}

export type TSetScheduleData = ITypedReduxAction<EFlatTourBookingActionTypes.SetScheduleData, IFlatTourBooking>;

export const setScheduleData = actionGenerator<EFlatTourBookingActionTypes.SetScheduleData, IFlatTourBooking>(
  EFlatTourBookingActionTypes.SetScheduleData,
);
