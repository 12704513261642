import * as React from 'react';
import { useSelector } from 'react-redux';
import { CallbackButton } from '../../components/CallbackButton';
import { getGaLabel, getUser } from '../../selectors/analytics';
import { getNewbuildingId, getNewbuildingName, getTimezone } from '../../selectors/newbuilding';
import { getAgentForCallback } from '../../selectors/newbuilding/agent';
import { getCallbackRecommendations } from '../../selectors/requestCallback';
import { getUserPhone } from '../../selectors/user';
import { TAgent } from '../../types/agent';
import { TRequestCallbackOpener } from '../../types/microfrontends/newbuildingCallbackWidget';
import { getPhoneString } from '../../utils/phone';
import { NewbuildingCallbackWidgetContext } from '../NewbuildingCallbackWidgetProvider';

export interface ICallMeBackContainerProps {
  placeType: TRequestCallbackOpener;
}

export function CallMeBackContainer({ placeType }: ICallMeBackContainerProps) {
  const [isLoading, setLoading] = React.useState(false);

  const agent = useSelector(getAgentForCallback);
  const gaLabel = useSelector(getGaLabel);
  const user = useSelector(getUser);
  const userPhone = useSelector(getUserPhone);
  const timezone = useSelector(getTimezone);
  const newbuildingName = useSelector(getNewbuildingName);
  const newbuildingId = useSelector(getNewbuildingId);
  const recommendations = useSelector(getCallbackRecommendations);

  const openWidget = React.useContext(NewbuildingCallbackWidgetContext);
  const callbackPhone = agent ? getPhoneString(agent.phone) : null;

  const handleCallBackPopupOpen = React.useCallback(async () => {
    if (!callbackPhone) {
      return;
    }

    setLoading(true);

    await openWidget({
      agentPhone: callbackPhone,
      recommendations,
      timezoneOffset: timezone,
      userPhoneNumber: userPhone,
      callbackButtonText: 'Заказать',
      builderRealtyUserId: (agent as TAgent).realtyUserId,
      newbuildingName,
      newbuildingId,
      trackingParams: {
        category: 'CardJK',
        opener: placeType,
        gaLabel,
        user,
      },
    });

    setLoading(false);
  }, [
    callbackPhone,
    recommendations,
    timezone,
    userPhone,
    agent,
    newbuildingName,
    newbuildingId,
    placeType,
    gaLabel,
    user,
    openWidget,
  ]);

  if (!agent || !callbackPhone) {
    return null;
  }

  return <CallbackButton onClick={handleCallBackPopupOpen} text="Заказ звонка" loading={isLoading} />;
}
