import * as React from 'react';
import { Faq16 } from '@cian/ui-kit/prev';

import * as styles from './priceRelevanceWarningInfo.css';

interface IPriceRelevanceWarningInfo {
  onMouseEnter(): void;
  onMouseLeave(): void;
}

export const PriceRelevanceWarningInfo = React.forwardRef(
  ({ onMouseEnter, onMouseLeave }: IPriceRelevanceWarningInfo, ref: React.Ref<HTMLDivElement>) => {
    return (
      <div className={styles['container']} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={ref}>
        <Faq16 color="primary_100" />
      </div>
    );
  },
);

PriceRelevanceWarningInfo.displayName = 'PriceRelevanceWarningInfo';
