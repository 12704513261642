import * as React from 'react';

import * as styles from './styles.css';
import { Section } from '../Section';
import { IPromosCollapsedButtonProps, PromosCollapsedBlock } from '../PromosCollapsedBlock';

export interface IPromos {
  banner: React.ReactElement;
  seoLink: React.ReactElement;
  Button: React.ComponentType<IPromosCollapsedButtonProps>;
  onChangeOpen(opened: boolean): void;
  title: string;
}

export function Promos({ banner, seoLink, title, children, Button, onChangeOpen }: React.PropsWithChildren<IPromos>) {
  return (
    <Section data-nav-tab="promoList/Акции" id="promoList" title={title}>
      {banner}
      <div className={styles['promos-list']}>
        <PromosCollapsedBlock Button={Button} onChangeOpen={onChangeOpen} control={seoLink}>
          {children}
        </PromosCollapsedBlock>
      </div>
    </Section>
  );
}
