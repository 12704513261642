import { IMicrofrontendManifest } from '@cian/mf-registry/shared';

import {
  TGetInfrastructureMicrofrontendResponse,
  fetchGetInfrastructureMicrofrontend,
} from '../../repositories/map-search-frontend/v1/get-infrastructure-microfrontend';
import { IApplicationContext } from '../../types/applicationContext';

const NEWBUILDING_CARD_PROFILE = 'newbuildingCard';

export async function getInfrastructureMicrofrontendManifest(
  { httpApi, logger }: IApplicationContext,
  newbuildingId: number,
): Promise<IMicrofrontendManifest | null> {
  try {
    const res: TGetInfrastructureMicrofrontendResponse = await fetchGetInfrastructureMicrofrontend({
      httpApi,
      parameters: {
        newbuildingId,
        profile: NEWBUILDING_CARD_PROFILE,
      },
    });

    return res.response.manifest;
  } catch (e) {
    logger.warning(e, {
      domain: 'getInfrastructureMicrofrontendManifest',
      message: 'Ошибка получения манифеста микрофронта инфраструктуры',
    });

    return null;
  }
}
