import { ca } from '@cian/analytics';

export function trackMortgageBannerClick() {
  ca('eventSite', {
    action: 'click_sopr',
    category: 'Promo',
    label: 'nb_card_morgage_banner',
    name: 'oldevent',
    useLastProducts: true,
  });
}
