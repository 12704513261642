import { ca } from '@cian/analytics';

// Добавление ЖК в избранное
export function trackingActionAddFromCardJG(gaLabel: string, newbuildingId: number): () => Promise<void> {
  return async () => {
    ca('eventSite', {
      name: 'oldevent',
      category: 'favorite',
      action: 'add_from_card_JK',
      label: gaLabel,
      oid: newbuildingId,
      useLastProducts: true,
    });
  };
}

// Удаление ЖК из избранного
export function trackingActionRemoveFromCardJK(gaLabel: string, newbuildingId: number): () => Promise<void> {
  return async () => {
    ca('eventSite', {
      name: 'oldevent',
      category: 'favorite',
      action: 'remove_from_card_JK',
      label: gaLabel,
      oid: newbuildingId,
      useLastProducts: true,
    });
  };
}
