import * as React from 'react';
import { Link } from '../../../Link';

interface ICianMortgageTextProps {
  cianMortgageUrl: string;
}

export const CianMortgageText: React.VFC<ICianMortgageTextProps> = ({ cianMortgageUrl }) => {
  const cianMortgageLinkElement = (
    <Link url={cianMortgageUrl} target="blank">
      Циан.Ипотеки
    </Link>
  );

  return <span>Воспользуйтесь предложениями от {cianMortgageLinkElement}</span>;
};
