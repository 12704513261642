import { SimpleCall } from '@cian/frontend-newbuilding-call-component';
import { EPageTypes, EPlatformTypes } from '@cian/frontend-dynamic-calltracking-component';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackShowPhone } from '../../actions/analytics';
import { SinglePhoneComponent } from '../../components/SinglePhoneComponent';
import { getSellers } from '../../selectors/newbuilding';
import { TThunkDispatch } from '../../types/redux/thunk';
import { useApplicationContext } from '../../utils/applicationContext';

interface ILeadFactoryCallButtonContainerProps {
  placeType: string;
  trackAction?: string;
  size: 'M' | 'XS';
  theme?: 'fill_primary' | 'fill_secondary';
}

export const LeadFactoryCallButtonContainer: React.FC<ILeadFactoryCallButtonContainerProps> = ({
  placeType,
  size,
  theme = 'fill_primary',
  trackAction = 'Open_jk_card_blockJK',
}) => {
  const sellers = useSelector(getSellers);
  const { httpApi, logger } = useApplicationContext();
  const dispatch: TThunkDispatch = useDispatch();

  const styles = { size, fullWidth: true, theme };

  const handleClick = React.useCallback(() => dispatch(trackShowPhone(trackAction)), []);

  return (
    <div data-testid={`LeadFactoryCallButtonBlock-${placeType}`}>
      <SimpleCall
        builders={[]}
        sellers={sellers}
        openContactsStyles={styles}
        showPhoneStyles={styles}
        httpApi={httpApi}
        logger={logger}
        onShowPhoneClick={handleClick}
        pageType={EPageTypes.NewbuildingCard}
        platformType={EPlatformTypes.WebDesktop}
        placeType={placeType}
        singlePhoneButtonText="Позвонить консультанту"
        multiplePhoneButtonText="Позвонить консультанту"
        withoutModal={true}
        SinglePhoneComponent={size === 'M' ? SinglePhoneComponent : undefined}
      />
    </div>
  );
};
