import * as React from 'react';
import { SeoMortgageLink as SeoMortgageLinkComponent } from '../../components/SeoMortgageLink';
import { useSelector } from 'react-redux';
import { mortgageSeoPageUrlSelector } from '../../selectors/seoPages';
import { trackMortgageOffersTabClick } from '../../analytics/promos';
import { EMortgageOffersTabs } from '../../types/mortgageOffers';

export const SeoMortgageLink = () => {
  const url = useSelector(mortgageSeoPageUrlSelector);

  const onClick = React.useCallback(() => {
    trackMortgageOffersTabClick(EMortgageOffersTabs.SeoUrl);
  }, []);

  return (
    <SeoMortgageLinkComponent
      url={url}
      onClick={onClick}
      title="Ипотечные предложения и аккредитованные банки"
      testId="seo_mortgage_link"
    />
  );
};
