import { ca } from '@cian/analytics';
import { getProductsData } from '../../selectors/analytics/builder';

export function trackAgentFlatsLinkClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'click_agentsFlats',
    label: 'all',
    useLastProducts: true,
  });
}

export function trackAllFlatsLinkClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'click_allFlats',
    label: 'all',
    useLastProducts: true,
  });
}

export function trackRoomTypeLinkClick(label: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'click_developersFlats',
    label,
    useLastProducts: true,
  });
}

export function trackOpenFlat(offerId: number, gaLabel: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'open_flat',
    label: gaLabel,
    page: {
      dealType: 'sale',
      objectType: 'flat',
      offerID: offerId,
      pageType: 'CardJK',
      siteType: 'desktop',
      extra: {
        PageTypeJK: 'Normal',
      },
    },
    products: [
      {
        id: offerId,
        offerType: 'offer',
        position: 1,
      },
    ],
    modelVersion: '1',
  });
}

export function trackOffersQuantityLinkClick(newbuildingId: number, roomType: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card_JK',
    action: 'Click',
    label: 'Flats',
    products: [getProductsData(newbuildingId, null, { room_num: roomType })],
  });
}
