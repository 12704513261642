import * as React from 'react';
import classNames from 'clsx';

import * as styles from './ImageSourcesButton.css';

type TButtonProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'type'
>;

export interface IImageSourcesButtonProps extends TButtonProps {
  opened: boolean;
  openedContent?: React.ReactNode;
  closedContent?: React.ReactNode;
  onClick(): void;
}

export class ImageSourcesButton extends React.Component<IImageSourcesButtonProps> {
  public static defaultProps = {
    openedContent: 'Свернуть',
    closedContent: 'Все источники',
  };

  public render() {
    const { opened, openedContent, closedContent, onClick, ...buttonProps } = this.props;

    return (
      <button
        {...buttonProps}
        type="button"
        className={classNames(styles['toggle-btn'], opened && styles['toggle-btn--close'], buttonProps.className)}
        onClick={onClick}
      >
        {opened ? openedContent : closedContent}
      </button>
    );
  }
}
