import { ca } from '@cian/analytics';

export function trackSelectionButtonClick(analyticsType?: string) {
  const page = analyticsType
    ? {
        page: {
          extra: {
            type: analyticsType,
          },
        },
      }
    : undefined;

  ca('eventSite', {
    action: 'click',
    category: 'CardJK',
    label: 'to_newbuilding_finder',
    name: 'oldevent',
    useLastProducts: true,
    ...page,
  });
}
