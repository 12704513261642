import { IPromoInfoSchema } from '../../../types/promo';

export function filterMortgageOffers(
  mortgageOffers: IPromoInfoSchema[],
  price: number,
  downPayment: number,
  loanTerm: number,
) {
  return mortgageOffers
    .filter(mortgageOffer => {
      const mortgageParams = mortgageOffer.mortgageParams || {};
      const { interestRate, maxCredit, maxLoanTerm, minInitialPaymentRate } = mortgageParams;

      if ((!interestRate && interestRate !== 0) || !maxCredit || !maxLoanTerm || !minInitialPaymentRate) {
        return true;
      }

      const credit = price - downPayment;
      const offerDownPayment = Math.floor(price * (minInitialPaymentRate / 100));

      return loanTerm <= maxLoanTerm && offerDownPayment <= downPayment && credit <= maxCredit;
    })
    .sort((firstMortgageOffer, secondMortgageOffer) => {
      const firstInterestRate = firstMortgageOffer?.mortgageParams?.interestRate;
      const secondInterestRate = secondMortgageOffer?.mortgageParams?.interestRate;

      if (firstInterestRate === 0) {
        return -1;
      }

      if (secondInterestRate === 0) {
        return 1;
      }

      if (!firstInterestRate) {
        return 1;
      }

      if (!secondInterestRate) {
        return -1;
      }

      return firstInterestRate - secondInterestRate;
    });
}
