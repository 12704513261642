import { connect } from 'react-redux';
import { Carousel, ICarouselProps } from '../../components/Carousel';
import { getMedia, getNewbuildingDisplayName } from '../../selectors/newbuilding';
import { IApplicationState } from '../../types/redux';

type ICarouselStateProps = Pick<ICarouselProps, 'media' | 'title'>;

export function mapStateToProps(state: IApplicationState): ICarouselStateProps {
  return {
    media: getMedia(state),
    title: getNewbuildingDisplayName(state),
  };
}

export const CarouselContainer = connect(mapStateToProps)(Carousel);
