import * as React from 'react';
import { ArticleHeading3, IconChevronRight16 } from '@cian/ui-kit';
import classNames from 'clsx';

import * as styles from './styles.css';

interface IAdvantageItemProps {
  title: string;
  imgUrl?: string;
}

export function AdvantageItem({ title, imgUrl }: IAdvantageItemProps) {
  const [hovered, setHovered] = React.useState(false);

  const changeHovered = React.useCallback(() => setHovered(!hovered), [hovered]);

  return (
    <div
      className={styles['container']}
      style={{ backgroundImage: `url(${imgUrl})` }}
      onMouseEnter={changeHovered}
      onMouseLeave={changeHovered}
    >
      <div className={styles['title']}>
        <ArticleHeading3 as="h3" color="white_100">
          {title}
        </ArticleHeading3>
      </div>
      <div className={classNames(styles['arrow'], hovered && styles['arrow-hovered'])}>
        <div className={styles['icon-container']}>
          <IconChevronRight16 color={'white_100'} />
        </div>
      </div>
    </div>
  );
}
