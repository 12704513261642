import * as styles from '../ItemsCarousel.css';
import { TScrollSize } from '../types';

const DEFAULT_SIZE: TScrollSize = 'XS';

const CLASSNAMES_BY_SIZE = new Map<TScrollSize, string>([
  ['M', styles['container--m']],
  ['XS', styles['container--xs']],
]);

export const getSizeClassName = (size?: TScrollSize): string => {
  return (size && CLASSNAMES_BY_SIZE.get(size)) || (CLASSNAMES_BY_SIZE.get(DEFAULT_SIZE) as string);
};
