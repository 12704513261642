import * as React from 'react';
import { StatItem } from './parts/StatItem';
import * as styles from './Stats.css';
import { IBuilderStats } from '../../types/newbuilding/builder';

interface IStatsProps {
  stats?: IBuilderStats | null;
}

export const Stats: React.FC<IStatsProps> = props => {
  const { stats } = props;

  if (!stats) {
    return null;
  }

  return (
    <div className={styles['wrapper']}>
      {stats.inProgress && (
        <StatItem statusLabel={'Строится '} qs={stats.inProgress.qs} text={stats.inProgress.housesText} />
      )}
      {stats.done && <StatItem statusLabel={'Сдано '} qs={stats.done.qs} text={stats.done.housesText} />}
    </div>
  );
};
