import { LinkButton } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackShowPhoneClick } from '../../../analytics/promos';
import { IPromoInfoSchema } from '../../../types/promo';
import { getGaLabel } from '../../../selectors/analytics';
import { getNewbuildingId } from '../../../selectors/newbuilding';
import { useApplicationContext } from '../../../utils/applicationContext';
import { getCianMortgagePromoUrl } from '../../../utils/config';
import { CallButtonContainer } from '../../CallButton';

export interface IActionButtonContainerProps {
  hasContacts: boolean;
  onMortgageClick?(): void;
  promo: IPromoInfoSchema;
}

export const ActionButtonContainer: React.FC<IActionButtonContainerProps> = ({
  hasContacts,
  onMortgageClick,
  promo,
}) => {
  const { config } = useApplicationContext();
  const cianMortgageLink = getCianMortgagePromoUrl(config);

  const gaLabel = useSelector(getGaLabel);
  const newbuildingId = useSelector(getNewbuildingId);

  const trackShowPhone = React.useCallback(() => {
    trackShowPhoneClick(gaLabel, promo, newbuildingId);
  }, [gaLabel, promo, newbuildingId]);

  const trackOpenContacts = React.useCallback(() => {
    trackShowPhoneClick(gaLabel, promo, newbuildingId);
  }, [gaLabel, promo, newbuildingId]);

  if (promo.promoType === 'cianMortgage') {
    return (
      <LinkButton href={cianMortgageLink} onClick={onMortgageClick} theme="fill_secondary" size="M" fullWidth>
        Получить ипотеку онлайн
      </LinkButton>
    );
  }

  if (!hasContacts) {
    return null;
  }

  return (
    <CallButtonContainer
      placeType="Promo"
      onOpenContactsClick={trackOpenContacts}
      onShowPhoneClick={trackShowPhone}
      openContactsStyles={{ size: 'M' as const, fullWidth: true }}
      showPhoneStyles={{ size: 'M' as const, fullWidth: true }}
      realtyUserIdToCall={promo.authorRealtyUserId}
    />
  );
};
