import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UpcomingSaleRequestForm } from '../../components/UpcomingSaleRequestForm';
import { UpcomingSaleSuccessModal } from '../../components/UpcomingSaleSuccessModal';
import { UpcomingSaleFailModal } from '../../components/UpcomingSaleFailModal';

// eslint-disable-next-line import/no-restricted-paths
import { createUpcomingSaleRequest } from '../../../browser/services/upcomingSaleRequest';
import { getAnalyticsUser, getGaLabel } from '../../selectors/analytics';
import { TThunkDispatch } from '../../types/redux/thunk';
import { IUpcomingSaleRequest } from '../../types/upcomingSaleRequest';
import { EUpcomingSaleTrackActions, trackUpcomingSaleRequest } from './tracking';

export function UpcomingSaleRequestFormContainer() {
  const [isOpenSuccessModal, setIsOpenSuccessModal] = React.useState(false);
  const [isOpenFailModal, setIsOpenFailModal] = React.useState(false);
  const gaLabel = useSelector(getGaLabel);
  const user = useSelector(getAnalyticsUser);
  const dispatch: TThunkDispatch = useDispatch();

  const handleSuccessModalClose = React.useCallback(() => setIsOpenSuccessModal(false), []);
  const handleFailModalClose = React.useCallback(() => setIsOpenFailModal(false), []);

  const handleSubmit = React.useCallback(
    async (params: IUpcomingSaleRequest) => {
      const trackingParams = {
        gaLabel,
        phone: params.phone,
        user,
        name: params.userName,
      };

      try {
        trackUpcomingSaleRequest({ ...trackingParams, action: EUpcomingSaleTrackActions.Send });
        await dispatch(createUpcomingSaleRequest(params));

        setIsOpenSuccessModal(true);
        trackUpcomingSaleRequest({ ...trackingParams, action: EUpcomingSaleTrackActions.Success });

        return true;
      } catch {
        setIsOpenFailModal(true);
        trackUpcomingSaleRequest({ ...trackingParams, action: EUpcomingSaleTrackActions.Fail });

        return false;
      }
    },
    [dispatch, gaLabel, user],
  );

  return (
    <>
      <UpcomingSaleRequestForm onSubmit={handleSubmit} />
      <UpcomingSaleSuccessModal open={isOpenSuccessModal} onClose={handleSuccessModalClose} />
      <UpcomingSaleFailModal open={isOpenFailModal} onClose={handleFailModalClose} />
    </>
  );
}
